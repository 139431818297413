import React from 'react';
import { connect } from 'react-redux';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import RelocationCargoItemForm from './RelocationCargoItemForm';

export const RelocationCargoItem = ({ breadcrumbs }) => (
  <Content
    breadcrumbs={breadcrumbs}
    title="Reubicar Encomienda"
    subtitle="Reubicar encomienda"
    content={<RelocationCargoItemForm />}
  />
);

RelocationCargoItem.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Reubicar Encomienda',
      href: '',
    },
  ],
});

export default connect(mapStateToProps)(RelocationCargoItem);
