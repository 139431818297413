import { array, number, shape, string } from 'prop-types';

const cabinCrewBasicInformationPropTypes = {
  customer: shape({
    id: number.isRequired,
    firstName: string.isRequired,
    lastName: string.isRequired,
    idDocumentNumber: string.isRequired,
  }).isRequired,
  cabinCrewType: shape({
    id: number.isRequired,
    name: string.isRequired,
  }).isRequired,
  location: shape({
    id: number.isRequired,
    name: string.isRequired,
  }).isRequired,
  crewStatus: shape({
    id: number.isRequired,
    name: string.isRequired,
  }).isRequired,
  cabinCrewDocumentList: array.isRequired,
  cabinCrewContractList: array.isRequired,
};

export default cabinCrewBasicInformationPropTypes;
