import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { optionsPropTypes } from './SelectPropTypes';
import Select from './Select';
import { clearDistricts, getDistricts } from '../../../../actions';

export const DistrictSelect = ({
  loading,
  options,
  isGridVariant,
  isMulti,
  isClearable,
  onValueChange,
  dispatchGetDistricts,
  dispatchClearDistricts,
  ...rest
}) => {
  useLayoutEffect(() => () => dispatchClearDistricts(), []);

  const onInputChange = (inputValue) => {
    if (inputValue.trim().length) {
      const query = [`name:${inputValue}`];
      dispatchGetDistricts({ query });
    }
  };

  const filterOption = (users) => users;

  return (
    <Select
      isLoading={loading}
      onInputChange={onInputChange}
      options={options}
      isMulti={isMulti}
      onValueChange={onValueChange}
      isClearable={isClearable}
      isGridVariant={isGridVariant}
      filterOption={filterOption}
      {...rest}
    />
  );
};

DistrictSelect.propTypes = {
  isGridVariant: PropTypes.bool,
  dispatchGetDistricts: PropTypes.func.isRequired,
  dispatchClearDistricts: PropTypes.func.isRequired,
  onValueChange: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  isMulti: PropTypes.bool,
  options: optionsPropTypes,
  isClearable: PropTypes.bool,
  placeholder: PropTypes.string,
};

DistrictSelect.defaultProps = {
  isGridVariant: false,
  isMulti: false,
  options: [],
  isClearable: false,
  onValueChange: undefined,
  placeholder: 'Seleccione...',
};

const mapStateToProps = ({ LocationUnit }) => ({
  options: LocationUnit.District.getIn(['all', 'content', 'content']).map(
    ({ id, name, city }) => ({
      value: id,
      label: `${name} - ${city ? city.name : ''} - ${
        city && city.region ? city.region.name : ''
      }`,
    }),
  ),
  loading: LocationUnit.District.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetDistricts: getDistricts,
  dispatchClearDistricts: clearDistricts,
};

export default connect(mapStateToProps, mapDispatchToProps)(DistrictSelect);
