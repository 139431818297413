import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from './Select';
import {
  inputPropTypes,
  metaPropTypes,
  inputDefaultProps,
  metaDefaultProps,
} from '../../forms/input/InputPropTypes';
import { DEFAULT_QUERY_GET_ALL } from '../../../../config/queries';
import { optionsPropTypes } from './SelectPropTypes';
import {
  clearCabinCrewDocumentTypes,
  getCabinCrewDocumentTypes,
} from '../../../../actions';

const CabinCrewDocumentTypeSelect = ({
  dispatchGetCabinCrewDocumentTypes,
  dispatchClearCabinCrewDocumentTypes,
  isMulti,
  input,
  options,
  meta,
  isLoading,
  cleanWhenUnmount,
}) => {
  useLayoutEffect(() => {
    dispatchGetCabinCrewDocumentTypes(DEFAULT_QUERY_GET_ALL);

    return () => {
      if (cleanWhenUnmount) {
        dispatchClearCabinCrewDocumentTypes();
      }
    };
  }, []);

  return (
    <Select
      options={options}
      isLoading={isLoading}
      isMulti={isMulti}
      input={input}
      meta={meta}
    />
  );
};

CabinCrewDocumentTypeSelect.propTypes = {
  dispatchGetCabinCrewDocumentTypes: PropTypes.func.isRequired,
  dispatchClearCabinCrewDocumentTypes: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  input: inputPropTypes,
  options: optionsPropTypes,
  meta: metaPropTypes,
  isLoading: PropTypes.bool,
  cleanWhenUnmount: PropTypes.bool,
};

CabinCrewDocumentTypeSelect.defaultProps = {
  isLoading: false,
  input: inputDefaultProps,
  meta: metaDefaultProps,
  isMulti: false,
  options: [],
  cleanWhenUnmount: true,
};

const mapStateToProps = ({ TrafficUnit }) => ({
  options: TrafficUnit.CabinCrewDocumentType.getIn([
    'all',
    'content',
    'content',
  ]).map((status) => ({
    value: status.id,
    label: status.name,
  })),
  loading: TrafficUnit.CabinCrewDocumentType.getIn(['all', 'loading']),
});

const mapDispatchToprops = {
  dispatchGetCabinCrewDocumentTypes: getCabinCrewDocumentTypes,
  dispatchClearCabinCrewDocumentTypes: clearCabinCrewDocumentTypes,
};

export default connect(
  mapStateToProps,
  mapDispatchToprops,
)(CabinCrewDocumentTypeSelect);
