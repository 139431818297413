import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import { SHELF_ENDPOINT } from '../../config/endpoints';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import { SHELF_PATH } from '../../config/paths';
import {
  CLEAR_SHELF,
  CLEAR_SHELVES,
  FLAG_GETTING_SHELVES,
  FLAG_SHELF_ACTIVITY,
  GET_SHELF,
  GET_SHELF_SPACE,
  GET_SHELVES,
} from '../types';

const flagGettingShelves = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_SHELVES,
    payload: flag,
  });

const getShelves = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingShelves(true));
    const query = tableFilters;
    const url = `${SHELF_ENDPOINT}?${QueryString.stringify(query)}`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(response, null, dispatch);
    const basePricesPerKilometer = await response.json();
    dispatch({
      type: GET_SHELVES,
      payload: basePricesPerKilometer,
    });
  } catch (error) {
    toastr.error('Error', error.message);
  } finally {
    dispatch(flagGettingShelves(false));
  }
};

const clearShelves = () => (dispatch) =>
  dispatch({
    type: CLEAR_SHELVES,
  });

const flagShelfActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_SHELF_ACTIVITY,
    payload: flag,
  });

const getShelf =
  async ({ shelfId }) =>
  async (dispatch) => {
    try {
      dispatch(flagShelfActivity(true));
      const url = `${SHELF_ENDPOINT}/${shelfId}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      await isErrorResponse(response, null, dispatch);
      const shelf = await response.json();
      dispatch({
        type: GET_SHELF,
        payload: shelf,
      });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagShelfActivity(false));
    }
  };

const clearShelf = () => (dispatch) =>
  dispatch({
    type: CLEAR_SHELF,
  });

const postShelf =
  async ({ name, width, height, locationId, shelfSpaceList }) =>
  async (dispatch) => {
    try {
      dispatch(flagShelfActivity(true));
      const payload = {
        name,
        locationId,
        width,
        height,
        shelfSpaceList,
      };
      const url = SHELF_ENDPOINT;
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      const shelf = await response.json();
      dispatch(push(`${SHELF_PATH}/${shelf.id}`));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagShelfActivity(false));
    }
  };

const putShelf =
  async (
    shelfId,
    { name, width, height, locationId, shelfSpaceList, active },
  ) =>
  async (dispatch) => {
    try {
      dispatch(flagShelfActivity(true));
      const payload = {
        id: shelfId,
        name,
        width,
        height,
        locationId,
        shelfSpaceList,
        active,
      };
      const url = `${SHELF_ENDPOINT}/${shelfId}`;
      const response = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      const shelf = await response.json();
      dispatch(push(`${SHELF_PATH}/${shelf.id}`));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagShelfActivity(false));
    }
  };

const getShelfByLocation =
  async (locationId, tableFilters) => async (dispatch) => {
    try {
      const query = tableFilters;
      dispatch(flagGettingShelves(true));
      const url = `${SHELF_ENDPOINT}/find-by-location/${locationId}?${QueryString.stringify(
        query,
      )}`;

      const response = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(response, null, dispatch);
      const shelves = await response.json();
      dispatch({
        type: GET_SHELVES,
        payload: shelves,
      });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagGettingShelves(false));
    }
  };

const getShelfSpace = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagShelfActivity(true));
    const url = `${SHELF_ENDPOINT}-space/find?${QueryString.stringify(
      tableFilters,
    )}`;
    const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
    await isErrorResponse(response, null, dispatch);
    const shelf = await response.json();
    dispatch({
      type: GET_SHELF_SPACE,
      payload: shelf,
    });
  } catch (error) {
    toastr.error('Error', error.message);
  } finally {
    dispatch(flagShelfActivity(false));
  }
};

export {
  flagGettingShelves,
  getShelves,
  clearShelves,
  getShelf,
  clearShelf,
  postShelf,
  putShelf,
  getShelfByLocation,
  getShelfSpace,
};
