import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import printExitGuide from '../../../../utils/printers/ExitGuide';
import withEndpointAuthorization from '../../authorization/withEndPointAuthorization';
import { INTERNAL_PARCEL_ENDPOINT } from '../../../../config/endpoints';
import { PUT } from '../../../../config/permissions';

const PrintExitGuideButton = ({ internalParcel }) => {
  const handlePrintExitGuide = () => {
    printExitGuide(internalParcel);
  };

  return (
    <Button
      color="success"
      className="border"
      onClick={handlePrintExitGuide}
      disabled={!internalParcel}
    >
      <i className="fa fa-print" /> Imprimir Guía de Salida
    </Button>
  );
};

PrintExitGuideButton.propTypes = {
  internalParcel: PropTypes.instanceOf(Object).isRequired,
};

export default withEndpointAuthorization({
  url: INTERNAL_PARCEL_ENDPOINT,
  permissionType: PUT,
})(PrintExitGuideButton);
