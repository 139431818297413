import React from 'react';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import deliveryBusinessBasicInformationPropTypes from '../proptypes/DeliveryBusinessPropTypes';
import IsActive from '../../../../common/cells/IsActive';
import Table from '../../../../common/Table';
import { DEFAULT_PAGE_SIZE } from '../../../../../config/constants';
import { filterMethodCaseInsensitive } from '../../../../../utils/filter-methods';
import {
  DELIVERY_BUSINESS_AGENCY_COLUMNS,
  DELIVERY_BUSINESS_USER_COLUMNS,
} from '../../../../../config/columns';

const DeliveryBusinessBasicInformationResource = ({
  id,
  business,
  agencyList,
  userList,
  active,
}) => (
  <>
    <ResourceProperty label="Id:">{id}</ResourceProperty>
    <ResourceProperty label="Empresa:">
      {`RUC: ${business.businessTaxId} - ${business.name}` || '-'}
    </ResourceProperty>
    <ResourceProperty label="Estado:">
      <IsActive value={active} />
    </ResourceProperty>
    <h3>Agencias</h3>
    <Table
      columns={DELIVERY_BUSINESS_AGENCY_COLUMNS}
      data={agencyList}
      defaultPageSize={
        agencyList.length <= 10 ? agencyList.length : DEFAULT_PAGE_SIZE
      }
      showPagination={agencyList.length > 10}
      manual={false}
      filterable
      defaultFilterMethod={filterMethodCaseInsensitive}
    />
    <h3>Usuarios</h3>
    <Table
      columns={DELIVERY_BUSINESS_USER_COLUMNS}
      data={userList}
      defaultPageSize={
        userList.length <= 10 ? userList.length : DEFAULT_PAGE_SIZE
      }
      showPagination={userList.length > 10}
      manual={false}
      filterable
      defaultFilterMethod={filterMethodCaseInsensitive}
    />
  </>
);

DeliveryBusinessBasicInformationResource.propTypes =
  deliveryBusinessBasicInformationPropTypes;

export default DeliveryBusinessBasicInformationResource;
