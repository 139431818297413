import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { DELIVERY_PRICE_CONFIGURATION_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import DeliveryPriceConfigurationForm from './DeliveryPriceConfigurationForm';
import {
  clearDeliveryPriceConfiguration,
  getDeliveryPriceConfiguration,
  putDeliveryPriceConfiguration,
} from '../../../../actions';

const EditDeliveryPriceConfiguration = ({
  breadcrumbs,
  dispatchGetDeliveryPriceConfiguration,
  dispatchClearDeliveryPriceConfiguration,
  dispatchPutDeliveryPriceConfiguration,
  match: {
    params: { id: deliveryPriceConfigurationId },
  },
  loading,
  deliveryPriceConfiguration,
}) => {
  useLayoutEffect(() => {
    dispatchGetDeliveryPriceConfiguration({ deliveryPriceConfigurationId });

    return () => dispatchClearDeliveryPriceConfiguration();
  }, []);

  const onSubmit = (formValues) =>
    dispatchPutDeliveryPriceConfiguration(deliveryPriceConfigurationId, {
      ...formValues,
    });

  const generateInitialValues = ({ minKilograms, maxKilograms }) => ({
    minKilograms,
    maxKilograms,
  });

  let content;

  if (loading) content = <Loader />;
  else if (deliveryPriceConfiguration.isEmpty())
    content = <NoDataResource returnPage={DELIVERY_PRICE_CONFIGURATION_PATH} />;
  else
    content = (
      <DeliveryPriceConfigurationForm
        onSubmit={onSubmit}
        initialValues={generateInitialValues(deliveryPriceConfiguration.toJS())}
      />
    );

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Editar Configuración de Precio de Reparto"
      subtitle="Editar configuración de precio de reparto"
      content={content}
    />
  );
};

EditDeliveryPriceConfiguration.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchClearDeliveryPriceConfiguration: PropTypes.func.isRequired,
  dispatchGetDeliveryPriceConfiguration: PropTypes.func.isRequired,
  dispatchPutDeliveryPriceConfiguration: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  deliveryPriceConfiguration: PropTypes.instanceOf(Immutable.Map).isRequired,
};

EditDeliveryPriceConfiguration.defaultProps = {
  loading: false,
};

const mapDispatchToProps = {
  dispatchClearDeliveryPriceConfiguration: clearDeliveryPriceConfiguration,
  dispatchGetDeliveryPriceConfiguration: getDeliveryPriceConfiguration,
  dispatchPutDeliveryPriceConfiguration: putDeliveryPriceConfiguration,
};

const mapStateToProps = (
  { CargoUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Configuraciones de Precio de Reparto',
      href: DELIVERY_PRICE_CONFIGURATION_PATH,
    },
    {
      text: 'Ver',
      href: `${DELIVERY_PRICE_CONFIGURATION_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  deliveryPriceConfiguration: CargoUnit.DeliveryPriceConfiguration.getIn([
    'current',
    'content',
  ]),
  loading: !CargoUnit.DeliveryPriceConfiguration.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditDeliveryPriceConfiguration);
