import React from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { patchStartDelivery } from '../../../../actions';

const DeliveryStartButton = ({ deliveryId, dispatchPatchStartDelivery }) => {
  const startDelivery = () => {
    dispatchPatchStartDelivery({ deliveryId });
  };

  return (
    <>
      <Button color="success" onClick={startDelivery}>
        <i className="fa fa-motorcycle" /> Empezar reparto
      </Button>
    </>
  );
};

DeliveryStartButton.propTypes = {
  dispatchPatchStartDelivery: PropTypes.func.isRequired,
  deliveryId: PropTypes.number.isRequired,
};

const mapDispatchToProps = {
  dispatchPatchStartDelivery: patchStartDelivery,
};

export default connect(null, mapDispatchToProps)(DeliveryStartButton);
