import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import ReactGa from 'react-ga';
import {
  POSTPAID_BOOKING_STEPS,
  POSTPAID_BOOKING_RECEIVER_STEP_INDEX,
  POSTPAID_BOOKING_SUMMARY_STEP_INDEX,
  POSTPAID_BOOKING_SEARCH_STEP_INDEX,
} from '../../config/constants';
import {
  POSTPAID_BOOKING_CHANGE_STEP,
  POSTPAID_BOOKING_FLAG_SEARCHING_FOR_ITINERARIES,
  POSTPAID_BOOKING_SEARCH_FOR_ITINERARIES,
  POSTPAID_BOOKING_FLAG_SEAT_SELECTION_CHANGE_IN_PROGRESS,
  POSTPAID_BOOKING_SELECT_SEAT,
  POSTPAID_BOOKING_DESELECT_SEAT,
  POSTPAID_BOOKING_SELECT_ITINERARY,
  POSTPAID_BOOKING_FLAG_POSTING_RESERVATION,
  POSTPAID_BOOKING_POST_RESERVATION_REQUESTS,
  POSTPAID_BOOKING_GET_ITINERARY_FOOD_GROUP,
  POSTPAID_BOOKING_FLAG_POSTING_PRETRANSACTION,
  POSTPAID_BOOKING_POST_PRETRANSACTION,
  POSTPAID_BOOKING_RESET_STEP,
  POSTPAID_BOOKING_FLAG_DELETING_CUSTOMER_ORDER,
  POSTPAID_BOOKING_FLAG_GETTING_ALL,
  POSTPAID_BOOKING_GET_ALL,
  POSTPAID_BOOKING_CLEAR_ALL,
  POSTPAID_BOOKING_FLAG_APPROVING,
  POSTPAID_BOOKING_FLAG_REJECTING,
  POSTPAID_BOOKINGS_REPLACE_ONE,
  FLAG_ADDING_VOUCHER_TO_POSTPAID_BOOKING,
  FLAG_GETTING_PRETRANSACTION_FOR_POSTPAID_BOOKING,
  POSTPAID_BOOKING_GET_PRETRANSACTION,
  FLAG_POSTPAID_BOOKING_RESERVATION_CREATED,
  FLAG_POSTING_FIX_PRICE_REQUEST_FOR_POSTPAID_BOOKING,
  SET_FIXED_PRICE_FOR_CURRENT_POSTPAID_BOOKING,
  POSTPAID_BOOKING_CLEAR_ITINERARY,
  POSTPAID_BOOKING_CLEAR_SEATS,
  POSTPAID_BOOKING_CLEAR_SELECT_ITINERARY,
} from '../types/booking/PostpaidBooking';
import {
  BASE_SECURE_URL,
  ITINERARY_ENDPOINT,
  POSTPAID_BOOKING_PRETRANSACTION_ENDPOINT,
  BUSINESS_ENDPOINT,
  CUSTOMER_ORDER_ENDPOINT,
  RESERVE_SEAT_ENDPOINT,
  POSTPAID_BOOKING_ENDPOINT,
  generateApprovePostpaidBookingEndpoint,
  generateRejectPostpaidBookingEndpoint,
  generateAddVoucherToPostpaidBookingEndpoint,
  TICKET_FIXED_PRICE_ENDPOINT,
  PAGOEFECTIVO_CIP_ENDPOINT,
  BASE_SECURE_URL_V2,
  NIUBIZ_LINK_ENDPOINT,
  OPENPAY_LINK_ENDPOINT,
} from '../../config/endpoints';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_DELETE_CONFIG,
  DEFAULT_PATCH_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import {
  deleteKidsOnItineraryRequest,
  flagKidsOnItineraryActivity,
  postKidsOnItineraryRequest,
} from '../itinerary/KidsOnItinerary';
import {
  DELETE_KIDS_ON_POSTPAID_ITINERARY,
  POST_KIDS_ON_POSTPAID_ITINERARY,
} from '../types';
import { tzNormalizeDate } from '../../utils/date';
import { TIMESTAMP_FORMAT } from '../../config/locale';

const flagPostpaidBookingReservationCreated = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_POSTPAID_BOOKING_RESERVATION_CREATED,
    payload: flag,
  });

const flagDeletingPostpaidBookingCustomerOrder = (flag) => (dispatch) =>
  dispatch({
    payload: flag,
    type: POSTPAID_BOOKING_FLAG_DELETING_CUSTOMER_ORDER,
  });

const deletePostpaidCustomerOrder =
  async (customerOrderId) => async (dispatch) => {
    try {
      dispatch(flagDeletingPostpaidBookingCustomerOrder(true));
      // delete seat reservation
      const url = `${CUSTOMER_ORDER_ENDPOINT}/${customerOrderId}`;
      const response = await fetch(url, DEFAULT_DELETE_CONFIG);

      // check if it is an error response
      await isErrorResponse(response, null, dispatch);
      await response.json();
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagDeletingPostpaidBookingCustomerOrder(false));
    }
  };

const flagChangingPostpaidBookingSeatSelection =
  ({ status, seatId }) =>
  (dispatch) =>
    dispatch({
      payload: { status, seatId },
      type: POSTPAID_BOOKING_FLAG_SEAT_SELECTION_CHANGE_IN_PROGRESS,
    });

const selectPostpaidBookingSeat =
  async ({
    sourceLocationId,
    destinationLocationId,
    itineraryId,
    seatId,
    specialPriceId,
  }) =>
  async (dispatch, getState) => {
    try {
      const payload = {
        sourceLocationId,
        destinationLocationId,
        itineraryId,
        seatId,
        callCenter: true,
        specialPriceId,
      };
      dispatch(
        flagChangingPostpaidBookingSeatSelection({
          status: 'start',
          seatId: `${itineraryId}-${seatId}`,
        }),
      );
      // post seat reservation
      const url = RESERVE_SEAT_ENDPOINT;
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });

      // will throw error if it contains one
      await isErrorResponse(response, null, dispatch);
      // parsing data into a usable format
      const seatReservation = await response.json();

      const itinerary = getState()
        .PostpaidBookingUnit.PostpaidBooking.getIn([
          'itineraries',
          'selectedItinerarySearchResults',
        ])
        .toJS();

      seatReservation.itinerary = {
        originName: itinerary[0].origin.name,
        destinationName: itinerary[0].destination.name,
        departureTime: itinerary[0].departureTime,
        arrivalTime: itinerary[0].arrivalTime,
        serviceName: itinerary[0].seatMapDisplayName,
      };

      dispatch({
        type: POSTPAID_BOOKING_SELECT_SEAT,
        payload: seatReservation,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(
        flagChangingPostpaidBookingSeatSelection({
          status: 'stop',
          seatId: `${itineraryId}-${seatId}`,
        }),
      );
    }
  };

const deselectPostpaidBookingSeat =
  async ({ seatReservationId, itineraryId, seatId }) =>
  async (dispatch) => {
    try {
      // raise flag
      dispatch(
        flagChangingPostpaidBookingSeatSelection({
          status: 'start',
          seatId: `${itineraryId}-${seatId}`,
        }),
      );

      // delete seat reservation
      const url = `${BASE_SECURE_URL}/reservation/reserve-seat/${seatReservationId}`;
      const response = await fetch(url, DEFAULT_DELETE_CONFIG);

      // will throw error if it contains one
      await isErrorResponse(response, null, dispatch);
      // parsing data into a usable format
      const seatReservation = await response.json();

      dispatch({
        type: POSTPAID_BOOKING_DESELECT_SEAT,
        payload: seatReservation.id,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(
        flagChangingPostpaidBookingSeatSelection({
          status: 'stop',
          seatId: `${itineraryId}-${seatId}`,
        }),
      );
    }
  };

const resetPostpaidBookingStepState = (step) => (dispatch) => {
  dispatch({
    payload: step,
    type: POSTPAID_BOOKING_RESET_STEP,
  });
};

const addKidOnPostpaidItinerary = async (payload) => async (dispatch) => {
  try {
    dispatch(flagKidsOnItineraryActivity(true));

    const kidOnItinerary = await postKidsOnItineraryRequest(payload);

    if (kidOnItinerary.id) {
      dispatch({
        type: POST_KIDS_ON_POSTPAID_ITINERARY,
        payload: kidOnItinerary.id,
      });
    }

    return kidOnItinerary;
  } catch ({ message }) {
    toastr.error('Error', message);
    return null;
  } finally {
    dispatch(flagKidsOnItineraryActivity(false));
  }
};

const removeKidOnPostpaidItinerary = async (payload) => async (dispatch) => {
  try {
    dispatch(flagKidsOnItineraryActivity(true));

    const kidOnItinerary = await deleteKidsOnItineraryRequest(payload);

    if (kidOnItinerary) {
      dispatch({
        type: DELETE_KIDS_ON_POSTPAID_ITINERARY,
        payload: kidOnItinerary,
      });
    }

    return kidOnItinerary;
  } catch ({ message }) {
    toastr.error('Error', message);
    return null;
  } finally {
    dispatch(flagKidsOnItineraryActivity(false));
  }
};

const resetPostpaidBookingSteps = (stepsToReset) => (dispatch) => {
  stepsToReset.forEach((stepIndex) => {
    dispatch(resetPostpaidBookingStepState(POSTPAID_BOOKING_STEPS[stepIndex]));
  });
};

const sendDeletePostpaidBookingDataRequest =
  async (stepsToReset) => async (dispatch, getState) => {
    // send customer order delete request
    if (stepsToReset.includes(POSTPAID_BOOKING_RECEIVER_STEP_INDEX)) {
      const customerOrderId = getState()
        .PostpaidBookingUnit.PostpaidBooking.get('receiver')
        .get('customerOrder')
        .get('id');
      if (customerOrderId) {
        await dispatch(deletePostpaidCustomerOrder(customerOrderId));

        // Delete kids on itinerary if user returns to
        // passenger form after of generate customer order
        const kidsOnItinerary = getState()
          .PostpaidBookingUnit.PostpaidBooking.get('passengers')
          .get('kidsOnPostpaidItinerary');
        const kidsOnItineraryJson = kidsOnItinerary.toJS();
        // eslint-disable-next-line no-restricted-syntax
        for (const id of kidsOnItineraryJson) {
          // eslint-disable-next-line no-await-in-loop
          await dispatch(removeKidOnPostpaidItinerary({ id }));
        }
      }
    }
  };

const clearPostpaidBookingState =
  async (currentStep) => async (dispatch, getState) => {
    try {
      // when user uses the browser's back button
      // the current step is not updated so we do it here
      const stateCurrentStep =
        getState().PostpaidBookingUnit.PostpaidBooking.get('currentStep');
      const reservationCreated =
        getState().PostpaidBookingUnit.PostpaidBooking.get(
          'reservationCreated',
        );
      if (currentStep !== stateCurrentStep) {
        dispatch({
          payload: currentStep,
          type: POSTPAID_BOOKING_CHANGE_STEP,
        });
      }

      // determine which steps need to be reseted
      const stepsToReset = [];
      POSTPAID_BOOKING_STEPS.forEach((forwardStep, stepIndex) => {
        if (stepIndex > currentStep) {
          stepsToReset.push(stepIndex);
        }
      });

      // send delete request only if the postpaidBooking process hasn't finished
      if (!reservationCreated) {
        await dispatch(sendDeletePostpaidBookingDataRequest(stepsToReset));
      }

      // reset identified steps
      dispatch(resetPostpaidBookingSteps(stepsToReset));

      // lower the flag indicating its a new reservation
      if (currentStep === POSTPAID_BOOKING_SEARCH_STEP_INDEX) {
        dispatch(flagPostpaidBookingReservationCreated(false));
      }
    } catch ({ message }) {
      toastr.error('Error', message);
    }
  };

const changePostpaidBookingStep = (step) => (dispatch) => {
  const newStepIndex = POSTPAID_BOOKING_STEPS.indexOf(step);
  // check if index exists
  if (newStepIndex >= 0) {
    dispatch({
      payload: newStepIndex,
      type: POSTPAID_BOOKING_CHANGE_STEP,
    });
    dispatch(push(step.path));
  }
};

const flagPostpaidBookingItinerariesSearch = (flag) => (dispatch) =>
  dispatch({
    payload: flag,
    type: POSTPAID_BOOKING_FLAG_SEARCHING_FOR_ITINERARIES,
  });

const clearSeatsForPostpaidBooking = () => (dispatch) =>
  dispatch({
    type: POSTPAID_BOOKING_CLEAR_SEATS,
  });

const clearItinerariesForPostpaidBooking = () => (dispatch) =>
  dispatch({
    type: POSTPAID_BOOKING_CLEAR_ITINERARY,
  });

const clearSelectItineraryForPostpaidBooking = () => (dispatch) =>
  dispatch({
    type: POSTPAID_BOOKING_CLEAR_SELECT_ITINERARY,
  });

const getItinerariesForPostpaidBooking =
  async ({ numPassengers, source, destination, time }) =>
  async (dispatch) => {
    try {
      // clear itinerary results and itineray selected
      dispatch(clearItinerariesForPostpaidBooking());

      // raise searching flag
      dispatch(flagPostpaidBookingItinerariesSearch(true));
      const data = {
        numPassengers: numPassengers.value
          ? parseInt(numPassengers.value, 10)
          : null,
        source: source.label, // todo switch to value
        destination: destination.label, // todo switch to value
        time: tzNormalizeDate({ date: time, format: TIMESTAMP_FORMAT }),
      };

      const itinerarySearchQuery = QueryString.stringify(data);
      const itinerarySearchUrl = `${BASE_SECURE_URL_V2}/itinerary-search`;
      const url = `${itinerarySearchUrl}?${itinerarySearchQuery}`;
      const response = await fetch(url, DEFAULT_GET_CONFIG);
      // check if it is an error response
      await isErrorResponse(response, null, dispatch);
      const results = await response.json();
      const payload = {
        numPassengers: data.numPassengers,
        time,
        results,
        source: { value: source.value, label: source.label },
        destination: { value: destination.value, label: destination.label },
      };
      dispatch({
        payload,
        type: POSTPAID_BOOKING_SEARCH_FOR_ITINERARIES,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagPostpaidBookingItinerariesSearch(false));
    }
  };

const getPostpaidBookingItineraryFoodGroup =
  async (itineraryId) => async (dispatch) => {
    try {
      const url = `${ITINERARY_ENDPOINT}/${itineraryId}/food-group`;
      const response = await fetch(url, DEFAULT_GET_CONFIG);

      await isErrorResponse(response, null, dispatch);

      // if response empty send null as payload
      const isEmpty = (await response.clone().text()).length === 0;
      const foodGroup = isEmpty ? null : await response.json();
      return dispatch({
        type: POSTPAID_BOOKING_GET_ITINERARY_FOOD_GROUP,
        payload: foodGroup,
      });
    } catch ({ message }) {
      return toastr.error('Error', message);
    }
  };

const selectPostpaidBookingItinerarySearchResult =
  ({ index, itinerarySearchResult }) =>
  (dispatch) => {
    const payload = {
      index,
      itinerarySearchResult,
    };
    dispatch(clearSelectItineraryForPostpaidBooking());
    dispatch({
      payload,
      type: POSTPAID_BOOKING_SELECT_ITINERARY,
    });
    // todo: verify all itineraries selected before changing step
  };

const flagPostingPostpaidBookingReservation = (flag) => (dispatch) =>
  dispatch({
    payload: flag,
    type: POSTPAID_BOOKING_FLAG_POSTING_RESERVATION,
  });

const postPostpaidBookingReservationRequests = async ({
  passengers,
  discountCode,
}) => {
  const reservationRequests = passengers.map(
    ({
      passenger: { value, discountCode: passengerDiscountCode },
      seatReservation,
      food,
    }) => ({
      customer: {
        id: value,
      },
      foodId: food ? food.value : null,
      seatReservationId: seatReservation.value,
      discountCodeString: discountCode || passengerDiscountCode,
      callCenter: true,
    }),
  );

  // make request
  const url = `${BASE_SECURE_URL}/reservation/reserve`;
  const response = await fetch(url, {
    ...DEFAULT_POST_CONFIG,
    body: JSON.stringify(reservationRequests),
  });
  // handle an error response from the server
  await isErrorResponse(response);

  // parsing data into a usable format
  const reservations = await response.json();
  return reservations;
};

const extractCustomerOrderDetails = (customerOrder) => {
  const passengers = [];

  customerOrder.customerOrderReservationList.forEach(
    ({
      reservation: {
        ticketList,
        itinerary: { route, departureTime },
      },
    }) => {
      const trip = {
        source: route.sourceLocation.name,
        destination: route.destinationLocation.name,
        departureTime,
      };

      ticketList.forEach(({ customer, salePrice, id, seatReservation }) => {
        passengers.push({
          id: customer.id,
          fullName: customer.fullName,
          idDocumentNumber: customer.idDocumentNumber,
          identificationType: customer.identificationType.name,
          salePrice,
          ticketId: id,
          seatNumber: seatReservation.seat.seatNumber,
          trip,
        });
      });
    },
  );

  return {
    id: customerOrder.id,
    reservationId: customerOrder.reservation.id,
    passengers,
    totalPrice: customerOrder.totalPrice,
  };
};

const postPostpaidBookingReservation =
  async (formValues) => async (dispatch, getState) => {
    try {
      dispatch(flagPostingPostpaidBookingReservation(true));
      // Register kid customer on itineary to manifest
      formValues.passengers.forEach(
        ({
          kidCustomer,
          passenger,
          seatReservation: { itineraryId, seatNumber, floorNumber },
          relationship,
          comments,
        }) => {
          if (kidCustomer) {
            const payloadKidOnItinerary = {
              itineraryId,
              seatNumber,
              floorNumber,
              parentCustomerId: passenger.value,
              kidCustomerId: kidCustomer.value,
              relationship: relationship.value,
              comments: comments || null,
            };
            dispatch(addKidOnPostpaidItinerary(payloadKidOnItinerary));
          }
        },
      );
      const reservations = await postPostpaidBookingReservationRequests(
        formValues,
      );
      const customerOrder = extractCustomerOrderDetails(reservations);
      // lower flag
      dispatch({
        type: POSTPAID_BOOKING_POST_RESERVATION_REQUESTS,
        payload: customerOrder,
      });
      const receiverStep =
        POSTPAID_BOOKING_STEPS[POSTPAID_BOOKING_RECEIVER_STEP_INDEX];

      dispatch(changePostpaidBookingStep(receiverStep));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagPostingPostpaidBookingReservation(false));
      const userFullName = getState().authentication
        ? getState().authentication.get('user').customer.fullName
        : '';
      ReactGa.event({
        category: 'postpaid-booking',
        action: 'reserve',
        label: userFullName,
      });
    }
  };

const flagPostingPostpaidBookingPretransaction = (flag) => (dispatch) =>
  dispatch({
    payload: flag,
    type: POSTPAID_BOOKING_FLAG_POSTING_PRETRANSACTION,
  });

const postPostpaidBookingPretransaction =
  async ({
    customerOrderId,
    customerDeliveryAddressId = null,
    customerEmailAddressId,
    paymentMethodId,
    voucherTypeId,
    voucherCode = null,
    businessId = null,
    businessTaxId = null,
    businessName = null,
    addressSummary = null,
  }) =>
  async (dispatch) => {
    try {
      dispatch(flagPostingPostpaidBookingPretransaction(true));

      let newBusiness = null;

      if (businessTaxId) {
        // new business
        const payloadNewBusiness = {
          name: businessName,
          businessTaxId,
          addressSummary,
        };
        const businessResponse = await fetch(BUSINESS_ENDPOINT, {
          ...DEFAULT_POST_CONFIG,
          body: JSON.stringify(payloadNewBusiness),
        });

        await isErrorResponse(businessResponse);
        newBusiness = await businessResponse.json();
      }

      const payload = {
        customerOrderId,
        customerDeliveryAddressId,
        customerEmailAddressId,
        paymentMethodId,
        voucherTypeId,
        voucherCode,
        businessId: newBusiness ? newBusiness.id : businessId,
      };

      const url = POSTPAID_BOOKING_PRETRANSACTION_ENDPOINT;
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });

      // check if it is an error response
      await isErrorResponse(response, null, dispatch);

      const pretransaction = await response.json();

      dispatch({
        type: POSTPAID_BOOKING_POST_PRETRANSACTION,
        payload: pretransaction,
      });

      // redirect to summary
      const summaryStep =
        POSTPAID_BOOKING_STEPS[POSTPAID_BOOKING_SUMMARY_STEP_INDEX];
      dispatch(changePostpaidBookingStep(summaryStep));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagPostingPostpaidBookingPretransaction(false));
    }
  };

const postPostpaidBookingPretransactionV2 =
  async (postpaidPretransactionList) => async (dispatch) => {
    try {
      dispatch(flagPostingPostpaidBookingPretransaction(true));

      let newBusiness = null;
      const {
        businessTaxId = null,
        businessName = null,
        addressSummary = null,
        businessId = null,
      } = postpaidPretransactionList[0];

      if (businessTaxId) {
        // new business
        const payloadNewBusiness = {
          name: businessName,
          businessTaxId,
          addressSummary,
        };
        const businessResponse = await fetch(BUSINESS_ENDPOINT, {
          ...DEFAULT_POST_CONFIG,
          body: JSON.stringify(payloadNewBusiness),
        });

        await isErrorResponse(businessResponse);
        newBusiness = await businessResponse.json();
      }

      const payload = postpaidPretransactionList.map(
        (postpaidPreTransaction) => ({
          ...postpaidPreTransaction,
          businessId: newBusiness ? newBusiness.id : businessId,
        }),
      );

      const url = `${POSTPAID_BOOKING_PRETRANSACTION_ENDPOINT}/all`;
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });

      // check if it is an error response
      await isErrorResponse(response, null, dispatch);

      const pretransactions = await response.json();

      dispatch({
        type: POSTPAID_BOOKING_POST_PRETRANSACTION,
        payload: pretransactions,
      });

      // redirect to summary
      const summaryStep =
        POSTPAID_BOOKING_STEPS[POSTPAID_BOOKING_SUMMARY_STEP_INDEX];
      dispatch(changePostpaidBookingStep(summaryStep));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagPostingPostpaidBookingPretransaction(false));
    }
  };

const postpaidBookingFlagGettingAll = (flag) => (dispatch) =>
  dispatch({
    type: POSTPAID_BOOKING_FLAG_GETTING_ALL,
    payload: flag,
  });

const getPostpaidBookings = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(postpaidBookingFlagGettingAll(true));
    const query = {
      ...tableFilters,
    };
    const url = `${POSTPAID_BOOKING_ENDPOINT}?${QueryString.stringify(query)}`;
    const response = await fetch(url, { ...DEFAULT_GET_CONFIG });

    // check if it is an error response
    await isErrorResponse(response, null, dispatch);
    const postpaidBookings = await response.json();
    dispatch({
      type: POSTPAID_BOOKING_GET_ALL,
      payload: postpaidBookings,
    });
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(postpaidBookingFlagGettingAll(false));
  }
};

const clearPostpaidBookings = () => (dispatch) =>
  dispatch({
    type: POSTPAID_BOOKING_CLEAR_ALL,
  });

const flagApprovingPostpaidBooking = (flag) => (dispatch) =>
  dispatch({
    type: POSTPAID_BOOKING_FLAG_APPROVING,
    payload: flag,
  });

const postApprovePostpaidBooking =
  async ({ customerOrderId }) =>
  async (dispatch) => {
    try {
      dispatch(flagApprovingPostpaidBooking(true));
      const url = generateApprovePostpaidBookingEndpoint(customerOrderId);
      const response = await fetch(url, { ...DEFAULT_POST_CONFIG });

      // check if it is an error response
      await isErrorResponse(response, null, dispatch);
      return response.json();
    } catch ({ message }) {
      toastr.error('Error', message);
      return null;
    } finally {
      dispatch(flagApprovingPostpaidBooking(false));
    }
  };

const flagRejectingPostpaidBooking = (flag) => (dispatch) =>
  dispatch({
    type: POSTPAID_BOOKING_FLAG_REJECTING,
    payload: flag,
  });

const postRejectPostpaidBooking =
  async ({ customerOrderId }) =>
  async (dispatch) => {
    try {
      dispatch(flagRejectingPostpaidBooking(true));
      const url = generateRejectPostpaidBookingEndpoint(customerOrderId);
      const response = await fetch(url, { ...DEFAULT_POST_CONFIG });

      // check if it is an error response
      await isErrorResponse(response, null, dispatch);
      return response.json();
    } catch ({ message }) {
      toastr.error('Error', message);
      return null;
    } finally {
      dispatch(flagRejectingPostpaidBooking(false));
    }
  };

const replacePostpaidBooking =
  ({ postpaidBooking }) =>
  (dispatch) =>
    dispatch({
      type: POSTPAID_BOOKINGS_REPLACE_ONE,
      payload: postpaidBooking,
    });

const flagAddingVoucherPostpaidBooking = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_ADDING_VOUCHER_TO_POSTPAID_BOOKING,
    payload: flag,
  });

/**
 * Takes a customerOrderId and voucherCode, patches the PostpaidBooking
 * and returns the updated postpaid booking object. Note that it does
 * not update any PostpaidBooking in the redux store.
 *
 * @param customerOrderId
 * @param voucherCode
 * @returns {Boolean} if true means it was succesfull request, false otherwise
 */
const patchPostpaidBookingVoucherCode =
  async ({ customerOrderId, voucherCode }) =>
  async (dispatch) => {
    try {
      dispatch(flagAddingVoucherPostpaidBooking(true));
      const url = generateAddVoucherToPostpaidBookingEndpoint(customerOrderId);
      const response = await fetch(url, {
        ...DEFAULT_PATCH_CONFIG,
        body: JSON.stringify(voucherCode),
      });

      // check if it is an error response
      await isErrorResponse(response, null, dispatch);
      return Boolean(response.json());
    } catch ({ message }) {
      toastr.error('Error', message);
      return false;
    } finally {
      dispatch(flagAddingVoucherPostpaidBooking(false));
    }
  };

const flagGettingPretransactionForPostpaidBooking = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_PRETRANSACTION_FOR_POSTPAID_BOOKING,
    payload: flag,
  });

const getPretransaction =
  async ({ customerOrderId }) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingPretransactionForPostpaidBooking(true));
      const params = QueryString.stringify({ customerOrderId });
      const url = `${POSTPAID_BOOKING_PRETRANSACTION_ENDPOINT}?${params}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });

      // check if it is an error response
      await isErrorResponse(response, null, dispatch);
      const pretransaction = response.json();
      dispatch({
        type: POSTPAID_BOOKING_GET_PRETRANSACTION,
        payload: pretransaction,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagGettingPretransactionForPostpaidBooking(false));
    }
  };

const getPretransactionV2 =
  async ({ customerOrderId }) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingPretransactionForPostpaidBooking(true));
      const params = QueryString.stringify({ customerOrderId });
      const url = `${POSTPAID_BOOKING_PRETRANSACTION_ENDPOINT}/all?${params}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });

      // check if it is an error response
      await isErrorResponse(response, null, dispatch);
      const pretransactions = await response.json();
      dispatch({
        type: POSTPAID_BOOKING_GET_PRETRANSACTION,
        payload: pretransactions,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagGettingPretransactionForPostpaidBooking(false));
    }
  };

const flagPostingFixPriceRequestForPostpaidBooking = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_POSTING_FIX_PRICE_REQUEST_FOR_POSTPAID_BOOKING,
    payload: flag,
  });

const setFixedPriceForCurrentPostpaidBooking = (newPrices) => (dispatch) => {
  dispatch({
    type: SET_FIXED_PRICE_FOR_CURRENT_POSTPAID_BOOKING,
    payload: newPrices,
  });
};

const postFixPriceRequestForPostpaidBooking =
  async ({ fixedTicketPrices }) =>
  async (dispatch) => {
    try {
      dispatch(flagPostingFixPriceRequestForPostpaidBooking(true));

      const url = TICKET_FIXED_PRICE_ENDPOINT;
      const payload = fixedTicketPrices;
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });

      // check if it's an error response
      await isErrorResponse(
        response,
        setFixedPriceForCurrentPostpaidBooking,
        dispatch,
      );
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagPostingFixPriceRequestForPostpaidBooking(false));
    }
  };

const postGenerateCip =
  async ({
    amount,
    currency,
    userName,
    userLastName,
    userEmail,
    userDocumentNumber,
    userDocumentType,
    userCodeCountry,
    userPhone,
    transactionCode,
  }) =>
  async () => {
    try {
      const payload = {
        amount,
        currency,
        userName,
        userLastName,
        userEmail,
        userDocumentNumber,
        userDocumentType,
        userCodeCountry,
        userPhone,
        transactionCode,
        paymentConcept: 'Turismociva',
        additionalData: 'Turismociva',
      };
      const url = PAGOEFECTIVO_CIP_ENDPOINT;
      const promise = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise);
      const response = await promise.json();
      return response;
    } catch ({ message }) {
      toastr.error('Error', message);
      return null;
    }
  };

const postGenerateNiubizLink =
  async ({
    amount,
    userName,
    userLastName,
    userEmail,
    userDocumentNumber,
    userDocumentType,
    userCodeCountry,
    userPhone,
    transactionCode,
  }) =>
  async () => {
    try {
      const payload = {
        externalId: transactionCode,
        description: 'Turismociva',
        amount,
        customer: {
          firstName: userName,
          lastName: userLastName,
          email: userEmail,
          documentType: userDocumentType,
          documentNumber: userDocumentNumber,
          phoneNumber: `${userCodeCountry}${userPhone}`,
        },
      };
      const url = NIUBIZ_LINK_ENDPOINT;
      const promise = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise);
      const response = await promise.json();
      return response;
    } catch ({ message }) {
      toastr.error('Error', message);
      return null;
    }
  };

const postGenerateOpenpayLink =
  async ({
    amount,
    userName,
    userLastName,
    userEmail,
    userPhone,
    transactionCode,
  }) =>
  async () => {
    try {
      const payload = {
        amount,
        description: 'Turismociva',
        orderId: transactionCode,
        sendEmail: true,
        customer: {
          name: userName,
          lastName: userLastName,
          phoneNumber: userPhone,
          email: userEmail,
        },
      };

      const url = OPENPAY_LINK_ENDPOINT;
      const promise = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });

      await isErrorResponse(promise);
      const response = await promise.json();
      return response;
    } catch (error) {
      toastr.error('Error', error);
      return null;
    }
  };

export {
  getItinerariesForPostpaidBooking,
  changePostpaidBookingStep,
  selectPostpaidBookingItinerarySearchResult,
  selectPostpaidBookingSeat,
  deselectPostpaidBookingSeat,
  getPostpaidBookingItineraryFoodGroup,
  postPostpaidBookingReservation,
  postPostpaidBookingPretransaction,
  clearPostpaidBookingState,
  getPostpaidBookings,
  clearPostpaidBookings,
  postApprovePostpaidBooking,
  postRejectPostpaidBooking,
  replacePostpaidBooking,
  patchPostpaidBookingVoucherCode,
  getPretransaction,
  flagPostpaidBookingReservationCreated,
  postFixPriceRequestForPostpaidBooking,
  clearItinerariesForPostpaidBooking,
  clearSeatsForPostpaidBooking,
  clearSelectItineraryForPostpaidBooking,
  postGenerateCip,
  postGenerateNiubizLink,
  postPostpaidBookingPretransactionV2,
  getPretransactionV2,
  postGenerateOpenpayLink,
};
