import React, { useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import ModuleList from '../../../layout/ModuleList';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import { PARCEL_PRICE_COLUMNS } from '../../../../config/columns';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import {
  NEW_DELIVERY_PRICE_PATH,
  DELIVERY_PRICE_PATH,
} from '../../../../config/paths';
import {
  clearDeliveryPrices,
  getDeliveryPrices,
} from '../../../../actions/cargo/DeliveryPrice';

const DeliveryPrices = ({
  breadcrumbs,
  deliveryPrices,
  loading,
  dispatchGetDeliveryPrices,
  dispatchClearDeliveryPrices,
}) => {
  useLayoutEffect(() => () => dispatchClearDeliveryPrices(), []);

  const data = deliveryPrices.get('content') || [];
  const pages = deliveryPrices.get('totalPages') || null;
  const defaultPageSize = deliveryPrices.get('size') || DEFAULT_PAGE_SIZE;

  return (
    <ModuleList
      title="Tarifarios de Reparto"
      breadcrumbs={breadcrumbs}
      buttonPath={NEW_DELIVERY_PRICE_PATH}
      tableStructure={{
        columns: PARCEL_PRICE_COLUMNS,
        data,
        pages,
        defaultPageSize,
        filterable: true,
        fetchData: dispatchGetDeliveryPrices,
        modelPath: DELIVERY_PRICE_PATH,
        loading,
        navigateToModelOnRowClick: true,
      }}
    />
  );
};

const mapDispatchToProps = {
  dispatchGetDeliveryPrices: getDeliveryPrices,
  dispatchClearDeliveryPrices: clearDeliveryPrices,
};

DeliveryPrices.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  deliveryPrices: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetDeliveryPrices: PropTypes.func.isRequired,
  dispatchClearDeliveryPrices: PropTypes.func.isRequired,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Tarifarios de Reparto',
      href: DELIVERY_PRICE_PATH,
    },
  ],
  deliveryPrices: CargoUnit.DeliveryPrice.getIn(['all', 'content']),
  loading: CargoUnit.DeliveryPrice.getIn(['all', 'loading']),
});

DeliveryPrices.defaultProps = {
  deliveryPrices: null,
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryPrices);
