import React, { useLayoutEffect, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { reduxForm, Field, change, formValueSelector } from 'redux-form';
import { Form, FormGroup } from 'reactstrap';
import {
  clearItemToItinerary,
  getUserAgenciesV2,
  getItineraryForCounter,
  clearItinerary,
  clearCargoLocationGroup,
  postCargoLocationGroup,
  getManifestItemToUnloadLegalAbandonment,
  getManifestItemToAddLegalAbandonment,
} from '../../../../actions';
import { isRequired } from '../../../../utils/validators';
import { CARGO_OPERATIONS } from '../../../../config/constants';
import { optionsPropTypes } from '../../../common/forms/select/SelectPropTypes';
import { getUniqueLocations } from '../unloading-cargo-from-itinerary/UnloadingCargoForm';
import Select from '../../../common/forms/select/Select';
import FormItem from '../../../common/forms/FormItem';
import ItinerarySearchInput from '../../../common/forms/input/ItinerarySearchInput';
import { getManifestItemByItinerary } from '../../../../actions/cargo/ItineraryManifest';
import RegisteredBusSelect from '../../../common/forms/select/RegisteredBusSelect';
import CargoOperationsButtons from '../manifest/CargoOperationsButtons';
import AddCargoLegalAbandonmentToItinerary from './add-item-legal-abandonment/AddCargoLegalAbandonmentToItinerary';
import UnloadCargoLegalAbandonmentFromItineraryForm from './unload-item-legal-abandonment/UnloadCargoLegalAbandonmentFromItineraryForm';
import ManifestCargo from '../manifest/manifest-cargo/ManifestCargo';

const selector = formValueSelector('ManifestForm');

export const ManifestLegalAbandonmentForm = ({
  user,
  handleSubmit,
  agencyOptions,
  loadingAgencies,
  registeredBusId,
  itineraryCounter,
  dispatchChange,
  dispatchClearItinerary,
  dispatchGetUserAgenciesV2,
  dispatchClearItemToItinerary,
  dispatchGetItineraryForCounter,
  dispatchGetManifestItemByItinerary,
  dispatchgetManifestItemToAddLegalAbandonment,
  dispatchGetManifestItemToUnloadLegalAbandonment,
  dispatchClearCargoLocationGroup,
  dispatchPostCargoLocationGroup,
  cargoLocationGroup,
}) => {
  const [itinerary, setItinerary] = useState(null);
  const [locationIdSelected, setLocationIdSelected] = useState(null);
  const [agenciesToShow, setAgenciesToShow] = useState([]);
  const [operation, setOperation] = useState(null);

  useLayoutEffect(
    () => () => {
      dispatchClearItemToItinerary();
      dispatchClearItinerary();
      dispatchClearCargoLocationGroup();
    },
    [],
  );

  useEffect(() => {
    dispatchGetUserAgenciesV2({ userId: user.id });
  }, [user, itinerary]);

  const verifyLocationWithAgencies = () => {
    const uniqueLocations = getUniqueLocations(itineraryCounter, true, true);

    const combinedLocations = new Set([
      ...uniqueLocations.map((location) => location.id),
      ...cargoLocationGroup.map((cargoLocation) => cargoLocation.locationId),
    ]);

    let newAgenciesToShow = [];

    if (combinedLocations.size !== 0) {
      newAgenciesToShow = agencyOptions.filter((agency) =>
        combinedLocations.has(agency.value),
      );

      if (newAgenciesToShow.length === 0) {
        toastr.error(
          'Error',
          'No puede realizar operaciones de carga en este itinerario, ninguna de sus agencias coincide con las paradas.',
        );
        dispatchChange('ManifestLegalAbandonmentForm', 'sourceAgency', '');
        setAgenciesToShow(newAgenciesToShow);
        return;
      }
    }

    dispatchChange(
      'ManifestLegalAbandonmentForm',
      'sourceAgency',
      newAgenciesToShow[0],
    );

    setAgenciesToShow(newAgenciesToShow);
  };

  const verifyOperationLocation = (currentLocation, unload, add) => {
    if (itineraryCounter.isEmpty()) {
      return true;
    }
    const uniqueLocations = getUniqueLocations(itineraryCounter, unload, add);

    if (uniqueLocations.length !== 0) {
      const combinedLocations = new Set([
        ...uniqueLocations.map((location) => location.id),
        ...cargoLocationGroup.map((cargoLocation) => cargoLocation.locationId),
      ]);

      const verified = combinedLocations.has(currentLocation);

      if (!verified) {
        toastr.error(
          'Error',
          add
            ? 'No puede subir carga en este itinerario, su agencia es la última parada'
            : 'No puede bajar carga de este itinerario, su agencia es la primera parada',
        );
        return verified;
      }

      return verified;
    }

    return false;
  };

  useEffect(() => {
    if (!itineraryCounter.isEmpty()) {
      const locationIds = getUniqueLocations(itineraryCounter, true, true).map(
        (location) => location.id,
      );

      if (locationIds.length > 0) {
        dispatchPostCargoLocationGroup(locationIds);
      }
    }
  }, [itineraryCounter]);

  useEffect(() => {
    if (!itineraryCounter.isEmpty()) {
      verifyLocationWithAgencies();
    }
  }, [cargoLocationGroup]);

  const onHandleSubmit = ({ sourceAgency }) => {
    const sourceLocationId = sourceAgency.value;

    switch (operation) {
      case CARGO_OPERATIONS.ADD_ITEM.value:
        if (verifyOperationLocation(sourceLocationId, false, true)) {
          dispatchgetManifestItemToAddLegalAbandonment({
            locationId: sourceLocationId,
          });
        }
        break;
      case CARGO_OPERATIONS.UNLOAD_ITEM.value:
        if (verifyOperationLocation(sourceLocationId, true, false)) {
          dispatchGetManifestItemToUnloadLegalAbandonment({
            itineraryId: itinerary,
          });
        }
        break;
      case CARGO_OPERATIONS.MANIFEST.value:
        dispatchGetManifestItemByItinerary({ itineraryId: itinerary });
        break;
      default:
        break;
    }

    setLocationIdSelected(sourceAgency.value);
  };

  const handleSelectItinerary = ({ itineraryId }) => {
    setItinerary(itineraryId);
    dispatchGetItineraryForCounter(itineraryId);
  };

  const handleClear = () => {
    setItinerary(null);
    setOperation(null);
    dispatchClearItinerary();
    dispatchClearCargoLocationGroup();
  };

  const handleShowItinerarySearch = () => {
    handleClear();
    dispatchClearItemToItinerary();
    dispatchChange('ManifestLegalAbandonmentForm', 'itineraryId', '');
    dispatchChange('ManifestLegalAbandonmentForm', 'itinerary', '');
  };

  const onClickAddItem = () => {
    setOperation(CARGO_OPERATIONS.ADD_ITEM.value);
  };

  const onClickUnloading = () => {
    setOperation(CARGO_OPERATIONS.UNLOAD_ITEM.value);
  };

  const onClickManifest = () => {
    setOperation(CARGO_OPERATIONS.MANIFEST.value);
  };

  const onClickHandlers = {
    [CARGO_OPERATIONS.ADD_ITEM.value]: onClickAddItem,
    [CARGO_OPERATIONS.UNLOAD_ITEM.value]: onClickUnloading,
    [CARGO_OPERATIONS.MANIFEST.value]: onClickManifest,
  };

  const onChangeSourceAgency = () => {
    setOperation(null);
  };

  useEffect(() => {
    if (registeredBusId === null || registeredBusId === '') {
      setOperation(null);
    } else {
      setAgenciesToShow(agencyOptions);
    }
  }, [registeredBusId]);

  return (
    <>
      <Form onSubmit={handleSubmit(onHandleSubmit)}>
        {registeredBusId !== null && registeredBusId !== '' ? null : (
          <FormGroup row>
            <FormItem label="Itinerario">
              <Field
                name="itineraryId"
                component={ItinerarySearchInput}
                placeholder="Itinerario"
                handleSelectItinerary={handleSelectItinerary}
                handleShowItinerarySearch={handleShowItinerarySearch}
                handleClear={handleClear}
                props={{
                  form: 'ManifestLegalAbandonmentForm',
                }}
              />
            </FormItem>
          </FormGroup>
        )}
        {itinerary !== null && itinerary ? null : (
          <FormGroup row>
            <FormItem label="Número de Bus">
              <Field
                name="registeredBusId"
                component={RegisteredBusSelect}
                isClearable
                onlyActive
              />
            </FormItem>
          </FormGroup>
        )}
        <FormGroup row>
          <FormItem label="Agencia Actual" required>
            <Field
              name="sourceAgency"
              component={Select}
              isLoading={loadingAgencies}
              options={
                registeredBusId === '' || !!itinerary
                  ? agenciesToShow
                  : agencyOptions
              }
              isDisabled={
                agenciesToShow.length <= 1 &&
                (registeredBusId === '' || !!itinerary)
              }
              onChange={onChangeSourceAgency}
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <CargoOperationsButtons
          operations={CARGO_OPERATIONS}
          showNothing={!itinerary && !registeredBusId}
          toShow={
            registeredBusId !== '' && !itinerary
              ? [CARGO_OPERATIONS.UNLOAD_ITEM.value]
              : []
          }
          showAll={!!itinerary}
          onClickHandlers={onClickHandlers}
        />
      </Form>

      {locationIdSelected
        ? (() => {
            switch (operation) {
              case CARGO_OPERATIONS.ADD_ITEM.value:
                return (
                  <AddCargoLegalAbandonmentToItinerary
                    itinerary={itinerary}
                    sourceLocationId={locationIdSelected}
                  />
                );
              case CARGO_OPERATIONS.UNLOAD_ITEM.value:
                return (
                  <UnloadCargoLegalAbandonmentFromItineraryForm
                    itineraryId={itinerary}
                    locationId={locationIdSelected}
                  />
                );
              case CARGO_OPERATIONS.MANIFEST.value:
                return <ManifestCargo itineraryId={itinerary} />;
              default:
                return null;
            }
          })()
        : null}
    </>
  );
};

ManifestLegalAbandonmentForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    customer: PropTypes.shape({
      fullName: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  loadingAgencies: PropTypes.bool,
  agencyOptions: optionsPropTypes,
  itineraryCounter: PropTypes.instanceOf(Immutable.Map).isRequired,
  registeredBusId: PropTypes.instanceOf(Object),
  dispatchChange: PropTypes.func.isRequired,
  dispatchClearItinerary: PropTypes.func.isRequired,
  dispatchGetUserAgenciesV2: PropTypes.func.isRequired,
  dispatchClearItemToItinerary: PropTypes.func.isRequired,
  dispatchGetItineraryForCounter: PropTypes.func.isRequired,
  dispatchGetManifestItemByItinerary: PropTypes.func.isRequired,
  dispatchgetManifestItemToAddLegalAbandonment: PropTypes.func.isRequired,
  dispatchGetManifestItemToUnloadLegalAbandonment: PropTypes.func.isRequired,
  dispatchClearCargoLocationGroup: PropTypes.func.isRequired,
  dispatchPostCargoLocationGroup: PropTypes.func.isRequired,
  cargoLocationGroup: PropTypes.instanceOf(Object).isRequired,
};

ManifestLegalAbandonmentForm.defaultProps = {
  loadingAgencies: false,
  agencyOptions: [],
  registeredBusId: null,
};

const mapStateToProps = (state) => ({
  user: state.authentication.get('user') || undefined,
  loadingAgencies: state.HumanResourcesUnit.Agency.getIn(['all', 'loading']),
  agencyOptions: state.HumanResourcesUnit.Agency.getIn([
    'all',
    'content',
    'content',
  ]).map((agency) => ({
    value: agency.locationId,
    label: agency.name,
  })),
  itineraryCounter:
    state.ItineraryUnit.Itinerary.getIn(['current', 'content']) || undefined,
  registeredBusId: selector(state, 'registeredBusId'),
  cargoLocationGroup: state.CargoUnit.ManifestCargo.getIn([
    'cargoLocationGroup',
  ]),
});

const mapDispatchToProps = {
  dispatchChange: change,
  dispatchClearItinerary: clearItinerary,
  dispatchGetUserAgenciesV2: getUserAgenciesV2,
  dispatchClearItemToItinerary: clearItemToItinerary,
  dispatchGetItineraryForCounter: getItineraryForCounter,
  dispatchGetManifestItemByItinerary: getManifestItemByItinerary,
  dispatchgetManifestItemToAddLegalAbandonment:
    getManifestItemToAddLegalAbandonment,
  dispatchGetManifestItemToUnloadLegalAbandonment:
    getManifestItemToUnloadLegalAbandonment,
  dispatchClearCargoLocationGroup: clearCargoLocationGroup,
  dispatchPostCargoLocationGroup: postCargoLocationGroup,
};

const formComponent = reduxForm({
  form: 'ManifestLegalAbandonmentForm',
})(ManifestLegalAbandonmentForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
