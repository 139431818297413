import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import { DELIVERY_BUSINESS_PATH } from '../../../../config/paths';
import { postDeliveryBusiness } from '../../../../actions';
import DeliveryBusinessForm from './DeliveryBusinessForm';

const NewDeliveryBusiness = ({ breadcrumbs, dispatchPostDeliveryBusiness }) => {
  const onSubmit = (formValues) => {
    const newFormValues = { businessId: formValues.business.value };

    const agencyList = formValues.agencies.map((agency) => agency.value);

    const userList = formValues.users.map((user) => user.value);

    newFormValues.agencyList = agencyList;
    newFormValues.userList = userList;

    dispatchPostDeliveryBusiness(newFormValues);
  };

  const content = <DeliveryBusinessForm onSubmit={onSubmit} />;

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Nueva Empresa de Reparto"
      subtitle="Crear nueva empresa de reparto"
      content={content}
    />
  );
};

NewDeliveryBusiness.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostDeliveryBusiness: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchPostDeliveryBusiness: postDeliveryBusiness,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Empresas de Reparto',
      href: DELIVERY_BUSINESS_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewDeliveryBusiness);
