import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import EditCommonProductButton from './EditCommonProductButton';
import DeleteCommonProductButton from './DeleteCommonProductButton';
import CommonProductPriceButton from './CommonProductPriceButton';

const CommonProductToolbar = ({ commonProductId }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <EditCommonProductButton commonProductId={commonProductId} />{' '}
      <DeleteCommonProductButton commonProductId={commonProductId} />{' '}
      <CommonProductPriceButton commonProductId={commonProductId} />
    </div>
  </ButtonToolbar>
);

CommonProductToolbar.propTypes = {
  commonProductId: PropTypes.string.isRequired,
};

export default CommonProductToolbar;
