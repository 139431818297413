import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { putInternalParcelReturn } from '../../../../actions';

const ReturnInternalParcelButton = ({
  internalParcelId,
  dispatchPutInternalParcelReturn,
}) => {
  const handleDeliverInternalParcel = () => {
    dispatchPutInternalParcelReturn({ internalParcelId });
  };

  return (
    <Button
      color="info"
      className="border"
      onClick={handleDeliverInternalParcel}
      disabled={!internalParcelId}
    >
      <i className="fa fa-share" /> Retorno a Almacén
    </Button>
  );
};

ReturnInternalParcelButton.propTypes = {
  internalParcelId: PropTypes.string.isRequired,
  dispatchPutInternalParcelReturn: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchPutInternalParcelReturn: putInternalParcelReturn,
};

export default connect(null, mapDispatchToProps)(ReturnInternalParcelButton);
