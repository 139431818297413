import { array, number, string } from 'prop-types';

const deliveryBasicInformationPropTypes = {
  id: number.isRequired,
  agencyName: string.isRequired,
  businessName: string.isRequired,
  businessTaxId: string.isRequired,
  userFullName: string.isRequired,
  createDate: number.isRequired,
  items: array.isRequired,
};

export default deliveryBasicInformationPropTypes;
