import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getCabinCrews,
  clearCabinCrews,
  getFreeCabinCrews,
} from '../../../../actions/index';
import Select from './Select';
import {
  inputPropTypes,
  metaPropTypes,
  inputDefaultProps,
  metaDefaultProps,
} from '../../forms/input/InputPropTypes';
import { optionsPropTypes } from './SelectPropTypes';

const CabinCrewSelect = ({
  dispatchGetFreeCabinCrews,
  dispatchGetCabinCrews,
  dispatchClearCabinCrews,
  isMulti,
  input,
  cabinCrews,
  meta,
  isLoading,
  itineraryId,
  isDisabled,
  ...rest
}) => {
  useLayoutEffect(() => {
    if (itineraryId) dispatchGetFreeCabinCrews({ itineraryId });

    return () => {
      dispatchClearCabinCrews();
    };
  }, []);

  const handleCabinCrewChange = (inputValue) => {
    if (inputValue.trim().length && inputValue.trim().length >= 4) {
      const index = inputValue.indexOf(',');

      let query = [`customer.firstName:${inputValue}`];

      if (index >= 0) {
        const { length } = inputValue;
        const firstName = inputValue.substring(0, index).trim();
        const lastName = inputValue.substring(index + 1, length).trim();
        query = [];
        if (firstName.length) query.push(`customer.firstName:${firstName}`);
        if (lastName.length) query.push(`customer.lastName:${lastName}`);
      }

      dispatchGetCabinCrews({ query });
    }
  };

  const props = {
    options: cabinCrews,
    isLoading,
    isMulti,
    input,
    meta,
    isDisabled,
    ...rest,
  };

  if (!itineraryId) {
    props.onInputChange = handleCabinCrewChange;
  }

  return <Select {...props} />;
};

CabinCrewSelect.propTypes = {
  dispatchGetFreeCabinCrews: PropTypes.func.isRequired,
  dispatchGetCabinCrews: PropTypes.func.isRequired,
  dispatchClearCabinCrews: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  input: inputPropTypes,
  cabinCrews: optionsPropTypes,
  meta: metaPropTypes,
  isLoading: PropTypes.bool,
  itineraryId: PropTypes.number,
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.string,
};

CabinCrewSelect.defaultProps = {
  isLoading: false,
  input: inputDefaultProps,
  meta: metaDefaultProps,
  isMulti: false,
  cabinCrews: [],
  itineraryId: null,
  isDisabled: false,
  placeholder: 'Busca así: nombre,apellido',
};

const mapStateToProps = ({ TrafficUnit }) => {
  const cabinCrews = TrafficUnit.CabinCrew.getIn([
    'all',
    'content',
    'content',
  ]).map((cabinCrew) => ({
    value: cabinCrew.id,
    label: `${cabinCrew.customer.firstName} ${cabinCrew.customer.lastName}`,
  }));
  const loading = TrafficUnit.CabinCrew.getIn(['all', 'loading']);
  return {
    cabinCrews,
    loading,
  };
};

const mapDispatchToprops = {
  dispatchGetCabinCrews: getCabinCrews,
  dispatchGetFreeCabinCrews: getFreeCabinCrews,
  dispatchClearCabinCrews: clearCabinCrews,
};

export default connect(mapStateToProps, mapDispatchToprops)(CabinCrewSelect);
