import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { SHELF_PATH } from '../../../../config/paths';

import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import { clearShelf, getShelf, putShelf } from '../../../../actions';
import ShelfForm from './ShelfForm';

const EditShelf = ({
  breadcrumbs,
  dispatchGetShelf,
  dispatchClearShelf,
  dispatchPutShelf,
  match: {
    params: { id: shelfId },
  },
  loading,
  shelf,
}) => {
  useLayoutEffect(() => {
    dispatchGetShelf({ shelfId });

    return () => dispatchClearShelf();
  }, []);

  const onSubmit = (formValues, shelfData) => {
    const newFormValues = {
      name: formValues.name,
      locationId: formValues.locationId ? formValues.locationId.value : null,
      width: formValues.width,
      height: formValues.height,
      shelfSpaceList: shelfData,
      active: formValues.active,
    };

    dispatchPutShelf(shelfId, newFormValues);
  };

  const generateInitialValues = ({
    name,
    locationId,
    location,
    height,
    width,
    shelfSpaceList,
    active,
  }) => ({
    name,
    locationId: {
      label: location.name,
      value: locationId,
    },
    height,
    width,
    disabledCells: shelfSpaceList
      .filter((space) => space.disabled)
      .map((space) => `${space.rowPosition - 1}-${space.columnPosition - 1}`),
    active,
  });

  let content;

  if (loading) content = <Loader />;
  else if (shelf.isEmpty())
    content = <NoDataResource returnPage={SHELF_PATH} />;
  else
    content = (
      <ShelfForm
        onSubmit={onSubmit}
        initialValues={generateInitialValues(shelf.toJS())}
        edit
      />
    );

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Editar Anaquel"
      subtitle="Editar anaquel"
      content={content}
    />
  );
};

EditShelf.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchClearShelf: PropTypes.func.isRequired,
  dispatchGetShelf: PropTypes.func.isRequired,
  dispatchPutShelf: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  shelf: PropTypes.instanceOf(Immutable.Map).isRequired,
};

EditShelf.defaultProps = {
  loading: false,
};

const mapDispatchToProps = {
  dispatchClearShelf: clearShelf,
  dispatchGetShelf: getShelf,
  dispatchPutShelf: putShelf,
};

const mapStateToProps = (
  { CargoUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Anaqueles',
      href: SHELF_PATH,
    },
    {
      text: 'Ver',
      href: `${SHELF_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  shelf: CargoUnit.Shelf.getIn(['current', 'content']),
  loading: !CargoUnit.Shelf.getIn(['current', 'activity']).isEmpty(),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditShelf);
