import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  CARGO_CORPORATE_CREDIT_ENDPOINT,
  generateEndpointCargoCorporateCreditToAddCreditLine,
  generateEndpointCargoCorporateCreditToUnifyInvoices,
} from '../../config/endpoints';
import {
  BASIC_POST_CONFIG,
  DEFAULT_DELETE_CONFIG,
  DEFAULT_GET_CONFIG,
  DEFAULT_PATCH_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import {
  CLEAR_CARGO_CORPORATE_CREDIT,
  CLEAR_CARGO_CORPORATE_CREDIT_MANUAL_VOUCHER_VALUES,
  CLEAR_CARGO_CORPORATE_CREDITS,
  CLEAR_CARGO_CORPORATE_INVOICES,
  FLAG_CARGO_CORPORATE_CREDIT_ACTIVITY,
  FLAG_GETTING_CARGO_CORPORATE_CREDITS,
  FLAG_GETTING_CARGO_CORPORATE_INVOICES,
  GET_CARGO_CORPORATE_CREDIT,
  GET_CARGO_CORPORATE_CREDIT_MANUAL_VOUCHER_VALUES,
  GET_CARGO_CORPORATE_CREDITS,
  GET_CARGO_CORPORATE_INVOICES,
} from '../types/cargo/CargoCorporateCredit';
import {
  CARGO_CORPORATE_CREDIT_PATH,
  MANUAL_VOUCHER_PATH,
} from '../../config/paths';

const flagGettingCargoCorporateCredits = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_CARGO_CORPORATE_CREDITS,
    payload: flag,
  });

const getCargoCorporateCredits = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingCargoCorporateCredits(true));
    const query = tableFilters;

    const url = `${CARGO_CORPORATE_CREDIT_ENDPOINT}?${QueryString.stringify(
      query,
    )}`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(response, null, dispatch);
    const cargoCorporateCredits = await response.json();
    dispatch({
      type: GET_CARGO_CORPORATE_CREDITS,
      payload: cargoCorporateCredits,
    });
  } catch (error) {
    toastr.error('Error', error.message);
  } finally {
    dispatch(flagGettingCargoCorporateCredits(false));
  }
};

const clearCargoCorporateCredits = () => (dispatch) =>
  dispatch({
    type: CLEAR_CARGO_CORPORATE_CREDITS,
  });

const flagCargoCorporateCreditActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_CARGO_CORPORATE_CREDIT_ACTIVITY,
    payload: flag,
  });

const getCargoCorporateCredit =
  async ({ cargoCorporateCreditId }) =>
  async (dispatch) => {
    try {
      dispatch(flagCargoCorporateCreditActivity(true));
      const url = `${CARGO_CORPORATE_CREDIT_ENDPOINT}/${cargoCorporateCreditId}`;
      const response = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(response, null, dispatch);
      const cargoCorporateCredit = await response.json();
      dispatch({
        type: GET_CARGO_CORPORATE_CREDIT,
        payload: cargoCorporateCredit,
      });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagCargoCorporateCreditActivity(false));
    }
  };

const clearCargoCorporateCredit = () => (dispatch) =>
  dispatch({
    type: CLEAR_CARGO_CORPORATE_CREDIT,
  });
const postCargoCorporateCredit =
  async ({
    name,
    description,
    active,
    companyId,
    customerId,
    initialBalance,
    actualBalance,
    creditDays,
    createDate,
    lastUpdate,
    discountPercentage,
    isDiscountAllowed,
  }) =>
  async (dispatch) => {
    try {
      dispatch(flagCargoCorporateCreditActivity(true));
      const payload = {
        name,
        description,
        companyId,
        customerId,
        initialBalance,
        actualBalance,
        creditDays,
        active,
        createDate,
        lastUpdate,
        discountPercentage,
        isDiscountAllowed,
      };
      const url = CARGO_CORPORATE_CREDIT_ENDPOINT;
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      const cargoCorporateCredit = await response.json();
      dispatch(
        push(`${CARGO_CORPORATE_CREDIT_PATH}/${cargoCorporateCredit.id}`),
      );
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagCargoCorporateCreditActivity(false));
    }
  };

const putCargoCorporateCredit =
  async (
    cargoCorporateCreditId,
    {
      name,
      description,
      active,
      initialBalance,
      actualBalance,
      creditDays,
      discountPercentage,
      isDiscountAllowed,
    },
  ) =>
  async (dispatch) => {
    try {
      dispatch(flagCargoCorporateCreditActivity(true));
      const payload = {
        id: cargoCorporateCreditId,
        name,
        description,
        initialBalance,
        actualBalance,
        creditDays,
        active,
        discountPercentage,
        isDiscountAllowed,
      };
      const url = `${CARGO_CORPORATE_CREDIT_ENDPOINT}/${cargoCorporateCreditId}`;
      const response = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      const cargoCorporateCredit = await response.json();
      dispatch(
        push(`${CARGO_CORPORATE_CREDIT_PATH}/${cargoCorporateCredit.id}`),
      );
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagCargoCorporateCreditActivity(false));
    }
  };

const patchCargoCorporateCreditLine =
  async ({ cargoCorporateCreditId, amount }) =>
  async (dispatch) => {
    try {
      dispatch(flagCargoCorporateCreditActivity(true));

      const url = generateEndpointCargoCorporateCreditToAddCreditLine(
        cargoCorporateCreditId,
        amount,
      );
      const response = await fetch(url, {
        ...DEFAULT_PATCH_CONFIG,
      });
      await isErrorResponse(response, null, dispatch);
      await response.json();
      dispatch(
        push(`${CARGO_CORPORATE_CREDIT_PATH}/${cargoCorporateCreditId}`),
      );
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagCargoCorporateCreditActivity(false));
    }
  };

const deleteCargoCorporateCredit =
  async ({ cargoCorporateCreditId }) =>
  async (dispatch) => {
    try {
      dispatch(flagCargoCorporateCreditActivity(true));
      const url = `${CARGO_CORPORATE_CREDIT_ENDPOINT}/${cargoCorporateCreditId}`;
      const response = await fetch(url, {
        ...DEFAULT_DELETE_CONFIG,
      });
      await isErrorResponse(response, null, dispatch);
      await response;
      dispatch(push(CARGO_CORPORATE_CREDIT_PATH));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagCargoCorporateCreditActivity(false));
    }
  };

const flagGettingCargoCorporateInvoices = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_CARGO_CORPORATE_INVOICES,
    payload: flag,
  });

const getCargoCorporateInvoices =
  async ({ cargoCorporateCreditId }) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingCargoCorporateInvoices(true));
      const url = `${CARGO_CORPORATE_CREDIT_ENDPOINT}/${cargoCorporateCreditId}/parcels`;
      const response = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(response, null, dispatch);
      const cargoCorporateInvoices = await response.json();
      dispatch({
        type: GET_CARGO_CORPORATE_INVOICES,
        payload: cargoCorporateInvoices,
      });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagGettingCargoCorporateInvoices(false));
    }
  };

const getCargoCorporateInvoicesByCompanyId =
  async ({ companyId }) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingCargoCorporateInvoices(true));
      const url = `${CARGO_CORPORATE_CREDIT_ENDPOINT}/company/${companyId}/parcels`;
      const response = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(response, null, dispatch);
      const cargoCorporateInvoices = await response.json();
      dispatch({
        type: GET_CARGO_CORPORATE_INVOICES,
        payload: cargoCorporateInvoices,
      });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagGettingCargoCorporateInvoices(false));
    }
  };

const clearCargoCorporateInvoices = () => (dispatch) =>
  dispatch({
    type: CLEAR_CARGO_CORPORATE_INVOICES,
  });

const putCargoCorporateInvoces =
  async ({ parcelIds, cargoCorporateCreditId }) =>
  async (dispatch) => {
    try {
      dispatch(flagCargoCorporateCreditActivity(true));
      const url = generateEndpointCargoCorporateCreditToUnifyInvoices(
        cargoCorporateCreditId,
      );
      const response = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(parcelIds),
      });
      await isErrorResponse(response, null, dispatch);
      const payload = await response.json();
      dispatch({
        type: GET_CARGO_CORPORATE_CREDIT_MANUAL_VOUCHER_VALUES,
        payload,
      });
      dispatch(push(`${MANUAL_VOUCHER_PATH}/new`));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagCargoCorporateCreditActivity(false));
    }
  };

const clearCargoCorporateCreditManualVoucherValues = () => (dispatch) => {
  dispatch({
    type: CLEAR_CARGO_CORPORATE_CREDIT_MANUAL_VOUCHER_VALUES,
  });
};

const postAssignmentCustomerToCargoCorporateCreditCity =
  async (cargoCorporateCreditId, payload) => async (dispatch) => {
    try {
      dispatch(flagCargoCorporateCreditActivity(true));
      const url = `${CARGO_CORPORATE_CREDIT_ENDPOINT}/${cargoCorporateCreditId}/city`;
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      dispatch(
        push(`${CARGO_CORPORATE_CREDIT_PATH}/${cargoCorporateCreditId}`),
      );
      toastr.success('Ubicación actualizada');
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagCargoCorporateCreditActivity(false));
    }
  };

const postCargoCorporateCreditCommonProduct =
  async (
    cargoCorporateCreditId,
    {
      height,
      length,
      width,
      weight,
      productName,
      productDescription,
      cargoCategoryId,
      commonProductTemplateId,
    },
  ) =>
  async (dispatch) => {
    try {
      dispatch(flagCargoCorporateCreditActivity(true));
      const url = `${CARGO_CORPORATE_CREDIT_ENDPOINT}/${cargoCorporateCreditId}/common-product`;
      const payload = {
        height,
        length,
        width,
        weight,
        productName,
        productDescription,
        cargoCategoryId,
        commonProductTemplateId,
      };
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      const data = await response.json();
      dispatch(push(`${CARGO_CORPORATE_CREDIT_PATH}/${data.id}`));
      toastr.success('Producto agregado');
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagCargoCorporateCreditActivity(false));
    }
  };

const getCargoCorporateCreditExcelTemplate =
  async ({ cargoCorporateCreditId }) =>
  async (dispatch) => {
    try {
      dispatch(flagCargoCorporateCreditActivity(true));
      const url = `${CARGO_CORPORATE_CREDIT_ENDPOINT}/${cargoCorporateCreditId}/customer/export`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      await isErrorResponse(response, null, dispatch);
      const blob = await response.blob();
      const urlBlob = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = urlBlob;
      link.download = `Plantilla de Corporativo ID-${cargoCorporateCreditId}.xlsx`;
      link.click();
      link.remove();
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagCargoCorporateCreditActivity(false));
    }
  };

const postCargoCorproateCreditExcel =
  async ({ cargoCorporateCreditId, file }) =>
  async (dispatch) => {
    try {
      dispatch(flagCargoCorporateCreditActivity(true));
      const formData = new FormData();
      formData.append('file', file);
      const url = `${CARGO_CORPORATE_CREDIT_ENDPOINT}/${cargoCorporateCreditId}/customer/import`;
      const response = await fetch(url, {
        ...BASIC_POST_CONFIG,
        body: formData,
      });
      await isErrorResponse(response, null, dispatch);
      const { messageList } = await response.json();
      dispatch(
        push(`${CARGO_CORPORATE_CREDIT_PATH}/${cargoCorporateCreditId}`),
      );
      dispatch(getCargoCorporateCredit({ cargoCorporateCreditId }));
      toastr.success('Archivo cargado');
      messageList.forEach((message) =>
        toastr.info('Info', message, { timeOut: 0 }),
      );
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagCargoCorporateCreditActivity(false));
    }
  };

const getCargoCorporateCreditSearch =
  async ({ customerId, businessId }) =>
  async (dispatch) => {
    try {
      dispatch(flagCargoCorporateCreditActivity(true));
      const query = {};
      if (customerId) query.customerId = customerId;
      if (businessId) query.businessId = businessId;
      const url = `${CARGO_CORPORATE_CREDIT_ENDPOINT}/search?${QueryString.stringify(
        query,
      )}`;
      const response = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(response, null, dispatch);
      const cargoCorporateCredit = await response.json();
      dispatch({
        type: GET_CARGO_CORPORATE_CREDIT,
        payload: cargoCorporateCredit,
      });
      return cargoCorporateCredit;
    } catch (error) {
      dispatch(clearCargoCorporateCredit());
      return null;
    } finally {
      dispatch(flagCargoCorporateCreditActivity(false));
    }
  };

export {
  getCargoCorporateCredits,
  clearCargoCorporateCredits,
  getCargoCorporateCredit,
  clearCargoCorporateCredit,
  postCargoCorporateCredit,
  putCargoCorporateCredit,
  patchCargoCorporateCreditLine,
  deleteCargoCorporateCredit,
  flagGettingCargoCorporateInvoices,
  clearCargoCorporateInvoices,
  getCargoCorporateInvoices,
  putCargoCorporateInvoces,
  clearCargoCorporateCreditManualVoucherValues,
  getCargoCorporateInvoicesByCompanyId,
  postAssignmentCustomerToCargoCorporateCreditCity,
  postCargoCorporateCreditCommonProduct,
  getCargoCorporateCreditExcelTemplate,
  postCargoCorproateCreditExcel,
  getCargoCorporateCreditSearch,
};
