import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'reactstrap';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import withEndpointAuthorization from '../../authorization/withEndPointAuthorization';
import { PUT } from '../../../../config/permissions';
import { INTERNAL_PARCEL_ENDPOINT } from '../../../../config/endpoints';

export const InternalTransferLogisticFields = ({ onChange }) => (
  <Label className="d-flex align-items-center justify-content-center">
    <Field
      name="logisticFields"
      component="input"
      type="checkbox"
      onChange={onChange}
      className="me-2"
    />
    Traslado Interno para Terceros
  </Label>
);

InternalTransferLogisticFields.propTypes = {
  onChange: PropTypes.func.isRequired,
};

const connectedComponent = connect(null, null)(InternalTransferLogisticFields);

export default withEndpointAuthorization({
  url: INTERNAL_PARCEL_ENDPOINT,
  permissionType: PUT,
})(connectedComponent);
