import React, { useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import Content from '../../../layout/Content';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import { CARGO_CORPORATE_CREDIT_PATH } from '../../../../config/paths';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import ParcelPriceConfigurationForm from '../parcel-price-configuration/ParcelPriceConfigurationForm';
import {
  clearCargoCorporateCredit,
  getCargoCorporateCredit,
} from '../../../../actions/cargo/CargoCorporateCredit';
import {
  postParcelPriceConfiguration,
  putParcelPriceConfiguration,
} from '../../../../actions';

const NewCorporateParcelPriceConfiguration = ({
  breadcrumbs,
  dispatchGetCargoCorporateCredit,
  dispatchClearCargoCorporateCredit,
  match: {
    params: { id: cargoCorporateCreditId },
  },
  cargoCorporateCredit,
  loading,
  dispatchPostParcelPriceConfiguration,
  dispatchPutParcelPriceConfiguration,
}) => {
  useLayoutEffect(() => {
    dispatchGetCargoCorporateCredit({ cargoCorporateCreditId });
    return () => dispatchClearCargoCorporateCredit();
  }, []);

  const onSubmit = (formValues) => {
    const cargoCorporateCreditJson = cargoCorporateCredit.toJS();

    const newFormValues = {
      ...formValues,
      cargoCorporateCreditId: formValues.cargoCorporateCredit
        ? formValues.cargoCorporateCredit.value
        : null,
    };

    if (cargoCorporateCreditJson.parcelPriceConfiguration) {
      dispatchPutParcelPriceConfiguration(
        cargoCorporateCreditJson.parcelPriceConfiguration.id,
        newFormValues,
      );
      return;
    }
    dispatchPostParcelPriceConfiguration(newFormValues);
  };

  const generateInitialValues = (cargoCorporateCreditJson) => ({
    cargoCorporateCredit: {
      value: cargoCorporateCreditJson.id,
      label: cargoCorporateCreditJson.name,
    },
    basePrice: cargoCorporateCreditJson.parcelPriceConfiguration
      ? cargoCorporateCreditJson.parcelPriceConfiguration.basePrice
      : '',
    maxEnvelopeWeight: cargoCorporateCreditJson.parcelPriceConfiguration
      ? cargoCorporateCreditJson.parcelPriceConfiguration.maxEnvelopeWeight
      : '',
    volumetricDivisor: cargoCorporateCreditJson.parcelPriceConfiguration
      ? cargoCorporateCreditJson.parcelPriceConfiguration.volumetricDivisor
      : '',
  });

  let content;

  if (loading) content = <Loader />;
  else if (cargoCorporateCredit.isEmpty()) {
    content = (
      <NoDataResource
        returnPage={`${CARGO_CORPORATE_CREDIT_PATH}/${cargoCorporateCreditId}`}
      />
    );
  } else {
    const cargoCorporateCreditJson = cargoCorporateCredit.toJS();
    content = (
      <ParcelPriceConfigurationForm
        initialValues={generateInitialValues(cargoCorporateCreditJson)}
        onSubmit={onSubmit}
        isEdit
      />
    );
  }

  return (
    <Content
      title="Nueva Configuración de Tarifa"
      content={content}
      breadcrumbs={breadcrumbs}
    />
  );
};

NewCorporateParcelPriceConfiguration.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetCargoCorporateCredit: PropTypes.func.isRequired,
  match: matchPropTypes.isRequired,
  dispatchClearCargoCorporateCredit: PropTypes.func.isRequired,
  cargoCorporateCredit: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchPostParcelPriceConfiguration: PropTypes.func.isRequired,
  dispatchPutParcelPriceConfiguration: PropTypes.func.isRequired,
};

NewCorporateParcelPriceConfiguration.defaultProps = {
  cargoCorporateCredit: Immutable.Map(),
  loading: false,
};

const mapStateToProps = (
  { CargoUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Créditos Corporativos',
      href: CARGO_CORPORATE_CREDIT_PATH,
    },
    {
      text: 'Ver Crédito Corporativo',
      href: `${CARGO_CORPORATE_CREDIT_PATH}/${id}`,
    },
    {
      text: 'Configuración de Tarifas',
      href: '',
    },
  ],
  cargoCorporateCredit: CargoUnit.CargoCorporateCredit.getIn([
    'current',
    'content',
  ]),
  loading: !CargoUnit.CargoCorporateCredit.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetCargoCorporateCredit: getCargoCorporateCredit,
  dispatchClearCargoCorporateCredit: clearCargoCorporateCredit,
  dispatchPostParcelPriceConfiguration: postParcelPriceConfiguration,
  dispatchPutParcelPriceConfiguration: putParcelPriceConfiguration,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewCorporateParcelPriceConfiguration);
