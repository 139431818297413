import QueryString from 'query-string';
import { push } from 'react-router-redux';
import { toastr } from 'react-redux-toastr';
import ReactGa from 'react-ga';
import {
  CHANGE_STEP,
  DESELECT_SEAT,
  FLAG_SEARCHING_FOR_ITINERARIES,
  FLAG_GETTING_PUBLIC_CITIES,
  FLAG_SEAT_SELECTION_CHANGE_IN_PROGRESS,
  POST_RESERVATION_REQUESTS,
  SEARCH_FOR_ITINERARIES,
  SELECT_ITINERARY_SEARCH_RESULT,
  SELECT_SEAT,
  GET_PUBLIC_CITIES,
  POST_PAYMENT,
  FLAG_GETTING_PRINTED_TICKET,
  GET_PRINTED_TICKET,
  CLEAR_PRINTED_TICKETS,
  GET_INTINERARY_FOOD_GROUP,
  RESET_STEP,
  FLAG_DELETING_CUSTOMER_ORDER,
  APPLY_CREDIT_NOTE,
  CANCEL_CREDIT_NOTE,
  FLAG_POSTING_RESERVATION,
  FLAG_POSTING_PAYMENT,
  FLAG_POSTING_FIX_PRICE_REQUEST,
  SET_FIXED_PRICE_FOR_CURRENT_BOOKING,
  CLEAR_ITINERARY_FOR_BOOKING,
  CLEAR_SELECT_ITINERARY,
  CLEAR_SEATS_FOR_BOOKING,
  POST_KIDS_ON_ITINERARY,
  DELETE_KIDS_ON_ITINERARY,
} from '../types';
import {
  BASE_SECURE_URL,
  PUBLIC_CITIES_ENDPOINT,
  RESERVATION_ENDPOINT,
  ITINERARY_ENDPOINT,
  BUSINESS_ENDPOINT,
  CUSTOMER_ORDER_ENDPOINT,
  VALIDATE_CREDIT_NOTE_ENDPOINT,
  TICKET_FIXED_PRICE_ENDPOINT,
  RESERVE_SEAT_ENDPOINT,
  SELF_SERVICE_RESERVE_SEAT_ENDPOINT,
  BASE_SECURE_URL_V2,
  COMPANY_CITIES_ENDPOINT,
  COMPANY_SEND_TICKET_BY_EMAIL_ENDPOINT,
  COMPANY_SEND_TICKET_BY_SMS_ENDPOINT,
  COMPANY_SEND_TICKET_VOID_BY_EMAIL_ENDPOINT,
  COMPANY_SEND_TICKET_VOID_BY_SMS_ENDPOINT,
} from '../../config/endpoints';
import { DEFAULT_QUERY } from '../../config/queries';
import {
  handleResponseError,
  isErrorResponse,
} from '../../utils/error-handlers';
import {
  MAX_PAGE_SIZE,
  CIVA_BOOKING_STEPS,
  CIVA_BOOKING_PAYMENT_STEP_INDEX,
  CIVA_BOOKING_CONFIRMATION_STEP_INDEX,
  BUSINESS_INVOICE_ID,
  THIRD_PARTY_BOOKING_STEPS,
  THIRD_PARTY_BOOKING_CONFIRMATION_STEP_INDEX,
} from '../../config/constants';
import {
  DEFAULT_DELETE_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_GET_CONFIG,
} from '../../config/rest';
import {
  deleteKidsOnItineraryRequest,
  flagKidsOnItineraryActivity,
  postKidsOnItineraryRequest,
} from '../itinerary/KidsOnItinerary';
import { tzNormalizeDate } from '../../utils/date';
import { TIMESTAMP_FORMAT } from '../../config/locale';
import { isProduction } from '../../utils/validators';
import {
  DEV_SELF_SERVICE_PAYMENT_METHOD_ID,
  PROD_SELF_SERVICE_PAYMENT_METHOD_ID,
} from '../../config/defaults';
import ApiError from '../../utils/errors/ApiError';

const flagDeletingCustomerOrder = (flag) => (dispatch) =>
  dispatch({
    payload: flag,
    type: FLAG_DELETING_CUSTOMER_ORDER,
  });

const deleteCustomerOrder = async (customerOrderId) => async (dispatch) => {
  dispatch(flagDeletingCustomerOrder(true));
  // delete seat reservation
  const url = `${CUSTOMER_ORDER_ENDPOINT}/${customerOrderId}`;
  try {
    const response = await fetch(url, DEFAULT_DELETE_CONFIG);

    // handle an error response from the server
    const serverError = await handleResponseError(response);
    if (serverError) {
      // lower flag
      toastr.error('Error', serverError);
      return dispatch(flagDeletingCustomerOrder(false));
    }

    await response.json();

    // lower flag
    return dispatch(flagDeletingCustomerOrder(false));
  } catch ({ message }) {
    // lower flag
    toastr.error('Error', `Error eliminando customer order: ${message}`);
    return dispatch(flagDeletingCustomerOrder(false));
  }
};

const flagChangingSeatSelection =
  ({ status, seatId }) =>
  (dispatch) =>
    dispatch({
      payload: { status, seatId },
      type: FLAG_SEAT_SELECTION_CHANGE_IN_PROGRESS,
    });

const deselectSeat =
  async ({ seatReservationId, itineraryId, seatId }) =>
  async (dispatch, getState) => {
    // raise flag
    dispatch(
      flagChangingSeatSelection({
        status: 'start',
        seatId: `${itineraryId}-${seatId}`,
      }),
    );

    // We verify if the registered user is from self service
    const { salesSessionUserId } = getState().authentication.get('user');

    // delete seat reservation
    const url = salesSessionUserId
      ? `${SELF_SERVICE_RESERVE_SEAT_ENDPOINT}/${seatReservationId}`
      : `${RESERVE_SEAT_ENDPOINT}/${seatReservationId}`;

    try {
      const response = await fetch(url, DEFAULT_DELETE_CONFIG);

      // handle an error response from the server
      const serverError = await handleResponseError(response);
      if (serverError) {
        // lower flag
        toastr.error('Error', serverError);
        return dispatch(
          flagChangingSeatSelection({
            status: 'stop',
            seatId: `${itineraryId}-${seatId}`,
          }),
        );
      }

      // the response is a response instance
      // parse the data into a usable format using `.json()`
      const seatReservation = await response.json();

      // lower flag
      dispatch(
        flagChangingSeatSelection({
          status: 'stop',
          seatId: `${itineraryId}-${seatId}`,
        }),
      );

      return dispatch({
        type: DESELECT_SEAT,
        payload: seatReservation.id,
      });
    } catch ({ message }) {
      // lower flag
      toastr.error('Error', `Error eliminando reserva de asiento: ${message}`);
      return dispatch(
        flagChangingSeatSelection({
          status: 'stop',
          seatId: `${itineraryId}-${seatId}`,
        }),
      );
    }
  };

const resetStepState = (step) => (dispatch) => {
  dispatch({
    payload: step,
    type: RESET_STEP,
  });
};

const addKidOnItinerary = async (payload) => async (dispatch) => {
  try {
    dispatch(flagKidsOnItineraryActivity(true));

    const kidOnItinerary = await postKidsOnItineraryRequest(payload);

    if (kidOnItinerary.id) {
      dispatch({ type: POST_KIDS_ON_ITINERARY, payload: kidOnItinerary.id });
    }

    return kidOnItinerary;
  } catch ({ message }) {
    toastr.error('Error', message);
    return null;
  } finally {
    dispatch(flagKidsOnItineraryActivity(false));
  }
};

const removeKidOnItinerary = async (payload) => async (dispatch) => {
  try {
    dispatch(flagKidsOnItineraryActivity(true));

    const kidOnItinerary = await deleteKidsOnItineraryRequest(payload);

    if (kidOnItinerary) {
      dispatch({ type: DELETE_KIDS_ON_ITINERARY, payload: kidOnItinerary });
    }

    return kidOnItinerary;
  } catch ({ message }) {
    toastr.error('Error', message);
    return null;
  } finally {
    dispatch(flagKidsOnItineraryActivity(false));
  }
};

const sendDeleteCustomerOrder =
  async (stepsToReset) => async (dispatch, getState) => {
    // Send customer order delete request when back in steps
    // This function validate the customerOrderId from reducer
    if (stepsToReset.includes(CIVA_BOOKING_PAYMENT_STEP_INDEX)) {
      const customerOrderId = getState()
        .BookingUnit.Booking.get('payment')
        .get('customerOrder')
        .get('id');

      if (customerOrderId) {
        await dispatch(deleteCustomerOrder(customerOrderId));

        // Delete kids on itinerary if user returns to
        // passenger form after of generate customer order
        const kidsOnItinerary = getState()
          .BookingUnit.Booking.get('passengers')
          .get('kidsOnItinerary');
        const kidsOnItineraryJson = kidsOnItinerary.toJS();
        // eslint-disable-next-line no-restricted-syntax
        for (const id of kidsOnItineraryJson) {
          // eslint-disable-next-line no-await-in-loop
          await dispatch(removeKidOnItinerary({ id }));
        }
      }
    }
  };

const clearBookingState = async (currentStep) => async (dispatch, getState) => {
  try {
    // when user uses the browser's back button
    // the current step is not updated so we do it here
    const stateCurrentStep = getState().BookingUnit.Booking.get('currentStep');
    const processedPayment = getState().BookingUnit.Booking.getIn([
      'confirmation',
      'reservationId',
    ]);
    if (currentStep !== stateCurrentStep) {
      dispatch({
        payload: currentStep,
        type: CHANGE_STEP,
      });
    }
    const stepsToReset = [];
    // determine which steps need to be reseted
    CIVA_BOOKING_STEPS.forEach((forwardStep, stepIndex) => {
      if (stepIndex > currentStep) {
        stepsToReset.push(stepIndex);
      }
    });
    // send delete request only if the reservation hasn't been paid
    if (!processedPayment) {
      await dispatch(sendDeleteCustomerOrder(stepsToReset));
    }
    stepsToReset.forEach((stepIndex) => {
      dispatch(resetStepState(CIVA_BOOKING_STEPS[stepIndex]));
    });
  } catch ({ message }) {
    toastr.error('Error', `Error limpiando estado: ${message}`);
  }
};

const changeStep = (step) => (dispatch) => {
  const newStepIndex = CIVA_BOOKING_STEPS.indexOf(step);
  dispatch({
    payload: newStepIndex,
    type: CHANGE_STEP,
  });
  dispatch(push(step.path));
};

const flagGettingPublicCities = (flag) => (dispatch) =>
  dispatch({
    payload: flag,
    type: FLAG_GETTING_PUBLIC_CITIES,
  });

// todo cache and get from cache
const getPublicCities = async () => async (dispatch, getState) => {
  dispatch(flagGettingPublicCities(true));
  let publicCities = [];

  // get all public cities by default
  const query = {
    ...DEFAULT_QUERY,
    size: MAX_PAGE_SIZE,
  };

  // We verify if the registered user is from self service
  const isForMine = !!getState().authentication.get('user').salesSessionUserId;

  const cityUrl = isForMine ? COMPANY_CITIES_ENDPOINT : PUBLIC_CITIES_ENDPOINT;

  const url = `${cityUrl}?${QueryString.stringify(query)}`;

  try {
    const response = await fetch(url, DEFAULT_GET_CONFIG);

    // handle an error response from the server
    const serverError = await handleResponseError(response);
    if (serverError) {
      toastr.error('Error', serverError);
      return dispatch(flagGettingPublicCities(false));
    }

    // the response is a response instance
    // parse the data into a usable format using `.json()`
    publicCities = await response.json();
    dispatch(flagGettingPublicCities(false));
    return dispatch({
      type: GET_PUBLIC_CITIES,
      payload: publicCities,
    });
  } catch ({ message }) {
    toastr.error('Error', `Error obteniendo ciudades: ${message}`);
    return dispatch(flagGettingPublicCities(false));
  }
};

const flagSearchingForItineraries = (flag) => (dispatch) =>
  dispatch({
    payload: flag,
    type: FLAG_SEARCHING_FOR_ITINERARIES,
  });

const clearItinerariesForBooking = () => (dispatch) =>
  dispatch({
    type: CLEAR_ITINERARY_FOR_BOOKING,
  });

const searchForItineraries =
  async ({ numPassengers, source, destination, time }) =>
  async (dispatch, getState) => {
    try {
      dispatch(clearItinerariesForBooking());

      dispatch(flagSearchingForItineraries(true));

      const data = {
        numPassengers: numPassengers.value
          ? parseInt(numPassengers.value, 10)
          : null,
        source: source.label, // todo switch to value
        destination: destination.label, // todo switch to value
        time: tzNormalizeDate({ date: time, format: TIMESTAMP_FORMAT }),
      };

      const itinerarySearchQuery = QueryString.stringify(data);

      // We verify if the registered user is from self service
      const { salesSessionUserId } = getState().authentication.get('user');

      const endpoint = salesSessionUserId
        ? `${BASE_SECURE_URL_V2}/self-service-itinerary-search`
        : `${BASE_SECURE_URL_V2}/itinerary-search`;

      const url = `${endpoint}?${itinerarySearchQuery}`;

      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });

      await isErrorResponse(response, null, dispatch);

      const results = await response.json();

      const payload = {
        numPassengers: data.numPassengers,
        time,
        results,
        source: { value: source.value, label: source.label },
        destination: { value: destination.value, label: destination.label },
      };

      dispatch({
        payload,
        type: SEARCH_FOR_ITINERARIES,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagSearchingForItineraries(false));
    }
  };

const selfSearchForItineraries =
  async ({ processId, sourceCity, destinationCity, childLocationId }) =>
  async (dispatch) => {
    try {
      dispatch(clearItinerariesForBooking());

      dispatch(flagSearchingForItineraries(true));

      const data = {
        processId,
        sourceCity,
        destinationCity,
        childLocationId,
      };

      const itinerarySearchQuery = QueryString.stringify(data);

      const endpoint = `${BASE_SECURE_URL}/self-service-itinerary-search/process`;

      const url = `${endpoint}?${itinerarySearchQuery}`;

      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });

      await isErrorResponse(response);

      const results = await response.json();

      const payload = {
        results,
      };

      dispatch({
        payload,
        type: SEARCH_FOR_ITINERARIES,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagSearchingForItineraries(false));
    }
  };

const clearSelectItinerary = () => (dispatch) =>
  dispatch({
    type: CLEAR_SELECT_ITINERARY,
  });

const clearSeatsForBooking = () => (dispatch) =>
  dispatch({ type: CLEAR_SEATS_FOR_BOOKING });

const selectItinerarySearchResult =
  ({ index, itinerarySearchResult }) =>
  (dispatch) => {
    const payload = {
      index,
      itinerarySearchResult,
    };
    dispatch(clearSelectItinerary());
    dispatch({
      payload,
      type: SELECT_ITINERARY_SEARCH_RESULT,
    });
  };

const selectSeat =
  async ({
    sourceLocationId,
    destinationLocationId,
    itineraryId,
    seatId,
    specialPriceId,
  }) =>
  async (dispatch, getState) => {
    const payload = {
      sourceLocationId,
      destinationLocationId,
      itineraryId,
      seatId,
      specialPriceId,
    };

    dispatch(
      flagChangingSeatSelection({
        status: 'start',
        seatId: `${itineraryId}-${seatId}`,
      }),
    );

    // We verify if the registered user is from self service
    const { salesSessionUserId } = getState().authentication.get('user');

    // post seat reservation
    const url = salesSessionUserId
      ? SELF_SERVICE_RESERVE_SEAT_ENDPOINT
      : RESERVE_SEAT_ENDPOINT;

    try {
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });

      // handle an error response from the server
      await isErrorResponse(response, null, dispatch);

      // the response is a response instance
      // parse the data into a usable format using `.json()`
      const seatReservation = await response.json();

      const itinerary = getState()
        .BookingUnit.Booking.getIn([
          'itineraries',
          'selectedItinerarySearchResults',
        ])
        .toJS();

      seatReservation.itinerary = {
        originName: itinerary[0].origin.name,
        destinationName: itinerary[0].destination.name,
        departureTime: itinerary[0].departureTime,
        arrivalTime: itinerary[0].arrivalTime,
        serviceName: itinerary[0].seatMapDisplayName,
      };

      dispatch({
        type: SELECT_SEAT,
        payload: seatReservation,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(
        flagChangingSeatSelection({
          status: 'stop',
          seatId: `${itineraryId}-${seatId}`,
        }),
      );
    }
  };

const extractCustomerOrderDetails = (customerOrder) => {
  const passengers = [];

  customerOrder.customerOrderReservationList.forEach(
    ({
      reservation: {
        ticketList,
        itinerary: { route, departureTime },
      },
    }) => {
      const trip = {
        source: route.sourceLocation.name,
        destination: route.destinationLocation.name,
        departureTime,
      };

      ticketList.forEach(({ customer, seatReservation, salePrice, id }) => {
        passengers.push({
          id: customer.id,
          fullName: customer.fullName,
          idDocumentNumber: customer.idDocumentNumber,
          identificationType: customer.identificationType.name,
          salePrice,
          ticketId: id,
          seatNumber: seatReservation.seat.seatNumber,
          trip,
        });
      });
    },
  );

  return {
    id: customerOrder.id,
    trip: {
      source: customerOrder.reservation.itinerary.route.sourceLocation.name,
      destination:
        customerOrder.reservation.itinerary.route.destinationLocation.name,
      departureTime: customerOrder.reservation.itinerary.departureTime,
    },
    reservationId: customerOrder.reservation.id,
    passengers,
    totalPrice: customerOrder.totalPrice,
  };
};

/**
 * @throws {FetchError}
 * @throws {JsonError}
 */
const postReservationRequests = async ({ passengers, discountCode }) => {
  const reservationRequests = passengers.map(
    ({
      passenger: { value, discountCode: passengerDiscountCode },
      seatReservation,
      food,
    }) => ({
      customer: {
        id: value,
      },
      foodId: food ? food.value : null,
      seatReservationId: seatReservation.value,
      discountCodeString: discountCode || passengerDiscountCode,
    }),
  );
  // Make request
  const url = `${BASE_SECURE_URL}/reservation/reserve`;
  const response = await fetch(url, {
    ...DEFAULT_POST_CONFIG,
    body: JSON.stringify(reservationRequests),
  });
  // Handle an error response from the server
  await isErrorResponse(response);

  // The response is a response instance
  // parse the data into a usable format using `.json()`
  const reservations = await response.json();
  return reservations;
};

const flagPostingReservation = (flag) => (dispatch) =>
  dispatch({
    payload: flag,
    type: FLAG_POSTING_RESERVATION,
  });

const postReservation = async (formValues) => async (dispatch, getState) => {
  try {
    dispatch(flagPostingReservation(true));
    // Register kid customer on itineary to manifest
    formValues.passengers.forEach(
      ({
        kidCustomer,
        passenger,
        seatReservation: { itineraryId, seatNumber, floorNumber },
        relationship,
        comments,
      }) => {
        if (kidCustomer) {
          const payloadKidOnItinerary = {
            itineraryId,
            seatNumber,
            floorNumber,
            parentCustomerId: passenger.value,
            kidCustomerId: kidCustomer.value,
            relationship: relationship.value,
            comments: comments || null,
          };
          dispatch(addKidOnItinerary(payloadKidOnItinerary));
        }
      },
    );
    const reservations = await postReservationRequests(formValues);
    const customerOrder = extractCustomerOrderDetails(reservations);
    // Lower flag
    dispatch({
      type: POST_RESERVATION_REQUESTS,
      payload: customerOrder,
    });

    dispatch(changeStep(CIVA_BOOKING_STEPS[CIVA_BOOKING_PAYMENT_STEP_INDEX]));
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagPostingReservation(false));
    const userFullName = getState().authentication
      ? getState().authentication.get('user').customer.fullName
      : '';
    ReactGa.event({
      category: 'booking',
      action: 'reserve',
      label: userFullName,
    });
  }
};

const postThirdPartyReservation = async () => async (dispatch, getState) => {
  try {
    dispatch(flagPostingReservation(true));

    // Getting active sales session
    const activeSaleSession = getState().SalesUnit.SalesSession.getIn([
      'active',
      'content',
    ]);

    const {
      company: { businessId },
    } = activeSaleSession.get('agency');

    // Validate business from active sales session
    if (businessId === null)
      throw new ApiError(null, 'Este Company no tiene configurado un business');

    // Getting selected seats
    const seatReservations = getState()
      .BookingUnit.Booking.getIn(['seats', 'seatReservations'])
      .toJS();

    // Getting customer id from authentication
    const {
      customer: { id: customerId },
    } = getState().authentication.get('user');

    // Generate reservation payload
    // Always will be one
    const reservationPayload = {
      passengers: [
        {
          passenger: {
            value: customerId,
            discountCode: null,
          },
          food: null,
          seatReservation: { value: seatReservations[0].id },
        },
      ],
      discountCode: null,
    };

    // Register reservation
    const { id: customerOrderId } = await postReservationRequests(
      reservationPayload,
    );

    // Get default payment method id
    const paymentMethodId = isProduction()
      ? PROD_SELF_SERVICE_PAYMENT_METHOD_ID
      : DEV_SELF_SERVICE_PAYMENT_METHOD_ID;

    // Generate pay payload
    const payReservationPayload = {
      transactionList: [
        {
          customerId,
          paymentMethodId,
          voucherTypeId: BUSINESS_INVOICE_ID,
          voucherCode: null,
          businessId,
          amount: seatReservations[0].listPrice,
        },
      ],
    };

    const url = `${RESERVATION_ENDPOINT}/${customerOrderId}/pay`;

    const payPromise = await fetch(url, {
      ...DEFAULT_POST_CONFIG,
      body: JSON.stringify(payReservationPayload),
    });

    await isErrorResponse(payPromise);

    const payResponse = await payPromise.json();

    dispatch({
      type: POST_PAYMENT,
      payload: payResponse.id,
    });

    // Redirect to confirmation page
    dispatch(
      changeStep(
        THIRD_PARTY_BOOKING_STEPS[THIRD_PARTY_BOOKING_CONFIRMATION_STEP_INDEX],
      ),
    );
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagPostingReservation(false));
    const userFullName = getState().authentication
      ? getState().authentication.get('user').customer.fullName
      : '';
    ReactGa.event({
      category: 'booking',
      action: 'reserve',
      label: userFullName,
    });
  }
};

const postThirdPartyReservationWithOtherCustomer =
  async ({ passengers }) =>
  async (dispatch, getState) => {
    try {
      dispatch(flagPostingReservation(true));

      // Getting active sales session
      const activeSaleSession = getState().SalesUnit.SalesSession.getIn([
        'active',
        'content',
      ]);

      const {
        company: { businessId },
      } = activeSaleSession.get('agency');

      // Validate business from active sales session
      if (businessId === null)
        throw new ApiError(
          null,
          'Este Company no tiene configurado un business',
        );

      // Getting selected seats
      const seatReservations = getState()
        .BookingUnit.Booking.getIn(['seats', 'seatReservations'])
        .toJS();

      // Register reservation
      const {
        id: customerOrderId,
        reservation: { ticketList },
      } = await postReservationRequests({ passengers });

      const { customerId } = ticketList[0];

      // Get default payment method id
      const paymentMethodId = isProduction()
        ? PROD_SELF_SERVICE_PAYMENT_METHOD_ID
        : DEV_SELF_SERVICE_PAYMENT_METHOD_ID;

      // Generate pay payload
      const payReservationPayload = {
        transactionList: [
          {
            customerId,
            paymentMethodId,
            voucherTypeId: BUSINESS_INVOICE_ID,
            voucherCode: null,
            businessId,
            amount: seatReservations[0].listPrice,
          },
        ],
      };

      const url = `${RESERVATION_ENDPOINT}/${customerOrderId}/pay`;

      const payPromise = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payReservationPayload),
      });

      await isErrorResponse(payPromise);

      const payResponse = await payPromise.json();

      dispatch({
        type: POST_PAYMENT,
        payload: payResponse.id,
      });

      // Redirect to confirmation page
      dispatch(
        changeStep(
          THIRD_PARTY_BOOKING_STEPS[
            THIRD_PARTY_BOOKING_CONFIRMATION_STEP_INDEX
          ],
        ),
      );
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagPostingReservation(false));
      const userFullName = getState().authentication
        ? getState().authentication.get('user').customer.fullName
        : '';
      ReactGa.event({
        category: 'booking',
        action: 'reserve',
        label: userFullName,
      });
    }
  };

const flagPostingPayment = (flag) => (dispatch) =>
  dispatch({
    payload: flag,
    type: FLAG_POSTING_PAYMENT,
  });

const postPayment =
  async ({
    payReservationRequest,
    customerOrderId,
    businessTaxId,
    businessName,
    addressSummary,
  }) =>
  async (dispatch, getState) => {
    let newBusiness = null;

    try {
      dispatch(flagPostingPayment(true));

      if (businessTaxId) {
        // new business
        const payloadNewBusiness = {
          name: businessName,
          businessTaxId,
          addressSummary,
        };

        const response = await fetch(BUSINESS_ENDPOINT, {
          ...DEFAULT_POST_CONFIG,
          body: JSON.stringify(payloadNewBusiness),
        });

        await isErrorResponse(response, null, dispatch);

        newBusiness = await response.json();
      }

      let payload = payReservationRequest;
      // by default get business from select, but if a
      // new business has been created, use that value
      if (newBusiness) {
        const newTransactonList = payReservationRequest.transactionList.map(
          (transaction) => ({
            ...transaction,
            businessId: newBusiness.id,
          }),
        );

        payload = {
          ...payReservationRequest,
          transactionList: newTransactonList,
        };
      }

      const url = `${RESERVATION_ENDPOINT}/${customerOrderId}/pay`;
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });

      await isErrorResponse(response, null, dispatch);

      const customerOrder = await response.json();

      dispatch({
        type: POST_PAYMENT,
        payload: customerOrder.id,
      });

      dispatch(
        changeStep(CIVA_BOOKING_STEPS[CIVA_BOOKING_CONFIRMATION_STEP_INDEX]),
      );
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagPostingPayment(false));
      const userFullName = getState().authentication
        ? getState().authentication.get('user').customer.fullName
        : '';
      ReactGa.event({
        category: 'booking',
        action: 'pay',
        label: userFullName,
        value: customerOrderId,
      });
    }
  };

const flagGettingPrintedTicket = (flag) => (dispatch) =>
  dispatch({
    payload: flag,
    type: FLAG_GETTING_PRINTED_TICKET,
  });

const getPrintedTickets = async (reservationId) => async (dispatch) => {
  dispatch(flagGettingPrintedTicket(true));

  const url = `${CUSTOMER_ORDER_ENDPOINT}/${reservationId}/print-tickets`;

  try {
    const response = await fetch(url, DEFAULT_GET_CONFIG);

    // handle an error response from the server
    const serverError = await handleResponseError(response);
    if (serverError) {
      toastr.error('Error', serverError);
      return dispatch(flagGettingPrintedTicket(false));
    }

    // the response is a response instance
    // parse the data into a usable format using `.json()`
    const printedTickets = await response.json();
    dispatch(flagGettingPrintedTicket(false));
    return dispatch({
      type: GET_PRINTED_TICKET,
      payload: printedTickets,
    });
  } catch ({ message }) {
    toastr.error('Error', 'Error obteniendo boletos impresos');
    return dispatch(flagGettingPrintedTicket(false));
  }
};

const clearPrintedTickets = () => (dispatch) =>
  dispatch({
    type: CLEAR_PRINTED_TICKETS,
  });

const getItineraryFoodGroup = async (itineraryId) => async (dispatch) => {
  try {
    const url = `${ITINERARY_ENDPOINT}/${itineraryId}/food-group`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);

    const serverError = await handleResponseError(response);
    if (serverError) {
      return toastr.error('Error', serverError);
    }
    // if response empty send null as payload
    const isEmpty = (await response.clone().text()).length === 0;
    const foodGroup = isEmpty ? null : await response.json();
    return dispatch({
      type: GET_INTINERARY_FOOD_GROUP,
      payload: foodGroup,
    });
  } catch ({ message }) {
    return toastr.error('Error', 'Error obteniendo alimentos');
  }
};

const validateCreditNote = async (reference, customerId) => {
  try {
    const url = `${VALIDATE_CREDIT_NOTE_ENDPOINT}?reference=${reference}&customerId=${customerId}`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);

    const serverError = await handleResponseError(response);
    if (serverError) {
      toastr.error('Error', serverError);
      return null;
    }
    // if response empty send null as payload
    return response.json();
  } catch ({ message }) {
    toastr.error('Error', 'Error validando PIN');
    return null;
  }
};

const applyCreditNote = (creditNote) => (dispatch) =>
  dispatch({
    type: APPLY_CREDIT_NOTE,
    payload: creditNote,
  });

const cancelCreditNote = () => (dispatch) =>
  dispatch({
    type: CANCEL_CREDIT_NOTE,
  });

const flagPostingFixPriceRequest = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_POSTING_FIX_PRICE_REQUEST,
    payload: flag,
  });

const setFixedPriceForCurrentBooking = (newPrices) => (dispatch) => {
  dispatch({
    type: SET_FIXED_PRICE_FOR_CURRENT_BOOKING,
    payload: newPrices,
  });
};

const postFixPriceRequest =
  async ({ fixedTicketPrices }) =>
  async (dispatch) => {
    try {
      dispatch(flagPostingFixPriceRequest(true));

      const url = TICKET_FIXED_PRICE_ENDPOINT;
      const payload = fixedTicketPrices;
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });

      // check if it's an error response
      await isErrorResponse(response, setFixedPriceForCurrentBooking, dispatch);
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagPostingFixPriceRequest(false));
    }
  };

const postSendTicketByEmailSMSToCustomer =
  async ({ email, ticketId }) =>
  async () => {
    try {
      const urlEmail = `${COMPANY_SEND_TICKET_BY_EMAIL_ENDPOINT}/${ticketId}`;
      const payload = [email];
      await fetch(urlEmail, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      const urlSMS = `${COMPANY_SEND_TICKET_BY_SMS_ENDPOINT}/${ticketId}`;
      await fetch(urlSMS, {
        ...DEFAULT_POST_CONFIG,
      });
      toastr.success('Exito', 'Correo y SMS enviado correctamente');
    } catch {
      toastr.error('Error', 'El correo no se envío');
    }
  };

const postSendTicketVoidByEmailSMSToCustomer =
  async ({ email, ticketId }) =>
  async () => {
    try {
      const url = `${COMPANY_SEND_TICKET_VOID_BY_EMAIL_ENDPOINT}/${ticketId}`;
      const payload = [email];
      await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      const urlSMS = `${COMPANY_SEND_TICKET_VOID_BY_SMS_ENDPOINT}/${ticketId}`;
      await fetch(urlSMS, {
        ...DEFAULT_POST_CONFIG,
      });
      toastr.success('Exito', 'Correo y SMS enviado correctamente');
    } catch {
      toastr.error('Error', 'El correo no se envío');
    }
  };

export * from './PostpaidBooking';

export {
  changeStep,
  getPublicCities,
  searchForItineraries,
  selectItinerarySearchResult,
  selectSeat,
  deselectSeat,
  postReservation,
  postPayment,
  getPrintedTickets,
  clearPrintedTickets,
  getItineraryFoodGroup,
  clearBookingState,
  validateCreditNote,
  applyCreditNote,
  cancelCreditNote,
  postFixPriceRequest as postFixedTicketPrice,
  clearItinerariesForBooking,
  clearSelectItinerary,
  clearSeatsForBooking,
  postThirdPartyReservation,
  postSendTicketByEmailSMSToCustomer,
  postSendTicketVoidByEmailSMSToCustomer,
  postThirdPartyReservationWithOtherCustomer,
  selfSearchForItineraries,
};
