import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { CARGO_CORPORATE_CREDIT_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Loader from '../../../common/Loader';
import Content from '../../../layout/Content';
import NoDataResource from '../../../common/resource/NoDataResource';
import CargoCorporateCreditForm from '../cargo-corporate-credit/CargoCorporateCreditForm';
import {
  clearCargoCorporateCredit,
  getCargoCorporateCredit,
  putCargoCorporateCredit,
} from '../../../../actions/cargo/CargoCorporateCredit';

export const EditCargoCorporateCredit = ({
  match: {
    params: { id: cargoCorporateCreditId },
  },
  dispatchGetCargoCorporateCredit,
  breadcrumbs,
  cargoCorporateCredit,
  loading,
  dispatchPutCargoCorporateCredit,
  dispatchClearCargoCorporateCredit,
}) => {
  useLayoutEffect(() => {
    dispatchGetCargoCorporateCredit({ cargoCorporateCreditId });

    return () => dispatchClearCargoCorporateCredit();
  }, []);

  const onSubmit = (formValues) => {
    const newFormValues = { ...formValues };
    newFormValues.id = cargoCorporateCredit.get('id');
    if (!formValues.creditActive) {
      newFormValues.initialBalance = 0;
      newFormValues.creditDays = 0;
      newFormValues.actualBalance = 0;
    }
    if (!formValues.isDiscountAllowed) {
      newFormValues.isDiscountAllowed = false;
    }
    dispatchPutCargoCorporateCredit(cargoCorporateCreditId, newFormValues);
  };

  const generateInitialValues = (cargoCorporateCreditJson) => ({
    ...cargoCorporateCreditJson,
    company: {
      value: cargoCorporateCreditJson.company
        ? cargoCorporateCreditJson.company.id
        : null,
      label: cargoCorporateCreditJson.company
        ? cargoCorporateCreditJson.company.name
        : null,
    },
    customer: {
      value: cargoCorporateCreditJson.customer
        ? cargoCorporateCreditJson.customer.id
        : null,
      label: cargoCorporateCreditJson.customer
        ? cargoCorporateCreditJson.customer.fullName
        : null,
    },
  });

  let content = null;

  if (loading) content = <Loader />;
  else if (cargoCorporateCredit.isEmpty())
    content = <NoDataResource returnPage={CARGO_CORPORATE_CREDIT_PATH} />;
  else
    content = (
      <CargoCorporateCreditForm
        onSubmit={onSubmit}
        initialValues={generateInitialValues(cargoCorporateCredit.toJS())}
        isEdit
      />
    );

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Editar Crédito Corporativo de Carga"
      subtitle="Edita este crédito corporativo"
      content={content}
    />
  );
};

const mapStateToProps = (
  { CargoUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Créditos Corporativos',
      href: CARGO_CORPORATE_CREDIT_PATH,
    },
    {
      text: 'Ver',
      href: `${CARGO_CORPORATE_CREDIT_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  cargoCorporateCredit: CargoUnit.CargoCorporateCredit.getIn([
    'current',
    'content',
  ]),
  loading: !CargoUnit.CargoCorporateCredit.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetCargoCorporateCredit: getCargoCorporateCredit,
  dispatchClearCargoCorporateCredit: clearCargoCorporateCredit,
  dispatchPutCargoCorporateCredit: putCargoCorporateCredit,
};

EditCargoCorporateCredit.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPutCargoCorporateCredit: PropTypes.func.isRequired,
  dispatchGetCargoCorporateCredit: PropTypes.func.isRequired,
  dispatchClearCargoCorporateCredit: PropTypes.func.isRequired,
  match: matchPropTypes.isRequired,
  cargoCorporateCredit: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool,
};

EditCargoCorporateCredit.defaultProps = {
  loading: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditCargoCorporateCredit);
