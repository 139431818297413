import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, FormGroup } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import {
  isRequired,
  validateIntegerNumber,
} from '../../../../utils/validators';
import FormItem from '../../../common/forms/FormItem';
import Loader from '../../../common/Loader';
import FormFooter from '../../../common/forms/FormFooter';
import TextInput from '../../../common/forms/input/TextInput';

export const DeliveryPriceConfigurationForm = ({ handleSubmit, loading }) => {
  if (loading) return <Loader />;

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup row>
        <FormItem label="Mínimo de Kilogramos" required>
          <Field
            name="minKilograms"
            component={TextInput}
            placeholder="Mínimo de Kilogramos"
            disabled
            validate={[isRequired, validateIntegerNumber]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Máximo de Kilogramos" required>
          <Field
            name="maxKilograms"
            component={TextInput}
            placeholder="Máximo de Kilogramos"
            validate={[isRequired, validateIntegerNumber]}
          />
        </FormItem>
      </FormGroup>
      <FormFooter />
    </Form>
  );
};

DeliveryPriceConfigurationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ CargoUnit }) => ({
  loading: !CargoUnit.DeliveryPriceConfiguration.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const formComponent = reduxForm({
  form: 'DeliveryPriceConfigurationForm',
})(DeliveryPriceConfigurationForm);

export default connect(mapStateToProps)(formComponent);
