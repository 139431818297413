import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ConfirmationModal from '../../../../common/modal/ConfirmationModal';
import Select from '../../../../common/forms/select/Select';
import { enumToSelectOptions } from '../../../../../utils/enum';
import { BACK_TO_AGENCY_REASONS } from '../../../../../config/constants';
import { patchReturnItems } from '../../../../../actions';

const DeliveryItemsBackButton = ({
  dispatchPatchReturnItems,
  deliveryId,
  cargoItemIds,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [justification, setJustification] = useState(null);
  const [validJustification, setValidJustification] = useState(false);

  const onClickDelete = () =>
    validJustification &&
    dispatchPatchReturnItems(deliveryId, { cargoItemIds, justification });

  const openModal = () => setShowModal(true);

  const closeModal = () => setShowModal(false);

  const onChangeSelectJustification = (option) => {
    const { label } = option;

    setJustification(label);

    if (label && label.length) {
      setValidJustification(true);
    }
  };

  const modalBody = (
    <Select
      input={{ onChange: onChangeSelectJustification }}
      options={enumToSelectOptions(BACK_TO_AGENCY_REASONS)}
    />
  );

  return (
    <>
      <ConfirmationModal
        show={showModal}
        body={modalBody}
        onClickConfirm={onClickDelete}
        onClickCancel={closeModal}
      />
      <Button color="danger" onClick={openModal}>
        <i className="fa fa-reply" /> Retornar a agencia
      </Button>
    </>
  );
};

DeliveryItemsBackButton.propTypes = {
  dispatchPatchReturnItems: PropTypes.func.isRequired,
  cargoItemIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  deliveryId: PropTypes.number.isRequired,
};

const mapDispatchToProps = {
  dispatchPatchReturnItems: patchReturnItems,
};

export default connect(null, mapDispatchToProps)(DeliveryItemsBackButton);
