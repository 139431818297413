import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'reactstrap';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import { tzNormalizeDate } from '../../../../utils/date';
import { FULL_DATE_TIME_FORMAT } from '../../../../config/locale';
import EnumStatus from '../../../common/EnumStatus';
import { DELIVERY_PRICE_STATUS } from '../../../../config/constants';
import LinkToModuleResource from '../../../common/resource/LinkToModuleResource';
import Alert from '../../../common/informative/Alert';

const DeliveryPriceBasicInformationResource = ({
  id,
  createDate,
  file,
  status,
  user,
  description,
  parcelDeliveryPriceItemList,
}) => {
  const groupedByConfiguration = parcelDeliveryPriceItemList.reduce(
    (acc, item) => {
      item.configurationItems.forEach((config) => {
        if (config.price === null) {
          const key = config.configuration;
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push({
            locationName: item.locationName,
            districtName: item.districtName,
          });
        }
      });
      return acc;
    },
    {},
  );

  const configurationComponents = Object.entries(groupedByConfiguration).map(
    ([configuration, routes]) => (
      <Col lg={6} md={12} key={configuration}>
        <Alert
          type="warning"
          message={
            <>
              <h3>Faltan precios para la configuración {configuration}</h3>
              {routes.map((route, index) => (
                <p key={+index}>
                  De <strong>{route.locationName}</strong> a{' '}
                  <strong>{route.districtName}</strong>
                </p>
              ))}
            </>
          }
        />
      </Col>
    ),
  );

  return (
    <>
      <ResourceProperty label="ID:">{id}</ResourceProperty>
      <ResourceProperty label="Archivo:">
        <LinkToModuleResource
          href={file}
          text={file}
          colorIcon="text-primary"
          isExternalPath
        />
      </ResourceProperty>

      <ResourceProperty label="Estado:">
        <EnumStatus enumObject={DELIVERY_PRICE_STATUS} enumValue={status} />
      </ResourceProperty>
      <ResourceProperty label="Descripción:">{description}</ResourceProperty>
      <ResourceProperty label="Creado por:">{user.fullName}</ResourceProperty>
      <ResourceProperty label="Fecha de Creación:">
        {tzNormalizeDate({ date: createDate, format: FULL_DATE_TIME_FORMAT })}
      </ResourceProperty>
      <Row>{configurationComponents}</Row>
    </>
  );
};

DeliveryPriceBasicInformationResource.propTypes = {
  id: PropTypes.number.isRequired,
  createDate: PropTypes.number.isRequired,
  file: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  user: PropTypes.shape({
    fullName: PropTypes.string.isRequired,
  }).isRequired,
  description: PropTypes.string,
  parcelDeliveryPriceItemList: PropTypes.arrayOf(
    PropTypes.shape({
      locationName: PropTypes.string.isRequired,
      locationId: PropTypes.number.isRequired,
      districtName: PropTypes.string.isRequired,
      districtId: PropTypes.number.isRequired,
      configurationItems: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          configuration: PropTypes.string,
          price: PropTypes.number,
        }),
      ),
    }),
  ).isRequired,
  cargoCorporateCredit: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }),
};

DeliveryPriceBasicInformationResource.defaultProps = {
  description: '',
  cargoCorporateCredit: null,
};

export default DeliveryPriceBasicInformationResource;
