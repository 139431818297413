import Immutable from 'immutable';
import {
  // CARGO_PICKUP_PATH,
  // UNLOADING_CARGO_PATH,
  // MANIFEST_ITINERARY_PATH,
  // ADD_CARGO_ITEM_TO_ITINERARY_PATH,
  // BASE_PRICE_PER_KILOMETER_PATH,
  // GEOGRAPHIC_ZONE_COMBINATION_PATH,
  // DELIVERY_GROUP_PATH,
  // CARGO_DISTANCE_PATH,
  // CARGO_MINIMAL_PRICE_PATH,
  // DEPRECIATION_RULE_PATH,
  HOME_PATH,
  CARGO_UNIT_PATH,
  COMMON_PRODUCT_PATH,
  CARGO_CATEGORY_PATH,
  CARGO_PATH,
  INTERNAL_TRANSFER_PATH,
  LEGAL_ABANDONMENT_PATH,
  MANUAL_SENDER_REMISSION_GUIDE_PATH,
  CARGO_CORPORATE_CREDIT_PATH,
  CARGO_ITEM_PATH,
  MANIFEST_PATH,
  PARCEL_PRICE_PATH,
  PARCEL_PRICE_CONFIGURATION_PATH,
  PARCEL_SERVICE_TYPE_PATH,
  MANIFEST_LEGAL_ABANDONMENT_PATH,
  DELIVERY_BUSINESS_PATH,
  DELIVERY_PATH,
  SHELF_PATH,
  RELOCATION_CARGO_ITEM_PATH,
  DELIVERY_LOCATION_DISTRICT_PATH,
  DELIVERY_PRICE_CONFIGURATION_PATH,
  DELIVERY_PRICE_PATH,
} from '../../../config/paths';

const INITIAL_STATE = Immutable.Map({
  cards: [
    // {
    //   key: 'unloading-cargo',
    //   title: 'Descarga de encomiendas',
    //   path: UNLOADING_CARGO_PATH,
    //   icon: 'minus-circle',
    // },
    // {
    //   key: 'pickup-cargo',
    //   title: 'Entrega de carga',
    //   path: CARGO_PICKUP_PATH,
    //   icon: 'handshake-o',
    // },
    // {
    //   key: 'manifest-itinerary',
    //   title: 'Manifiesto de Itinerarios',
    //   path: MANIFEST_ITINERARY_PATH,
    //   icon: 'paste',
    // },
    // {
    //   key: 'add-item-to-itinerary',
    //   title: 'Agregar Item a Itinerario',
    //   path: ADD_CARGO_ITEM_TO_ITINERARY_PATH,
    //   icon: 'plus-circle',
    // },
    // {
    //   key: 'basePricePerKilometer',
    //   title: 'Precio Base por Kilómetro',
    //   path: BASE_PRICE_PER_KILOMETER_PATH,
    //   icon: 'compress',
    // },
    // {
    //   key: 'geographicZonesCombination',
    //   title: 'Combinación de Zonas Geográficas',
    //   path: GEOGRAPHIC_ZONE_COMBINATION_PATH,
    //   icon: 'retweet',
    // },
    // {
    //   key: 'kilometers-between-cities',
    //   title: 'Kilómetros entre Ciudades',
    //   path: CARGO_DISTANCE_PATH,
    //   icon: 'road',
    // },
    // {
    //   key: 'minimalPrice',
    //   title: 'Precio mínimo',
    //   path: CARGO_MINIMAL_PRICE_PATH,
    //   icon: 'sort-numeric-desc',
    // },
    // {
    //   key: 'delivery-group',
    //   title: 'Grupo Delivery',
    //   path: DELIVERY_GROUP_PATH,
    //   icon: 'motorcycle',
    // },
    // {
    //   key: 'depreciation-rule',
    //   title: 'Reglas de Depreciación',
    //   path: DEPRECIATION_RULE_PATH,
    //   icon: 'percent',
    // },
    {
      key: 'delivery-business',
      title: 'Empresas de Reparto',
      path: DELIVERY_BUSINESS_PATH,
      icon: 'motorcycle',
    },
    {
      key: 'delivery',
      title: 'Repartos',
      path: DELIVERY_PATH,
      icon: 'motorcycle',
    },
    {
      key: 'delivery-location-district',
      title: 'Ubicaciones con Distritos Autorizados para Reparto',
      path: DELIVERY_LOCATION_DISTRICT_PATH,
      icon: 'motorcycle',
    },
    {
      key: 'delivery-price-configuration',
      title: 'Configuraciones de Precio de Reparto',
      path: DELIVERY_PRICE_CONFIGURATION_PATH,
      icon: 'motorcycle',
    },
    {
      key: 'delivery-price',
      title: 'Tarifario de Reparto',
      path: DELIVERY_PRICE_PATH,
      icon: 'money',
    },
    {
      key: 'commonProduct',
      title: 'Producto Frecuente',
      path: COMMON_PRODUCT_PATH,
      icon: 'product-hunt',
    },
    {
      key: 'cargoCategory',
      title: 'Categorías',
      path: CARGO_CATEGORY_PATH,
      icon: 'filter',
    },
    {
      key: 'cargo',
      title: 'Carga',
      path: CARGO_PATH,
      icon: 'archive',
    },
    {
      key: 'internalTransfer',
      title: 'Translado Interno',
      path: INTERNAL_TRANSFER_PATH,
      icon: 'archive',
    },
    {
      key: 'cargo-item',
      title: 'Encomienda',
      path: CARGO_ITEM_PATH,
      icon: 'cube',
    },
    {
      key: 'manifest',
      title: 'Manifiesto de Carga',
      path: MANIFEST_PATH,
      icon: 'paste',
    },
    {
      key: 'depure-legal-abandonment',
      title: 'Depurar Abandono Legal',
      path: LEGAL_ABANDONMENT_PATH,
      icon: 'minus-circle',
    },
    {
      key: 'manifest-legal-abandonment',
      title: 'Manifestar Abandono Legal',
      path: MANIFEST_LEGAL_ABANDONMENT_PATH,
      icon: 'home',
    },
    {
      key: 'manual-grr',
      title: 'Guía de Remision Remitente Manual',
      path: MANUAL_SENDER_REMISSION_GUIDE_PATH,
      icon: 'file-pdf-o',
    },
    {
      key: 'cargo-corporate-credit',
      title: 'Crédito Corporativo',
      path: CARGO_CORPORATE_CREDIT_PATH,
      icon: 'credit-card',
    },
    {
      key: 'parcel-price',
      title: 'Tarifario',
      path: PARCEL_PRICE_PATH,
      icon: 'money',
    },
    {
      key: 'parcel-service-type',
      title: 'Servicio de Tarifario',
      path: PARCEL_SERVICE_TYPE_PATH,
      icon: 'newspaper-o',
    },
    {
      key: 'parcel-price-configurations',
      title: 'Configuraciones de Tarifario',
      path: PARCEL_PRICE_CONFIGURATION_PATH,
      icon: 'cog',
    },
    {
      key: 'shelves',
      title: 'Anaqueles',
      path: SHELF_PATH,
      icon: 'bars',
    },
    {
      key: 'relocation-cargo-item',
      title: 'Reubicar Encomiendas',
      path: RELOCATION_CARGO_ITEM_PATH,
      icon: 'retweet',
    },
  ],
  breadcrumbs: [
    {
      text: 'Inicio',
      tag: 'a',
      href: HOME_PATH,
    },
    {
      text: 'Cargo',
      tag: 'span',
      href: CARGO_UNIT_PATH,
    },
  ],
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
