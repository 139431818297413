import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ConfirmationModal from '../../../common/modal/ConfirmationModal';
import { deleteDeliveryLocationDistrict } from '../../../../actions';

const DeliveryLocationDistrictDeleteButton = ({
  dispatchDeleteDeliveryLocationDistrict,
  locationId,
  disabled,
}) => {
  const [showModal, setShowModal] = useState(false);

  const onClickDelete = () =>
    dispatchDeleteDeliveryLocationDistrict({ locationId });

  const openModal = () => setShowModal(true);

  const closeModal = () => setShowModal(false);

  return (
    <>
      <ConfirmationModal
        show={showModal}
        onClickConfirm={onClickDelete}
        onClickCancel={closeModal}
      />
      <Button color="danger" onClick={openModal} disabled={disabled}>
        <i className="fa fa-trash-o" /> Eliminar
      </Button>
    </>
  );
};

DeliveryLocationDistrictDeleteButton.propTypes = {
  dispatchDeleteDeliveryLocationDistrict: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  locationId: PropTypes.number.isRequired,
};

DeliveryLocationDistrictDeleteButton.defaultProps = {
  disabled: false,
};

const mapDispatchToProps = {
  dispatchDeleteDeliveryLocationDistrict: deleteDeliveryLocationDistrict,
};

export default connect(
  null,
  mapDispatchToProps,
)(DeliveryLocationDistrictDeleteButton);
