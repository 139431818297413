import { toastr } from 'react-redux-toastr';
import QueryString from 'query-string';
import { push } from 'react-router-redux';
import {
  FLAG_PARCEL_PRICE_ACTIVITY,
  GET_PARCEL_PRICES,
  CLEAR_PARCEL_PRICES,
  GET_PARCEL_PRICE,
  CLEAR_PARCEL_PRICE,
} from '../types/cargo/ParcelPrice';
import { isErrorResponse } from '../../utils/error-handlers';
import { PARCEL_PRICE_ENDPOINT } from '../../config/endpoints';
import { BASIC_POST_CONFIG, DEFAULT_GET_CONFIG } from '../../config/rest';
import { PARCEL_PRICE_PATH } from '../../config/paths';
import { PARCEL_TEMPLATE_FILENAME } from '../../config/constants';

const flagGettingParcelPrices = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_PARCEL_PRICE_ACTIVITY,
    payload: flag,
  });

const getParcelPrices =
  async (tableFilters = null) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingParcelPrices(true));
      const query = tableFilters;
      const url = `${PARCEL_PRICE_ENDPOINT}?${QueryString.stringify(query)}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      await isErrorResponse(response);
      const parcelPrices = await response.json();
      dispatch({ type: GET_PARCEL_PRICES, payload: parcelPrices });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagGettingParcelPrices(false));
    }
  };

const clearParcelPrices = () => (dispatch) =>
  dispatch({
    type: CLEAR_PARCEL_PRICES,
  });

const getParcelPrice =
  async ({ parcelPriceId }) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingParcelPrices(true));
      const url = `${PARCEL_PRICE_ENDPOINT}/${parcelPriceId}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      await isErrorResponse(response);
      const parcelPrice = await response.json();
      dispatch({ type: GET_PARCEL_PRICE, payload: parcelPrice });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagGettingParcelPrices(false));
    }
  };

const clearParcelPrice = () => (dispatch) =>
  dispatch({
    type: CLEAR_PARCEL_PRICE,
  });

const flagParcelPriceActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_PARCEL_PRICE_ACTIVITY,
    payload: flag,
  });

const postParcelPrice =
  async ({ description, file, cargoCorporateCreditId }) =>
  async (dispatch) => {
    try {
      dispatch(flagParcelPriceActivity(true));
      const url = `${PARCEL_PRICE_ENDPOINT}/import-data`;
      const formData = new FormData();
      formData.append('file', file);
      formData.append('description', description || '');
      if (cargoCorporateCreditId) {
        formData.append('cargoCorporateCreditId', cargoCorporateCreditId);
      }
      const response = await fetch(url, {
        ...BASIC_POST_CONFIG,
        body: formData,
      });
      await isErrorResponse(response);
      const parcelPrice = await response.json();
      dispatch(push(`${PARCEL_PRICE_PATH}/${parcelPrice.id}`));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagParcelPriceActivity(false));
    }
  };

const getExcelTemplate =
  async ({ cargoCorporateCreditId }) =>
  async (dispatch) => {
    try {
      dispatch(flagParcelPriceActivity(true));
      const url = `${PARCEL_PRICE_ENDPOINT}/template${
        cargoCorporateCreditId
          ? `?cargoCorporateCreditId=${cargoCorporateCreditId}`
          : ''
      }`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      await isErrorResponse(response);
      const blob = await response.blob();
      const downloadUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = PARCEL_TEMPLATE_FILENAME;
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagParcelPriceActivity(false));
    }
  };

export {
  flagGettingParcelPrices,
  getParcelPrices,
  clearParcelPrices,
  getParcelPrice,
  clearParcelPrice,
  postParcelPrice,
  getExcelTemplate,
};
