import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ConfirmationModal from '../../../common/modal/ConfirmationModal';
import { deleteDeliveryPriceConfiguration } from '../../../../actions';

const DeliveryPriceConfigurationDeleteButton = ({
  dispatchDeleteDeliveryPriceConfiguration,
  deliveryPriceConfigurationId,
  disabled,
}) => {
  const [showModal, setShowModal] = useState(false);

  const onClickDelete = () =>
    dispatchDeleteDeliveryPriceConfiguration({ deliveryPriceConfigurationId });

  const openModal = () => setShowModal(true);

  const closeModal = () => setShowModal(false);

  return (
    <>
      <ConfirmationModal
        show={showModal}
        onClickConfirm={onClickDelete}
        onClickCancel={closeModal}
      />
      <Button color="danger" onClick={openModal} disabled={disabled}>
        <i className="fa fa-trash-o" /> Eliminar
      </Button>
    </>
  );
};

DeliveryPriceConfigurationDeleteButton.propTypes = {
  dispatchDeleteDeliveryPriceConfiguration: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  deliveryPriceConfigurationId: PropTypes.number.isRequired,
};

DeliveryPriceConfigurationDeleteButton.defaultProps = {
  disabled: false,
};

const mapDispatchToProps = {
  dispatchDeleteDeliveryPriceConfiguration: deleteDeliveryPriceConfiguration,
};

export default connect(
  null,
  mapDispatchToProps,
)(DeliveryPriceConfigurationDeleteButton);
