import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import {
  DELIVERY_BUSINESS_PATH,
  NEW_DELIVERY_BUSINESS_PATH,
} from '../../../../config/paths';
import {
  clearDeliveryBusinesses,
  getDeliveryBusinesses,
} from '../../../../actions/cargo/DeliveryBusiness';
import { DELIVERY_BUSINESS_COLUMNS } from '../../../../config/columns';

export const DeliveryBusinesses = ({
  breadcrumbs,
  deliveryBusinesses,
  loading,
  dispatchGetDeliveryBusinesses,
  dispatchClearDeliveryBusinesses,
}) => {
  useLayoutEffect(() => () => dispatchClearDeliveryBusinesses(), []);

  const data = deliveryBusinesses.get('content') || [];
  const pages = deliveryBusinesses.get('totalPages') || null;
  const defaultPageSize = DEFAULT_PAGE_SIZE;

  const extraButtons = (
    <>
      <Link
        className="btn btn-primary mr-2"
        to={`${DELIVERY_BUSINESS_PATH}-assign-cargo`}
      >
        <i className="fa fa-pencil-square-o" /> Asignar encomiendas
      </Link>{' '}
    </>
  );

  return (
    <ModuleList
      title="Lista de Empresas de Reparto"
      breadcrumbs={breadcrumbs}
      buttonPath={NEW_DELIVERY_BUSINESS_PATH}
      extraButtons={extraButtons}
      tableStructure={{
        columns: DELIVERY_BUSINESS_COLUMNS,
        data,
        pages,
        defaultPageSize,
        filterable: true,
        fetchData: dispatchGetDeliveryBusinesses,
        modelPath: DELIVERY_BUSINESS_PATH,
        loading,
        navigateToModelOnRowClick: true,
      }}
    />
  );
};

const mapDispatchToProps = {
  dispatchGetDeliveryBusinesses: getDeliveryBusinesses,
  dispatchClearDeliveryBusinesses: clearDeliveryBusinesses,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Empresas de Reparto',
      href: DELIVERY_BUSINESS_PATH,
    },
  ],
  deliveryBusinesses: CargoUnit.DeliveryBusiness.getIn(['all', 'content']),
  loading: CargoUnit.DeliveryBusiness.getIn(['all', 'loading']),
});

DeliveryBusinesses.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  deliveryBusinesses: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetDeliveryBusinesses: PropTypes.func.isRequired,
  dispatchClearDeliveryBusinesses: PropTypes.func.isRequired,
};

DeliveryBusinesses.defaultProps = {
  deliveryBusinesses: null,
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryBusinesses);
