import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, FormGroup } from 'reactstrap';
import { Field, Form, reduxForm } from 'redux-form';
import FormItem from '../../../../common/forms/FormItem';
import TextInput from '../../../../common/forms/input/TextInput';
import { isRequired } from '../../../../../utils/validators';
import FormFooter from '../../../../common/forms/FormFooter';

export const DeliveryItemDeliveredModal = ({ handleSubmit, onCancel }) => (
  <Form onSubmit={handleSubmit}>
    <h4>Se entrega a: </h4>
    <FormGroup row className="mt-4">
      <FormItem label="DNI" required>
        <Field
          name="idDocumentNumber"
          component={TextInput}
          type="text"
          placeholder="DNI"
          validate={[isRequired]}
        />
      </FormItem>
    </FormGroup>
    <FormGroup row className="mt-4">
      <FormItem label="Nombres y Apellidos" required>
        <Field
          name="name"
          component={TextInput}
          type="text"
          placeholder="Nombre y apellidos"
          validate={[isRequired]}
        />
      </FormItem>
    </FormGroup>
    <FormFooter
      saveButtonColor="secondary"
      saveButtonIcon="fa fa-share"
      saveButtonText="Entregar"
    >
      <Button color="primary" onClick={onCancel}>
        Cancelar
      </Button>
    </FormFooter>
  </Form>
);

DeliveryItemDeliveredModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

const formComponent = reduxForm({
  form: 'DeliveryItemDeliveredModal',
})(DeliveryItemDeliveredModal);

export default connect()(formComponent);
