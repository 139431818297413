import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, FormGroup, Label } from 'reactstrap';
import { Field, formValueSelector, reduxForm, change } from 'redux-form';
import { isRequired } from '../../../../utils/validators';
import FormItem from '../../../common/forms/FormItem';
import Loader from '../../../common/Loader';
import FormFooter from '../../../common/forms/FormFooter';
import TextInput from '../../../common/forms/input/TextInput';
import { MAX_SHELF_SPACE_SIZE } from '../../../../config/constants';
import LocationSelect from '../../../common/forms/select/LocationSelect';
import './Grid.css';

const selector = formValueSelector('ShelfForm');

const validateSize = (value) => {
  if (value < 1) return 'Debe ser mayor a 0';
  if (value > MAX_SHELF_SPACE_SIZE)
    return `Máximo permitido: ${MAX_SHELF_SPACE_SIZE}`;
  return undefined;
};

const getColumnLetter = (index) => {
  let letter = '';
  let num = index;
  while (num >= 0) {
    letter = String.fromCharCode((num % 26) + 65) + letter;
    num = Math.floor(num / 26) - 1;
  }
  return letter;
};

const renderGrid = (width, height, disabledCells, toggleCellState) => {
  if (!width || !height) return null;

  if (width > MAX_SHELF_SPACE_SIZE || height > MAX_SHELF_SPACE_SIZE)
    return null;

  return (
    <div className="grid-container">
      <div
        className="grid-layout"
        style={{
          gridTemplateColumns: `repeat(${width}, minmax(50px, 1fr))`,
          gridTemplateRows: `repeat(${height}, minmax(50px, 1fr))`,
        }}
      >
        {Array.from({ length: height }).map((_, rowIndex) =>
          // eslint-disable-next-line no-shadow
          Array.from({ length: width }).map((_, colIndex) => {
            const cellKey = `space-${rowIndex}-${colIndex}`;
            const cellName = `${getColumnLetter(colIndex)}${rowIndex + 1}`;
            const isDisabled = disabledCells.includes(
              `${rowIndex}-${colIndex}`,
            );

            return (
              <div
                key={cellKey}
                className={`grid-cell ${
                  isDisabled ? 'grid-cell-disabled' : ''
                }`}
              >
                <Field
                  name={cellKey}
                  component={TextInput}
                  placeholder={cellName}
                  disabled
                  className={`grid-input ${
                    isDisabled ? 'grid-input-disabled' : 'grid-input-enabled'
                  }`}
                />
                <button
                  type="button"
                  onClick={() => toggleCellState(`${rowIndex}-${colIndex}`)}
                  className={`grid-button ${
                    isDisabled ? 'grid-button-disabled' : 'grid-button-enabled'
                  }`}
                >
                  {isDisabled ? '✓' : 'X'}
                </button>
              </div>
            );
          }),
        )}
      </div>
    </div>
  );
};

const ShelfForm = ({
  handleSubmit,
  onSubmit,
  loading,
  width,
  height,
  disabledCells,
  dispatchChange,
  edit,
}) => {
  const [disabledInput, setDisabledInput] = useState(edit);

  if (loading) return <Loader />;

  const toggleCellState = (cellKey) => {
    const updatedList = Array.isArray(disabledCells) ? [...disabledCells] : [];
    if (updatedList.includes(cellKey)) {
      dispatchChange(
        'ShelfForm',
        'disabledCells',
        updatedList.filter((key) => key !== cellKey),
      );
    } else {
      updatedList.push(cellKey);
      dispatchChange('ShelfForm', 'disabledCells', updatedList);
    }
  };

  const handleFormSubmit = (formValues) => {
    const shelfData = [];

    for (let row = 0; row < height; row += 1) {
      for (let col = 0; col < width; col += 1) {
        const cellName = getColumnLetter(col) + (row + 1);
        const isDisabled = disabledCells.includes(`${row}-${col}`);

        shelfData.push({
          spaceName: cellName,
          rowPosition: row + 1,
          columnPosition: col + 1,
          disabled: isDisabled,
        });
      }
    }

    onSubmit(formValues, shelfData);
  };

  return (
    <Form onSubmit={handleSubmit(handleFormSubmit)}>
      <FormGroup row>
        <FormItem label="Nombre" required>
          <Field
            name="name"
            component={TextInput}
            placeholder="Nombre"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Ubicación" required>
          <Field
            name="locationId"
            component={LocationSelect}
            placeholder="Ubicación"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      {edit && (
        <FormGroup row>
          <FormItem>
            <Label>
              <Field
                name="disabledWithAndHeight"
                id="disabledWithAndHeight"
                component="input"
                type="checkbox"
                onChange={(e) => setDisabledInput(!e.target.checked)}
              />{' '}
              Modificar Alto y Ancho
            </Label>
          </FormItem>
        </FormGroup>
      )}
      <FormGroup row>
        <FormItem label="Columnas" required>
          <Field
            name="width"
            component={TextInput}
            placeholder="Columnas"
            type="number"
            disabled={disabledInput}
            validate={[isRequired, validateSize]}
            parse={(value) => (value ? Number(value) : 0)}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Filas" required>
          <Field
            name="height"
            component={TextInput}
            placeholder="Filas"
            type="number"
            disabled={disabledInput}
            validate={[isRequired, validateSize]}
            parse={(value) => (value ? Number(value) : 0)}
          />
        </FormItem>
      </FormGroup>
      {edit && (
        <FormGroup row>
          <FormItem label="Estado" required>
            <Label>
              <Field name="active" component="input" type="checkbox" /> Activo
            </Label>
          </FormItem>
        </FormGroup>
      )}

      <Field name="disabledCells" component="input" type="hidden" />

      {renderGrid(width, height, disabledCells, toggleCellState)}

      <FormFooter />
    </Form>
  );
};

ShelfForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  width: PropTypes.number,
  edit: PropTypes.bool,
  height: PropTypes.number,
  disabledCells: PropTypes.arrayOf(PropTypes.string).isRequired,
  dispatchChange: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loading: !state.CargoUnit.Shelf.getIn(['current', 'activity']).isEmpty(),
  width: selector(state, 'width') || 0,
  height: selector(state, 'height') || 0,
  disabledCells: selector(state, 'disabledCells') || [],
});

const mapDispatchToProps = {
  dispatchChange: change,
};

ShelfForm.defaultProps = {
  loading: false,
  width: null,
  height: null,
  edit: false,
};

const formComponent = reduxForm({
  form: 'ShelfForm',
})(ShelfForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
