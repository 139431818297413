import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import { DELIVERY_LOCATION_DISTRICT_PATH } from '../../../../config/paths';
import {
  clearDeliveryLocationDistrict,
  getDeliveryLocationDistrict,
} from '../../../../actions';
import DeliveryLocationDistrictBasicInformationResource from './resource/DeliveryLocationDistrictBasicInformationResource';
import DeliveryLocationDistrictToolbar from './DeliveryLocationDistrictToolbar';

export const DeliveryLocationDistrict = ({
  breadcrumbs,
  dispatchGetDeliveryLocationDistrict,
  dispatchClearDeliveryLocationDistrict,
  match: {
    params: { id: locationId },
  },
  loading,
  deliveryLocationDistrict,
}) => {
  useLayoutEffect(() => {
    dispatchGetDeliveryLocationDistrict({ locationId });

    return () => dispatchClearDeliveryLocationDistrict();
  }, []);

  let content;
  let toolbar;

  if (loading) content = <Loader />;
  else if (deliveryLocationDistrict.isEmpty())
    content = <NoDataResource returnPage={DELIVERY_LOCATION_DISTRICT_PATH} />;
  else {
    const deliveryLocationDistrictJson = deliveryLocationDistrict.toJS();

    content = (
      <DeliveryLocationDistrictBasicInformationResource
        {...deliveryLocationDistrictJson}
      />
    );

    toolbar = (
      <DeliveryLocationDistrictToolbar
        locationId={deliveryLocationDistrictJson.locationId}
      />
    );
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      toolbar={toolbar}
      title="Ubicación con Distritos Autorizados para Reparto"
      content={content}
    />
  );
};

DeliveryLocationDistrict.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchClearDeliveryLocationDistrict: PropTypes.func.isRequired,
  dispatchGetDeliveryLocationDistrict: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  deliveryLocationDistrict: PropTypes.instanceOf(Immutable.Map).isRequired,
};

DeliveryLocationDistrict.defaultProps = {
  loading: false,
};

const mapDispatchToProps = {
  dispatchClearDeliveryLocationDistrict: clearDeliveryLocationDistrict,
  dispatchGetDeliveryLocationDistrict: getDeliveryLocationDistrict,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Ubicaciones con Distritos Autorizados para Reparto',
      href: DELIVERY_LOCATION_DISTRICT_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  deliveryLocationDistrict: CargoUnit.DeliveryLocationDistrict.getIn([
    'current',
    'content',
  ]),
  loading: !CargoUnit.DeliveryLocationDistrict.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeliveryLocationDistrict);
