import React from 'react';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import deliveryPriceConfigurationBasicInformationPropTypes from '../proptypes/DeliveryPriceConfigurationPropTypes';

const DeliveryPriceConfigurationBasicInformationResource = ({
  minKilograms,
  maxKilograms,
}) => (
  <>
    <ResourceProperty label="Mínimo de Kilogramos:">
      {minKilograms !== null && minKilograms !== undefined ? minKilograms : '-'}
    </ResourceProperty>
    <ResourceProperty label="Máximo de Kilogramos:">
      {maxKilograms || '-'}
    </ResourceProperty>
  </>
);

DeliveryPriceConfigurationBasicInformationResource.propTypes =
  deliveryPriceConfigurationBasicInformationPropTypes;

export default DeliveryPriceConfigurationBasicInformationResource;
