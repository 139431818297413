import Immutable from 'immutable';
import {
  FLAG_GETTING_CABIN_CREWS,
  GET_CABIN_CREWS,
  CLEAR_CABIN_CREWS,
  FLAG_CABIN_CREW_ACTIVITY,
  CLEAR_CABIN_CREW,
  GET_CABIN_CREW,
  FLAG_GETTING_CABIN_CREWS_TRIPS,
  GET_CABIN_CREWS_TRIPS,
  CLEAR_CABIN_CREWS_TRIPS,
} from '../../../actions/types/traffic';
import { COLLECTION_SHAPE } from '../../../config/shapes';

const INITIAL_STATE = Immutable.Map({
  all: Immutable.Map({
    content: Immutable.Map(COLLECTION_SHAPE),
    loading: false,
  }),
  current: Immutable.Map({
    content: Immutable.Map(),
    activity: Immutable.List(),
    loadingTrips: false,
    trips: Immutable.Map(),
  }),
});

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case FLAG_GETTING_CABIN_CREWS:
      return state.setIn(['all', 'loading'], payload);
    case GET_CABIN_CREWS:
      return state.setIn(['all', 'content'], Immutable.Map(payload));
    case CLEAR_CABIN_CREWS:
      return state.setIn(
        ['all', 'content'],
        INITIAL_STATE.getIn(['all', 'content']),
      );
    case FLAG_CABIN_CREW_ACTIVITY:
      if (payload) {
        return state.setIn(
          ['current', 'activity'],
          state.getIn(['current', 'activity']).push(payload),
        );
      }

      return state.setIn(
        ['current', 'activity'],
        state.getIn(['current', 'activity']).pop(),
      );
    case GET_CABIN_CREW:
      return state.setIn(['current', 'content'], Immutable.Map(payload));
    case CLEAR_CABIN_CREW:
      return state.setIn(['current'], INITIAL_STATE.getIn(['current']));
    case FLAG_GETTING_CABIN_CREWS_TRIPS:
      return state.setIn(['current', 'loadingTrips'], payload);
    case GET_CABIN_CREWS_TRIPS:
      return state.setIn(['current', 'trips'], Immutable.Map(payload));
    case CLEAR_CABIN_CREWS_TRIPS:
      return state.setIn(
        ['current', 'trips'],
        INITIAL_STATE.getIn(['current', 'trips']),
      );
    default:
      return state;
  }
};
