import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import { CABIN_CREW_PATH } from '../../../../config/paths';
import {
  clearCabinCrew,
  getCabinCrew,
  putCabinCrew,
} from '../../../../actions';
import CabinCrewForm from './CabinCrewForm';
import { tzNormalizeDate } from '../../../../utils/date';
import { TIMESTAMP_FORMAT } from '../../../../config/locale';

const EditCabinCrew = ({
  breadcrumbs,
  dispatchGetCabinCrew,
  dispatchClearCabinCrew,
  dispatchPutCabinCrew,
  match: {
    params: { id: cabinCrewId },
  },
  loading,
  cabinCrew,
}) => {
  useLayoutEffect(() => {
    dispatchGetCabinCrew({ cabinCrewId });

    return () => dispatchClearCabinCrew();
  }, []);

  const onSubmit = (formValues) => {
    const newFormValues = {
      homeBaseId: formValues.homeBase.value,
      customerId: formValues.customer.value,
      cabinCrewTypeId: formValues.cabinCrewType.value,
      crewStatusId: formValues.crewStatus.value,
    };

    newFormValues.cabinCrewDocumentList = formValues.cabinCrewDocuments
      ? formValues.cabinCrewDocuments.map((document) => ({
          id: document.id,
          cabinCrewDocumentTypeId: document.cabinCrewDocumentType.value,
          documentNumber: document.documentNumber,
          effectiveDate: tzNormalizeDate({
            date: document.effectiveDate,
            format: TIMESTAMP_FORMAT,
          }),
          expirationDate: tzNormalizeDate({
            date: document.expirationDate,
            format: TIMESTAMP_FORMAT,
          }),
          note: document.note,
        }))
      : [];

    newFormValues.cabinCrewContractList = formValues.cabinCrewContracts
      ? formValues.cabinCrewContracts.map((contract) => ({
          id: contract.id,
          cabinCrewContractTypeId: contract.cabinCrewContractType.value,
          reason: contract.reason,
          effectiveDate: tzNormalizeDate({
            date: contract.effectiveDate,
            format: TIMESTAMP_FORMAT,
          }),
          expirationDate: tzNormalizeDate({
            date: contract.expirationDate,
            format: TIMESTAMP_FORMAT,
          }),
          note: contract.note,
        }))
      : [];

    dispatchPutCabinCrew(cabinCrewId, newFormValues);
  };

  const generateInitialValues = ({
    customer,
    cabinCrewType,
    location,
    crewStatus,
    cabinCrewDocumentList,
    cabinCrewContractList,
  }) => ({
    customer: {
      value: customer.id,
      label: `${customer.firstName} ${customer.lastName} | DNI: ${customer.idDocumentNumber} (PE)`,
    },
    cabinCrewType: {
      value: cabinCrewType.id,
      label: cabinCrewType.name,
    },
    homeBase: {
      value: location.id,
      label: location.name,
    },
    crewStatus: {
      value: crewStatus.id,
      label: crewStatus.name,
    },
    cabinCrewContracts: cabinCrewContractList.map((contract) => ({
      id: contract.id,
      cabinCrewContractType: {
        value: contract.cabinCrewContractType.id,
        label: contract.cabinCrewContractType.name,
      },
      reason: contract.reason,
      effectiveDate: contract.effectiveDate,
      expirationDate: contract.expirationDate,
      note: contract.note,
    })),
    cabinCrewDocuments: cabinCrewDocumentList.map((document) => ({
      id: document.id,
      cabinCrewDocumentType: {
        value: document.cabinCrewDocumentType.id,
        label: document.cabinCrewDocumentType.name,
      },
      documentNumber: document.documentNumber,
      effectiveDate: document.effectiveDate,
      expirationDate: document.expirationDate,
      note: document.note,
    })),
  });

  let content;

  if (loading) content = <Loader />;
  else if (cabinCrew.isEmpty())
    content = <NoDataResource returnPage={CABIN_CREW_PATH} />;
  else
    content = (
      <CabinCrewForm
        onSubmit={onSubmit}
        initialValues={generateInitialValues(cabinCrew.toJS())}
      />
    );

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Editar Servicio a Bordo"
      subtitle="Editar servicio a bordo"
      content={content}
    />
  );
};

EditCabinCrew.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchClearCabinCrew: PropTypes.func.isRequired,
  dispatchGetCabinCrew: PropTypes.func.isRequired,
  dispatchPutCabinCrew: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  cabinCrew: PropTypes.instanceOf(Immutable.Map).isRequired,
};

EditCabinCrew.defaultProps = {
  loading: false,
};

const mapDispatchToProps = {
  dispatchClearCabinCrew: clearCabinCrew,
  dispatchGetCabinCrew: getCabinCrew,
  dispatchPutCabinCrew: putCabinCrew,
};

const mapStateToProps = (
  { TrafficUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...TrafficUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Servicio a Bordo',
      href: CABIN_CREW_PATH,
    },
    {
      text: 'Ver',
      href: `${CABIN_CREW_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  cabinCrew: TrafficUnit.CabinCrew.getIn(['current', 'content']),
  loading: !TrafficUnit.CabinCrew.getIn(['current', 'activity']).isEmpty(),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditCabinCrew);
