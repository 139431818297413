import React from 'react';
import PropTypes from 'prop-types';
import { change, reduxForm } from 'redux-form';
import { Button, Form } from 'reactstrap';
import { connect } from 'react-redux';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import FormFooter from '../../../common/forms/FormFooter';
import PaymentMethodInputGroup from '../../../common/forms/PaymentMethodInputGroup';
import {
  PAYMENT_METHOD_PAGO_EN_DESTINO,
  PAYMENT_METHOD_BUSINESS_CREDIT,
  CREDIT_NOTE,
} from '../../../../config/constants';

const PayParcelForm = ({
  handleSubmit,
  handleProcess,
  parcel,
  onCancel,
  dispatchChange,
}) => {
  const onShowVoucherCode = () => {
    dispatchChange('PayParcelForm', 'voucherCode', '');
  };

  return (
    <>
      <Form onSubmit={handleSubmit(handleProcess)}>
        <ResourceProperty label="Serie:">
          {parcel.documentSeries}
        </ResourceProperty>
        <ResourceProperty label="Número de serie:">
          {parcel.documentCode}
        </ResourceProperty>
        <ResourceProperty label="Origen:">
          {parcel.sourceCityName}
        </ResourceProperty>
        <ResourceProperty label="Destino:">
          {parcel.destinationCityName}
        </ResourceProperty>
        <hr />
        <PaymentMethodInputGroup
          onShowVoucherCode={onShowVoucherCode}
          transactionCodesNotIncluded={[
            PAYMENT_METHOD_PAGO_EN_DESTINO,
            PAYMENT_METHOD_BUSINESS_CREDIT,
            CREDIT_NOTE,
          ]}
        />
        <FormFooter
          saveButtonColor="secondary"
          saveButtonIcon="fa fa-money"
          saveButtonText="Pagar"
        >
          <Button color="primary" onClick={onCancel}>
            Cancelar
          </Button>
        </FormFooter>
      </Form>
    </>
  );
};

PayParcelForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  parcel: PropTypes.shape({
    documentSeries: PropTypes.string,
    documentCode: PropTypes.string,
    sourceCityName: PropTypes.string,
    destinationCityName: PropTypes.string,
  }).isRequired,
  onCancel: PropTypes.func.isRequired,
  handleProcess: PropTypes.func.isRequired,
  dispatchChange: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchChange: change,
};

const formComponent = reduxForm({
  form: 'PayParcelForm',
})(PayParcelForm);

export default connect(null, mapDispatchToProps)(formComponent);
