import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import { PROCESS_ENDPOINT } from '../../config/endpoints';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_DELETE_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import {
  generateItineraryByProcessStatusEditPath,
  PROCESS_PATH,
} from '../../config/paths';
import {
  CLEAR_ACTIVE_PROCESS,
  CLEAR_ACTIVE_PROCESSES,
  CLEAR_PROCESS,
  CLEAR_PROCESSES,
  FLAG_ACTIVE_PROCESS_ACTIVITY,
  FLAG_GETTING_ACTIVE_PROCESSES,
  FLAG_GETTING_PROCESSES,
  FLAG_PROCESS_ACTIVITY,
  GET_ACTIVE_PROCESS,
  GET_ACTIVE_PROCESSES,
  GET_PROCESS,
  GET_PROCESSES,
} from '../types';

const flagGettingProcesses = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_PROCESSES,
    payload: flag,
  });

const getProcesses = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingProcesses(true));
    const query = tableFilters;
    const url = `${PROCESS_ENDPOINT}?${QueryString.stringify(query)}`;
    const promise = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(promise, null, dispatch);
    const response = await promise.json();
    dispatch({
      type: GET_PROCESSES,
      payload: response,
    });
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagGettingProcesses(false));
  }
};

const clearProcesses = () => (dispatch) =>
  dispatch({
    type: CLEAR_PROCESSES,
  });

const flagProcessActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_PROCESS_ACTIVITY,
    payload: flag,
  });

const getProcess =
  async ({ processId }) =>
  async (dispatch) => {
    try {
      dispatch(flagProcessActivity(true));
      const url = `${PROCESS_ENDPOINT}/${processId}`;
      const promise = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(promise, null, dispatch);
      const response = await promise.json();
      dispatch({
        type: GET_PROCESS,
        payload: response,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagProcessActivity(false));
    }
  };

const clearProcess = () => (dispatch) =>
  dispatch({
    type: CLEAR_PROCESS,
  });

const postProcess =
  async ({ companyItineraryRequestList }) =>
  async (dispatch) => {
    try {
      dispatch(flagProcessActivity(true));
      const payload = companyItineraryRequestList;
      const url = PROCESS_ENDPOINT;
      const promise = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise, null, dispatch);
      const process = await promise.json();

      dispatch(push(generateItineraryByProcessStatusEditPath(process.id)));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagProcessActivity(false));
    }
  };

const deleteProcess =
  async ({ processId }) =>
  async (dispatch) => {
    try {
      dispatch(flagProcessActivity(true));
      const url = `${PROCESS_ENDPOINT}/${processId}`;
      const promise = await fetch(url, {
        ...DEFAULT_DELETE_CONFIG,
      });
      await isErrorResponse(promise, null, dispatch);
      await promise.json();
      dispatch(push(PROCESS_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagProcessActivity(false));
    }
  };

const putProcess =
  async (processId, { companyItineraryRequestList }) =>
  async (dispatch) => {
    try {
      dispatch(flagProcessActivity(true));
      const payload = companyItineraryRequestList;
      const url = `${PROCESS_ENDPOINT}/${processId}`;
      const promise = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise);
      dispatch(push(`${PROCESS_PATH}/${processId}`));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagProcessActivity(false));
    }
  };

const putProcessSchedule =
  async (processId, itineraryScheduleProcessRequestList) =>
  async (dispatch) => {
    try {
      dispatch(flagProcessActivity(true));
      const payload = itineraryScheduleProcessRequestList;
      const url = `${PROCESS_ENDPOINT}/${processId}/change-schedule`;
      const promise = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise, null, dispatch);
      const process = await promise.json();
      dispatch(push(`${PROCESS_PATH}/${process.id}`));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagProcessActivity(false));
    }
  };

const postDuplicateProcess =
  async (processId, { startDate }) =>
  async (dispatch) => {
    try {
      dispatch(flagProcessActivity(true));
      const payload = startDate;
      const url = `${PROCESS_ENDPOINT}/${processId}/duplicate`;
      const promise = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise, null, dispatch);
      const process = await promise.json();

      dispatch(push(generateItineraryByProcessStatusEditPath(process.id)));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagProcessActivity(false));
    }
  };

const flagGettingActiveProcesses = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_ACTIVE_PROCESSES,
    payload: flag,
  });

const getProcessByUser =
  async (mobilizing, tableFilters) => async (dispatch) => {
    try {
      dispatch(flagGettingActiveProcesses(true));
      const query = { mobilizing, ...tableFilters };
      const url = `${PROCESS_ENDPOINT}/user?${QueryString.stringify(query)}`;
      const promise = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(promise);
      const response = await promise.json();
      dispatch({
        type: GET_ACTIVE_PROCESSES,
        payload: response,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagGettingActiveProcesses(false));
    }
  };

const clearActiveProcesses = () => (dispatch) =>
  dispatch({
    type: CLEAR_ACTIVE_PROCESSES,
  });

const flagActiveProcessActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_ACTIVE_PROCESS_ACTIVITY,
    payload: flag,
  });

const getActiveProcess = async (process) => async (dispatch) => {
  try {
    dispatch(flagActiveProcessActivity(true));
    dispatch({
      type: GET_ACTIVE_PROCESS,
      payload: process,
    });
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagActiveProcessActivity(false));
  }
};

const clearActiveProcess = () => (dispatch) =>
  dispatch({
    type: CLEAR_ACTIVE_PROCESS,
  });

export {
  flagGettingProcesses,
  getProcesses,
  clearProcesses,
  flagProcessActivity,
  getProcess,
  postProcess,
  clearProcess,
  deleteProcess,
  putProcessSchedule,
  postDuplicateProcess,
  putProcess,
  clearActiveProcesses,
  getProcessByUser,
  getActiveProcess,
  clearActiveProcess,
};
