import React, { useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SelectFilter from './SelectFilter';
import {
  clearCargoCorporateCredits,
  getCargoCorporateCredits,
} from '../../../actions/cargo/CargoCorporateCredit';
import { DEFAULT_QUERY_GET_ALL } from '../../../config/queries';
import { optionsPropTypes } from '../forms/select/SelectPropTypes';

const CargoCorporateCreditFilter = ({
  onChangeFilter,
  isMulti,
  loading,
  isClearable,
  placeholder,
  dispatchGetCargoCorporateCredits,
  dispatchClearCargoCorporateCredits,
  commonProductOptions,
  ...args
}) => {
  useLayoutEffect(() => {
    dispatchGetCargoCorporateCredits({ ...DEFAULT_QUERY_GET_ALL });
    return () => dispatchClearCargoCorporateCredits();
  }, []);

  return (
    <SelectFilter
      placeholder={placeholder}
      isMulti={isMulti}
      onChangeFilter={onChangeFilter}
      options={commonProductOptions}
      isLoading={loading}
      isClearable={isClearable}
      {...args}
    />
  );
};

CargoCorporateCreditFilter.propTypes = {
  placeholder: PropTypes.string,
  onChangeFilter: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  loading: PropTypes.bool,
  isClearable: PropTypes.bool,
  commonProductOptions: optionsPropTypes,
  dispatchGetCargoCorporateCredits: PropTypes.func.isRequired,
  dispatchClearCargoCorporateCredits: PropTypes.func.isRequired,
};

CargoCorporateCreditFilter.defaultProps = {
  placeholder: 'Ingrese nombre del corporativo',
  isMulti: false,
  loading: false,
  isClearable: false,
  commonProductOptions: [],
};

const mapStateToProps = ({ CargoUnit }) => ({
  loading: !CargoUnit.CargoCorporateCredit.getIn(['all', 'content', 'content']),

  commonProductOptions: CargoUnit.CargoCorporateCredit.getIn([
    'all',
    'content',
    'content',
  ]).map((commonProduct) => ({
    value: commonProduct.id,
    label: commonProduct.name,
  })),
});

const mapDispatchToProps = {
  dispatchGetCargoCorporateCredits: getCargoCorporateCredits,
  dispatchClearCargoCorporateCredits: clearCargoCorporateCredits,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CargoCorporateCreditFilter);
