import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, FormGroup } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { isRequired } from '../../../../utils/validators';
import FormItem from '../../../common/forms/FormItem';
import Loader from '../../../common/Loader';
import FormFooter from '../../../common/forms/FormFooter';
import CustomerInputGroup from '../../../common/forms/CustomerInputGroup';
import CabinCrewTypesSelect from '../../../common/forms/select/CabinCrewTypesSelect';
import LocationSelect from '../../../common/forms/select/LocationSelect';
import CrewStatusSelect from '../../../common/forms/select/CrewStatusSelect';
import DynamicForm from '../../../common/forms/DynamicForm';
import {
  CABIN_CREW_CONTRACT_FORM_COLUMNS,
  CABIN_CREW_DOCUMENT_FORM_COLUMNS,
} from '../../../../config/dynamicFormFields';

export const CabinCrewForm = ({ handleSubmit, loading }) => {
  if (loading) return <Loader />;

  return (
    <Form onSubmit={handleSubmit}>
      <CustomerInputGroup
        label="Cliente"
        labelRequired
        name="customer"
        form="CabinCrewForm"
        validate={[isRequired]}
        showDetails
      />
      <h3>Documentos</h3>
      <DynamicForm
        name="cabinCrewDocuments"
        columns={CABIN_CREW_DOCUMENT_FORM_COLUMNS}
      />
      <h3>Estado</h3>
      <FormGroup row>
        <FormItem label="Tipo" required>
          <Field
            name="cabinCrewType"
            component={CabinCrewTypesSelect}
            placeholder="Tipo"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Base" required>
          <Field
            name="homeBase"
            component={LocationSelect}
            placeholder="Base"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Estado" required>
          <Field
            name="crewStatus"
            component={CrewStatusSelect}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <h3>Contratos</h3>
      <DynamicForm
        name="cabinCrewContracts"
        columns={CABIN_CREW_CONTRACT_FORM_COLUMNS}
      />
      <FormFooter />
    </Form>
  );
};

CabinCrewForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ TrafficUnit }) => ({
  loading: !TrafficUnit.CabinCrew.getIn(['current', 'activity']).isEmpty(),
});

const formComponent = reduxForm({
  form: 'CabinCrewForm',
})(CabinCrewForm);

export default connect(mapStateToProps)(formComponent);
