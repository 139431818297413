import Immutable from 'immutable';

import { COLLECTION_SHAPE } from '../../../config/shapes';
import {
  CLEAR_DELIVERY_PRICE_CONFIGURATION,
  CLEAR_DELIVERY_PRICE_CONFIGURATIONS,
  CLEAR_DELIVERY_LAST_CONFIGURATION,
  FLAG_DELIVERY_PRICE_CONFIGURATION_ACTIVITY,
  FLAG_GETTING_DELIVERY_PRICE_CONFIGURATIONS,
  FLAG_GETTING_DELIVERY_LAST_CONFIGURATION,
  GET_DELIVERY_PRICE_CONFIGURATION,
  GET_DELIVERY_PRICE_CONFIGURATIONS,
  GET_DELIVERY_LAST_CONFIGURATION,
} from '../../../actions/types';

const INITIAL_STATE = Immutable.Map({
  all: Immutable.Map({
    content: Immutable.Map(COLLECTION_SHAPE),
    loading: false,
  }),
  current: Immutable.Map({
    content: Immutable.Map(),
    activity: Immutable.List(),
  }),
  last: Immutable.Map({
    content: Immutable.Map(),
    loading: false,
  }),
});

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case FLAG_GETTING_DELIVERY_PRICE_CONFIGURATIONS:
      return state.setIn(['all', 'loading'], payload);
    case GET_DELIVERY_PRICE_CONFIGURATIONS:
      return state.setIn(['all', 'content'], Immutable.Map(payload));
    case CLEAR_DELIVERY_PRICE_CONFIGURATIONS:
      return state.setIn(
        ['all', 'content'],
        INITIAL_STATE.getIn(['all', 'content']),
      );
    case FLAG_DELIVERY_PRICE_CONFIGURATION_ACTIVITY:
      if (payload) {
        return state.setIn(
          ['current', 'activity'],
          state.getIn(['current', 'activity']).push(payload),
        );
      }

      return state.setIn(
        ['current', 'activity'],
        state.getIn(['current', 'activity']).pop(),
      );
    case GET_DELIVERY_PRICE_CONFIGURATION:
      return state.setIn(['current', 'content'], Immutable.Map(payload));
    case CLEAR_DELIVERY_PRICE_CONFIGURATION:
      return state.setIn(['current'], INITIAL_STATE.getIn(['current']));
    case FLAG_GETTING_DELIVERY_LAST_CONFIGURATION:
      return state.setIn(['last', 'loading'], payload);
    case GET_DELIVERY_LAST_CONFIGURATION:
      return state.setIn(['last', 'content'], Immutable.Map(payload));
    case CLEAR_DELIVERY_LAST_CONFIGURATION:
      return state.setIn(
        ['last', 'content'],
        INITIAL_STATE.getIn(['last', 'content']),
      );
    default:
      return state;
  }
};
