import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { MAX_PAGE_SIZE } from '../../config/constants';
import { COMPANY_PARENT_CITIES_ENDPOINT } from '../../config/endpoints';
import { DEFAULT_QUERY } from '../../config/queries';
import {
  CLEAR_CHILD_CITIES,
  CLEAR_DESTINATION_PARENT_CITIES,
  CLEAR_ORIGIN_PARENT_CITIES,
  FLAG_GETTING_CHILD_CITIES,
  FLAG_GETTING_DESTINATION_PARENT_CITIES,
  FLAG_GETTING_ORIGIN_PARENT_CITIES,
  GET_CHILD_CITIES,
  GET_DESTINATION_PARENT_CITIES,
  GET_ORIGIN_PARENT_CITIES,
} from '../types/my-reservations';
import { DEFAULT_GET_CONFIG } from '../../config/rest';
import { handleResponseError } from '../../utils/error-handlers';

const flagGettingOriginParentCities = (flag) => (dispatch) =>
  dispatch({
    payload: flag,
    type: FLAG_GETTING_ORIGIN_PARENT_CITIES,
  });

const flagGettingDestinationParentCities = (flag) => (dispatch) =>
  dispatch({
    payload: flag,
    type: FLAG_GETTING_DESTINATION_PARENT_CITIES,
  });

const flagGettingChildCities = (flag) => (dispatch) =>
  dispatch({
    payload: flag,
    type: FLAG_GETTING_CHILD_CITIES,
  });

const clearOriginParentCities = () => (dispatch) =>
  dispatch({ type: CLEAR_ORIGIN_PARENT_CITIES });

const clearDestinationParentCities = () => (dispatch) =>
  dispatch({ type: CLEAR_DESTINATION_PARENT_CITIES });

const clearChildCities = () => (dispatch) =>
  dispatch({ type: CLEAR_CHILD_CITIES });

const getParentCities = async (mobilizing, processId) => async (dispatch) => {
  dispatch(flagGettingOriginParentCities(true));
  const query = {
    ...DEFAULT_QUERY,
    size: MAX_PAGE_SIZE,
    mobilizing,
    processId,
  };

  const url = `${COMPANY_PARENT_CITIES_ENDPOINT}?${QueryString.stringify(
    query,
  )}`;

  try {
    const response = await fetch(url, DEFAULT_GET_CONFIG);

    const serverError = await handleResponseError(response);
    if (serverError) {
      toastr.error('Error', serverError);
      dispatch(flagGettingOriginParentCities(false));
      return;
    }

    const parentCities = await response.json();
    const cities = parentCities.content;

    const originParentCities = {
      ...parentCities,
      content: cities.filter((city) => city.origin === true),
    };

    const destinationParentCities = {
      ...parentCities,
      content: cities.filter((city) => city.origin === false),
    };

    dispatch({
      type: GET_ORIGIN_PARENT_CITIES,
      payload: originParentCities,
    });

    dispatch({
      type: GET_DESTINATION_PARENT_CITIES,
      payload: destinationParentCities,
    });
  } catch ({ message }) {
    toastr.error('Error', `Error obteniendo ciudades: ${message}`);
  } finally {
    dispatch(flagGettingOriginParentCities(false));
  }
};

const getChildCities = async (childCities) => async (dispatch) => {
  dispatch(flagGettingChildCities(true));
  try {
    dispatch({
      type: GET_CHILD_CITIES,
      payload: childCities,
    });
  } catch ({ message }) {
    toastr.error('Error', `Error obteniendo ciudades: ${message}`);
  } finally {
    dispatch(flagGettingChildCities(false));
  }
};

export {
  getChildCities,
  clearChildCities,
  getParentCities,
  clearOriginParentCities,
  clearDestinationParentCities,
  flagGettingChildCities,
  flagGettingDestinationParentCities,
  flagGettingOriginParentCities,
};
