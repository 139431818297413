import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import { CABIN_CREW_PATH } from '../../../../config/paths';
import {
  clearCabinCrew,
  clearCabinCrewTrips,
  getCabinCrew,
  getCabinCrewTrips,
} from '../../../../actions';
import CabinCrewBasicInformationResource from './resource/CabinCrewBasicInformationResource';
import CabinCrewToolbar from './CabinCrewToolbar';

export const CabinCrew = ({
  breadcrumbs,
  dispatchGetCabinCrew,
  dispatchClearCabinCrew,
  dispatchGetCabinCrewTrips,
  dispatchClearCabinCrewTrips,
  match: {
    params: { id: cabinCrewId },
  },
  loading,
  cabinCrew,
  loadingTrips,
  cabinCrewTrips,
}) => {
  useLayoutEffect(() => {
    dispatchGetCabinCrew({ cabinCrewId });

    return () => {
      dispatchClearCabinCrew();
      dispatchClearCabinCrewTrips();
    };
  }, []);

  const dispatchGetCabinCrewTripsTable = (tableFilters) => {
    dispatchGetCabinCrewTrips({ cabinCrewId }, tableFilters);
  };

  let content;
  let toolbar;

  if (loading) content = <Loader />;
  else if (cabinCrew.isEmpty())
    content = <NoDataResource returnPage={CABIN_CREW_PATH} />;
  else {
    const cabinCrewJson = cabinCrew.toJS();

    content = (
      <CabinCrewBasicInformationResource
        {...cabinCrewJson}
        loadingTrips={loadingTrips}
        cabinCrewTrips={cabinCrewTrips}
        dispatchGetCabinCrewTripsTable={dispatchGetCabinCrewTripsTable}
      />
    );

    toolbar = <CabinCrewToolbar cabinCrewId={cabinCrewJson.id} />;
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      toolbar={toolbar}
      title="Servicio a Bordo"
      content={content}
    />
  );
};

CabinCrew.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchClearCabinCrew: PropTypes.func.isRequired,
  dispatchGetCabinCrew: PropTypes.func.isRequired,
  dispatchGetCabinCrewTrips: PropTypes.func.isRequired,
  dispatchClearCabinCrewTrips: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  cabinCrew: PropTypes.instanceOf(Immutable.Map).isRequired,
  loadingTrips: PropTypes.bool,
  cabinCrewTrips: PropTypes.instanceOf(Immutable.Map).isRequired,
};

CabinCrew.defaultProps = {
  loading: false,
  loadingTrips: false,
};

const mapDispatchToProps = {
  dispatchClearCabinCrew: clearCabinCrew,
  dispatchGetCabinCrew: getCabinCrew,
  dispatchGetCabinCrewTrips: getCabinCrewTrips,
  dispatchClearCabinCrewTrips: clearCabinCrewTrips,
};

const mapStateToProps = ({ TrafficUnit }) => ({
  breadcrumbs: [
    ...TrafficUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Servicio a Bordo',
      href: CABIN_CREW_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  cabinCrew: TrafficUnit.CabinCrew.getIn(['current', 'content']),
  loading: !TrafficUnit.CabinCrew.getIn(['current', 'activity']).isEmpty(),
  cabinCrewTrips: TrafficUnit.CabinCrew.getIn(['current', 'trips']),
  loadingTrips: TrafficUnit.CabinCrew.getIn(['current', 'loadingTrips']),
});

export default connect(mapStateToProps, mapDispatchToProps)(CabinCrew);
