import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { MY_RESERVATIONS_UNIT_PATH } from '../../../config/paths';
import { clearTicket, getTicket } from '../../../actions';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../common/resource/proptypes/CommonPropTypes';
import Loader from '../../common/Loader';
import NoDataResource from '../../common/resource/NoDataResource';
import ResourceProperty from '../../common/resource/ResourceProperty';
import { tzNormalizeDate } from '../../../utils/date';
import { DATE_TIME_FORMAT } from '../../../config/locale';
import CreditNote from '../reservation/ticket/CreditNote';
import { numberFormatter } from '../../../utils/number';
import { TICKET_STATUS } from '../../../config/constants';
import TicketVoid from '../reservation/ticket/TicketVoid';
import Refund from '../reservation/ticket/Refund';
import EnumStatus from '../../common/EnumStatus';
import History from '../reservation/ticket/History';
import TicketToolbar from '../reservation/ticket/ticket-toolbar/TicketToolbar';
import Content from '../../layout/Content';

export const MyReservation = ({
  breadcrumbs,
  dispatchGetTicket,
  dispatchClearTicket,
  ticket,
  match: {
    params: { id: ticketId },
  },
  activity,
}) => {
  useLayoutEffect(() => {
    dispatchGetTicket({ ticketId });

    return () => {
      dispatchClearTicket();
    };
  }, []);

  let ticketVoidComponent = null;
  let creditNoteComponent = null;
  let refundComponent = null;

  let content;
  let toolbar;
  let title = 'Boleto';

  if (activity) {
    content = <Loader />;
  } else if (ticket.isEmpty()) {
    content = <NoDataResource returnPage={MY_RESERVATIONS_UNIT_PATH} />;
  } else {
    const ticketJson = ticket.toJS();

    const {
      creditNote,
      nextTicketId,
      previousTicketId,
      ticketStatus: status,
      sourceLocation,
      destinationLocation,
      departureDate,
      seatReservation,
      nextTicketCode,
      previousTicketCode,
      customer,
      listPrice,
      ticketVoid,
      user,
      transactions,
      fullDocumentCode,
      documentAssignmentDate,
    } = ticketJson;

    const {
      voucherType: { id: voucherTypeId },
      business,
      voucherType,
      paymentMethod,
    } = transactions[0];

    const showHistory = nextTicketId || previousTicketId;

    const tripData = (
      <>
        <h3>Viaje</h3>
        <ResourceProperty label="Origen:">
          {sourceLocation.name}
        </ResourceProperty>
        <ResourceProperty label="Destino:">
          {destinationLocation.name}
        </ResourceProperty>
        <ResourceProperty label="Embarque:">
          {`${sourceLocation.name} - ${sourceLocation.address}`}
        </ResourceProperty>
        <ResourceProperty label="Desembarque:">
          {`${destinationLocation.name} - ${destinationLocation.address}`}
        </ResourceProperty>
        <ResourceProperty label="Fecha de Salida:">
          {departureDate
            ? tzNormalizeDate({
                date: departureDate,
                format: DATE_TIME_FORMAT,
              })
            : '-'}
        </ResourceProperty>
        <ResourceProperty label="Asiento:">
          {seatReservation ? seatReservation.seatNumber : '-'}
        </ResourceProperty>
        <ResourceProperty label="Piso:">
          {seatReservation ? seatReservation.floorNumber : '-'}
        </ResourceProperty>
      </>
    );

    const passengerData = (
      <>
        <h3>Pasajero</h3>
        <ResourceProperty label="Nombre Completo:">
          {customer.fullName}
        </ResourceProperty>
        <ResourceProperty label="Documento de identificación:">
          {`${customer.identificationType.name} ${customer.idDocumentNumber}`}
        </ResourceProperty>
        <ResourceProperty label="País:">
          {`${customer.idCountryOfOrigin}`}
        </ResourceProperty>
        <ResourceProperty label="Celular:">
          {customer.mobilePhone || '-'}
        </ResourceProperty>
        <ResourceProperty label="Email:">
          {customer.email || '-'}
        </ResourceProperty>
      </>
    );

    const paymentData = (
      <>
        <h3>Pago</h3>
        <ResourceProperty label="Método de Pago:">
          {paymentMethod.name}
        </ResourceProperty>
        <ResourceProperty label="Precio de Lista:">
          {listPrice &&
            numberFormatter({
              value: listPrice,
            })}
        </ResourceProperty>
        <ResourceProperty label="Precio de Venta:">
          {numberFormatter({
            value: ticketJson.salePrice,
          })}
        </ResourceProperty>
      </>
    );

    if (creditNote)
      creditNoteComponent = <CreditNote creditNote={creditNote} />;

    const businessData = business && (
      <>
        <h3>Factura</h3>
        <ResourceProperty label="RUC:">
          {business.businessTaxId}
        </ResourceProperty>
        <ResourceProperty label="Razón Social:">
          {business.name}
        </ResourceProperty>
      </>
    );

    const userData = (
      <>
        <h3>Usuario</h3>
        <ResourceProperty label="Nombre Completo:">
          {user.customer.fullName}
        </ResourceProperty>
      </>
    );

    if (ticketVoid)
      ticketVoidComponent = <TicketVoid ticketVoid={ticketVoid} />;

    if (status === TICKET_STATUS.REFUNDED.value) refundComponent = <Refund />;

    content = (
      <>
        <Row>
          <Col lg={6} md={12}>
            <ResourceProperty label="Tipo de Comprobante:">
              {voucherType.name}
            </ResourceProperty>
            <ResourceProperty label="Fecha de Emisión:">
              {documentAssignmentDate
                ? tzNormalizeDate({
                    date: documentAssignmentDate,
                    format: DATE_TIME_FORMAT,
                  })
                : null}
            </ResourceProperty>
            <ResourceProperty label="Estado:">
              <EnumStatus enumObject={TICKET_STATUS} enumValue={status} />
            </ResourceProperty>
            {tripData}
            {passengerData}
            {paymentData}
            {businessData}
            {userData}
          </Col>
          <Col lg={6} md={12}>
            {showHistory && (
              <History
                nextTicketId={nextTicketId}
                nextTicketCode={nextTicketCode}
                previousTicketId={previousTicketId}
                previousTicketCode={previousTicketCode}
              />
            )}
            {ticketVoidComponent}
            {creditNoteComponent}
            {refundComponent}
          </Col>
        </Row>
      </>
    );

    toolbar = (
      <TicketToolbar
        creditNote={creditNote || null}
        ticketId={+ticketId}
        ticketStatus={status}
        voucherTypeId={voucherTypeId}
        departureDate={departureDate}
      />
    );

    title = `Boleto ${fullDocumentCode}`;
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      toolbar={toolbar}
      title={title}
      content={content}
    />
  );
};

MyReservation.propTypes = {
  breadcrumbs: breadcrumbsPropTypes,
  dispatchGetTicket: PropTypes.func.isRequired,
  dispatchClearTicket: PropTypes.func.isRequired,
  ticket: PropTypes.instanceOf(Immutable.Map).isRequired,
  match: matchPropTypes.isRequired,
  activity: PropTypes.bool.isRequired,
};

MyReservation.defaultProps = {
  breadcrumbs: [],
};

const mapStateToProps = ({ ReservationUnit, MyReservationsUnit }) => ({
  breadcrumbs: [
    ...MyReservationsUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Reservaciones',
      href: MY_RESERVATIONS_UNIT_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  ticket: ReservationUnit.Ticket.getIn(['current', 'content']),
  activity: !ReservationUnit.Ticket.getIn(['current', 'activity']).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetTicket: getTicket,
  dispatchClearTicket: clearTicket,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyReservation);
