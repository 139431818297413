import Immutable from 'immutable';
import { COLLECTION_SHAPE } from '../../../config/shapes';
import {
  CLEAR_CHILD_CITIES,
  CLEAR_DESTINATION_PARENT_CITIES,
  CLEAR_ORIGIN_PARENT_CITIES,
  FLAG_GETTING_CHILD_CITIES,
  FLAG_GETTING_DESTINATION_PARENT_CITIES,
  FLAG_GETTING_ORIGIN_PARENT_CITIES,
  GET_CHILD_CITIES,
  GET_DESTINATION_PARENT_CITIES,
  GET_ORIGIN_PARENT_CITIES,
} from '../../../actions/types/my-reservations';

const INITIAL_STATE = Immutable.Map({
  originParent: Immutable.Map({
    content: Immutable.Map(COLLECTION_SHAPE),
    loading: false,
  }),
  destinationParent: Immutable.Map({
    content: Immutable.Map(COLLECTION_SHAPE),
    loading: false,
  }),
  child: Immutable.Map({
    content: Immutable.Set(),
    loading: false,
  }),
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ORIGIN_PARENT_CITIES:
      return state.setIn(
        ['originParent', 'content'],
        Immutable.Map(action.payload),
      );
    case FLAG_GETTING_ORIGIN_PARENT_CITIES:
      return state.setIn(['originParent', 'loading'], action.payload);
    case CLEAR_ORIGIN_PARENT_CITIES:
      return state.setIn(
        ['originParent', 'content'],
        INITIAL_STATE.getIn(['originParent', 'content']),
      );
    case GET_DESTINATION_PARENT_CITIES:
      return state.setIn(
        ['destinationParent', 'content'],
        Immutable.Map(action.payload),
      );
    case FLAG_GETTING_DESTINATION_PARENT_CITIES:
      return state.setIn(['destinationParent', 'loading'], action.payload);
    case CLEAR_DESTINATION_PARENT_CITIES:
      return state.setIn(
        ['destinationParent', 'content'],
        INITIAL_STATE.getIn(['destinationParent', 'content']),
      );
    case GET_CHILD_CITIES:
      return state.setIn(['child', 'content'], Immutable.Set(action.payload));
    case FLAG_GETTING_CHILD_CITIES:
      return state.setIn(['child', 'loading'], action.payload);
    case CLEAR_CHILD_CITIES:
      return state.setIn(
        ['child', 'content'],
        INITIAL_STATE.getIn(['child', 'content']),
      );
    default:
      return state;
  }
};
