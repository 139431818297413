import QueryString from 'query-string';
import { push } from 'react-router-redux';
import { toastr } from 'react-redux-toastr';
import {
  FLAG_GETTING_CABIN_CREWS,
  GET_CABIN_CREWS,
  CLEAR_CABIN_CREWS,
  FLAG_CABIN_CREW_ACTIVITY,
  GET_CABIN_CREW,
  CLEAR_CABIN_CREW,
  GET_CABIN_CREWS_TRIPS,
  FLAG_GETTING_CABIN_CREWS_TRIPS,
  CLEAR_CABIN_CREWS_TRIPS,
} from '../types/traffic';
import {
  CABIN_CREW_ENDPOINT,
  generateCabinCrewTripsEndpoint,
  generateFreeCabinCrewsEndpoint,
} from '../../config/endpoints';
import {
  DEFAULT_DELETE_CONFIG,
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import { CABIN_CREW_PATH } from '../../config/paths';

const flagGettingCabinCrews = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_CABIN_CREWS,
    payload: flag,
  });

const getCabinCrews = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingCabinCrews(true));
    const query = tableFilters;
    const url = `${CABIN_CREW_ENDPOINT}?${QueryString.stringify(query)}`;
    const promise = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(promise, null, dispatch);
    const response = await promise.json();
    dispatch({
      type: GET_CABIN_CREWS,
      payload: response,
    });
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagGettingCabinCrews(false));
  }
};

const clearCabinCrews = () => (dispatch) =>
  dispatch({
    type: CLEAR_CABIN_CREWS,
  });

const flagCabinCrewActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_CABIN_CREW_ACTIVITY,
    payload: flag,
  });

const getCabinCrew =
  async ({ cabinCrewId }) =>
  async (dispatch) => {
    try {
      dispatch(flagCabinCrewActivity(true));
      const url = `${CABIN_CREW_ENDPOINT}/${cabinCrewId}`;
      const promise = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(promise, null, dispatch);
      const response = await promise.json();
      dispatch({
        type: GET_CABIN_CREW,
        payload: response,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagCabinCrewActivity(false));
    }
  };

const clearCabinCrew = () => (dispatch) =>
  dispatch({
    type: CLEAR_CABIN_CREW,
  });

const postCabinCrew =
  async ({
    homeBaseId,
    customerId,
    crewStatusId,
    cabinCrewTypeId,
    cabinCrewContractList,
    cabinCrewDocumentList,
  }) =>
  async (dispatch) => {
    try {
      dispatch(flagCabinCrewActivity(true));
      const payload = {
        homeBaseId,
        customerId,
        crewStatusId,
        cabinCrewTypeId,
        cabinCrewContractList,
        cabinCrewDocumentList,
      };
      const url = CABIN_CREW_ENDPOINT;
      const promise = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise, null, dispatch);
      await promise.json();
      dispatch(push(CABIN_CREW_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagCabinCrewActivity(false));
    }
  };

const deleteCabinCrew =
  async ({ cabinCrewId }) =>
  async (dispatch) => {
    try {
      dispatch(flagCabinCrewActivity(true));
      const url = `${CABIN_CREW_ENDPOINT}/${cabinCrewId}`;
      const promise = await fetch(url, {
        ...DEFAULT_DELETE_CONFIG,
      });
      await isErrorResponse(promise, null, dispatch);
      await promise.json();
      dispatch(push(CABIN_CREW_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagCabinCrewActivity(false));
    }
  };

const putCabinCrew =
  async (
    cabinCrewId,
    {
      homeBaseId,
      customerId,
      crewStatusId,
      cabinCrewTypeId,
      cabinCrewContractList,
      cabinCrewDocumentList,
    },
  ) =>
  async (dispatch) => {
    try {
      dispatch(flagCabinCrewActivity(true));
      const payload = {
        id: cabinCrewId,
        homeBaseId,
        customerId,
        crewStatusId,
        cabinCrewTypeId,
        cabinCrewContractList,
        cabinCrewDocumentList,
      };
      const url = `${CABIN_CREW_ENDPOINT}/${cabinCrewId}`;
      const promise = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise, null, dispatch);
      await promise.json();
      dispatch(push(CABIN_CREW_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagCabinCrewActivity(false));
    }
  };

const getFreeCabinCrews =
  async ({ itineraryId }) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingCabinCrews(true));
      const url = generateFreeCabinCrewsEndpoint(itineraryId);
      const response = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(response, null, dispatch);
      const cabinCrews = await response.json();
      dispatch({
        type: GET_CABIN_CREWS,
        payload: cabinCrews,
      });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagGettingCabinCrews(false));
    }
  };

const flagGettingCabinCrewTrips = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_CABIN_CREWS_TRIPS,
    payload: flag,
  });

const getCabinCrewTrips =
  async ({ cabinCrewId }, tableFilters) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingCabinCrewTrips(true));
      const query = tableFilters;
      const url = `${generateCabinCrewTripsEndpoint(
        cabinCrewId,
      )}?${QueryString.stringify(query)}`;
      const promise = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(promise, null, dispatch);
      const response = await promise.json();
      dispatch({
        type: GET_CABIN_CREWS_TRIPS,
        payload: response,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagGettingCabinCrewTrips(false));
    }
  };

const clearCabinCrewTrips = () => (dispatch) =>
  dispatch({
    type: CLEAR_CABIN_CREWS_TRIPS,
  });

export {
  flagGettingCabinCrews,
  getCabinCrews,
  clearCabinCrews,
  getCabinCrew,
  clearCabinCrew,
  getFreeCabinCrews,
  postCabinCrew,
  deleteCabinCrew,
  putCabinCrew,
  clearCabinCrewTrips,
  getCabinCrewTrips,
};
