import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_DELETE_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import {
  CLEAR_DELIVERY_LOCATION_DISTRICT,
  CLEAR_DELIVERY_LOCATION_DISTRICTS,
  FLAG_DELIVERY_LOCATION_DISTRICT_ACTIVITY,
  FLAG_GETTING_DELIVERY_LOCATION_DISTRICTS,
  GET_DELIVERY_LOCATION_DISTRICT,
  GET_DELIVERY_LOCATION_DISTRICTS,
} from '../types';
import { DELIVERY_LOCATION_DISTRICT_PATH } from '../../config/paths';
import { DELIVERY_LOCATION_DISTRICT_ENDPOINT } from '../../config/endpoints';

const flagGettingDeliveryLocationDistricts = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_DELIVERY_LOCATION_DISTRICTS,
    payload: flag,
  });

const getDeliveryLocationDistricts =
  async (tableFilters) => async (dispatch) => {
    try {
      dispatch(flagGettingDeliveryLocationDistricts(true));
      const query = tableFilters;
      const url = `${DELIVERY_LOCATION_DISTRICT_ENDPOINT}?${QueryString.stringify(
        query,
      )}`;
      const promise = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(promise, null, dispatch);
      const response = await promise.json();
      dispatch({
        type: GET_DELIVERY_LOCATION_DISTRICTS,
        payload: response,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagGettingDeliveryLocationDistricts(false));
    }
  };

const clearDeliveryLocationDistricts = () => (dispatch) =>
  dispatch({
    type: CLEAR_DELIVERY_LOCATION_DISTRICTS,
  });

const flagDeliveryLocationDistrictActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_DELIVERY_LOCATION_DISTRICT_ACTIVITY,
    payload: flag,
  });

const getDeliveryLocationDistrict =
  async ({ locationId }) =>
  async (dispatch) => {
    try {
      dispatch(flagDeliveryLocationDistrictActivity(true));
      const url = `${DELIVERY_LOCATION_DISTRICT_ENDPOINT}/${locationId}`;
      const promise = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(promise, null, dispatch);
      const response = await promise.json();
      dispatch({
        type: GET_DELIVERY_LOCATION_DISTRICT,
        payload: response,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagDeliveryLocationDistrictActivity(false));
    }
  };

const clearDeliveryLocationDistrict = () => (dispatch) =>
  dispatch({
    type: CLEAR_DELIVERY_LOCATION_DISTRICT,
  });

const postDeliveryLocationDistrict =
  async ({ locationId, districtIds }) =>
  async (dispatch) => {
    try {
      dispatch(flagDeliveryLocationDistrictActivity(true));
      const payload = {
        locationId,
        districtIds,
      };
      const url = DELIVERY_LOCATION_DISTRICT_ENDPOINT;
      const promise = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise, null, dispatch);
      await promise.json();
      dispatch(push(DELIVERY_LOCATION_DISTRICT_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagDeliveryLocationDistrictActivity(false));
    }
  };

const deleteDeliveryLocationDistrict =
  async ({ locationId }) =>
  async (dispatch) => {
    try {
      dispatch(flagDeliveryLocationDistrictActivity(true));
      const url = `${DELIVERY_LOCATION_DISTRICT_ENDPOINT}/${locationId}`;
      const promise = await fetch(url, {
        ...DEFAULT_DELETE_CONFIG,
      });
      await isErrorResponse(promise, null, dispatch);
      dispatch(push(DELIVERY_LOCATION_DISTRICT_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagDeliveryLocationDistrictActivity(false));
    }
  };

const putDeliveryLocationDistrict =
  async (locationId, { locationId: currentLocationId, districtIds }) =>
  async (dispatch) => {
    try {
      dispatch(flagDeliveryLocationDistrictActivity(true));
      const payload = {
        locationId: currentLocationId,
        districtIds,
      };
      const url = `${DELIVERY_LOCATION_DISTRICT_ENDPOINT}/${locationId}`;
      const promise = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise, null, dispatch);
      const response = await promise.json();
      dispatch(
        push(`${DELIVERY_LOCATION_DISTRICT_PATH}/${response.locationId}`),
      );
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagDeliveryLocationDistrictActivity(false));
    }
  };

export {
  flagGettingDeliveryLocationDistricts,
  getDeliveryLocationDistricts,
  clearDeliveryLocationDistricts,
  flagDeliveryLocationDistrictActivity,
  getDeliveryLocationDistrict,
  postDeliveryLocationDistrict,
  clearDeliveryLocationDistrict,
  deleteDeliveryLocationDistrict,
  putDeliveryLocationDistrict,
};
