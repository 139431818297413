import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, FormGroup, Label } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import {
  isRequired,
  validateLength,
  validateNumber,
  validateYear,
} from '../../../../utils/validators';
import FormItem from '../../../common/forms/FormItem';
import Loader from '../../../common/Loader';
import FormFooter from '../../../common/forms/FormFooter';
import TextInput from '../../../common/forms/input/TextInput';
import DynamicForm from '../../../common/forms/DynamicForm';
import { REGISTERED_BUS_DOCUMENT_COLUMNS } from '../../../../config/dynamicFormFields';
import LocationSelect from '../../../common/forms/select/LocationSelect';
import SeatMapSelect from '../../../common/forms/select/SeatMapSelect';
import BusBodyWorkSelect from '../../../common/forms/select/BusBodyWorkSelect';
import BusChassisSelect from '../../../common/forms/select/BusChassisSelect';

const validateLength4 = validateLength(4, 'dígitos');

export const RegisteredBusForm = ({ handleSubmit, loading, isPost }) => {
  if (loading) return <Loader />;

  const onClickRemove = (index, fields) => {
    fields.remove(index);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup row>
        <FormItem label="Número de Bus" required>
          <Field
            name="companyBusId"
            component={TextInput}
            placeholder="Número de Bus"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Matrícula" required>
          <Field
            name="licensePlate"
            component={TextInput}
            placeholder="Matrícula"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Base" required>
          <Field
            name="homeBaseLocation"
            component={LocationSelect}
            placeholder="Bases"
            validate={[isRequired]}
            forHomeBase
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Chasís" required>
          <Field
            name="busChassis"
            component={BusChassisSelect}
            placeholder="Chasís"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Chasis Número de serie" required>
          <Field
            name="chassisSerialNumber"
            component={TextInput}
            placeholder="Chasis Número de serie"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Carrocería" required>
          <Field
            name="busBodywork"
            component={BusBodyWorkSelect}
            placeholder="Carrocerías"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Carrocería Número de serie" required>
          <Field
            name="bodyworkSerialNumber"
            component={TextInput}
            placeholder="Carrocería Número de serie"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Mapa de Asientos" required>
          <Field
            name="seatMap"
            component={SeatMapSelect}
            placeholder="Mapa de Asientos"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Año" required>
          <Field
            name="year"
            component={TextInput}
            placeholder="Año"
            validate={[
              isRequired,
              validateNumber,
              validateLength4,
              validateYear,
            ]}
          />
        </FormItem>
      </FormGroup>
      {!isPost && (
        <FormGroup row>
          <FormItem label="Estado" required>
            <Label>
              <Field name="active" component="input" type="checkbox" /> Activo
            </Label>
          </FormItem>
        </FormGroup>
      )}

      <DynamicForm
        name="documentList"
        columns={REGISTERED_BUS_DOCUMENT_COLUMNS}
        onClickRemove={onClickRemove}
      />
      <FormFooter />
    </Form>
  );
};

RegisteredBusForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  isPost: PropTypes.bool,
};

RegisteredBusForm.defaultProps = {
  isPost: false,
};

const mapStateToProps = ({ BusUnit }) => ({
  loading: !BusUnit.RegisteredBus.getIn(['current', 'activity']).isEmpty(),
});

const formComponent = reduxForm({
  form: 'RegisteredBusForm',
})(RegisteredBusForm);

export default connect(mapStateToProps)(formComponent);
