import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { putRelocationCargoItems, clearShelf } from '../../../../../actions';

import deleteDataImage from '../../../../../resources/images/escaner-modo-almacen-limpiar-datos.png';
import startScanImage from '../../../../../resources/images/escaner-modo-almacen.png';
import uploadDataImage from '../../../../../resources/images/escaner-modo-almacen-cargar-datos.png';
import normalScanImage from '../../../../../resources/images/escaner-modo-normal.png';

const barcodeImages = {
  1: deleteDataImage,
  2: startScanImage,
  3: uploadDataImage,
  4: normalScanImage,
};

const RelocationCargoItemWizard = ({
  setShowModal,
  dispatchPutRelocationCargoItems,
  dispatchClearShelf,
  locationId,
}) => {
  const [step, setStep] = useState(1);
  const [scannedData, setScannedData] = useState([]);
  const [currentCode, setCurrentCode] = useState('');
  const [isCodeScanned, setIsCodeScanned] = useState(false);

  useEffect(() => {
    if (step === 3) {
      const input = document.querySelector('.barcode-input');
      if (input) {
        input.focus();
      }
    }
  }, [step]);

  const handleNextStep = async () => {
    if (step === 1) {
      dispatchClearShelf();
      toastr.warning(
        'Memoria borrada',
        'Se ha limpiado la memoria del escáner.',
      );
    }

    if (step === 3) {
      if (!currentCode.trim()) {
        toastr.error(
          'Error',
          'Debe escanear un código válido antes de continuar.',
        );
        return;
      }

      const newCodes = currentCode.trim().split(/\s+/);
      setScannedData((prevData) => [...prevData, ...newCodes]);
      setCurrentCode('');
      setIsCodeScanned(false);
    }

    if (step === 4) {
      const response = await dispatchPutRelocationCargoItems(
        scannedData,
        locationId,
      );

      if (response) {
        toastr.success('Confirmación', 'Encomiendas reubicadas exitosamente');
      }
      setScannedData([]);
      setShowModal(false);
      return;
    }

    setStep(step + 1);
  };

  const handlePreviousStep = () => {
    if (step > 1) {
      setStep(step - 1);
      setCurrentCode('');
      setIsCodeScanned(false);
    }
  };

  return (
    <div className="container text-center">
      <h4 className="mb-4">
        {step === 1 &&
          'Escanee este código para borrar los datos en la memoria del escáner'}
        {step === 2 &&
          'Escanee este código para activar el modo almacén en el escáner'}
        {step === 3 &&
          'Escanee un código y espere el pitido antes de continuar'}
        {step === 4 &&
          'Proceso finalizado. Escanee el siguiente código para volver al modo normal'}
      </h4>

      {step === 4 && (
        <h5 className="mb-3 text-muted">
          Escanee este código para volver al modo normal del escáner
        </h5>
      )}

      {/* ✅ Imagen responsiva */}
      <img
        src={barcodeImages[step]}
        alt="Código de barras"
        className="img-fluid mb-4"
        style={{ maxWidth: '100%', height: 'auto' }}
      />

      {step === 3 && (
        <textarea
          style={{ position: 'absolute', opacity: 0 }}
          placeholder="Escanee aquí"
          className="barcode-input form-control"
          value={currentCode}
          onChange={(e) => {
            setCurrentCode(e.target.value);
            if (e.target.value.trim()) {
              setIsCodeScanned(true);
            }
          }}
        />
      )}

      <div className="d-flex flex-column align-items-center mt-5">
        {step > 1 && step < 4 && (
          <button
            className="btn btn-secondary mb-3 w-50"
            onClick={handlePreviousStep}
          >
            Atrás
          </button>
        )}

        <button
          className="btn btn-primary w-50"
          onClick={handleNextStep}
          disabled={step === 3 && !isCodeScanned}
        >
          {step === 4 ? 'Terminar' : 'Siguiente'}
        </button>
      </div>
    </div>
  );
};

RelocationCargoItemWizard.propTypes = {
  setShowModal: PropTypes.func.isRequired,
  dispatchPutRelocationCargoItems: PropTypes.func.isRequired,
  dispatchClearShelf: PropTypes.func.isRequired,
  locationId: PropTypes.number,
};

RelocationCargoItemWizard.defaultProps = {
  locationId: 0,
};

const mapDispatchToProps = {
  dispatchPutRelocationCargoItems: putRelocationCargoItems,
  dispatchClearShelf: clearShelf,
};

export default connect(null, mapDispatchToProps)(RelocationCargoItemWizard);
