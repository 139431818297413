import React, { useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { PARCEL_PRICE_CONFIGURATION_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import {
  clearParcelPriceConfiguration,
  getParcelPriceConfiguration,
  putParcelPriceConfiguration,
} from '../../../../actions/cargo/ParcelPriceConfiguration';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import ParcelPriceConfigurationForm from './ParcelPriceConfigurationForm';
import Content from '../../../layout/Content';

const EditParcelPriceConfiguration = ({
  breadcrumbs,
  loading,
  parcelPriceConfiguration,
  match: {
    params: { id: parcelPriceConfigurationId },
  },
  dispatchGetParcelPriceConfiguration,
  dispatchClearParcelPriceConfiguration,
  dispatchPutParcelPriceConfiguration,
}) => {
  useLayoutEffect(() => {
    dispatchGetParcelPriceConfiguration({ parcelPriceConfigurationId });
    return () => dispatchClearParcelPriceConfiguration();
  }, []);

  const onSubmit = (formValues) => {
    const newFormValues = {
      ...formValues,
      cargoCorporateCreditId: formValues.cargoCorporateCredit.value,
    };
    dispatchPutParcelPriceConfiguration(
      parcelPriceConfigurationId,
      newFormValues,
    );
  };

  const generateInitialValues = (parcelPriceConfigurationJS) => ({
    cargoCorporateCredit: {
      value: parcelPriceConfigurationJS.cargoCorporateCredit
        ? parcelPriceConfigurationJS.cargoCorporateCredit.id
        : null,
      label: parcelPriceConfigurationJS.cargoCorporateCredit
        ? parcelPriceConfigurationJS.cargoCorporateCredit.name
        : '',
    },
    basePrice: parcelPriceConfigurationJS.basePrice,
    maxEnvelopeWeight: parcelPriceConfigurationJS.maxEnvelopeWeight,
    volumetricDivisor: parcelPriceConfigurationJS.volumetricDivisor,
  });

  let content;

  if (loading) content = <Loader />;
  else if (parcelPriceConfiguration.isEmpty())
    content = <NoDataResource returnPage={PARCEL_PRICE_CONFIGURATION_PATH} />;
  else
    content = (
      <ParcelPriceConfigurationForm
        onSubmit={onSubmit}
        initialValues={generateInitialValues(parcelPriceConfiguration.toJS())}
        isEdit
      />
    );

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Editar Configuración de Tarifas"
      content={content}
    />
  );
};

EditParcelPriceConfiguration.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  dispatchGetParcelPriceConfiguration: PropTypes.func.isRequired,
  dispatchClearParcelPriceConfiguration: PropTypes.func.isRequired,
  dispatchPutParcelPriceConfiguration: PropTypes.func.isRequired,
  parcelPriceConfiguration: PropTypes.instanceOf(Immutable.Map).isRequired,
};

EditParcelPriceConfiguration.defaultProps = {
  loading: false,
};

const mapStateToProps = (
  { CargoUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Configuracion de Tarifas',
      href: PARCEL_PRICE_CONFIGURATION_PATH,
    },
    {
      text: 'Ver',
      href: `${PARCEL_PRICE_CONFIGURATION_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  parcelPriceConfiguration: CargoUnit.ParcelPriceConfiguration.getIn([
    'current',
    'content',
  ]),
  loading: !CargoUnit.ParcelPriceConfiguration.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetParcelPriceConfiguration: getParcelPriceConfiguration,
  dispatchClearParcelPriceConfiguration: clearParcelPriceConfiguration,
  dispatchPutParcelPriceConfiguration: putParcelPriceConfiguration,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditParcelPriceConfiguration);
