import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { optionsPropTypes } from './SelectPropTypes';
import Select from './Select';
import {
  clearCargoDeliveryFequentVehicles,
  getCargoDeliveryFequentVehicles,
} from '../../../../actions/cargo/CargoDeliveryFequentVehicle';

export const CargoDeliveryFrequentVehicleSelect = ({
  loading,
  options,
  isGridVariant,
  isMulti,
  isClearable,
  onValueChange,
  dispatchClearCargoDeliveryFequentVehicles,
  dispatchGetCargoDeliveryFequentVehicles,
  ...rest
}) => {
  useLayoutEffect(() => () => dispatchClearCargoDeliveryFequentVehicles(), []);

  const onInputChangeActivity = (inputValue) => {
    const inputValueLength = inputValue.trim().length;

    if (inputValueLength >= 4) {
      dispatchGetCargoDeliveryFequentVehicles({
        query: [`licensePlate:${inputValue.trim()}`],
      });
    }
  };

  return (
    <Select
      isLoading={loading}
      onInputChange={onInputChangeActivity}
      options={options}
      isMulti={isMulti}
      onValueChange={onValueChange}
      isClearable={isClearable}
      isGridVariant={isGridVariant}
      {...rest}
    />
  );
};

CargoDeliveryFrequentVehicleSelect.propTypes = {
  isGridVariant: PropTypes.bool,
  dispatchGetCargoDeliveryFequentVehicles: PropTypes.func.isRequired,
  dispatchClearCargoDeliveryFequentVehicles: PropTypes.func.isRequired,
  onValueChange: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  isMulti: PropTypes.bool,
  options: optionsPropTypes,
  isClearable: PropTypes.bool,
  placeholder: PropTypes.string,
  noOptionsMessage: PropTypes.func,
};

CargoDeliveryFrequentVehicleSelect.defaultProps = {
  isGridVariant: false,
  isMulti: false,
  options: [],
  isClearable: false,
  onValueChange: undefined,
  placeholder: 'Busca por placa de vehículo',
  noOptionsMessage: undefined,
};

const mapStateToProps = ({ CargoUnit }) => ({
  options: CargoUnit.CargoDeliveryFrequentVehicle.getIn([
    'all',
    'content',
    'content',
  ]).map(
    ({ licensePlate, id, businessName, cargoDeliveryVehicleDriverList }) => ({
      value: id,
      label: `${licensePlate} - ${businessName}`,
      cargoDeliveryVehicleDriverList,
    }),
  ),
  loading: CargoUnit.CargoDeliveryFrequentVehicle.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetCargoDeliveryFequentVehicles: getCargoDeliveryFequentVehicles,
  dispatchClearCargoDeliveryFequentVehicles: clearCargoDeliveryFequentVehicles,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CargoDeliveryFrequentVehicleSelect);
