import React, { Component } from 'react';
import { Container } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Card from '../layout/Card';
import Loader from '../common/Loader';
import {
  TRAFFIC_UNIT_PATH,
  ROUTE_UNIT_PATH,
  BAGGAGE_UNIT_PATH,
  LOCATION_UNIT_PATH,
  CARGO_UNIT_PATH,
  BUS_UNIT_PATH,
  USER_UNIT_PATH,
  ITINERARY_UNIT_PATH,
  RESERVATION_UNIT_PATH,
  SALES_UNIT_PATH,
  ACCOUNTING_UNIT_PATH,
  SEARCH_STEP_PATH,
  POSTPAID_BOOKING_UNIT_PATH,
  CONTRACT_UNIT_PATH,
  SYSTEM_UNIT_PATH,
  MECHANICAL_MAINTENANCE_UNIT_PATH,
  MY_RESERVATIONS_UNIT_PATH,
} from '../../config/paths';
import SystemNotificationList from './SystemNotificationList';

// exporting unconected component for testing
// https://bit.ly/2HtSJlT
export class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cards: [
        {
          key: 'bus',
          title: 'Gestión de Buses',
          path: BUS_UNIT_PATH,
          icon: 'bus',
        },
        {
          key: 'user',
          title: 'Gestión de Usuarios',
          path: USER_UNIT_PATH,
          icon: 'user',
        },
        {
          key: 'location',
          title: 'Gestión de Ubicaciones',
          path: LOCATION_UNIT_PATH,
          icon: 'globe',
        },
        {
          key: 'route',
          title: 'Gestión de Rutas',
          path: ROUTE_UNIT_PATH,
          icon: 'road',
        },
        {
          key: 'itinerary',
          title: 'Gestión de Itinerarios',
          path: ITINERARY_UNIT_PATH,
          icon: 'calendar',
        },
        {
          key: 'reservation',
          title: 'Gestión de Reservas',
          path: RESERVATION_UNIT_PATH,
          icon: 'book',
        },
        {
          key: 'sales-management',
          title: 'Gestión de Ventas',
          path: SALES_UNIT_PATH,
          icon: 'credit-card',
        },
        {
          key: 'traffic-management',
          title: 'Gestión de Tráfico',
          path: TRAFFIC_UNIT_PATH,
          icon: 'car',
        },
        {
          key: 'maciva-management',
          title: 'Gestión MACIVA',
          path: '/maciva-management',
          icon: 'bolt',
        },
        {
          key: 'human-resources',
          title: 'Recursos Humanos',
          path: '/human-resources',
          icon: 'users',
        },
        {
          key: 'security',
          title: 'Gestión de Seguridad',
          path: '/security',
          icon: 'lock',
        },
        {
          key: 'accounting',
          title: 'Contabilidad',
          path: ACCOUNTING_UNIT_PATH,
          icon: 'calculator',
        },
        {
          key: 'book',
          title: 'Book',
          path: SEARCH_STEP_PATH,
          icon: 'search',
          className: 'primary',
        },
        {
          key: 'postPaymentBook',
          title: 'Pasajes Pospago',
          path: POSTPAID_BOOKING_UNIT_PATH,
          icon: 'calendar-minus-o',
          className: 'primary',
        },
        {
          key: 'baggage',
          title: 'Equipajes',
          path: BAGGAGE_UNIT_PATH,
          icon: 'suitcase',
        },
        {
          key: 'cargo',
          title: 'Cargo',
          path: CARGO_UNIT_PATH,
          icon: 'truck',
        },
        {
          key: 'contract',
          title: 'Gestión de Contratos',
          path: CONTRACT_UNIT_PATH,
          icon: 'file-text-o',
        },
        {
          key: 'mechanical-maintenance',
          title: 'Gestión de Mantenimiento Mecánico',
          path: MECHANICAL_MAINTENANCE_UNIT_PATH,
          icon: 'wrench',
        },
        {
          key: 'reports',
          title: 'Reportes',
          path: '/reports',
          icon: 'pie-chart',
        },
        {
          key: 'system',
          title: 'Sistema',
          path: SYSTEM_UNIT_PATH,
          icon: 'tasks',
        },
      ],
    };

    if (props.isForMine) {
      this.state.cards.push({
        key: 'my-reservations',
        title: 'Mis Reservas',
        path: MY_RESERVATIONS_UNIT_PATH,
        icon: 'book',
      });
    }
  }

  render() {
    if (!this.props.user) {
      return <Loader />;
    }

    return (
      <Container fluid>
        <div className="row">
          <div className="col">
            <h1 id="title">Home</h1>
            {!this.props.isForMine ? (
              <p id="text">Bienvenido a tu página de inicio</p>
            ) : (
              <h4 id="text">
                Bienvenido, <strong>{this.props.user.customer.fullName}</strong>
              </h4>
            )}
          </div>
        </div>
        <SystemNotificationList />
        <div className="row text-xs-center">
          {this.state.cards.map((card) => (
            <Card key={card.id} {...card} />
          ))}
        </div>
      </Container>
    );
  }
}

const mapStateToProps = ({ authentication }) => ({
  authenticated: authentication.get('authenticated'),
  user: authentication.get('user'),
  isForMine: !!authentication.get('user').salesSessionUserId,
});

Home.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    username: PropTypes.string.isRequired,
    customer: PropTypes.shape({
      fullName: PropTypes.string.isRequired,
    }),
  }),
  isForMine: PropTypes.bool.isRequired,
};

Home.defaultProps = {
  user: null,
};

export default connect(mapStateToProps)(Home);
