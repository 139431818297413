import React from 'react';
import PropTypes from 'prop-types';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import IsActive from '../../../common/cells/IsActive';
import Checkbox from '../../../common/forms/Checkbox';
import { CARGO_CORPORATE_CREDIT_PATH } from '../../../../config/paths';
import LinkToModuleResource from '../../../common/resource/LinkToModuleResource';
import Table from '../../../common/Table';
import { COMMON_PRODUCT_PRICE_SCHEDULE_COLUMNS } from '../../../../config/columns';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';

const CommonProductBasicInformationResource = ({
  productName,
  productDescription,
  cargoCategory,
  weight,
  width,
  length,
  height,
  internalProduct,
  active,
  cargoCorporateCreditId,
  cargoCorporateCreditName,
  commonProductPriceScheduleList,
}) => (
  <>
    <ResourceProperty label="Nombre Producto:">{productName}</ResourceProperty>
    <ResourceProperty label="Descripción Producto:">
      {productDescription}
    </ResourceProperty>
    <ResourceProperty label="Categoría:">{cargoCategory.name}</ResourceProperty>
    <ResourceProperty label="Peso:">{weight}</ResourceProperty>
    <ResourceProperty label="Ancho:">{width}</ResourceProperty>
    <ResourceProperty label="Largo:">{length}</ResourceProperty>
    <ResourceProperty label="Alto:">{height}</ResourceProperty>

    {cargoCorporateCreditId ? (
      <ResourceProperty label="Crédito Corporativo:">
        <LinkToModuleResource
          text={cargoCorporateCreditName}
          href={`${CARGO_CORPORATE_CREDIT_PATH}/${cargoCorporateCreditId}`}
        />
      </ResourceProperty>
    ) : (
      <ResourceProperty label="Producto Interno:">
        <Checkbox checked={internalProduct} />
      </ResourceProperty>
    )}

    <ResourceProperty label="Estado:">
      <IsActive value={active} />
    </ResourceProperty>

    <Table
      columns={COMMON_PRODUCT_PRICE_SCHEDULE_COLUMNS}
      data={commonProductPriceScheduleList.reverse()}
      manual
      defaultPageSize={DEFAULT_PAGE_SIZE}
    />
  </>
);

CommonProductBasicInformationResource.propTypes = {
  productName: PropTypes.string.isRequired,
  productDescription: PropTypes.string.isRequired,
  cargoCategory: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  weight: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  length: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  internalProduct: PropTypes.bool.isRequired,
  active: PropTypes.bool.isRequired,
  cargoCorporateCreditId: PropTypes.number,
  cargoCorporateCreditName: PropTypes.string,
  commonProductPriceScheduleList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      file: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      createDate: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

CommonProductBasicInformationResource.defaultProps = {
  cargoCorporateCreditId: null,
  cargoCorporateCreditName: null,
};

export default CommonProductBasicInformationResource;
