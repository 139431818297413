import React, { Component } from 'react';
import { toastr } from 'react-redux-toastr';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar } from 'reactstrap';
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import 'react-table/react-table.css';
import '../../config/columns.css';
import {
  DEFAULT_SORT,
  DEFAULT_PAGE_SIZE,
  DEFAULT_ORDER,
  DATE_STRING_LENGTH,
  COLOR,
} from '../../config/constants';
import * as ColumnFilterDataTypes from '../../config/columnFilterDataTypes';
import { debounce } from '../../utils/promise';
import './reactTable.css';

const hasValidArrayColumnFilter = (value) => value.length > 0;

const hasValidDateColumnFilter = (value) => value.length === DATE_STRING_LENGTH;

export class Table extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rowSelected: null,
      appliedFilters: [],
    };

    this.onFetchData = this.onFetchData.bind(this);
    this.fetchDataWithDebounce = debounce(this.onFetchData);
    this.getTrProps = this.getTrProps.bind(this);
    this.onFilteredChange = this.onFilteredChange.bind(this);
    this.fetchStrategy = this.fetchStrategy.bind(this);
    this.getTheadFilterThProps = this.getTheadFilterThProps.bind(this);
    this.generateQueryToFetchData = this.generateQueryToFetchData.bind(this);
    this.onDownloadClick = this.onDownloadClick.bind(this); // Manejar clic del botón
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate() {
    this.onDidUpdate();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  onDidUpdate = () =>
    this.props.loading && this.state.rowSelected && this.setRowSelected(null);

  onFilteredChange() {
    this.filtering = true;
  }

  onDownloadClick() {
    const { appliedFilters } = this.state;
    const {
      downloadFetchData,
      requiredDownloadFilters,
      filterDependencies = null,
    } = this.props;

    if (!appliedFilters || appliedFilters.length === 0) {
      toastr.error('Error', 'Aplique filtros para descargar');
      return;
    }

    if (!requiredDownloadFilters || requiredDownloadFilters.length === 0) {
      toastr.error('Error', 'No está configurado los filtros requeridos');
      return;
    }

    let filteredRequiredFilters = [...requiredDownloadFilters];

    if (filterDependencies && typeof filterDependencies === 'object') {
      Object.keys(filterDependencies).forEach((filter) => {
        if (
          appliedFilters.some((appliedFilter) =>
            appliedFilter.startsWith(`${filter}:`),
          )
        ) {
          filteredRequiredFilters = filteredRequiredFilters.filter(
            (requiredFilter) =>
              !filterDependencies[filter].includes(requiredFilter.param),
          );
        }
      });
    }

    const missingFilters = filteredRequiredFilters.filter(
      (requiredFilter) =>
        !appliedFilters.some((filter) =>
          filter.startsWith(`${requiredFilter.param}:`),
        ),
    );

    if (missingFilters.length > 0) {
      const missingDescriptions = missingFilters
        .map((filter) => filter.description)
        .join(', ');

      toastr.error(
        'Error',
        `Faltan los siguientes filtros obligatorios: ${missingDescriptions}`,
      );
      return;
    }

    downloadFetchData({ query: appliedFilters });
  }

  onFetchData(state, whenPressEnterKey = false) {
    this.filtering = false;

    const { fetchData, params, queryProps } = this.props;

    const { page, pageSize, sorted, filtered } = state;

    let sort;
    sort = sorted.map(({ id, desc }) => {
      const order = desc ? 'desc' : 'asc';
      return `${id},${order}`;
    });
    if (sort && !sort.length > 0) {
      sort = DEFAULT_SORT;
    }

    let validFetchData = true;

    let query = [];

    if (whenPressEnterKey) {
      ({ query, validFetchData } = this.generateQueryToFetchData(
        state,
        whenPressEnterKey,
      ));
    } else {
      if (filtered.length !== 0) {
        validFetchData = false;
      }

      if (filtered.length === 0) {
        if (sorted.length !== 0) {
          validFetchData = true;
        }
      } else {
        ({ query, validFetchData } = this.generateQueryToFetchData(
          state,
          whenPressEnterKey,
        ));
      }
    }

    if (queryProps !== '') {
      query.push(queryProps);
    }

    if (validFetchData) {
      fetchData({
        ...params,
        page: page || 0,
        sort: sort || DEFAULT_SORT,
        size: pageSize || DEFAULT_PAGE_SIZE,
        order: DEFAULT_ORDER,
        query,
      });
    }
  }

  getTrProps(state, row) {
    const {
      modelPath,
      navigateToModelOnRowClick = false,
      isExternalPath,
      getRowData,
      generateStyle,
      highlightSelectedRow,
      keyField,
      modelId,
    } = this.props;

    // only clickable if flagged and row actually has content
    const clickable = navigateToModelOnRowClick && modelPath && row;

    const className = clickable || getRowData ? 'clickable' : '';

    const { dispatch, openPathInNewTab } = this.props;

    let style = generateStyle ? generateStyle(row) : {};

    if (highlightSelectedRow) {
      style = {
        background:
          row &&
          row.original[keyField] === this.state.rowSelected &&
          COLOR.selectedRow,
      };
    }

    const setRowSelected = (rowSelected) => this.setRowSelected(rowSelected);

    return {
      className,
      style,
      onClick(event, handleOriginal) {
        if (highlightSelectedRow) setRowSelected(row.original[keyField]);
        if (getRowData) getRowData(row.original);
        else if (clickable) {
          const {
            original: { id },
          } = row;

          const pathId =
            modelId &&
            Object.prototype.hasOwnProperty.call(row.original, modelId)
              ? row.original[modelId]
              : id;

          let path = `${modelPath}/${pathId}`;

          if (typeof modelPath === 'function') path = modelPath({ pathId });

          // open in new tab if alt key is also pressed
          if (event.altKey || openPathInNewTab) {
            const tab = window.open(path, '_blank');
            tab.focus();
          } else if (!isExternalPath) dispatch(push(path));
          else window.location = path;
        } else if (handleOriginal) {
          // IMPORTANT! React-Table uses onClick internally to trigger
          // events like expanding SubComponents and pivots.
          // By default a custom 'onClick' handler will override this functionality.
          // If you want to fire the original onClick handler, call the
          // 'handleOriginal' function.
          handleOriginal();
        }
      },
    };
  }

  getTheadFilterThProps(state) {
    return {
      onKeyPress: (event) => {
        if (event.keyCode === 13 || event.which === 13)
          this.onFetchData(state, true);
      },
    };
  }

  getTdProps = (state, rowInfo, column) => {
    if (column && column.notNavigateToModel) {
      return {
        onClick: (e) => e.stopPropagation(),
        style: { cursor: 'default' },
        rest: { fetch: () => this.onFetchData(state) },
      };
    }
    return {};
  };

  setRowSelected = (rowSelected) => this.setState({ rowSelected });

  getTdProps = (state, rowInfo, column) => {
    if (column && column.notNavigateToModel) {
      return {
        onClick: (e) => e.stopPropagation(),
        style: { cursor: 'default' },
        rest: { fetch: () => this.onFetchData(state) },
      };
    }
    return {};
  };

  handleScroll = () => {
    const { tablePosition } = this.props;

    const rtTheadElement = document.getElementsByClassName('rt-thead');

    if (window.scrollY > tablePosition && tablePosition > 0)
      rtTheadElement[0].classList.add('fixed-header');
    else rtTheadElement[0].classList.remove('fixed-header');
  };

  generateQueryToFetchData(state, whenPressEnterKey = true) {
    const { filtered } = state;

    const { columns } = this.props;

    // If "whenPressEnterKey" param is true, it means that
    // onFetch is executed when user presses Enter Key.
    // If it's false, it means that onFetch is executed when
    // it detects a change in filtered state only for Filters of type Select
    let validFetchData = whenPressEnterKey;

    // Check if there is a date filter
    // If there is, only send it when having 10 characters
    // TODO: Implement a Date Filter Component
    const query = filtered.reduce((filters, filter) => {
      const { id } = filter;

      let { value } = filter;

      const column = columns.find((columnElement) => {
        if (!columnElement.id) return columnElement.accessor === id;

        return columnElement.id === id;
      });

      let validFilter = true;

      if (value === null) validFilter = false;

      if (column.dataType) {
        switch (column.dataType) {
          case ColumnFilterDataTypes.ARRAY:
            // If array column filter, concatenate with |
            if (hasValidArrayColumnFilter(value)) {
              value = value.join('|');
              validFilter = true;
              validFetchData = true;
            } else {
              validFilter = false;
            }
            break;
          case ColumnFilterDataTypes.DATE:
            // If date column filter and has no valid format, don't include in query
            validFilter = hasValidDateColumnFilter(value);
            validFetchData = !!validFilter;
            break;
          default:
            validFilter = true;
        }
      }
      if (validFilter) {
        filters.push(`${id}:${value}`);
      }
      return filters;
    }, []);

    this.setState({ appliedFilters: query });

    return { query, validFetchData };
  }

  // Debounce only when filtering
  fetchStrategy(tableState) {
    const { filtered } = tableState;
    const newTableState = tableState;
    newTableState.filtered = filtered.filter(
      (item) =>
        item.value !== null &&
        !(Array.isArray(item.value) && item.value.length === 0),
    );
    if (this.filtering) {
      return this.fetchDataWithDebounce(newTableState);
    }
    return this.onFetchData(newTableState);
  }

  render() {
    const {
      columns,
      manual,
      data,
      pages,
      loading,
      filterable,
      defaultPageSize,
      rowComponent,
      defaultFilterMethod,
      showPagination,
      getTbodyProps,
      showDownloadButton,
    } = this.props;

    const tableProps = {
      columns,
      manual, // handle paginate and sort it server-side
      data,
      pages, // display the total number of pages
      loading, // display the loading overlay when we need it
      onFetchData: this.fetchStrategy, // request new data when things change
      onFilteredChange: this.onFilteredChange,
      filterable,
      defaultPageSize,
      getTrProps: this.getTrProps,
      getTdProps: this.getTdProps,
      getTheadFilterThProps: this.getTheadFilterThProps,
      SubComponent: rowComponent,
      defaultFilterMethod,
      className: '-striped -highlight',
      showPagination,
      getTbodyProps,
    };

    return (
      <>
        {showDownloadButton && (
          <ButtonToolbar className="d-flex justify-content-end mb-3">
            <Button color="info" type="button" onClick={this.onDownloadClick}>
              <i className="fa fa-download" /> Descargar
            </Button>
          </ButtonToolbar>
        )}
        {/* Contenedor de la tabla */}
        <div className="Table mt-2">
          <ReactTable {...tableProps} />
        </div>
      </>
    );
  }
}

Table.propTypes = {
  keyField: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string,
      accessor: PropTypes.string,
    }),
  ).isRequired,
  manual: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array,
  fetchData: PropTypes.func,
  pages: PropTypes.number,
  loading: PropTypes.bool,
  filterable: PropTypes.bool,
  defaultPageSize: PropTypes.number,
  navigateToModelOnRowClick: PropTypes.bool,
  modelPath: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  dispatch: PropTypes.func,
  isExternalPath: PropTypes.bool,
  rowComponent: PropTypes.func,
  defaultFilterMethod: PropTypes.func,
  showPagination: PropTypes.bool,
  getRowData: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  params: PropTypes.object,
  openPathInNewTab: PropTypes.bool,
  generateStyle: PropTypes.func,
  highlightSelectedRow: PropTypes.bool,
  tablePosition: PropTypes.number,
  getTbodyProps: PropTypes.func,
  queryProps: PropTypes.string,
  modelId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showDownloadButton: PropTypes.bool,
  requiredDownloadFilters: PropTypes.arrayOf(
    PropTypes.shape({
      param: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }),
  ),
  downloadFetchData: PropTypes.func,
  filterDependencies: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
};

Table.defaultProps = {
  manual: true,
  pages: undefined,
  loading: false,
  filterable: false,
  data: [],
  defaultPageSize: DEFAULT_PAGE_SIZE,
  rowComponent: null,
  getRowData: null,
  // by default fetch data does nothing
  // takes two arguments: state, instance
  // state has the following interesting props:
  // { pageSize, page, sorted, filtered }
  fetchData: () => {},
  navigateToModelOnRowClick: false,
  modelPath: null,
  isExternalPath: false,
  defaultFilterMethod: undefined,
  showPagination: true,
  dispatch: null,
  params: null,
  openPathInNewTab: false,
  generateStyle: null,
  highlightSelectedRow: false,
  keyField: 'id',
  tablePosition: 0,
  getTbodyProps: () => ({}),
  queryProps: '',
  modelId: null,
  showDownloadButton: false,
  requiredDownloadFilters: [],
  downloadFetchData: () => {},
  filterDependencies: null,
};

export default connect()(Table);
