import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from '../../../common/modal/Modal';
import CargoCorporateCreditUnifyInvoicesForm from './CargoCorporateCreditUnifyInvoicesForm';
import { putCargoCorporateInvoces } from '../../../../actions/cargo/CargoCorporateCredit';

const CargoCorporateCreditUnifyInvoicesButton = ({
  cargoCorporateCreditId,
  dispatchPutCargoCorporateInvoces,
  companyId,
}) => {
  const [showModal, setShowModal] = useState(false);

  const onSubmit = ({ parcelIds }) => {
    dispatchPutCargoCorporateInvoces({ parcelIds, cargoCorporateCreditId });
  };

  const body = (
    <CargoCorporateCreditUnifyInvoicesForm
      cargoCorporateCreditId={cargoCorporateCreditId}
      onSubmit={onSubmit}
      companyId={companyId}
    />
  );

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  return (
    <>
      <Button color="success" onClick={openModal}>
        <i className="fa fa-files-o" />{' '}
        {companyId ? 'Unificar Facturas' : 'Unificar Boletas'}
      </Button>
      <Modal
        show={showModal}
        title={companyId ? 'Unificar facturas' : 'Unificar boletas'}
        body={body}
        onClickClose={closeModal}
      />
    </>
  );
};

CargoCorporateCreditUnifyInvoicesButton.propTypes = {
  cargoCorporateCreditId: PropTypes.number.isRequired,
  dispatchPutCargoCorporateInvoces: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
};

const mapDispatchToProps = {
  dispatchPutCargoCorporateInvoces: putCargoCorporateInvoces,
};

export default connect(
  null,
  mapDispatchToProps,
)(CargoCorporateCreditUnifyInvoicesButton);
