import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { DELIVERY_PRICE_PATH } from '../../../../config/paths';
import {
  getExcelTemplate,
  postDeliveryPrice,
} from '../../../../actions/cargo/DeliveryPrice';
import Content from '../../../layout/Content';
import DeliveryPriceForm from './DeliveryPriceForm';

const NewDeliveryPrice = ({
  breadcrumbs,
  dispatchPostDeliveryPrice,
  dispatchGetTemplate,
}) => {
  const onSubmit = (formValues) => {
    dispatchPostDeliveryPrice(formValues);
  };

  const content = (
    <DeliveryPriceForm
      onSubmit={onSubmit}
      dispatchGetTemplate={dispatchGetTemplate}
    />
  );

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Nueva Tarifa de Reparto"
      subtitle="Crea una nueva tarifa de reparto"
      content={content}
    />
  );
};

NewDeliveryPrice.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostDeliveryPrice: PropTypes.func.isRequired,
  dispatchGetTemplate: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchPostDeliveryPrice: postDeliveryPrice,
  dispatchGetTemplate: getExcelTemplate,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Tarifarios de Reparto',
      href: DELIVERY_PRICE_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
});

export default connect(mapStateToProps, mapDispatchToProps)(NewDeliveryPrice);
