import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { CABIN_CREW_PATH, NEW_CABIN_CREW_PATH } from '../../../../config/paths';
import { clearCabinCrews, getCabinCrews } from '../../../../actions';
import { CABIN_CREW_COLUMNS } from '../../../../config/columns';

export const CabinCrews = ({
  breadcrumbs,
  cabinCrews,
  loading,
  dispatchGetCabinCrews,
  dispatchClearCabinCrews,
}) => {
  useLayoutEffect(() => () => dispatchClearCabinCrews(), []);

  const data = cabinCrews.get('content') || [];
  const pages = cabinCrews.get('totalPages') || null;
  const defaultPageSize = cabinCrews.get('size') || DEFAULT_PAGE_SIZE;

  return (
    <ModuleList
      title="Lista de Servicio a Bordo"
      breadcrumbs={breadcrumbs}
      buttonPath={NEW_CABIN_CREW_PATH}
      tableStructure={{
        columns: CABIN_CREW_COLUMNS,
        data,
        pages,
        defaultPageSize,
        filterable: true,
        fetchData: dispatchGetCabinCrews,
        modelPath: CABIN_CREW_PATH,
        loading,
        navigateToModelOnRowClick: true,
      }}
    />
  );
};

const mapDispatchToProps = {
  dispatchGetCabinCrews: getCabinCrews,
  dispatchClearCabinCrews: clearCabinCrews,
};

const mapStateToProps = ({ TrafficUnit }) => ({
  breadcrumbs: [
    ...TrafficUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Servicio a Bordo',
      href: CABIN_CREW_PATH,
    },
  ],
  cabinCrews: TrafficUnit.CabinCrew.getIn(['all', 'content']),
  loading: TrafficUnit.CabinCrew.getIn(['all', 'loading']),
});

CabinCrews.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  cabinCrews: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetCabinCrews: PropTypes.func.isRequired,
  dispatchClearCabinCrews: PropTypes.func.isRequired,
};

CabinCrews.defaultProps = {
  cabinCrews: null,
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(CabinCrews);
