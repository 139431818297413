import { addText, createPdfDocument, setFont } from '../pdf';

let myY = 64;

const printDocumentHeader = (doc, response) => {
  setFont(doc);
  doc.setFontSize(8);

  addText(doc, 'TURISMO CIVA S.A.C', 62, myY, 80, 'right');
  addText(doc, '20102427891', 36, (myY += 5.2), 30);
  doc.setFontSize(5);
  addText(doc, response.sourceAdress, 170, myY, 200, 'center');
  addText(doc, response.destinationAdress, 170, (myY += 5.2), 150, 'center');
  doc.setFontSize(8);
  addText(doc, `${response.bus.licensePlate}`, 140, (myY += 20), 150, 'right');
  addText(doc, '20102427891', 45, (myY += 10), 80, 'right');
  addText(doc, 'TURISMO CIVA S.A.C', 97, myY, 80, 'right');

  if (response.subContractedCompany !== null) {
    addText(
      doc,
      `${response.subContractedCompany.businessTaxId}`,
      45,
      myY + 17,
      80,
      'right',
    );
    addText(
      doc,
      `${response.subContractedCompany.name}`,
      92,
      myY + 20,
      80,
      'right',
    );
  }

  doc.setFontSize(5);

  let driverMy = myY;

  if (response.driverList && response.driverList.length > 0) {
    addText(
      doc,
      `${response.driverList[0].name} ${response.driverList[0].lastName}`,
      120,
      (driverMy += -5),
      170,
      'left',
    );

    addText(
      doc,
      `${response.driverList[0].driverAuthorization}`,
      180,
      driverMy,
      90,
      'left',
    );

    if (response.driverList[1]) {
      addText(
        doc,
        `${response.driverList[1].name} ${response.driverList[1].lastName}`,
        125,
        (driverMy += 5.2),
        80,
        'left',
      );
      addText(
        doc,
        `${response.driverList[1].driverAuthorization}`,
        180,
        driverMy,
        90,
        'left',
      );
    }

    if (response.driverList[2]) {
      addText(
        doc,
        `${response.driverList[2].name} ${response.driverList[2].lastName}`,
        125,
        (driverMy += 5.2),
        80,
        'left',
      );
      addText(
        doc,
        `${response.driverList[2].driverAuthorization}`,
        180,
        driverMy,
        90,
        'left',
      );
    }
  }
};

const printDocumentBody = (doc, itemList) => {
  setFont(doc);
  doc.setFontSize(8);

  let startY = 138;
  const lineSpacing = 6;

  itemList.forEach((item, index) => {
    const description = item.descripcionItem || 'N/A';
    const weight = item.totalWeight || 0.0;
    const quantity = item.quantity || 0.0;

    addText(doc, (index + 1).toString(), 20, startY, 80, 'center');
    addText(doc, quantity.toString(), 40, startY, 15, 'center');
    addText(doc, 'KG', 60, startY, 30, 'center');
    addText(doc, description, 110, startY, 150, 'center');
    addText(doc, weight.toString(), 174, startY, 50, 'center');

    startY += lineSpacing;
  });
};
const printContingencyDeliveryCarrierRemissionGuide = (guide) => {
  if (
    !guide ||
    typeof guide !== 'object' ||
    !Array.isArray(guide.itemList) ||
    guide.itemList.length === 0
  ) {
    console.error('Datos inválidos para la guía de remisión.');
    return;
  }

  const doc = createPdfDocument();
  const rowsPerPage = 23;
  const pages = Math.ceil(guide.itemList.length / rowsPerPage);

  for (let i = 0; i < pages; i += 1) {
    if (i > 0) doc.addPage();
    myY = 65;
    printDocumentHeader(doc, guide);

    const currentPageItems = guide.itemList.slice(
      i * rowsPerPage,
      (i + 1) * rowsPerPage,
    );

    printDocumentBody(doc, currentPageItems);
  }

  window.open(doc.output('bloburl'), '_blank');
};

export default printContingencyDeliveryCarrierRemissionGuide;
