import React, { useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { CARGO_PATH, COMMON_PRODUCT_PATH } from '../../../../config/paths';
import {
  getCommonProduct,
  clearCommonProduct,
} from '../../../../actions/cargo';
import Loader from '../../../common/Loader';
import {
  matchPropTypes,
  breadcrumbsPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import NoDataResource from '../../../common/resource/NoDataResource';
import CommonProductToolbar from './CommonProductToolbar';
import CommonProductBasicInformationResource from './CommonProductBasicInformationResource';

export const CommonProduct = ({
  breadcrumbs,
  commonProduct,
  loading,
  match: {
    params: { id },
  },
  dispatchGetCommonProduct,
  dispatchClearCommonProduct,
}) => {
  useLayoutEffect(() => {
    dispatchGetCommonProduct({ id });
    return () => dispatchClearCommonProduct();
  }, []);

  let content;
  let toolbar;

  if (loading) {
    return <Loader />;
  } else if (commonProduct.isEmpty()) {
    content = <NoDataResource returnPage={CARGO_PATH} />;
  } else {
    const commonProductJson = commonProduct.toJS();
    toolbar = <CommonProductToolbar commonProductId={id} />;
    content = <CommonProductBasicInformationResource {...commonProductJson} />;
  }

  return (
    <Content
      title="Producto Frecuente"
      breadcrumbs={breadcrumbs}
      toolbar={toolbar}
      content={content}
    />
  );
};

CommonProduct.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetCommonProduct: PropTypes.func.isRequired,
  dispatchClearCommonProduct: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  commonProduct: PropTypes.instanceOf(Immutable.Map),
};

CommonProduct.defaultProps = {
  loading: false,
  commonProduct: null,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Productos Frecuentes',
      href: COMMON_PRODUCT_PATH,
    },
    {
      text: 'Ver',
      href: ``,
    },
  ],
  commonProduct: CargoUnit.CommonProduct.getIn(['current', 'content']),
  loading: !CargoUnit.CommonProduct.getIn(['current', 'activity']).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetCommonProduct: getCommonProduct,
  dispatchClearCommonProduct: clearCommonProduct,
};

export default connect(mapStateToProps, mapDispatchToProps)(CommonProduct);
