import Immutable from 'immutable';
import {
  FLAG_GETTING_CABIN_CREW_DOCUMENT_TYPES,
  GET_CABIN_CREW_DOCUMENT_TYPES,
  CLEAR_CABIN_CREW_DOCUMENT_TYPES,
} from '../../../actions/types/traffic';
import { COLLECTION_SHAPE } from '../../../config/shapes';

const INITIAL_STATE = Immutable.Map({
  all: Immutable.Map({
    content: Immutable.Map(COLLECTION_SHAPE),
    loading: false,
  }),
});

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case FLAG_GETTING_CABIN_CREW_DOCUMENT_TYPES:
      return state.setIn(['all', 'loading'], payload);
    case GET_CABIN_CREW_DOCUMENT_TYPES:
      return state.setIn(['all', 'content'], Immutable.Map(payload));
    case CLEAR_CABIN_CREW_DOCUMENT_TYPES:
      return state.setIn(
        ['all', 'content'],
        INITIAL_STATE.getIn(['all', 'content']),
      );
    default:
      return state;
  }
};
