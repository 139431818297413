import React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  // ADD_CARGO_ITEM_TO_ITINERARY_PATH,
  // UNLOADING_CARGO_PATH,
  // DELIVERY_GROUP_PATH,
  // NEW_DELIVERY_GROUP_PATH,
  // NEW_BASE_PRICE_PER_KILOMETER_PATH,
  // GEOGRAPHIC_ZONE_COMBINATION_PATH,
  // NEW_GEOGRAPHIC_ZONE_COMBINATION_PATH,
  // DEPRECIATION_RULE_PATH,
  // NEW_DEPRECIATION_RULE_PATH,
  // BASE_PRICE_PER_KILOMETER_PATH,
  // CARGO_MINIMAL_PRICE_PATH,
  // NEW_CARGO_MINIMAL_PRICE_PATH,
  CARGO_UNIT_PATH,
  NEW_COMMON_PRODUCT_PATH,
  COMMON_PRODUCT_PATH,
  CARGO_DISTANCE_PATH,
  NEW_CARGO_DISTANCE_PATH,
  NEW_CARGO_PATH,
  CARGO_CATEGORY_PATH,
  NEW_CARGO_CATEGORY_PATH,
  CARGO_PATH,
  INTERNAL_TRANSFER_PATH,
  NEW_INTERNAL_TRANSFER_PATH,
  MANIFEST_ITINERARY_PATH,
  LEGAL_ABANDONMENT_PATH,
  CARGO_PICKUP_PATH,
  MANUAL_SENDER_REMISSION_GUIDE_PATH,
  NEW_MANUAL_SENDER_REMISSION_GUIDE_PATH,
  CARGO_ITEM_PATH,
  INTERNAL_CARGO_ITEM_PATH,
  CARGO_CORPORATE_CREDIT_PATH,
  NEW_CARGO_CORPORATE_CREDIT_PATH,
  MANIFEST_PATH,
  PARCEL_PRICE_PATH,
  NEW_PARCEL_PRICE_PATH,
  PARCEL_PRICE_CONFIGURATION_PATH,
  PARCEL_SERVICE_TYPE_PATH,
  NEW_PARCEL_SERVICE_TYPE_PATH,
  MANIFEST_LEGAL_ABANDONMENT_PATH,
  NEW_DELIVERY_BUSINESS_PATH,
  DELIVERY_BUSINESS_PATH,
  DELIVERY_PATH,
  NEW_CARGO_CORPORATE_CREDIT_COMMON_PRODUCT,
  NEW_PARCEL_PRICE_CONFIGURATION_PATH,
  SHELF_PATH,
  NEW_SHELF_PATH,
  RELOCATION_CARGO_ITEM_PATH,
  DELIVERY_LOCATION_DISTRICT_PATH,
  NEW_DELIVERY_LOCATION_DISTRICT_PATH,
  DELIVERY_PRICE_CONFIGURATION_PATH,
  NEW_DELIVERY_PRICE_CONFIGURATION_PATH,
  DELIVERY_PRICE_PATH,
  NEW_DELIVERY_PRICE_PATH,
  NEW_CARGO_CORPORATE_CREDIT_CITY_CUSTOMER_PATH,
  NEW_CARGO_CORPORATE_CREDIT_CITY_PATH,
} from '../../../config/paths';
// import ItemToItinerary from './add-item-to-itinerary/ItemToItinerary';
// import UnloadingCargo from './unloading-cargo-from-itinerary/UnloadingCargo';
// import DeliveryGroups from './delivery-group/DeliveryGroups';
// import NewDeliveryGroup from './delivery-group/NewDeliveryGroup';
// import DeliveryGroup from './delivery-group/DeliveryGroup';
// import EditDeliveryGroup from './delivery-group/EditDeliveryGroup';
// import BasePricesPerKilometer from './base-price-per-kilometer/BasePricesPerKilometer';
// import BasePricePerKilometer from './base-price-per-kilometer/BasePricePerKilometer';
// import EditBasePricePerKilometer from './base-price-per-kilometer/EditBasePricePerKilometer';
// import GeographicZoneCombinations from './geographic-zone-combination/GeographicZoneCombinations';
// import NewGeographicZonesCombination from './geographic-zone-combination/NewGeographicZonesCombination';
// import GeographicZonesCombination from './geographic-zone-combination/GeographicZonesCombination';
// import EditGeographicZonesCombination from './geographic-zone-combination/EditGeographicZonesCombination';
// import DepreciationRules from './depreciation-rule/DepreciationRules';
// import DepreciationRule from './depreciation-rule/DepreciationRule';
// import NewDepreciationRule from './depreciation-rule/NewDepreciationRule';
// import EditDepreciationRule from './depreciation-rule/EditDepreciationRule';
// import NewBasePricePerKilometer from './base-price-per-kilometer/NewBasePricePerKilometer';
// import CargoMinimalPrices from './minimal-price/CargoMinimalPrices';
// import NewCargoMinimal from './minimal-price/NewCargoMinimalPrice';
// import EditCargoMinimalPrice from './minimal-price/EditCargoMinimalPrice';
import CargoHome from './CargoHome';
import NewCommonProduct from './common-product/NewCommonProduct';
import CommonProducts from './common-product/CommonProducts';
import CommonProduct from './common-product/CommonProduct';
import EditCommonProduct from './common-product/EditCommonProduct';
import CargoDistances from './cargo-distance/CargoDistances';
import NewCargoDistance from './cargo-distance/NewCargoDistance';
import CargoDistance from './cargo-distance/CargoDistance';
import EditCargoDistance from './cargo-distance/EditCargoDistance';
import Parcels from './cargo/Parcels';
import NewParcel from './cargo/NewParcel';
import Parcel from './cargo/Parcel';
import CargoCategories from './cargo-category/CargoCategories';
import CargoCategory from './cargo-category/CargoCategory';
import NewCargoCategory from './cargo-category/NewCargoCategory';
import EditCargoCategory from './cargo-category/EditCargoCategory';
import InternalParcels from './internal-transfer/InternalParcels';
import NewInternalParcel from './internal-transfer/NewInternalParcel';
import InternalParcel from './internal-transfer/InternalParcel';
import ManifestItinerary from './manifest-itinerary/ItineraryManifest';
import LegalAbandonment from './legal-abandonment/LegalAbandonment';
import CargoPickup from './cargo-pickup/CargoPickup';
import ManualSenderRemissionGuide from './manual-sender-remission-guide/ManualSenderRemissionGuide';
import ManualSenderRemissionGuides from './manual-sender-remission-guide/ManualSenderRemissionGuides';
import NewManualSenderRemissionGuide from './manual-sender-remission-guide/NewSenderRemissionGuide';
import CargoItemDetail from './cargo-item/CargoItemDetail';
import CargoCorporateCredits from './cargo-corporate-credit/CargoCorporateCredits';
import CargoCorporateCredit from './cargo-corporate-credit/CargoCorporateCredit';
import NewCargoCorporateCredit from './cargo-corporate-credit/NewCargoCorporateCredit';
import EditCargoCorporateCredit from './cargo-corporate-credit/EditCargoCorporateCredit';
import AddCreditLineCargoCorporateCredit from './cargo-corporate-credit/AddCreditLineCargoCorporateCredit';
import CargoItems from './cargo-item/CargoItems';
import Manifest from './manifest/Manifest';
import ParcelPrices from './parcel-price/ParcelPrices';
import ParcelPrice from './parcel-price/ParcelPrice';
import NewParcelPrice from './parcel-price/NewParcelPrice';
import ParcelPriceConfigurations from './parcel-price-configuration/ParcelPriceConfigurations';
import ParcelPriceConfiguration from './parcel-price-configuration/ParcelPriceConfiguration';
import EditParcelPriceConfiguration from './parcel-price-configuration/EditParcelPriceConfiguration';
import ParcelServiceTypes from './parcel-service-type/ParcelServiceTypes';
import ParcelServiceType from './parcel-service-type/ParcelServiceType';
import NewParcelServiceType from './parcel-service-type/NewParcelServiceType';
import EditParcelServiceType from './parcel-service-type/EditParcelServiceType';
import ManifestLegalAbandonment from './manifest-legal-abandonment/ManifestLegalAbandonment';
import NewDeliveryBusiness from './delivery-businesses/NewDeliveryBusiness';
import DeliveryBusinesses from './delivery-businesses/DeliveryBusinesses';
import DeliveryBusiness from './delivery-businesses/DeliveryBusiness';
import EditDeliveryBusiness from './delivery-businesses/EditDeliveryBusiness';
import AssignCargoToUser from './delivery-businesses/AssignCargoToUser';
import Deliveries from './delivery/Deliveries';
import Delivery from './delivery/Delivery';
import NewCargoCorporateCreditCity from './cargo-corporate-credit/NewCargoCorporateCreditCity';
import NewCargoCorporateCreditCustomer from './cargo-corporate-credit/NewCargoCorporateCreditCustomer';
import NewCargoCorporateCreditCommonProduct from './cargo-corporate-credit/NewCargoCorporateCreditCommonProduct';
import NewParcelPriceConfiguration from './parcel-price-configuration/NewParcelPriceConfiguration';
import NewCorporateParcelPriceConfiguration from './cargo-corporate-credit/NewCorporateParcelPriceConfiguration';
import NewCorporateParcelPriceSchedule from './cargo-corporate-credit/NewCorporateParcelPriceSchedule';
import Shelves from './shelf/Shelves';
import NewShelf from './shelf/NewShelf';
import Shelf from './shelf/Shelf';
import EditShelf from './shelf/EditShelf';
import RelocationCargoItem from './relocation-cargo-item/RelocationCargoItem';
import DeliveryLocationDistricts from './delivery-location-district/DeliveryLocationDistricts';
import DeliveryLocationDistrict from './delivery-location-district/DeliveryLocationDistrict';
import NewDeliveryLocationDistrict from './delivery-location-district/NewDeliveryLocationDistrict';
import EditDeliveryLocationDistrict from './delivery-location-district/EditDeliveryLocationDistrict';
import DeliveryPriceConfigurations from './delivery-price-configuration/DeliveryPriceConfigurations';
import DeliveryPriceConfiguration from './delivery-price-configuration/DeliveryPriceConfiguration';
import NewDeliveryPriceConfiguration from './delivery-price-configuration/NewDeliveryPriceConfiguration';
import EditDeliveryPriceConfiguration from './delivery-price-configuration/EditDeliveryPriceConfiguration';
import DeliveryPrices from './delivery-price/DeliveryPrices';
import NewDeliveryPrice from './delivery-price/NewDeliveryPrice';
import DeliveryPrice from './delivery-price/DeliveryPrice';

const CargoUnit = () => (
  <Switch>
    {/* <Route
      exact
      path={ADD_CARGO_ITEM_TO_ITINERARY_PATH}
      component={ItemToItinerary}
    />
    <Route exact path={UNLOADING_CARGO_PATH} component={UnloadingCargo} /> */}
    {/* <Route exact path={DELIVERY_GROUP_PATH} component={DeliveryGroups} />
    <Route exact path={NEW_DELIVERY_GROUP_PATH} component={NewDeliveryGroup} />
    <Route
      exact
      path={`${DELIVERY_GROUP_PATH}/:id`}
      component={DeliveryGroup}
    />
    <Route
      exact
      path={`${DELIVERY_GROUP_PATH}/:id/edit`}
      component={EditDeliveryGroup}
    /> */}
    {/* <Route
      exact
      path={NEW_BASE_PRICE_PER_KILOMETER_PATH}
      component={NewBasePricePerKilometer}
    />
    <Route
      exact
      path={BASE_PRICE_PER_KILOMETER_PATH}
      component={BasePricesPerKilometer}
    />
    <Route
      exact
      path={`${BASE_PRICE_PER_KILOMETER_PATH}/:id`}
      component={BasePricePerKilometer}
    />
    <Route
      exact
      path={`${BASE_PRICE_PER_KILOMETER_PATH}/:id/edit`}
      component={EditBasePricePerKilometer}
    />
    <Route
      exact
      path={GEOGRAPHIC_ZONE_COMBINATION_PATH}
      component={GeographicZoneCombinations}
    />
    <Route
      exact
      path={NEW_GEOGRAPHIC_ZONE_COMBINATION_PATH}
      component={NewGeographicZonesCombination}
    />
    <Route
      exact
      path={`${GEOGRAPHIC_ZONE_COMBINATION_PATH}/:id`}
      component={GeographicZonesCombination}
    />
    <Route
      exact
      path={`${GEOGRAPHIC_ZONE_COMBINATION_PATH}/:id/edit`}
      component={EditGeographicZonesCombination}
    />
    <Route exact path={DEPRECIATION_RULE_PATH} component={DepreciationRules} />
    <Route
      exact
      path={NEW_DEPRECIATION_RULE_PATH}
      component={NewDepreciationRule}
    />
    <Route
      exact
      path={`${DEPRECIATION_RULE_PATH}/:id/edit`}
      component={EditDepreciationRule}
    />
    <Route
      exact
      path={`${DEPRECIATION_RULE_PATH}/:id`}
      component={DepreciationRule}
    /> */}
    {/* <Route
      exact
      path={CARGO_MINIMAL_PRICE_PATH}
      component={CargoMinimalPrices}
    />
    <Route
      exact
      path={NEW_CARGO_MINIMAL_PRICE_PATH}
      component={NewCargoMinimal}
    />
    <Route
      exact
      path={`${CARGO_MINIMAL_PRICE_PATH}/:id`}
      component={EditCargoMinimalPrice}
    />
    <Route
      exact
      path={`${CARGO_MINIMAL_PRICE_PATH}/:id/edit`}
      component={EditCargoMinimalPrice}
    /> */}
    <Route exact path={CARGO_UNIT_PATH} component={CargoHome} />
    <Route
      exact
      path={NEW_DELIVERY_BUSINESS_PATH}
      component={NewDeliveryBusiness}
    />
    <Route exact path={DELIVERY_PATH} component={Deliveries} />
    <Route exact path={`${DELIVERY_PATH}/:id`} component={Delivery} />
    <Route exact path={DELIVERY_BUSINESS_PATH} component={DeliveryBusinesses} />
    <Route
      exact
      path={`${DELIVERY_BUSINESS_PATH}/:id`}
      component={DeliveryBusiness}
    />
    <Route
      exact
      path={`${DELIVERY_BUSINESS_PATH}/:id/edit`}
      component={EditDeliveryBusiness}
    />
    <Route
      exact
      path={`${DELIVERY_BUSINESS_PATH}-assign-cargo`}
      component={AssignCargoToUser}
    />
    <Route
      exact
      path={`${DELIVERY_LOCATION_DISTRICT_PATH}`}
      component={DeliveryLocationDistricts}
    />
    <Route
      exact
      path={`${NEW_DELIVERY_LOCATION_DISTRICT_PATH}`}
      component={NewDeliveryLocationDistrict}
    />
    <Route
      exact
      path={`${DELIVERY_LOCATION_DISTRICT_PATH}/:id`}
      component={DeliveryLocationDistrict}
    />
    <Route
      exact
      path={`${DELIVERY_LOCATION_DISTRICT_PATH}/:id/edit`}
      component={EditDeliveryLocationDistrict}
    />
    <Route
      exact
      path={`${DELIVERY_PRICE_CONFIGURATION_PATH}`}
      component={DeliveryPriceConfigurations}
    />
    <Route
      exact
      path={`${NEW_DELIVERY_PRICE_CONFIGURATION_PATH}`}
      component={NewDeliveryPriceConfiguration}
    />
    <Route
      exact
      path={`${DELIVERY_PRICE_CONFIGURATION_PATH}/:id`}
      component={DeliveryPriceConfiguration}
    />
    <Route
      exact
      path={`${DELIVERY_PRICE_CONFIGURATION_PATH}/:id/edit`}
      component={EditDeliveryPriceConfiguration}
    />
    <Route exact path={DELIVERY_PRICE_PATH} component={DeliveryPrices} />
    <Route exact path={NEW_DELIVERY_PRICE_PATH} component={NewDeliveryPrice} />
    <Route
      exact
      path={`${DELIVERY_PRICE_PATH}/:id`}
      component={DeliveryPrice}
    />
    <Route exact path={NEW_COMMON_PRODUCT_PATH} component={NewCommonProduct} />
    <Route exact path={COMMON_PRODUCT_PATH} component={CommonProducts} />
    <Route
      exact
      path={`${COMMON_PRODUCT_PATH}/:id`}
      component={CommonProduct}
    />
    <Route
      exact
      path={`${COMMON_PRODUCT_PATH}/:id/edit`}
      component={EditCommonProduct}
    />
    <Route exact path={CARGO_DISTANCE_PATH} component={CargoDistances} />
    <Route exact path={NEW_CARGO_DISTANCE_PATH} component={NewCargoDistance} />
    <Route
      exact
      path={`${CARGO_DISTANCE_PATH}/:id`}
      component={CargoDistance}
    />
    <Route
      exact
      path={`${CARGO_DISTANCE_PATH}/:id/edit`}
      component={EditCargoDistance}
    />
    <Route exact path={CARGO_PATH} component={Parcels} />
    <Route exact path={NEW_CARGO_PATH} component={NewParcel} />
    <Route exact path={`${CARGO_PATH}/:id`} component={Parcel} />
    <Route exact path={NEW_CARGO_CATEGORY_PATH} component={NewCargoCategory} />
    <Route exact path={CARGO_CATEGORY_PATH} component={CargoCategories} />
    <Route
      exact
      path={`${CARGO_CATEGORY_PATH}/:id`}
      component={CargoCategory}
    />
    <Route
      exact
      path={`${CARGO_CATEGORY_PATH}/:id/edit`}
      component={EditCargoCategory}
    />
    <Route exact path={MANIFEST_PATH} component={Manifest} />
    <Route exact path={INTERNAL_TRANSFER_PATH} component={InternalParcels} />
    <Route
      exact
      path={NEW_INTERNAL_TRANSFER_PATH}
      component={NewInternalParcel}
    />
    <Route
      exact
      path={`${INTERNAL_TRANSFER_PATH}/:id`}
      component={InternalParcel}
    />
    <Route
      exact
      path={`${MANIFEST_ITINERARY_PATH}/:id`}
      component={ManifestItinerary}
    />
    <Route exact path={LEGAL_ABANDONMENT_PATH} component={LegalAbandonment} />
    <Route exact path={CARGO_PICKUP_PATH} component={CargoPickup} />
    <Route
      exact
      path={MANUAL_SENDER_REMISSION_GUIDE_PATH}
      component={ManualSenderRemissionGuides}
    />
    <Route
      exact
      path={NEW_MANUAL_SENDER_REMISSION_GUIDE_PATH}
      component={NewManualSenderRemissionGuide}
    />
    <Route
      exact
      path={`${MANUAL_SENDER_REMISSION_GUIDE_PATH}/:id`}
      component={ManualSenderRemissionGuide}
    />

    <Route exact path={`${CARGO_ITEM_PATH}/:id`} component={CargoItemDetail} />

    <Route
      exact
      path={`${INTERNAL_CARGO_ITEM_PATH}/:id`}
      component={CargoItemDetail}
    />
    <Route
      exact
      path={CARGO_CORPORATE_CREDIT_PATH}
      component={CargoCorporateCredits}
    />
    <Route
      exact
      path={NEW_CARGO_CORPORATE_CREDIT_PATH}
      component={NewCargoCorporateCredit}
    />
    <Route
      exact
      path={`${CARGO_CORPORATE_CREDIT_PATH}/:id`}
      component={CargoCorporateCredit}
    />
    <Route
      exact
      path={`${CARGO_CORPORATE_CREDIT_PATH}/:id/edit`}
      component={EditCargoCorporateCredit}
    />
    <Route
      exact
      path={`${CARGO_CORPORATE_CREDIT_PATH}/:id/add-credit-line`}
      component={AddCreditLineCargoCorporateCredit}
    />
    <Route exact path={CARGO_ITEM_PATH} component={CargoItems} />
    <Route exact path={PARCEL_PRICE_PATH} component={ParcelPrices} />
    <Route exact path={NEW_PARCEL_PRICE_PATH} component={NewParcelPrice} />
    <Route exact path={`${PARCEL_PRICE_PATH}/:id`} component={ParcelPrice} />
    <Route
      exact
      path={PARCEL_PRICE_CONFIGURATION_PATH}
      component={ParcelPriceConfigurations}
    />
    <Route
      exact
      path={`${PARCEL_PRICE_CONFIGURATION_PATH}/:id/edit`}
      component={EditParcelPriceConfiguration}
    />
    <Route
      exact
      path={NEW_PARCEL_PRICE_CONFIGURATION_PATH}
      component={NewParcelPriceConfiguration}
    />
    <Route
      exact
      path={`${PARCEL_PRICE_CONFIGURATION_PATH}/:id`}
      component={ParcelPriceConfiguration}
    />
    <Route
      exact
      path={PARCEL_SERVICE_TYPE_PATH}
      component={ParcelServiceTypes}
    />
    <Route
      exact
      path={NEW_PARCEL_SERVICE_TYPE_PATH}
      component={NewParcelServiceType}
    />
    <Route
      exact
      path={`${PARCEL_SERVICE_TYPE_PATH}/:id`}
      component={ParcelServiceType}
    />
    <Route
      exact
      path={`${PARCEL_SERVICE_TYPE_PATH}/:id/edit`}
      component={EditParcelServiceType}
    />
    <Route
      exact
      path={MANIFEST_LEGAL_ABANDONMENT_PATH}
      component={ManifestLegalAbandonment}
    />
    <Route
      exact
      path={NEW_CARGO_CORPORATE_CREDIT_CITY_PATH}
      component={NewCargoCorporateCreditCity}
    />
    <Route
      exact
      path={NEW_CARGO_CORPORATE_CREDIT_CITY_CUSTOMER_PATH}
      component={NewCargoCorporateCreditCustomer}
    />
    <Route
      exact
      path={NEW_CARGO_CORPORATE_CREDIT_COMMON_PRODUCT}
      component={NewCargoCorporateCreditCommonProduct}
    />
    <Route
      exact
      path={`${CARGO_CORPORATE_CREDIT_PATH}/:id/parcel-price-configuration`}
      component={NewCorporateParcelPriceConfiguration}
    />
    <Route
      exact
      path={`${CARGO_CORPORATE_CREDIT_PATH}/:id/parcel-price-schedule`}
      component={NewCorporateParcelPriceSchedule}
    />
    <Route exact path={SHELF_PATH} component={Shelves} />
    <Route exact path={NEW_SHELF_PATH} component={NewShelf} />
    <Route exact path={`${SHELF_PATH}/:id`} component={Shelf} />
    <Route exact path={`${SHELF_PATH}/:id/edit`} component={EditShelf} />
    <Route
      exact
      path={RELOCATION_CARGO_ITEM_PATH}
      component={RelocationCargoItem}
    />
  </Switch>
);

export default CargoUnit;
