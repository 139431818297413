import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field, change } from 'redux-form';
import { Form, FormGroup } from 'reactstrap';
import Immutable from 'immutable';
import { toastr } from 'react-redux-toastr';
import FormItem from '../../../common/forms/FormItem';
import { isRequired } from '../../../../utils/validators';
import {
  clearItemToItinerary,
  clearItinerary,
  deleteItemFromItinerary,
  getCargoItemByItinerary,
  getItineraryForCounter,
  getUserAgenciesV2,
  putRemoveItemFromItinerary,
} from '../../../../actions';
import ItinerarySearchInput from '../../../common/forms/input/ItinerarySearchInput';
import UnloadingCargoResult from './UnloadingCargoResult';
import { generateUnloadingCargoItemsColumns } from '../../../../config/columns';
import { DEFAULT_QUERY } from '../../../../config/queries';
import Select from '../../../common/forms/select/Select';
import { optionsPropTypes } from '../../../common/forms/select/SelectPropTypes';
import FormFooter from '../../../common/forms/FormFooter';

export const getUniqueLocations = (
  itineraryCounter,
  getDestinations,
  getOrigins,
) => {
  const uniqueLocations = [];
  const locationSet = new Set();

  if (getDestinations) {
    itineraryCounter.get('itineraryScheduleSegmentList').forEach((segment) => {
      const { destinationLocation } = segment.segment;

      if (!locationSet.has(destinationLocation.id)) {
        uniqueLocations.push({
          id: destinationLocation.id,
          name: destinationLocation.name,
          legalAbandonmentHomeBase:
            destinationLocation.legalAbandonmentHomeBase,
        });
        locationSet.add(destinationLocation.id);
      }
    });
  }

  if (getOrigins) {
    itineraryCounter.get('itineraryScheduleSegmentList').forEach((segment) => {
      const { sourceLocation } = segment.segment;

      if (!locationSet.has(sourceLocation.id)) {
        uniqueLocations.push({
          id: sourceLocation.id,
          name: sourceLocation.name,
          legalAbandonmentHomeBase: sourceLocation.legalAbandonmentHomeBase,
        });
        locationSet.add(sourceLocation.id);
      }
    });
  }

  return uniqueLocations;
};

export const UnloadingCargoForm = ({
  handleSubmit,
  loadingAgencies,
  itineraryCounter,
  loadingItinerary,
  agencyOptions,
  user,
  dispatchChange,
  dispatchClearItemToItinerary,
  dispatchGetCargoItemByItinerary,
  dispatchGetUserAgenciesV2,
  dispatchPutRemoveItemFromItinerary,
  dispatchDeleteItemFromItinerary,
  dispatchGetItineraryForCounter,
  dispatchClearItinerary,
}) => {
  const [destinationLocationId, setDestinationLocationId] = useState(null);
  const [itineraryId, setItineraryId] = useState(0);
  const [agenciesToShow, setAgenciesToShow] = useState([]);

  const verifyLocationWithAgencies = () => {
    const uniqueLocations = getUniqueLocations(itineraryCounter, true);

    let newAgenciesToShow = [];

    if (uniqueLocations.length !== 0) {
      newAgenciesToShow = agencyOptions.filter((agency) =>
        uniqueLocations.some((location) => agency.value === location.id),
      );

      if (newAgenciesToShow.length === 0) {
        toastr.error(
          'Error',
          'No puedes descargar de este itinerario, ninguna de sus agencias coincide con los destinos.',
        );
        return;
      }
    }

    dispatchChange(
      'UnloadingCargoForm',
      'destinationLocation',
      newAgenciesToShow[0],
    );

    setAgenciesToShow(newAgenciesToShow);
  };

  useEffect(() => {
    if (user) {
      dispatchGetUserAgenciesV2({ userId: user.id });
    }
  }, [user, dispatchGetUserAgenciesV2]);

  useEffect(() => {
    if (itineraryId && loadingItinerary && !itineraryCounter.isEmpty()) {
      verifyLocationWithAgencies();
    }
  }, [itineraryId, itineraryCounter, loadingItinerary]);

  useEffect(
    () => () => {
      dispatchClearItemToItinerary();
      dispatchClearItinerary();
    },
    [dispatchClearItemToItinerary, dispatchClearItinerary],
  );

  const onHandleSubmit = ({ destinationLocation }) => {
    setDestinationLocationId(destinationLocation.value);
    dispatchClearItemToItinerary();
    dispatchGetCargoItemByItinerary({
      itineraryId,
      destinationLocationId: destinationLocation.value,
      ...DEFAULT_QUERY,
    });
  };

  const onRemoveItemFromItinerary = async (
    correlation,
    cargoItemId,
    internalCargoItem,
  ) => {
    const removed = await dispatchPutRemoveItemFromItinerary({
      cargoItemId,
      itineraryId,
      locationId: destinationLocationId,
      internalCargoItem,
    });
    if (removed) {
      dispatchDeleteItemFromItinerary(correlation);
    }
  };

  const handleSelectItinerary = ({ itineraryId: selectedItineraryId }) => {
    setItineraryId(selectedItineraryId);
    dispatchGetItineraryForCounter(selectedItineraryId);
  };

  const handleShowItinerarySearch = () => {
    dispatchClearItemToItinerary();
    dispatchChange('UnloadingCargoForm', 'itineraryId', '');
    setAgenciesToShow([]);
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onHandleSubmit)}>
        <FormGroup row>
          <FormItem label="Itinerario" required>
            <Field
              name="itineraryId"
              component={ItinerarySearchInput}
              placeholder="Itinerario"
              handleSelectItinerary={handleSelectItinerary}
              handleShowItinerarySearch={handleShowItinerarySearch}
              validate={[isRequired]}
              props={{
                form: 'UnloadingCargoForm',
              }}
            />
          </FormItem>
        </FormGroup>
        {!itineraryCounter.isEmpty() && (
          <FormGroup row>
            <FormItem label="Destino" required>
              <Field
                name="destinationLocation"
                component={Select}
                isLoading={loadingAgencies}
                options={agenciesToShow}
                isDisabled={agenciesToShow.length <= 1}
                validate={[isRequired]}
              />
            </FormItem>
          </FormGroup>
        )}

        <FormFooter />
      </Form>
      <h5>Lista de Items</h5>
      <UnloadingCargoResult
        columns={generateUnloadingCargoItemsColumns(onRemoveItemFromItinerary)}
        searchFormValues={{ itineraryId, destinationLocationId: 0 }}
      />
    </>
  );
};

UnloadingCargoForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loadingAgencies: PropTypes.bool,
  agencyOptions: optionsPropTypes,
  itineraryCounter: PropTypes.instanceOf(Immutable.Map).isRequired,
  loadingItinerary: PropTypes.bool,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    customer: PropTypes.shape({
      fullName: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  dispatchChange: PropTypes.func.isRequired,
  dispatchClearItemToItinerary: PropTypes.func.isRequired,
  dispatchGetCargoItemByItinerary: PropTypes.func.isRequired,
  dispatchGetUserAgenciesV2: PropTypes.func.isRequired,
  dispatchPutRemoveItemFromItinerary: PropTypes.func.isRequired,
  dispatchDeleteItemFromItinerary: PropTypes.func.isRequired,
  dispatchGetItineraryForCounter: PropTypes.func.isRequired,
  dispatchClearItinerary: PropTypes.func.isRequired,
};

UnloadingCargoForm.defaultProps = {
  loadingAgencies: false,
  loadingItinerary: false,
  agencyOptions: [],
};

const mapStateToProps = ({
  HumanResourcesUnit,
  ItineraryUnit,
  authentication,
}) => ({
  loadingAgencies: HumanResourcesUnit.Agency.getIn(['all', 'loading']),
  agencyOptions: HumanResourcesUnit.Agency.getIn([
    'all',
    'content',
    'content',
  ]).map((agency) => ({
    value: agency.locationId,
    label: agency.name,
  })),
  itineraryCounter: ItineraryUnit.Itinerary.getIn(['current', 'content']),
  loadingItinerary: !ItineraryUnit.Itinerary.getIn([
    'current',
    'activity',
  ]).isEmpty(),
  user: authentication.get('user') || undefined,
});

const mapDispatchToProps = {
  dispatchChange: change,
  dispatchGetCargoItemByItinerary: getCargoItemByItinerary,
  dispatchClearItemToItinerary: clearItemToItinerary,
  dispatchGetUserAgenciesV2: getUserAgenciesV2,
  dispatchPutRemoveItemFromItinerary: putRemoveItemFromItinerary,
  dispatchDeleteItemFromItinerary: deleteItemFromItinerary,
  dispatchGetItineraryForCounter: getItineraryForCounter,
  dispatchClearItinerary: clearItinerary,
};

const formComponent = reduxForm({
  form: 'UnloadingCargoForm',
})(UnloadingCargoForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
