import React, { useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { ButtonToolbar } from 'reactstrap';
import { Link } from 'react-router-dom';
import {
  clearParcelPriceConfigurations,
  getParcelPriceConfigurations,
} from '../../../../actions/cargo/ParcelPriceConfiguration';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { PARCEL_PRICE_CONFIGURATION_PATH } from '../../../../config/paths';
import {
  CARGO_GLOBAL_PARCEL_PRICE_CONFIGURATION_ID,
  DEFAULT_PAGE_SIZE,
} from '../../../../config/constants';
import { PARCEL_PRICE_CONFIGURATION_COLUMNS } from '../../../../config/columns';
import Content from '../../../layout/Content';
import Table from '../../../common/Table';

const ParcelPriceConfigurations = ({
  breadcrumbs,
  parcelPriceConfigurations,
  loading,
  dispatchGetParcelPriceConfiguration,
  dispatchClearParcelPriceConfigurations,
}) => {
  useLayoutEffect(() => () => dispatchClearParcelPriceConfigurations(), []);

  const data = parcelPriceConfigurations.get('content') || [];
  const pages = parcelPriceConfigurations.get('totalPages') || null;
  const defaultPageSize =
    parcelPriceConfigurations.get('size') || DEFAULT_PAGE_SIZE;

  const content = (
    <Table
      columns={PARCEL_PRICE_CONFIGURATION_COLUMNS}
      data={data}
      pages={pages}
      defaultPageSize={defaultPageSize}
      filterable
      fetchData={dispatchGetParcelPriceConfiguration}
      modelPath={PARCEL_PRICE_CONFIGURATION_PATH}
      loading={loading}
      navigateToModelOnRowClick
    />
  );

  const toolbar = (
    <ButtonToolbar className="pull-right">
      <div>
        <Link
          className="btn btn-secondary"
          to={`${PARCEL_PRICE_CONFIGURATION_PATH}/${CARGO_GLOBAL_PARCEL_PRICE_CONFIGURATION_ID}`}
        >
          <i className="fa fa-cog" /> Configuración Global
        </Link>{' '}
        <Link
          className="btn btn-primary"
          to={`${PARCEL_PRICE_CONFIGURATION_PATH}/new`}
        >
          <i className="fa fa-plus-circle" /> Agregar
        </Link>
      </div>
    </ButtonToolbar>
  );

  return (
    <Content
      title="Configuración de Tarifas"
      breadcrumbs={breadcrumbs}
      content={content}
      toolbar={toolbar}
    />
  );
};

const mapDispatchToProps = {
  dispatchGetParcelPriceConfiguration: getParcelPriceConfigurations,
  dispatchClearParcelPriceConfigurations: clearParcelPriceConfigurations,
};

ParcelPriceConfigurations.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  parcelPriceConfigurations: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetParcelPriceConfiguration: PropTypes.func.isRequired,
  dispatchClearParcelPriceConfigurations: PropTypes.func.isRequired,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Configuración de Tarifas',
      href: PARCEL_PRICE_CONFIGURATION_PATH,
    },
  ],
  parcelPriceConfigurations: CargoUnit.ParcelPriceConfiguration.getIn([
    'all',
    'content',
  ]),
  loading: CargoUnit.ParcelPriceConfiguration.getIn(['all', 'loading']),
});

ParcelPriceConfigurations.defaultProps = {
  parcelPriceConfigurations: null,
  loading: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ParcelPriceConfigurations);
