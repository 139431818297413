import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import EditButton from '../../../common/button/EditButton';
import { CARGO_CORPORATE_CREDIT_PATH } from '../../../../config/paths';
import CargoCorporateCreditAddCreditLineButton from './CargoCorporateCreditAddCreditLineButton';
import CargoCorporateCreditDeleteButton from './CargoCorporateCreditDeleteButton';
import CargoCorporateCreditUnifyInvoicesButton from './CargoCorporateCreditUnifyInvoicesButton';
import CargoCorporateCreditDropdownButton from './CargoCorporateCreditDropdownButton';

const CargoCorporateCreditToolBar = ({ cargoCorporateCreditId, companyId }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <CargoCorporateCreditUnifyInvoicesButton
        cargoCorporateCreditId={cargoCorporateCreditId}
        companyId={companyId}
      />{' '}
      <CargoCorporateCreditAddCreditLineButton
        cargoCorporateCreditId={cargoCorporateCreditId}
      />{' '}
      <CargoCorporateCreditDropdownButton
        cargoCorporateCreditId={cargoCorporateCreditId}
      />{' '}
      <EditButton
        path={`${CARGO_CORPORATE_CREDIT_PATH}/${cargoCorporateCreditId}/edit`}
      />{' '}
      <CargoCorporateCreditDeleteButton
        cargoCorporateCreditId={cargoCorporateCreditId}
      />{' '}
    </div>
  </ButtonToolbar>
);

CargoCorporateCreditToolBar.propTypes = {
  cargoCorporateCreditId: PropTypes.number.isRequired,
  companyId: PropTypes.number,
};

CargoCorporateCreditToolBar.defaultProps = {
  companyId: null,
};

export default CargoCorporateCreditToolBar;
