import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from './Select';
import {
  clearCargoCorporateCredits,
  getCargoCorporateCredits,
} from '../../../../actions/cargo/CargoCorporateCredit';
import { DEFAULT_QUERY_GET_ALL } from '../../../../config/queries';

const CargoCorporateCreditSelect = ({
  loading,
  commonProductOptions,
  isGridVariant,
  value,
  isMulti,
  isClearable,
  onValueChange,
  dispatchGetCargoCorporateCredits,
  dispatchClearCargoCorporateCredits,
  ...rest
}) => {
  useLayoutEffect(() => {
    dispatchGetCargoCorporateCredits(DEFAULT_QUERY_GET_ALL);
    return () => dispatchClearCargoCorporateCredits();
  }, []);

  return (
    <Select
      isLoading={loading}
      options={commonProductOptions}
      isMulti={isMulti}
      input={{ value }}
      onValueChange={onValueChange}
      isClearable={isClearable}
      isGridVariant={isGridVariant}
      {...rest}
    />
  );
};

CargoCorporateCreditSelect.propTypes = {
  isGridVariant: PropTypes.bool,
  onValueChange: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  isMulti: PropTypes.bool,
  commonProductOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
    }),
  ).isRequired,
  isClearable: PropTypes.bool,
  value: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  }),
  cleanWhenUnmount: PropTypes.bool,
  dispatchGetCargoCorporateCredits: PropTypes.func.isRequired,
  dispatchClearCargoCorporateCredits: PropTypes.func.isRequired,
};

CargoCorporateCreditSelect.defaultProps = {
  isGridVariant: false,
  isMulti: false,
  isClearable: false,
  value: {},
  cleanWhenUnmount: false,
  onValueChange: undefined,
};

const mapStateToProps = ({ CargoUnit }) => ({
  loading: !CargoUnit.CargoCorporateCredit.getIn(['all', 'content', 'content']),

  commonProductOptions: CargoUnit.CargoCorporateCredit.getIn([
    'all',
    'content',
    'content',
  ]).map((commonProduct) => ({
    value: commonProduct.id,
    label: commonProduct.name,
  })),
});

const mapDispatchToProps = {
  dispatchGetCargoCorporateCredits: getCargoCorporateCredits,
  dispatchClearCargoCorporateCredits: clearCargoCorporateCredits,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CargoCorporateCreditSelect);
