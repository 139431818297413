import React, { useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import Content from '../../../layout/Content';
import CargoCorporateCreditCityForm from './CargoCorporateCreditCityForm';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import {
  clearCargoCorporateCredit,
  getCargoCorporateCredit,
} from '../../../../actions/cargo/CargoCorporateCredit';
import { CARGO_CORPORATE_CREDIT_PATH } from '../../../../config/paths';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import { postCargoCorporateCreditCity } from '../../../../actions/cargo/CargoCorporateCreditCity';

const NewCargoCorporateCreditCity = ({
  match: {
    params: { id: cargoCorporateCreditId },
  },
  dispatchGetCargoCorporateCredit,
  dispatchClearCargoCorporateCredit,
  loading,
  cargoCorporateCredit,
  breadcrumbs,
  dispatchPostCargoCorporateCreditCity,
}) => {
  useLayoutEffect(() => {
    dispatchGetCargoCorporateCredit({ cargoCorporateCreditId });
    return () => dispatchClearCargoCorporateCredit();
  }, []);

  const handleSubmit = (formValues) => {
    const newFormValues = {
      cityId: formValues.city.value,
      customerList:
        formValues.customerList && formValues.customerList.length
          ? formValues.customerList.map((item) => ({
              customerId: item.customer.value,
              customerType: item.customerType.value,
            }))
          : [],
    };

    dispatchPostCargoCorporateCreditCity(cargoCorporateCreditId, newFormValues);
  };

  let content;

  if (loading) content = <Loader />;
  else if (cargoCorporateCredit.isEmpty())
    content = <NoDataResource returnPage={CARGO_CORPORATE_CREDIT_PATH} />;
  else {
    content = <CargoCorporateCreditCityForm onSubmit={handleSubmit} />;
  }

  return (
    <Content
      title="Nueva Ciudad de Corporativo"
      content={content}
      breadcrumbs={breadcrumbs}
    />
  );
};

NewCargoCorporateCreditCity.propTypes = {
  match: matchPropTypes.isRequired,
  dispatchGetCargoCorporateCredit: PropTypes.func.isRequired,
  dispatchClearCargoCorporateCredit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  cargoCorporateCredit: PropTypes.instanceOf(Immutable.Map).isRequired,
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostCargoCorporateCreditCity: PropTypes.func.isRequired,
};

NewCargoCorporateCreditCity.defaultProps = {
  loading: true,
};

const mapStateToProps = (
  { CargoUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Créditos Corporativos',
      href: CARGO_CORPORATE_CREDIT_PATH,
    },
    {
      text: 'Ver Crédito Corporativo',
      href: `${CARGO_CORPORATE_CREDIT_PATH}/${id}`,
    },
    {
      text: 'Nueva Ciudad de Corporativo',
      href: '',
    },
  ],
  cargoCorporateCredit: CargoUnit.CargoCorporateCredit.getIn([
    'current',
    'content',
  ]),
  loading: !CargoUnit.CargoCorporateCredit.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetCargoCorporateCredit: getCargoCorporateCredit,
  dispatchClearCargoCorporateCredit: clearCargoCorporateCredit,
  dispatchPostCargoCorporateCreditCity: postCargoCorporateCreditCity,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewCargoCorporateCreditCity);
