import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { EXPENSE_PATH } from '../../../../config/paths';
import { EXPENSE_STATUS } from '../../../../config/constants';

const EditExpenseButton = ({ expenseId, status }) => {
  if (status === EXPENSE_STATUS.PRE_APPROVED.value) {
    return null;
  }

  return (
    <Link className="btn btn-primary" to={`${EXPENSE_PATH}/${expenseId}/edit`}>
      Editar
    </Link>
  );
};

EditExpenseButton.propTypes = {
  expenseId: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
};

export default connect(null, null)(EditExpenseButton);
