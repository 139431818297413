import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { change, Field } from 'redux-form';
import { toastr } from 'react-redux-toastr';
import { Button, Col, FormGroup, Row } from 'reactstrap';
import { optionPropTypes } from '../../../common/forms/select/SelectPropTypes';
import withEndpointAuthorization from '../../authorization/withEndPointAuthorization';
import { POST } from '../../../../config/permissions';
import { OPENPAY_LINK_ENDPOINT } from '../../../../config/endpoints';
import { postGenerateOpenpayLink } from '../../../../actions';
import OpenpayLinkModal from './OpenpayLinkModal';
import FormItem from '../../../common/forms/FormItem';
import TextInput from '../../../common/forms/input/TextInput';
import { isRequired, validateEmail } from '../../../../utils/validators';
import Select from '../../../common/forms/select/Select';
import { enumToSelectOptions } from '../../../../utils/enum';
import { CURRENCY_CODE } from '../../../../config/constants';
import Modal from '../../../common/modal/Modal';

const OpenpayFields = ({
  form,
  dispatchChange,
  receiverValues: {
    amount,
    currency,
    userName,
    userLastName,
    userEmail,
    userPhone,
    transactionCode,
  },
  dispatchPostGenerateOpenpayLink,
  remainingPrice,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [openpayResponse, setOpenpayResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const modalBody = (
    <OpenpayLinkModal openpayResponse={openpayResponse} isLoading={isLoading} />
  );

  useEffect(() => {
    if (remainingPrice != null) {
      dispatchChange(form, 'amount', remainingPrice);
    }
  }, [remainingPrice, dispatchChange, form]);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const validateValues = () => {
    let isValid = false;
    if (!userName) toastr.error('Error', 'Nombre es obligatorio.');
    if (!userLastName) toastr.error('Error', 'Apellido es obligatorio.');
    if (!userEmail) toastr.error('Error', 'Correo es obligatorio.');
    if (!userPhone) toastr.error('Error', 'Celular es obligatorio.');

    if (userName && userLastName && userEmail && userPhone) {
      isValid = true;
    }

    return isValid;
  };

  const validateOpenpayResponse = (response) => {
    if (!response) return false;
    if (!response.checkoutLink) return false;
    return true;
  };

  const handleGenerateLink = async () => {
    const isValid = validateValues();

    if (!isValid || openpayResponse) return;

    setIsLoading(true);
    setShowModal(true);

    const response = await dispatchPostGenerateOpenpayLink({
      amount,
      currency: currency.value,
      userName,
      userLastName,
      userEmail,
      userPhone,
      transactionCode,
    });

    const isSucces = validateOpenpayResponse(response);

    setIsLoading(false);

    if (!isSucces) {
      toastr.error('Error al crear el link');
      setShowModal(false);
      return;
    }

    setOpenpayResponse(response);

    dispatchChange(form, 'openpayLink', response.checkoutLink);
  };

  return (
    <>
      <h2>Openpay Pago Link</h2>
      <hr />
      <p className="text-muted">
        Los siguientes datos son necesarios para la correcta generación del Link
      </p>
      <FormGroup row>
        <FormItem label="Monto" required>
          <Row>
            <Col lg={3} md={4} sm={6} xs={6}>
              <Field
                name="amount"
                id="amount"
                component={TextInput}
                showToolTip
                type="text"
                placeholder="Monto"
                disabled
                validate={[isRequired]}
              />
            </Col>
            <Col lg={3} md={4} sm={6} xs={6}>
              <Field
                name="currency"
                id="currency"
                component={Select}
                showToolTip
                options={enumToSelectOptions(CURRENCY_CODE)}
                placeholder="Moneda"
                isDisabled
                validate={[isRequired]}
              />
            </Col>
          </Row>
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Pasajero" required>
          <Row>
            <Col md={6} sm={12} xs={12}>
              <Field
                name="userName"
                id="userName"
                component={TextInput}
                showToolTip
                type="text"
                placeholder="Nombre"
                validate={[isRequired]}
              />
            </Col>
            <Col md={6} sm={12} xs={12}>
              <Field
                name="userLastName"
                id="userLastName"
                component={TextInput}
                showToolTip
                type="text"
                validate={[isRequired]}
                placeholder="Apellido"
              />
            </Col>
          </Row>
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Correo" required>
          <Field
            name="userEmail"
            component={TextInput}
            type="text"
            validate={[isRequired, validateEmail]}
            placeholder="Correo"
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Celular" required>
          <Field
            name="userPhone"
            id="userPhone"
            component={TextInput}
            type="text"
            showToolTip
            placeholder="Celular"
            validate={[isRequired]}
          />
          <Field
            name="transactionCode"
            component={TextInput}
            type="hidden"
            placeholder=""
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem>
          <Button
            type="button"
            color="secondary"
            onClick={handleGenerateLink}
            disabled={Boolean(openpayResponse) || isLoading}
          >
            <i className="fa fa-link" /> Generar Link
          </Button>{' '}
          {Boolean(openpayResponse) && (
            <Button type="button" color="secondary" onClick={handleOpenModal}>
              <i className="fa fa-window-maximize" /> Mostrar Link
            </Button>
          )}
        </FormItem>
      </FormGroup>
      <Modal
        title="Openpay Pago Link"
        onClickCancel={handleCloseModal}
        onClickClose={handleCloseModal}
        show={showModal}
        body={modalBody}
      />
    </>
  );
};

OpenpayFields.propTypes = {
  dispatchChange: PropTypes.func.isRequired,
  form: PropTypes.string.isRequired,
  dispatchPostGenerateOpenpayLink: PropTypes.func.isRequired,
  receiverValues: PropTypes.shape({
    amount: PropTypes.number,
    currency: optionPropTypes,
    userName: PropTypes.string,
    userLastName: PropTypes.string,
    userEmail: PropTypes.string,
    userDocumentNumber: PropTypes.string,
    userDocumentType: optionPropTypes,
    userCodeCountry: PropTypes.string,
    userPhone: PropTypes.string,
    transactionCode: PropTypes.number,
  }).isRequired,
  remainingPrice: PropTypes.number,
};

OpenpayFields.defaultProps = {
  remainingPrice: null,
};

const mapStateToProps = (state, { selector }) => ({
  receiverValues: {
    amount: selector(state, 'amount'),
    currency: selector(state, 'currency'),
    userName: selector(state, 'userName'),
    userLastName: selector(state, 'userLastName'),
    userEmail: selector(state, 'userEmail'),
    userDocumentNumber: selector(state, 'userDocumentNumber'),
    userDocumentType: selector(state, 'userDocumentType'),
    userCodeCountry: selector(state, 'userCodeCountry'),
    userPhone: selector(state, 'userPhone'),
    transactionCode: selector(state, 'transactionCode'),
  },
});

const mapDispatchToProps = {
  dispatchPostGenerateOpenpayLink: postGenerateOpenpayLink,
  dispatchChange: change,
};

const connectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(OpenpayFields);

export default withEndpointAuthorization({
  url: OPENPAY_LINK_ENDPOINT,
  permissionType: POST,
})(connectedComponent);
