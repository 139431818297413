import {
  addImage,
  addQrCode,
  addText,
  createPdfDocument,
  setFont,
} from '../pdf';
import LogoCiva from '../../resources/images/logo-civa.png';

const margin = 3;
const qrWidth = 38;
const qrHeight = 38;
const imageWidth = 25;
const imageHeight = 8;
let myY = -margin;

const printItemTicket = async (
  doc,
  {
    shipmentFields: { origin, destiny, originAgencyCode, destinyAgencyCode },
    consignedFields: {
      consignedName,
      consignedDocumentType,
      consignedDocumentNumber,
    },
    descriptionItem,
    correlationCodeItem,
    itemNumber,
    itemList,
    weight,
    isFirstPrint,
  },
) => {
  myY = -margin;

  if (!isFirstPrint) {
    doc.addPage('l', 'mm');
  }

  const column1X = 3;
  const column2X = 70;

  setFont(doc, 'bold');
  doc.setFontSize(14);

  addImage(doc, LogoCiva, column1X, myY + margin * 2, imageWidth, imageHeight);

  addText(
    doc,
    correlationCodeItem,
    column2X + 2,
    (myY += margin * 4),
    75,
    'left',
  );

  setFont(doc);
  doc.setFontSize(8);
  addText(
    doc,
    'Turismo Civa S.A.C - RUC:20102427891',
    column1X,
    (myY += margin * 2),
    75,
    'left',
  );

  setFont(doc, 'bold');
  doc.setFontSize(8);

  doc.text('DESDE:', column1X, (myY += margin * 1.5));

  doc.setFontSize(10);
  const originText = `${origin} (${originAgencyCode || '-'})`.toUpperCase();
  const originWrappedText = doc.splitTextToSize(originText, 70);
  doc.text(originWrappedText, column1X, (myY += margin * 1.2), {
    align: 'left',
  });

  myY += margin * originWrappedText.length;

  doc.setFontSize(8);
  doc.text('HASTA:', column1X, (myY += margin * 1.2));

  doc.setFontSize(10);
  const destinyText = `${destiny} (${destinyAgencyCode || '-'})`.toUpperCase();
  const destinyWrappedText = doc.splitTextToSize(destinyText, 77);
  doc.text(destinyWrappedText, column1X, (myY += margin * 1.2), {
    align: 'left',
  });

  myY += margin * destinyWrappedText.length;

  setFont(doc, 'bold');
  doc.setFontSize(8);
  addText(
    doc,
    'DATOS DEL CONSIGNADO',
    column1X,
    (myY += margin * 1.2),
    75,
    'left',
  );

  doc.setFontSize(8);
  setFont(doc);

  addText(
    doc,
    `Nombre: ${consignedName}`,
    column1X,
    (myY += margin),
    80,
    'left',
  );

  addText(doc, 'Tipo de Documento', column1X, (myY += margin), 40, 'left');
  addText(doc, `: ${consignedDocumentType}`, column1X + 37, myY, 55, 'left');

  addText(doc, 'Numero de Documento', column1X, (myY += margin), 40, 'left');
  addText(doc, `: ${consignedDocumentNumber}`, column1X + 37, myY, 55, 'left');

  setFont(doc, 'bold');
  doc.setFontSize(14);

  const MAX_LENGTH = 20;

  if (descriptionItem.length > MAX_LENGTH) {
    addText(
      doc,
      descriptionItem.substring(0, MAX_LENGTH),
      column1X,
      (myY += margin * 2),
      55,
      'left',
    );
  } else {
    addText(doc, descriptionItem, column1X, (myY += margin * 2), 55, 'left');
  }

  setFont(doc);
  doc.setFontSize(10);

  myY = -margin;
  setFont(doc, 'bold');
  doc.setFontSize(16);

  myY += margin * 4.1;

  await addQrCode(doc, correlationCodeItem, column2X, myY, qrWidth, qrHeight);

  setFont(doc);
  doc.setFontSize(10);
  addText(
    doc,
    `PAQUETE: ${itemNumber}/${itemList}`,
    column2X + 2,
    (myY += qrHeight + margin),
    75,
    'left',
  );
  addText(doc, `PESO: ${weight} kg`, column2X + 2, myY + 6, 55, 'left');
};

const formatTicket = (ticket) => {
  const formatedTicket = {
    shipmentFields: {
      origin: ticket.sourceLocationName,
      originAgencyCode: ticket.sourceAgencyCode,
    },
    consignedFields: {
      consignedName: ticket.consigneeCustomer.fullName,
      consignedDocumentType: ticket.consigneeCustomer.identificationType.name,
      consignedDocumentNumber: ticket.consigneeCustomer.idDocumentNumber,
    },
    destinationAddress: ticket.destinationAddress
      ? ticket.destinationAddress
      : '-',
    cargoItemList: ticket.cargoItemList
      ? ticket.cargoItemList
      : ticket.internalCargoItemList,
    comments: ticket.comments,
  };

  return formatedTicket;
};

export default async function printTicketCargo(ticket) {
  const doc = createPdfDocument('l', 'mm', [112, 65]);

  const formatedTicket = formatTicket(ticket);

  const {
    shipmentFields: { origin, originAgencyCode },
    consignedFields: {
      consignedName,
      consignedDocumentType,
      consignedDocumentNumber,
    },
    comments,
    cargoItemList,
    destinationAddress,
  } = formatedTicket;

  const printTasks = cargoItemList.map((item, index) => async () => {
    await printItemTicket(doc, {
      shipmentFields: {
        origin,
        originAgencyCode,
        destiny: item.finalDestinationLocation
          ? item.finalDestinationLocation.name
          : destinationAddress,
        destinyAgencyCode: item.finalDestinationLocation
          ? item.finalDestinationLocation.agencyCode
          : '',
      },
      consignedFields: {
        consignedName,
        consignedDocumentType,
        consignedDocumentNumber,
      },
      descriptionItem: item.description,
      comments,
      correlationCodeItem: item.correlation,
      itemNumber: index + 1,
      itemList: cargoItemList.length,
      weight: item.weight,
      isFirstPrint: index === 0,
    });
  });

  await printTasks.reduce(
    (promiseChain, currentTask) => promiseChain.then(() => currentTask()),
    Promise.resolve(),
  );

  doc.autoPrint();
  window.open(doc.output('bloburl'), '_blank');
}
