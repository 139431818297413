import autoTable from 'jspdf-autotable';
import {
  addImage,
  addText,
  createPdfDocument,
  paragraphSize,
  setFont,
  textSpacing,
  titleSize,
  x,
  y,
} from '../pdf';
import LogoCiva from '../../resources/images/logo-civa.png';
import { DATE_FORMAT, TIME_FORMAT } from '../../config/locale';
import { tzNormalizeDate } from '../date';

const myX = x;
const initialY = y;
let myY = initialY;
let pageWidth = 0;
const newTitleSize = titleSize - 2;
let page = 1;
let tableFinished = false;

const printDocumentHeader = (doc, internalParcel) => {
  addImage(doc, LogoCiva, myX, 3, 35, 10);

  setFont(doc, 'bold', 'arial-bold');

  doc.setFontSize(newTitleSize);
  addText(
    doc,
    'GUÍA DE SALIDA DE MATERIALES',
    pageWidth / 2,
    (myY += 5),
    pageWidth,
  );

  doc.setFontSize(paragraphSize);

  doc.rect(160, myY - 5, 45, 15);
  addText(doc, 'TURISMO CIVA S.A.C', 195, myY, 80, 'right');
  addText(doc, 'RUC:', 170, (myY += 5), 30);
  addText(doc, '20102427891', 188, myY, 30);

  doc.rect(x, (myY += 10), pageWidth - 10, 33);

  addText(doc, 'FECHA DE EMISIÓN:', 37.8, (myY += 8), 40, 'right');
  addText(
    doc,
    tzNormalizeDate({ date: internalParcel.createDate, format: DATE_FORMAT }),
    50,
    myY,
    30,
  );

  const originText = `${internalParcel.sourceLocationAddress}`;
  const originWrappedText = doc.splitTextToSize(originText, 80);
  addText(doc, 'ORIGEN:', 21.5, (myY += textSpacing), 30, 'right');
  doc.text(originWrappedText, 30, myY);
  myY += (originWrappedText.length - 1) * textSpacing;

  addText(doc, 'REMITENTE:', 143, myY, 30, 'right');
  addText(
    doc,
    `${internalParcel.senderCustomer.firstName} ${internalParcel.senderCustomer.lastName}`,
    155,
    myY,
    30,
  );

  const destinationText = `${internalParcel.destinationAddress}`;
  const destinationWrappedText = doc.splitTextToSize(destinationText, 80);
  addText(doc, 'DESTINO:', 23, (myY += textSpacing), 30, 'right');
  doc.text(destinationWrappedText, 30, myY, 30);
  myY += (destinationWrappedText.length - 1) * textSpacing;

  addText(doc, 'CONSIGNADO:', 143, myY, 30, 'right');
  addText(
    doc,
    `${internalParcel.consigneeCustomer.firstName} ${internalParcel.consigneeCustomer.lastName}`,
    155,
    myY,
    130,
  );

  addText(doc, 'FECHA SALIDA:', 31.2, (myY += textSpacing), 30, 'right');
  addText(
    doc,
    tzNormalizeDate({ date: internalParcel.createDate, format: DATE_FORMAT }),
    45,
    myY,
    130,
  );
  addText(doc, 'HORA SALIDA:', 30, (myY += textSpacing), 30, 'right');
  addText(
    doc,
    tzNormalizeDate({ date: internalParcel.createDate, format: TIME_FORMAT }),
    35,
    myY,
    30,
  );
};

const printFooter = (doc) => {
  addText(doc, '_____________________________', +50, myY + 20);
  addText(doc, 'TURISMO CIVA SAC', x + 46, myY + 25);
  addText(doc, '_____________________________', x + 110, myY + 20);
  addText(doc, 'Conformidad del Consignado', x + 110, myY + 25);
};

const printItems = (doc, internalParcel) => {
  const columns = [
    { title: '#', dataKey: 'item' },
    { title: 'Cantidad', dataKey: 'quantity' },
    { title: 'Descripcion', dataKey: 'description' },
    { title: 'Peso', dataKey: 'weight' },
  ];

  let rowNumber = 0;

  const rows = [];

  internalParcel.internalCargoItemList.forEach((cargoItem) => {
    rowNumber += 1;

    const row = {
      item: rowNumber,
      quantity: cargoItem.quantity,
      description: cargoItem.description,
      weight: cargoItem.weight,
      price: cargoItem.price,
    };

    rows.push(row);
  });

  const autoTableOptions = {
    columns,
    body: rows,
    startY: myY + 5,
    showHead: 'everyPage',
    margin: { top: 65, right: 5, left: 5, bottom: 10 },
    theme: 'striped',
    headStyles: { fillColor: [255, 255, 255] },
    styles: {
      overflow: 'hidden',
      cellPadding: 0.5,
      textColor: 0,
      halign: 'center',
    },
    didDrawPage: (drawData) => {
      if (drawData.pageNumber > page) {
        page = drawData.pageNumber;
        myY = initialY;
        printDocumentHeader(doc, internalParcel);
      }
    },
  };

  autoTable(doc, autoTableOptions);
  tableFinished = true;
};

const printExitGuide = (internalParcel) => {
  const doc = createPdfDocument();

  pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.height;
  page = 1;
  myY = initialY;

  printDocumentHeader(doc, internalParcel);

  doc.setFontSize(newTitleSize);

  setFont(doc, 'bold', 'arial-bold');

  addText(doc, 'Listado de Materiales', pageWidth / 2, (myY += 20), pageWidth);

  setFont(doc);

  doc.setFontSize(paragraphSize);

  printItems(doc, internalParcel);

  if (tableFinished) {
    const finalTableY = doc.lastAutoTable.finalY;
    const remainingSpace = pageHeight - finalTableY - 20;

    if (remainingSpace > 50) {
      myY = finalTableY + 15;
      doc.setFontSize(newTitleSize);

      doc.setFontSize(paragraphSize);

      printFooter(doc, internalParcel);
    } else {
      doc.addPage();
      myY = initialY;

      printDocumentHeader(doc, internalParcel);

      doc.setFontSize(newTitleSize);

      doc.setFontSize(paragraphSize);

      printFooter(doc, internalParcel);
    }
  }

  myY = 5;

  window.open(doc.output('bloburl'), '_blank');
};

export default printExitGuide;
