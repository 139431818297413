import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ConfirmationModal from '../../../common/modal/ConfirmationModal';
import { deleteDelivery } from '../../../../actions';
import withEndpointAuthorization from '../../authorization/withEndPointAuthorization';
import { generateDeleteDeliveryEndpoint } from '../../../../config/endpoints';
import { POST } from '../../../../config/permissions';

const DeliveryDeleteButton = ({
  dispatchDeleteDelivery,
  deliveryId,
  disabled,
}) => {
  const [showModal, setShowModal] = useState(false);

  const onClickDelete = () => dispatchDeleteDelivery({ deliveryId });

  const openModal = () => setShowModal(true);

  const closeModal = () => setShowModal(false);

  return (
    <>
      <ConfirmationModal
        show={showModal}
        onClickConfirm={onClickDelete}
        onClickCancel={closeModal}
      />
      <Button color="danger" onClick={openModal} disabled={disabled}>
        <i className="fa fa-trash-o" /> Eliminar
      </Button>
    </>
  );
};

DeliveryDeleteButton.propTypes = {
  dispatchDeleteDelivery: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  deliveryId: PropTypes.number.isRequired,
};

DeliveryDeleteButton.defaultProps = {
  disabled: false,
};

const mapDispatchToProps = {
  dispatchDeleteDelivery: deleteDelivery,
};

const connectedComponent = connect(
  null,
  mapDispatchToProps,
)(DeliveryDeleteButton);

export default withEndpointAuthorization({
  url: generateDeleteDeliveryEndpoint(),
  mapUrlParamsToProps: { deliveryId: 'deliveryId' },
  permissionType: POST,
})(connectedComponent);
