import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SelectFilter from './SelectFilter';
import { optionsPropTypes } from '../forms/select/SelectPropTypes';
import { clearUsers, getUsers } from '../../../actions';

const UserFilter = ({
  users,
  onChangeFilter,
  loading,
  dispatchClearUsers,
  dispatchGetUsers,
}) => {
  useLayoutEffect(() => () => dispatchClearUsers(), []);

  const handleInputChange = (inputValue) => {
    if (inputValue.trim().length && inputValue.trim().length >= 4) {
      const index = inputValue.indexOf(',');

      let query = [`customer.firstName:${inputValue}`];

      if (index >= 0) {
        const { length } = inputValue;
        const firstName = inputValue.substring(0, index).trim();
        const lastName = inputValue.substring(index + 1, length).trim();
        query = [];
        if (firstName.length) query.push(`customer.firstName:${firstName}`);
        if (lastName.length) query.push(`customer.lastName:${lastName}`);
      }

      dispatchGetUsers({ query });
    }
  };

  const userFilterOption = (options) => options;

  return (
    <SelectFilter
      isMulti
      isLoading={loading}
      onChangeFilter={onChangeFilter}
      onInputChange={handleInputChange}
      options={users}
      filterOption={userFilterOption}
      placeholder="Ingrese usuario"
    />
  );
};

UserFilter.propTypes = {
  dispatchGetUsers: PropTypes.func.isRequired,
  dispatchClearUsers: PropTypes.func.isRequired,
  users: optionsPropTypes,
  onChangeFilter: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

UserFilter.defaultProps = {
  users: [],
  loading: false,
};

const mapStateToProps = ({ UserUnit }) => ({
  users: UserUnit.User.getIn(['all', 'content', 'content']).map((user) => ({
    value: user.id,
    label: user.customer.fullName,
  })),
  loading: UserUnit.User.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetUsers: getUsers,
  dispatchClearUsers: clearUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserFilter);
