import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { DELIVERY_PATH } from '../../../../config/paths';
import { clearDeliveries, getDeliveries } from '../../../../actions';
import { DELIVERY_COLUMNS } from '../../../../config/columns';

export const Deliveries = ({
  breadcrumbs,
  deliveries,
  loading,
  dispatchGetDeliveries,
  dispatchClearDeliveries,
}) => {
  useLayoutEffect(() => () => dispatchClearDeliveries(), []);

  const data = deliveries.get('content') || [];
  const pages = deliveries.get('totalPages') || null;
  const defaultPageSize = deliveries.get('size') || DEFAULT_PAGE_SIZE;

  return (
    <ModuleList
      title="Lista de Repartos"
      breadcrumbs={breadcrumbs}
      tableStructure={{
        columns: DELIVERY_COLUMNS,
        data,
        pages,
        defaultPageSize,
        filterable: true,
        fetchData: dispatchGetDeliveries,
        modelPath: DELIVERY_PATH,
        loading,
        navigateToModelOnRowClick: true,
      }}
    />
  );
};

const mapDispatchToProps = {
  dispatchGetDeliveries: getDeliveries,
  dispatchClearDeliveries: clearDeliveries,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Repartos',
      href: DELIVERY_PATH,
    },
  ],
  deliveries: CargoUnit.Delivery.getIn(['all', 'content']),
  loading: CargoUnit.Delivery.getIn(['all', 'loading']),
});

Deliveries.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  deliveries: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetDeliveries: PropTypes.func.isRequired,
  dispatchClearDeliveries: PropTypes.func.isRequired,
};

Deliveries.defaultProps = {
  deliveries: null,
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(Deliveries);
