import { SubmissionError } from 'redux-form';
import {
  MAX_DAYS_IN_REPORT,
  EMAIL_PATTERN,
  TICKET_PATTERN,
  DNI_ENUM_VALUE,
  DNI_PATTERN,
  CARNET_EXTRANJERIA_ENUM_VALUE,
  CARNET_EXTRANJERIA_PATTERN,
  PASAPORTE_PATTERN,
  PASAPORTE_ENUM_VALUE,
  OTROS_ENUM_VALUE,
  OTROS_PATTERN,
  BILLING_DATE_PATTERN,
  BILLING_TIME_PATTERN,
} from '../config/constants';
import { tzDiff, tzNormalizeDate } from './date';
import { YEAR_FORMAT } from '../config/locale';

const isRequired = (value) =>
  value || value === '0' || value === 0 ? undefined : 'Campo requerido';

const arrayMultiIsRequired = (value) =>
  value && value.length > 0 ? undefined : 'Campo requerido';

const isExternalUrl = (url) => /^https?:\/\//.test(url);

const isProduction = () => process.env.NODE_ENV === 'production';

const validateReportDateRange = (fromDate, toDate) => {
  const numDays = tzDiff({
    startDate: fromDate,
    endDate: toDate,
    unit: 'days',
  });
  // date range needs to be positive
  if (numDays < 0) {
    throw new SubmissionError({
      _error: 'La fecha de fin debe ocurrir después de la fecha de inicio.',
    });
  }
  // same day reports are not allowed (need at least one day difference)
  if (numDays === 0) {
    throw new SubmissionError({
      _error: 'El rango de fechas debe incluir por lo menos un día entero.',
    });
  }
  // maximum date range for reports is 120 days
  if (numDays > MAX_DAYS_IN_REPORT) {
    throw new SubmissionError({
      _error: 'El rango de fechas no debe exceder los 120 días.',
    });
  }
};

const validateReportFormDateRange = (fromDate, toDate) => {
  const numDays = tzDiff({
    startDate: fromDate,
    endDate: toDate,
    unit: 'days',
  });
  // date range needs to be positive
  if (numDays < 0) {
    return 'La fecha de fin debe ocurrir después de la fecha de inicio.';
  }
  // same day reports are not allowed (need at least one day difference)
  if (numDays === 0) {
    return 'El rango de fechas debe incluir por lo menos un día entero.';
  }
  // maximum date range for reports is 120 days
  if (numDays > MAX_DAYS_IN_REPORT) {
    return 'El rango de fechas no debe exceder los 120 días.';
  }

  return null;
};

const validateLength =
  (max, text = 'caracteres') =>
  (value) =>
    value && value.length === max ? undefined : `Debe tener ${max} ${text}`;

const validateMaxLength = (max) => (value) =>
  value && value.length > max
    ? `Debe tener ${max} caracteres o menos`
    : undefined;

const isValidNumber = (value) => !Number.isNaN(Number(value));

const isIntegerNumber = (value) => value % 1 === 0;

const isValidTicket = (value) => TICKET_PATTERN.test(value);

const isValidEmail = (value) => EMAIL_PATTERN.test(value);

const validateNumber = (value) =>
  value && !isValidNumber(value) ? 'Debe ingresar sólo dígitos' : undefined;

const validateIntegerNumber = (value) =>
  value && !isIntegerNumber(value)
    ? 'Debe ingresar un número entero'
    : undefined;

const validateTicket = (value) =>
  !isValidTicket(value) ? 'Debe ingresar un ticket válido' : undefined;

const validateEmail = (value) =>
  value && !isValidEmail(value) ? 'Email no válido' : undefined;

const validateMinNumber = (min) => (value) =>
  value && Number(value) < min ? `Debe ser mayor a ${min}` : undefined;

// validate ruc according to SUNAT parameters
// that closely follow https://bit.ly/2FEubbW
const validateRuc = (value) => {
  if (value) {
    if (value.length !== 11) {
      return 'El RUC debe ser de 11 dígitos';
    }

    const rucArray = value.split('');
    const lastRucCharacter = rucArray.pop();
    let sum = 0;
    let mod = 0;
    let factor = 5;
    // multiply each ruc character for its
    // respective factor which should go
    // in the next order 5, 4, 3, 2, 7, 6, 5, 4, 3, 2
    // then sum it all up
    rucArray.forEach((rucElement, index) => {
      if (index === 4) factor = 7;

      const rucDigit = Number(rucElement);
      sum += rucDigit * factor;
      factor -= 1;
    });

    // get the mod of the sum
    mod = sum % 11;
    const result = (11 - mod) % 10;

    // the result should be equals to last number of RUC
    if (result === Number(lastRucCharacter)) {
      return undefined;
    }

    return 'Ingrese RUC válido';
  }

  return undefined;
};

const validateEndDate = (
  endDate,
  startDate = tzNormalizeDate(),
  validateEqualDates = false,
) => {
  const seconds = tzDiff({
    startDate,
    endDate,
    unit: 'seconds',
  });

  let error;

  if (seconds < 0) error = 'Fecha no puede ser anterior';

  if (validateEqualDates && seconds === 0) error = 'Fecha no puede ser igual';

  return error;
};

const validateIdDocumentNumber = (idDocumentNumber, identificationTypeId) => {
  let error;

  if (idDocumentNumber && identificationTypeId) {
    const { identificationInCountry, label } = identificationTypeId;

    switch (identificationInCountry) {
      case DNI_ENUM_VALUE:
        if (!idDocumentNumber.match(DNI_PATTERN)) {
          error = `${label} debe ser 8 digitos`;
        }
        break;
      case CARNET_EXTRANJERIA_ENUM_VALUE:
        if (!idDocumentNumber.match(CARNET_EXTRANJERIA_PATTERN)) {
          error = `${label} debe ser alfanumerico de 12 caracteres`;
        }
        break;
      case PASAPORTE_ENUM_VALUE:
        if (!idDocumentNumber.match(PASAPORTE_PATTERN)) {
          error = `${label} debe ser alfanumerico de maximo 12 caracteres`;
        }
        break;
      case OTROS_ENUM_VALUE:
        if (!idDocumentNumber.match(OTROS_PATTERN)) {
          error = `${label} debe ser alfanumerico de maximo 15 caracteres`;
        }
        break;
      default:
        break;
    }
  }

  return error;
};

const validateDynamicFormDuplicateElements = (value, rows, column) => {
  let duplicateCount = 0;

  rows.forEach((row) => {
    if (row[column] && row[column].value === value) duplicateCount += 1;
  });

  return duplicateCount >= 2 ? 'Elemento repetido' : undefined;
};

const isValidBillingDate = (value) =>
  !BILLING_DATE_PATTERN.test(value) ? 'Fecha inválida' : undefined;

const isValidBillingTime = (value) =>
  !BILLING_TIME_PATTERN.test(value) ? 'Hora inválida' : undefined;

const validateYear = (value) => {
  let error;

  const currentYear = tzNormalizeDate({
    format: YEAR_FORMAT,
  });

  if (parseInt(value, 10) > currentYear) {
    error = `El año no puede ser mayor al año actual (${currentYear}).`;
  }

  return error;
};

export {
  isExternalUrl,
  isRequired,
  isProduction,
  validateReportDateRange,
  validateReportFormDateRange,
  validateRuc,
  validateLength,
  isValidNumber,
  validateNumber,
  validateEmail,
  isValidTicket,
  validateTicket,
  validateIntegerNumber,
  validateEndDate,
  validateIdDocumentNumber,
  validateDynamicFormDuplicateElements,
  arrayMultiIsRequired,
  isIntegerNumber,
  isValidBillingDate,
  isValidBillingTime,
  validateMaxLength,
  validateYear,
  validateMinNumber,
};
