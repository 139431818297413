import React from 'react';
import { Form, FormGroup } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import FormItem from '../../../common/forms/FormItem';
import { isRequired } from '../../../../utils/validators';
import DynamicForm from '../../../common/forms/DynamicForm';
import { CARGO_CORPORATE_CREDIT_CITY_COLUMNS } from '../../../../config/dynamicFormFields';
import FormFooter from '../../../common/forms/FormFooter';
import CitySelect from '../../../common/forms/select/CitySelect';

export const CargoCorporateCreditCityForm = ({ handleSubmit, isEdit }) => (
  <Form onSubmit={handleSubmit}>
    <FormGroup row>
      <FormItem label="Ciudad" required>
        <Field
          name="city"
          component={CitySelect}
          placeholder="Ciudad"
          validate={[isRequired]}
          isDisabled={isEdit}
        />
      </FormItem>
    </FormGroup>
    <DynamicForm
      name="customerList"
      columns={CARGO_CORPORATE_CREDIT_CITY_COLUMNS}
    />
    <FormFooter />
  </Form>
);

CargoCorporateCreditCityForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
};

CargoCorporateCreditCityForm.defaultProps = {
  isEdit: false,
};

const form = reduxForm({
  form: 'CargoCorporateCreditCityForm',
})(CargoCorporateCreditCityForm);

export default form;
