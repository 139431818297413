import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import packageJson from '../../../package.json';

global.appVersion = packageJson.version;

const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g).map(Number);
  const versionsB = versionB.split(/\./g).map(Number);

  for (let i = 0; i < Math.max(versionsA.length, versionsB.length); i += 1) {
    const a = versionsA[i] || 0;
    const b = versionsB[i] || 0;

    if (a !== b) {
      return a > b;
    }
  }

  return false;
};

const CacheBuster = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [isLatestVersion, setIsLatestVersion] = useState(false);

  const refreshCacheAndReload = () => {
    console.log('Clearing cache and hard reloading...');

    if (caches) {
      caches.keys().then((names) => {
        names.forEach((name) => caches.delete(name));
      });
    }
    window.location.reload(true);
  };

  useEffect(() => {
    fetch(`/meta.json`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version;
        const currentVersion = global.appVersion;

        const shouldForceRefresh = semverGreaterThan(
          latestVersion,
          currentVersion,
        );
        if (shouldForceRefresh) {
          console.log(
            `We have a new version - ${latestVersion}. Should force refresh`,
          );
          setIsLatestVersion(false);
        } else {
          console.log(
            `You already have the latest version - ${latestVersion}. No cache refresh needed.`,
          );
          setIsLatestVersion(true);
        }
        setLoading(false);
      });
  }, []);

  if (loading) return null;

  if (!loading && !isLatestVersion) {
    refreshCacheAndReload();
  }

  return children({ loading, isLatestVersion, refreshCacheAndReload });
};
CacheBuster.propTypes = {
  children: PropTypes.func.isRequired,
};

export default CacheBuster;
