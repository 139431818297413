import { push } from 'react-router-redux';
import { toastr } from 'react-redux-toastr';
import QueryString from 'query-string';
import {
  CLEAR_PARCEL_PRICE_CONFIGURATION,
  CLEAR_PARCEL_PRICE_CONFIGURATIONS,
  FLAG_GETTING_PARCEL_PRICE_CONFIGURATIONS,
  FLAG_PARCEL_PRICE_CONFIGURATION_ACTIVITY,
  GET_PARCEL_PRICE_CONFIGURATION,
  GET_PARCEL_PRICE_CONFIGURATIONS,
} from '../types/cargo/ParcelPriceConfiguration';
import { PARCEL_PRICE_CONFIGURATION_ENDPOINT } from '../../config/endpoints';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import { PARCEL_PRICE_CONFIGURATION_PATH } from '../../config/paths';

const flagGettingParcelPriceConfigurations = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_PARCEL_PRICE_CONFIGURATIONS,
    payload: flag,
  });

const getParcelPriceConfigurations =
  async (tableFilters) => async (dispatch) => {
    try {
      dispatch(flagGettingParcelPriceConfigurations(true));
      const query = tableFilters;
      const url = `${PARCEL_PRICE_CONFIGURATION_ENDPOINT}?${QueryString.stringify(
        query,
      )}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      await isErrorResponse(response);
      const parcelPriceConfigurations = await response.json();
      dispatch({
        type: GET_PARCEL_PRICE_CONFIGURATIONS,
        payload: parcelPriceConfigurations,
      });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagGettingParcelPriceConfigurations(false));
    }
  };

const flagParcelPriceActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_PARCEL_PRICE_CONFIGURATION_ACTIVITY,
    payload: flag,
  });

const clearParcelPriceConfigurations = () => (dispatch) =>
  dispatch({ type: CLEAR_PARCEL_PRICE_CONFIGURATIONS });

const getParcelPriceConfiguration =
  async ({ parcelPriceConfigurationId }) =>
  async (dispatch) => {
    try {
      dispatch(flagParcelPriceActivity(true));
      const url = `${PARCEL_PRICE_CONFIGURATION_ENDPOINT}/${parcelPriceConfigurationId}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      await isErrorResponse(response);
      const parcelPriceConfiguration = await response.json();
      dispatch({
        type: GET_PARCEL_PRICE_CONFIGURATION,
        payload: parcelPriceConfiguration,
      });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagParcelPriceActivity(false));
    }
  };

const clearParcelPriceConfiguration = () => (dispatch) =>
  dispatch({ type: CLEAR_PARCEL_PRICE_CONFIGURATION });

const putParcelPriceConfiguration =
  (
    parcelPriceConfigurationId,
    { basePrice, maxEnvelopeWeight, volumetricDivisor },
  ) =>
  async (dispatch) => {
    try {
      dispatch(flagParcelPriceActivity(true));
      const url = `${PARCEL_PRICE_CONFIGURATION_ENDPOINT}/${parcelPriceConfigurationId}`;
      const payload = { basePrice, maxEnvelopeWeight, volumetricDivisor };
      const response = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response);
      const parcelPrice = await response.json();
      dispatch(push(`${PARCEL_PRICE_CONFIGURATION_PATH}/${parcelPrice.id}`));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagParcelPriceActivity(false));
    }
  };

const postParcelPriceConfiguration =
  async ({
    cargoCorporateCreditId,
    basePrice,
    maxEnvelopeWeight,
    volumetricDivisor,
  }) =>
  async (dispatch) => {
    try {
      dispatch(flagParcelPriceActivity(true));
      const url = PARCEL_PRICE_CONFIGURATION_ENDPOINT;
      const payload = {
        cargoCorporateCreditId,
        basePrice,
        maxEnvelopeWeight,
        volumetricDivisor,
      };
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response);
      const parcelPrice = await response.json();
      dispatch(push(`${PARCEL_PRICE_CONFIGURATION_PATH}/${parcelPrice.id}`));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagParcelPriceActivity(false));
    }
  };

export {
  flagGettingParcelPriceConfigurations,
  getParcelPriceConfigurations,
  clearParcelPriceConfigurations,
  getParcelPriceConfiguration,
  clearParcelPriceConfiguration,
  putParcelPriceConfiguration,
  postParcelPriceConfiguration,
};
