import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import { DELIVERY_BUSINESS_PATH } from '../../../../config/paths';
import DeliveryBusinessForm from './DeliveryBusinessForm';
import {
  clearDeliveryBusiness,
  getDeliveryBusiness,
  putDeliveryBusiness,
} from '../../../../actions';

const EditDeliveryBusiness = ({
  breadcrumbs,
  dispatchGetDeliveryBusiness,
  dispatchClearDeliveryBusiness,
  dispatchPutDeliveryBusiness,
  match: {
    params: { id: deliveryBusinessId },
  },
  loading,
  deliveryBusiness,
}) => {
  useLayoutEffect(() => {
    dispatchGetDeliveryBusiness({ deliveryBusinessId });

    return () => dispatchClearDeliveryBusiness();
  }, []);

  const onSubmit = (formValues) => {
    const newFormValues = {
      businessId: formValues.business.value,
      active: formValues.active,
    };

    const agencyList = formValues.agencies.map((agency) => agency.value);

    const userList = formValues.users.map((user) => user.value);

    newFormValues.agencyList = agencyList;
    newFormValues.userList = userList;
    dispatchPutDeliveryBusiness(deliveryBusinessId, newFormValues);
  };

  const generateInitialValues = ({
    business,
    agencyList,
    userList,
    active,
  }) => ({
    business: {
      value: business.id,
      label: `${business.businessTaxId} - ${business.name}`,
    },
    agencies: agencyList.map(({ agency }) => ({
      value: agency.id,
      label: agency.name,
    })),
    users: userList.map(({ user }) => ({
      value: user.id,
      label: user.customer.fullName,
    })),
    active,
  });

  let content;

  if (loading) content = <Loader />;
  else if (deliveryBusiness.isEmpty())
    content = <NoDataResource returnPage={DELIVERY_BUSINESS_PATH} />;
  else
    content = (
      <DeliveryBusinessForm
        onSubmit={onSubmit}
        initialValues={generateInitialValues(deliveryBusiness.toJS())}
      />
    );

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Editar Empresa de Delivery"
      subtitle="Editar empresa de delivery"
      content={content}
    />
  );
};

EditDeliveryBusiness.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchClearDeliveryBusiness: PropTypes.func.isRequired,
  dispatchGetDeliveryBusiness: PropTypes.func.isRequired,
  dispatchPutDeliveryBusiness: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  deliveryBusiness: PropTypes.instanceOf(Immutable.Map).isRequired,
};

EditDeliveryBusiness.defaultProps = {
  loading: false,
};

const mapDispatchToProps = {
  dispatchClearDeliveryBusiness: clearDeliveryBusiness,
  dispatchGetDeliveryBusiness: getDeliveryBusiness,
  dispatchPutDeliveryBusiness: putDeliveryBusiness,
};

const mapStateToProps = (
  { CargoUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Empresas de Reparto',
      href: DELIVERY_BUSINESS_PATH,
    },
    {
      text: 'Ver',
      href: `${DELIVERY_BUSINESS_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  deliveryBusiness: CargoUnit.DeliveryBusiness.getIn(['current', 'content']),
  loading: !CargoUnit.DeliveryBusiness.getIn(['current', 'activity']).isEmpty(),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditDeliveryBusiness);
