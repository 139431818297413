import React from 'react';
import PropTypes from 'prop-types';
import Badge from '../../../Badge';

const CargoItemStatusCell = ({ value }) => {
  switch (value) {
    case 'RECEIVED':
      return <Badge color="info" text="REGISTRADO" />;
    case 'IN_TRANSIT':
      return <Badge color="warning" text="DESPACHADO" />;
    case 'READY_FOR_PICKUP':
      return <Badge color="primary" text="RECEPCIONADO EN DESTINO" />;
    case 'REFUNDED':
      return <Badge color="info" text="DEVUELTO AL CLIENTE" />;
    case 'DELIVERED':
      return <Badge color="success" text="ENTREGADO" />;
    case 'UNCLAIMED':
      return <Badge color="danger" text="ABANDONO LEGAL" />;
    case 'DEPURATED':
      return <Badge color="warning" text="DEPURADO" />;
    case 'LOST':
      return <Badge color="danger" text="NO LLEGO" />;
    case 'UNCLAIMED_IN_TRANSIT':
      return <Badge color="warning" text="ABANDONO LEGAL - EN TRANSITO" />;
    case 'COMMERCIAL_SCALE':
      return <Badge color="warning" text="EN ESCALA COMERCIAL" />;
    case 'UNCLAIMED_IN_WAREHOUSE':
      return <Badge color="warning" text="ABANDONO LEGAL - EN BASE" />;
    case 'UNCLAIMED_IN_DESTINY':
      return <Badge color="warning" text="ABANDONO LEGAL - EN DESTINO" />;
    case 'UNCLAIMED_IN_SCALE':
      return <Badge color="warning" text="ABANDONO LEGAL - EN ESCALA" />;
    case 'UNCLAIMED_LOST':
      return <Badge color="danger" text="ABANDONO LEGAL - PERDIDO" />;
    case 'MIGRATED':
      return <Badge color="info" text="MIGRADO" />;
    case 'REGISTERED_FOR_DELIVERY':
      return <Badge color="info" text="REGISTRADO PARA REPARTO" />;
    case 'DELIVERY_IN_TRANSIT':
      return <Badge color="warning" text="REPARTO - EN TRANSITO" />;
    case 'BACK_TO_AGENCY':
      return <Badge color="info" text="REGRESANDO A AGENCIA" />;
    case 'RETURNED':
      return <Badge color="info" text="RETORNADO A ALMACÉN" />;
    default:
      return <Badge color="danger" text="INCAUTADO" />;
  }
};

CargoItemStatusCell.propTypes = {
  value: PropTypes.string,
};

CargoItemStatusCell.defaultProps = {
  value: '',
};

export default CargoItemStatusCell;
