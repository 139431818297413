import autoTable from 'jspdf-autotable';
import { AGE_TYPES, COLOR, RELATIONSHIP } from '../../config/constants';
import { createPdfDocument, subtitleSize, addText } from '../pdf';
import { orderBy } from '../array';

const MAX_NAME_LENGTH = 15;
const MAX_POSITION_LENGTH = 20;

const splitTextIfNeeded = (text, maxTextLength) => {
  if (text.length <= maxTextLength) {
    return { firstPart: text, secondPart: '' };
  }

  if (text[maxTextLength] !== ' ' && text[maxTextLength - 1] !== ' ') {
    let start = maxTextLength;
    let end = maxTextLength;

    while (start > 0 && text[start - 1] !== ' ') {
      start -= 1;
    }

    while (end < text.length && text[end] !== ' ') {
      end += 1;
    }

    const firstPart = text.slice(0, end).trim();
    const secondPart = text.slice(end).trim();

    return { firstPart, secondPart };
  }

  let firstPart = '';
  let secondPart = text;

  for (let i = maxTextLength; i > 0; i -= 1) {
    if (text[i] === ' ') {
      firstPart = text.slice(0, i).trim();
      secondPart = text.slice(i).trim();
      break;
    }
  }

  if (!firstPart) {
    firstPart = text.slice(0, maxTextLength).trim();
    secondPart = text.slice(maxTextLength).trim();
  }

  return { firstPart, secondPart };
};

const printDocumentHeader = (doc, manifest) => {
  const x = 5;
  let y = 5;
  addText(doc, 'Jr. Sancho de Rivera 1184 - Cercado de Lima', x, y);
  addText(doc, 'TURISMO CIVA S.A.C.', x, (y += 5));
  addText(doc, '20102427891', x, (y += 5));
  addText(
    doc,
    `MANIFIESTO DE PASAJEROS N° ${manifest.manifestNumber}`,
    doc.internal.pageSize.getWidth() - 110,
    y,
  );

  if (!manifest.hasSalesSessionUser) {
    addText(doc, 'N° Resolución', x, (y += 5));
    addText(doc, manifest.resolutionNumber.toString(), x + 40, y);
    addText(doc, 'Autorización Sunat', x, (y += 5));
    addText(doc, manifest.taxAuthorizationNumber.toString(), x + 40, y);
  }
  addText(doc, 'Dirección Partida', x, (y += 5));
  addText(doc, manifest.startAddress, x + 40, y);

  addText(doc, 'Embarque', x, (y += 5));
  addText(doc, manifest.boarding, x + 40, y);
  addText(doc, 'Desembarque', x, (y += 5));
  addText(doc, manifest.disembarking, x + 40, y);
};

const printRouteHeader = (doc, manifest) => {
  const x = 5;
  let y = 45;
  addText(doc, 'RUTA', x, y);

  addText(doc, 'Origen', x, (y += 5));
  addText(doc, manifest.origin.substring(0, 22), x + 25, y);

  addText(doc, 'Ruta/Escala\nComercial', x, (y += 5));
  addText(doc, manifest.stop.substring(0, 22), x + 25, (y += 3));

  addText(doc, 'Destino', x, (y += 6));
  addText(doc, manifest.destination.substring(0, 22), x + 25, y);

  addText(doc, 'Fecha', x, (y += 5));
  addText(doc, manifest.tripDate, x + 25, y);

  addText(doc, 'Hora', x, (y += 5));
  addText(doc, manifest.tripTime, x + 25, y);
};

const printBusHeader = (
  doc,
  {
    bus,
    circulationPermissionNumber,
    circulationPermissionExpDate = '',
    hasSalesSessionUser,
  },
) => {
  const x = 80;
  let y = 45;

  addText(doc, 'BUS', x, y);

  addText(doc, 'Código Bus', x, (y += 5));
  addText(doc, bus.companyBusId.toString(), x + 25, y);

  addText(doc, 'Placa', x, (y += 5));
  addText(doc, bus.licensePlate, x + 25, y);

  addText(doc, 'Marca', x, (y += 5));
  addText(doc, bus.busChassis.make, x + 25, y);

  addText(doc, 'Modelo', x, (y += 5));
  addText(doc, bus.busChassis.model, x + 25, y);

  if (!hasSalesSessionUser) {
    addText(doc, 'Certificado\nHabilitacion', x, (y += 5));
    addText(doc, circulationPermissionNumber || '', x + 25, (y += 3));

    addText(doc, 'Vencimiento\nCertificado', x, (y += 6));
    addText(doc, circulationPermissionExpDate || '', x + 25, (y += 3));
  }
};

const printCapacityHeader = (doc, manifest) => {
  const x = 150;
  let y = 45;

  addText(doc, 'CAPACIDAD', x, y);

  addText(doc, 'Capacidad Bus', x, (y += 5));
  addText(doc, manifest.busCapacity.toString(), x + 40, y);

  if (!manifest.hasSalesSessionUser) {
    addText(doc, 'N° Pasajeros', x, (y += 5));
    addText(doc, manifest.numPassengers.toString(), x + 40, y);

    addText(doc, 'N° Tripulación', x, (y += 5));
    addText(doc, manifest.numStaffOnBoard.toString(), x + 40, y);

    addText(doc, 'N° Pasajeros Ruta', x, (y += 5));
    addText(
      doc,
      manifest.routePassengersNumber
        ? manifest.routePassengersNumber.toString()
        : '',
      x + 35,
      y,
    );
  }
};

const printCrewHeader = (doc, manifest) => {
  const x = 5;
  let y = 85;

  addText(doc, 'TRIPULACIÓN', x, y);
  y += 5;

  if (manifest.drivers) {
    manifest.drivers.forEach((driver) => {
      addText(doc, 'Conductor', x, y);
      addText(doc, driver.fullName, x + 25, y);
      addText(doc, 'N° Licencia', x + 125, y);
      addText(
        doc,
        driver.licenseNumber ? driver.licenseNumber.toString() : '',
        x + 150,
        y,
      );
      y += 5;
    });
  }

  if (manifest.cabinCrew) {
    manifest.cabinCrew.forEach((cabinCrew) => {
      addText(doc, 'Nombre SAB', x, y);
      addText(doc, cabinCrew.cabinCrew.customer.fullName, x + 25, y);
      y += 5;
    });
  }
  return { xpos: x, ypos: y };
};

const printPassengers = (doc, manifest, { xpos, ypos }) => {
  const x = xpos;
  let y = ypos;

  const head = !manifest.hasSalesSessionUser
    ? [
        [
          {
            content: 'ITEM',
            styles: { halign: 'right' },
          },
          'NOMBRE PASAJERO',
          'DOCUMENTO',
          'N° BOLETO',
          { content: 'ASIENTO', styles: { halign: 'center' } },
          'EDAD',
          'RUTA',
          { content: 'COSTO', styles: { halign: 'right' } },
          'OBS.',
        ],
      ]
    : [
        [
          {
            content: 'Nro.',
            styles: { halign: 'right' },
          },
          'Apellido y Nombres',
          'DNI',
          'Empresa',
          'Puesto',
          { content: 'Ato.', styles: { halign: 'center' } },
          'Origen',
          'Destino',
        ],
      ];

  if (manifest.floors) {
    let rowNumber = 0;
    manifest.floors.forEach((floor) => {
      y = doc.autoTable.previous
        ? Math.round(doc.autoTable.previous.finalY) + 5
        : y + 5;
      addText(doc, `Piso: ${floor.floorNumber}`, x, y);

      const passengerList = floor.manifestPassengerList.map((row) => ({
        ...row,
        seatNumber: +row.seatNumber,
      }));
      const passengerListSorted = !manifest.hasSalesSessionUser
        ? orderBy(passengerList, ['seatNumber'])
        : orderBy(passengerList, ['fullName']);

      const body = [];
      if (!manifest.hasSalesSessionUser) {
        passengerListSorted.forEach(
          ({
            ageType,
            age,
            fullName,
            documentType,
            idDocumentNumber,
            ticketNumber,
            seatNumber,
            salePrice,
            destinationName,
            comments,
            relationship,
            id,
          }) => {
            rowNumber += 1;
            const [idInteger, idDecimal] = id.split('.');

            const myAgeType = ageType ? ` ${AGE_TYPES[ageType].label}` : '';
            const myAge = age && age !== 'null' ? ` ${age}${myAgeType}` : '';
            body.push([
              {
                content: rowNumber,
                styles: {
                  halign: 'right',
                },
              },
              fullName,
              `${documentType} ${idDocumentNumber}`,
              ticketNumber,
              {
                content: seatNumber,
                styles: { halign: 'center' },
              },
              myAge,
              destinationName,
              {
                content: salePrice.toFixed(2),
                styles: {
                  halign: 'right',
                },
              },
              comments,
            ]);

            if (+idDecimal > 0) {
              const previousPassenger = floor.manifestPassengerList.find(
                (passenger) => passenger.id === `${idInteger}.0`,
              );

              if (previousPassenger && relationship) {
                body.push([
                  { content: '' },
                  {
                    content: `${RELATIONSHIP[relationship].label}: ${previousPassenger.fullName} Ref. ${previousPassenger.ticketNumber}`,
                    colSpan: 8,
                    styles: { fontStyle: 'italic' },
                  },
                ]);
              }
            }
          },
        );
      } else {
        passengerListSorted.forEach(
          ({
            fullName,
            idDocumentNumber,
            seatNumber,
            businessName,
            position,
            originName,
            destinationName,
          }) => {
            rowNumber += 1;

            let nameTop = fullName;
            let nameBottom = '';

            if (manifest.hasSalesSessionUser) {
              const { firstPart, secondPart } = splitTextIfNeeded(
                fullName,
                MAX_NAME_LENGTH,
              );
              nameTop = firstPart;
              nameBottom = secondPart;
            }

            let positionTop = position;
            let positionBottom = '';

            if (manifest.hasSalesSessionUser) {
              const { firstPart, secondPart } = splitTextIfNeeded(
                position,
                MAX_POSITION_LENGTH,
              );
              positionTop = firstPart;
              positionBottom = secondPart;
            }

            body.push([
              {
                content: rowNumber,
                styles: {
                  halign: 'right',
                },
              },
              nameTop,
              idDocumentNumber,
              businessName,
              positionTop,
              {
                content: seatNumber,
                styles: { halign: 'center' },
              },
              originName,
              destinationName,
            ]);

            if (nameBottom || positionBottom) {
              body.push([
                { content: '' },
                nameBottom,
                { content: '', colSpan: 2 },
                positionBottom,
                { content: '', colSpan: 3 },
              ]);
            }
          },
        );
      }

      autoTable(doc, {
        head,
        body,
        headStyles: {
          fillColor: COLOR.white,
          lineColor: '#000',
          lineWidth: { top: 0.1, bottom: 0.1 },
        },
        startY: (y += 1),
        showHead: 'everyPage',
        margin: 5,
        theme: 'striped',
        styles: {
          overflow: 'hidden',
          cellPadding: 0.5,
          textColor: 0,
          fontSize: !manifest.hasSalesSessionUser ? 10 : 8,
        },
      });
    });
    y = doc.autoTable.previous
      ? Math.round(doc.autoTable.previous.finalY) + 5
      : y + 5;
    if (!manifest.hasSalesSessionUser) {
      addText(
        doc,
        'Total Manifiesto',
        doc.internal.pageSize.getWidth() - 90,
        y,
      );
      addText(
        doc,
        manifest.totalSalePrice,
        doc.internal.pageSize.getWidth() - 40,
        y,
      );
    }
  }
};

const printManifest = (manifest) => {
  const doc = createPdfDocument();

  doc.autoTable.previous = null;

  doc.setFontSize(subtitleSize);
  doc.setTextColor(COLOR.black);

  printDocumentHeader(doc, manifest);
  printRouteHeader(doc, manifest);
  printBusHeader(doc, manifest);
  printCapacityHeader(doc, manifest);
  const position = printCrewHeader(doc, manifest, 5, 110);
  printPassengers(doc, manifest, position);

  window.open(doc.output('bloburl'), '_blank');
};

// eslint-disable-next-line import/prefer-default-export
export { printManifest };
