import PropTypes from 'prop-types';
import React from 'react';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import { tzNormalizeDate } from '../../../../utils/date';
import { FULL_DATE_TIME_FORMAT } from '../../../../config/locale';
import LinkToModuleResource from '../../../common/resource/LinkToModuleResource';
import { CARGO_CORPORATE_CREDIT_PATH } from '../../../../config/paths';

const ParcelPriceConfigurationBasicInformationResource = ({
  id,
  basePrice,
  maxEnvelopeWeight,
  volumetricDivisor,
  createDate,
  lastUpdate,
  cargoCorporateCredit,
}) => (
  <>
    <ResourceProperty label="ID:">{id}</ResourceProperty>
    <ResourceProperty label="Precio Base:">{basePrice}</ResourceProperty>
    <ResourceProperty label="Peso Máximo de Sobre:">
      {maxEnvelopeWeight}
    </ResourceProperty>
    <ResourceProperty label="Divisor Volumétrico:">
      {volumetricDivisor}
    </ResourceProperty>
    <ResourceProperty label="Crédito Corporativo:">
      {cargoCorporateCredit ? (
        <LinkToModuleResource
          text={cargoCorporateCredit.name}
          href={`${CARGO_CORPORATE_CREDIT_PATH}/${cargoCorporateCredit.id}`}
          isExternalPath
        />
      ) : (
        '-'
      )}
    </ResourceProperty>
    <ResourceProperty label="Fecha de Creación:">
      {tzNormalizeDate({ date: createDate, format: FULL_DATE_TIME_FORMAT })}
    </ResourceProperty>
    <ResourceProperty label="Última Actualización:">
      {tzNormalizeDate({ date: lastUpdate, format: FULL_DATE_TIME_FORMAT })}
    </ResourceProperty>
  </>
);

ParcelPriceConfigurationBasicInformationResource.propTypes = {
  id: PropTypes.number.isRequired,
  basePrice: PropTypes.number.isRequired,
  maxEnvelopeWeight: PropTypes.number.isRequired,
  volumetricDivisor: PropTypes.number.isRequired,
  cargoCorporateCredit: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }),
  createDate: PropTypes.number.isRequired,
  lastUpdate: PropTypes.number.isRequired,
};

ParcelPriceConfigurationBasicInformationResource.defaultProps = {
  cargoCorporateCredit: null,
};

export default ParcelPriceConfigurationBasicInformationResource;
