import React, { useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { change } from 'redux-form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import {
  getExpensesPerUser,
  putMassiveExpenseStatus,
} from '../../../../actions/sales/ExpenseStatus';
import ExpenseStatusSearchForm from './ExpenseStatusSearchForm';
import ExpenseStatusSearchResult from './ExpenseStatusSearchResult';
import { EXPENSE_STATUS } from '../../../../config/constants';
import { DEFAULT_QUERY } from '../../../../config/queries';
import Content from '../../../layout/Content';
import { DATE_FORMAT } from '../../../../config/locale';
import { tzNormalizeDate } from '../../../../utils/date';

const ExpensesPerUser = ({
  breadcrumbs,
  dispatchGetExpensesPerUser,
  dispatchPutMassiveExpenseStatus,
  dispatchChange,
}) => {
  const [searchFormValues, setSearchFormValues] = useState({
    expenseStatus: EXPENSE_STATUS.PENDING.value,
  });
  const [query, setQuery] = useState('');

  const onSubmit = (formValues) => {
    const newFormValues = {
      ...DEFAULT_QUERY,
      size: 100,
      expenseStatus: formValues.expenseStatus.value,
      query: `createDate:${tzNormalizeDate({
        date: formValues.createDate,
        format: DATE_FORMAT,
      })}`,
    };
    setQuery(newFormValues.query);

    setSearchFormValues(newFormValues);
    dispatchGetExpensesPerUser(newFormValues);
  };

  const onSubmitResults = async ({
    expenseIds,
    actionType,
    rejectedMotive,
  }) => {
    if (!expenseIds || !expenseIds.length) {
      toastr.error('Error', 'Debe seleccionar egresos');
      return;
    }

    const newFormValues = {
      expenseStatus: actionType,
      expenseIds,
      rejectedMotive: rejectedMotive ? rejectedMotive.value : null,
    };
    const response = await dispatchPutMassiveExpenseStatus(newFormValues);

    if (response) {
      dispatchGetExpensesPerUser({
        ...DEFAULT_QUERY,
        expenseStatus: EXPENSE_STATUS.PENDING.value,
      });

      dispatchChange('ExpensesUserSearchResultForm', 'expenseIds', null);
    }
  };

  const isDisabled =
    searchFormValues.expenseStatus !== EXPENSE_STATUS.PENDING.value;

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Autorización de Egresos"
      content={
        <>
          <ExpenseStatusSearchForm
            onSubmit={onSubmit}
            initialValues={{
              expenseStatus: EXPENSE_STATUS.PENDING,
              createDate: tzNormalizeDate(),
            }}
          />
          <ExpenseStatusSearchResult
            searchFormValues={searchFormValues}
            onSubmit={onSubmitResults}
            isDisabled={isDisabled}
            queryProps={query}
          />
        </>
      }
    />
  );
};

const mapStateToProps = ({ SalesUnit }) => ({
  breadcrumbs: [
    ...SalesUnit.UnitHome.get('breadcrumbs'),
    { text: 'Autorización de Egresos', href: '' },
  ],
});

const mapDispatchToProps = {
  dispatchGetExpensesPerUser: getExpensesPerUser,
  dispatchPutMassiveExpenseStatus: putMassiveExpenseStatus,
  dispatchChange: change,
};

ExpensesPerUser.propTypes = {
  breadcrumbs: breadcrumbsPropTypes,
  dispatchGetExpensesPerUser: PropTypes.func.isRequired,
  dispatchPutMassiveExpenseStatus: PropTypes.func.isRequired,
  dispatchChange: PropTypes.func.isRequired,
};

ExpensesPerUser.defaultProps = {
  breadcrumbs: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpensesPerUser);
