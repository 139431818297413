import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { getLocations, clearLocations } from '../../../../actions/';
import { LOCATION_PATH, NEW_LOCATION_PATH } from '../../../../config/paths';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import { LOCATION_COLUMNS } from '../../../../config/columns';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Alert from '../../../common/informative/Alert';

export class Locations extends Component {
  componentWillUnmount() {
    this.props.dispatchClearLocations();
  }

  render() {
    const { locations, loading, breadcrumbs, dispatchGetLocations } =
      this.props;
    const data = locations.get('content') || [];
    const pages = locations.get('totalPages') || null;
    const defaultPageSize = locations.get('size') || DEFAULT_PAGE_SIZE;

    const description = (
      <Alert
        type="warning"
        message="
        Se filtrará los datos según el último criterio ingresado"
      />
    );

    return (
      <ModuleList
        title="Ubicaciones"
        description={description}
        breadcrumbs={breadcrumbs}
        buttonPath={NEW_LOCATION_PATH}
        tableStructure={{
          columns: LOCATION_COLUMNS,
          data,
          pages,
          defaultPageSize,
          filterable: true,
          fetchData: dispatchGetLocations,
          modelPath: LOCATION_PATH,
          loading,
          navigateToModelOnRowClick: true,
        }}
      />
    );
  }
}

const mapDispatchToProps = {
  dispatchGetLocations: getLocations,
  dispatchClearLocations: clearLocations,
};

const mapStateToProps = ({ LocationUnit }) => ({
  breadcrumbs: [
    ...LocationUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Ubicaciones',
      href: LOCATION_PATH,
    },
  ],
  locations: LocationUnit.Location.getIn(['all', 'content']),
  loading: LocationUnit.Location.getIn(['all', 'loading']),
});

Locations.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  locations: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetLocations: PropTypes.func.isRequired,
  dispatchClearLocations: PropTypes.func.isRequired,
};

Locations.defaultProps = {
  locations: null,
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(Locations);
