import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CARGO_CORPORATE_CREDIT_PATH } from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import CargoCorporateCreditForm from '../cargo-corporate-credit/CargoCorporateCreditForm';
import { postCargoCorporateCredit } from '../../../../actions/cargo/CargoCorporateCredit';
import { CORPORATE_CREDIT_OPTIONS } from '../../../../config/constants';

const NewCargoCorporateCredit = ({
  breadcrumbs,
  dispatchPostCargoCorporateCredit,
}) => {
  const onSubmit = (formValues) => {
    const newFormValues = { ...formValues };

    if (!formValues.creditActive) {
      newFormValues.initialBalance = 0;
      newFormValues.creditDays = 0;
      newFormValues.actualBalance = 0;
    }

    if (formValues.type.value === CORPORATE_CREDIT_OPTIONS.COMPANY.value) {
      newFormValues.customerId = null;
      newFormValues.companyId = formValues.company.value;
    }

    if (formValues.type.value === CORPORATE_CREDIT_OPTIONS.PERSON.value) {
      newFormValues.companyId = null;
      newFormValues.customerId = formValues.customer.value;
    }

    if (!formValues.isDiscountAllowed) {
      newFormValues.isDiscountAllowed = false;
    }

    dispatchPostCargoCorporateCredit(newFormValues);
  };
  const content = <CargoCorporateCreditForm onSubmit={onSubmit} />;

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Nuevo Crédito Corporativo de Carga"
      subtitle="Crear crédito corporativo de carga"
      content={content}
    />
  );
};

NewCargoCorporateCredit.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostCargoCorporateCredit: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchPostCargoCorporateCredit: postCargoCorporateCredit,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Créditos Corporativos',
      href: CARGO_CORPORATE_CREDIT_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewCargoCorporateCredit);
