import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import EditButton from '../../../common/button/EditButton';
import { CABIN_CREW_PATH } from '../../../../config/paths';
import CabinCrewDeleteButton from './CabinCrewDeleteButton';

const CabinCrewToolbar = ({ cabinCrewId }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <EditButton path={`${CABIN_CREW_PATH}/${cabinCrewId}/edit`} />{' '}
      <CabinCrewDeleteButton cabinCrewId={cabinCrewId} />
    </div>
  </ButtonToolbar>
);

CabinCrewToolbar.propTypes = {
  cabinCrewId: PropTypes.number.isRequired,
};

export default CabinCrewToolbar;
