import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import { CABIN_CREW_PATH } from '../../../../config/paths';
import { postCabinCrew } from '../../../../actions';
import CabinCrewForm from './CabinCrewForm';
import { tzNormalizeDate } from '../../../../utils/date';
import { TIMESTAMP_FORMAT } from '../../../../config/locale';

const NewCabinCrew = ({ breadcrumbs, dispatchPostCabinCrew }) => {
  const onSubmit = (formValues) => {
    const newFormValues = {
      homeBaseId: formValues.homeBase.value,
      customerId: formValues.customer.value,
      cabinCrewTypeId: formValues.cabinCrewType.value,
      crewStatusId: formValues.crewStatus.value,
    };

    newFormValues.cabinCrewDocumentList = formValues.cabinCrewDocuments
      ? formValues.cabinCrewDocuments.map((document) => ({
          cabinCrewDocumentTypeId: document.cabinCrewDocumentType.value,
          documentNumber: document.documentNumber,
          effectiveDate: tzNormalizeDate({
            date: document.effectiveDate,
            format: TIMESTAMP_FORMAT,
          }),
          expirationDate: tzNormalizeDate({
            date: document.expirationDate,
            format: TIMESTAMP_FORMAT,
          }),
          note: document.note,
        }))
      : [];

    newFormValues.cabinCrewContractList = formValues.cabinCrewContracts
      ? formValues.cabinCrewContracts.map((contract) => ({
          cabinCrewContractTypeId: contract.cabinCrewContractType.value,
          reason: contract.reason,
          effectiveDate: tzNormalizeDate({
            date: contract.effectiveDate,
            format: TIMESTAMP_FORMAT,
          }),
          expirationDate: tzNormalizeDate({
            date: contract.expirationDate,
            format: TIMESTAMP_FORMAT,
          }),
          note: contract.note,
        }))
      : [];

    dispatchPostCabinCrew(newFormValues);
  };

  const content = <CabinCrewForm onSubmit={onSubmit} />;

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Nuevo Servicio a Bordo"
      subtitle="Crear nuevo servicio a bordo"
      content={content}
    />
  );
};

NewCabinCrew.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostCabinCrew: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchPostCabinCrew: postCabinCrew,
};

const mapStateToProps = ({ TrafficUnit }) => ({
  breadcrumbs: [
    ...TrafficUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Servicio a Bordo',
      href: CABIN_CREW_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
});

export default connect(mapStateToProps, mapDispatchToProps)(NewCabinCrew);
