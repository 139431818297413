import React from 'react';
import { connect } from 'react-redux';
import { Form, formValueSelector, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import SelectionableTable from '../../../../common/forms/table/SelectionableTable';
import { DELIVERY_CARGO_ITEMS_COLUMNS } from '../../../../../config/columns';
import {
  CARGO_ITEM_STATUS,
  DEFAULT_PAGE_SIZE,
  DELIVERY_STATUS,
} from '../../../../../config/constants';
import DeliveryItemsDeliveredButton from './DeliveryItemsDeliveredButton';
import DeliveryItemsBackButton from './DeliveryItemsBackButton';

const selector = formValueSelector('DeliveryItemsForm');

const DeliveryItemsForm = ({
  handleSubmit,
  deliveryId,
  items,
  deliveryStatus,
  selectedItems,
}) => {
  const deliveryAddressMap = items.reduce((map, item) => {
    const deliveryAddress = item.cargoItemDeliveryAddress;

    if (map.has(deliveryAddress)) {
      map.get(deliveryAddress).push(item);
    } else {
      map.set(deliveryAddress, [item]);
    }

    return map;
  }, new Map());

  const selectionableTableByDeliveryAddressResource = Array.from(
    deliveryAddressMap.entries(),
  ).map(([deliveryAddress, itemsByDeliveryAddress], index) => (
    <div key={deliveryAddress}>
      <div className="d-flex flex-column flex-md-row justify-content-md-between">
        <h5>
          Dirección de entrega: <strong>{deliveryAddress}</strong>
        </h5>
        {deliveryStatus === DELIVERY_STATUS.IN_TRANSIT.value &&
          selectedItems[index] &&
          selectedItems[index].length > 0 && (
            <div>
              <DeliveryItemsDeliveredButton
                cargoItemIds={selectedItems[index]}
                deliveryId={deliveryId}
                consigneeCustomers={
                  itemsByDeliveryAddress[0].cargoItemConsigneeCustomers
                }
              />{' '}
              <DeliveryItemsBackButton
                deliveryId={deliveryId}
                cargoItemIds={selectedItems[index]}
              />
            </div>
          )}
      </div>
      <SelectionableTable
        name={`items[${index}]`}
        manual={false}
        columns={DELIVERY_CARGO_ITEMS_COLUMNS}
        data={itemsByDeliveryAddress}
        defaultPageSize={DEFAULT_PAGE_SIZE}
        returnOnlySelectedItems
        keyField="cargoItemId"
        form="DeliveryItemsForm"
        isItemSelectable={(item) =>
          [
            CARGO_ITEM_STATUS.REGISTERED_FOR_DELIVERY.value,
            CARGO_ITEM_STATUS.DELIVERY_IN_TRANSIT.value,
          ].includes(item.cargoItemStatus) &&
          deliveryStatus === DELIVERY_STATUS.IN_TRANSIT.value
        }
      />
    </div>
  ));

  return (
    <Form onSubmit={handleSubmit}>
      {selectionableTableByDeliveryAddressResource}
    </Form>
  );
};

DeliveryItemsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  items: PropTypes.instanceOf(Array).isRequired,
  deliveryId: PropTypes.number.isRequired,
  deliveryStatus: PropTypes.string.isRequired,
  selectedItems: PropTypes.instanceOf(Array),
};

DeliveryItemsForm.defaultProps = {
  selectedItems: [],
};

const mapStateToProps = (state) => ({
  selectedItems: selector(state, 'items'),
});

const formComponent = reduxForm({
  form: 'DeliveryItemsForm',
})(DeliveryItemsForm);

export default connect(mapStateToProps)(formComponent);
