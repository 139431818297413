// For all
export const FLAG_GETTING_ITINERARIES = 'FLAG_GETTING_ITINERARIES';
export const GET_ITINERARIES = 'GET_ITINERARIES';
export const CLEAR_ITINERARIES = 'CLEAR_ITINERARIES';
// For one
export const GET_ITINERARY = 'GET_ITINERARY';
export const CLEAR_ITINERARY = 'CLEAR_ITINERARY';
export const FLAG_ITINERARY_ACTIVITY = 'FLAG_ITINERARY_ACTIVITY';
// For drivers
export const GET_LIST_DRIVERS = 'GET_LIST_DRIVERS';
export const CLEAR_LIST_DRIVERS = 'CLEAR_LIST_DRIVERS';
// For passenger flow (boarding and leaving)
export const FLAG_GETTING_PASSENGER_FLOW = 'FLAG_GETTING_PASSENGER_FLOW';
export const GET_PASSENGER_FLOW = 'GET_PASSENGER_FLOW';
export const CLEAR_PASSENGER_FLOW = 'CLEAR_PASSENGER_FLOW';
// For user spot
export const GET_CURRENT_USER_SPOT_IN_ITINERARY =
  'GET_CURRENT_USER_SPOT_IN_ITINERARY';
export const CLEAR_CURRENT_USER_SPOT_IN_ITINERARY =
  'CLEAR_CURRENT_USER_SPOT_IN_ITINERARY';
// For itinerary promises
export const FLAG_GETTING_ITINERARY_PROMISES =
  'FLAG_GETTING_ITINERARY_PROMISES';
export const GET_ITINERARY_PROMISES = 'GET_ITINERARY_PROMISES';
export const CLEAR_ITINERARY_PROMISES = 'CLEAR_ITINERARY_PROMISES';
// For company itinerary default fields
export const GET_COMPANY_ITINERARY_DEFAULT_FIELDS =
  'GET_COMPANY_ITINERARY_DEFAULT_FIELDS';
export const CLEAR_COMPANY_ITINERARY_DEFAULT_FIELDS =
  'CLEAR_COMPANY_ITINERARY_DEFAULT_FIELDS';
// For customers in itinerary
export const GET_PERSONNEL_FOR_ITINERARY = 'GET_PERSONNEL_FOR_ITINERARY';
export const CLEAR_PERSONNEL_FOR_ITINERARY = 'CLEAR_PERSONNEL_FOR_ITINERARY';
// For itinerary history
export const GET_ITINERARY_HISTORY = 'GET_ITINERARY_HISTORY';
export const CLEAR_ITINERARY_HISTORY = 'CLEAR_ITINERARY_HISTORY';
export const FLAG_PERSONNEL_ACTIVITY = 'FLAG_PERSONNEL_ACTIVITY';
