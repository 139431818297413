import React from 'react';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import deliveryBasicInformationPropTypes from '../proptypes/DeliveryPropTypes';
import { tzNormalizeDate } from '../../../../../utils/date';
import { DATE_TIME_FORMAT } from '../../../../../config/locale';
import DeliveryItemsForm from './DeliveryItemsForm';
import EnumStatus from '../../../../common/EnumStatus';
import { DELIVERY_STATUS } from '../../../../../config/constants';

const DeliveryBasicInformationResource = ({
  id,
  agencyName,
  deliveryStatus,
  businessName,
  businessTaxId,
  userFullName,
  createDate,
  items,
}) => (
  <>
    <ResourceProperty label="Código:">{id || '-'}</ResourceProperty>
    <ResourceProperty label="Agencia:">{agencyName || '-'}</ResourceProperty>
    <ResourceProperty label="Empresa:">
      {`RUC: ${businessTaxId} - ${businessName}` || '-'}
    </ResourceProperty>
    <ResourceProperty label="Usuario asignado:">
      {userFullName || '-'}
    </ResourceProperty>
    <ResourceProperty label="Fecha de asignación:">
      {tzNormalizeDate({
        date: createDate,
        format: DATE_TIME_FORMAT,
      }) || '-'}
    </ResourceProperty>
    <ResourceProperty label="Estado:">
      <EnumStatus enumObject={DELIVERY_STATUS} enumValue={deliveryStatus} />
    </ResourceProperty>
    <DeliveryItemsForm
      onSubmit={() => {}}
      deliveryId={id}
      items={items}
      deliveryStatus={deliveryStatus}
    />
  </>
);

DeliveryBasicInformationResource.propTypes = deliveryBasicInformationPropTypes;

export default DeliveryBasicInformationResource;
