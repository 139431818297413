import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Content from '../../../layout/Content';
import ParcelPriceConfigurationForm from './ParcelPriceConfigurationForm';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { PARCEL_PRICE_CONFIGURATION_PATH } from '../../../../config/paths';
import { postParcelPriceConfiguration } from '../../../../actions';

const NewParcelPriceConfiguration = ({
  breadcrumbs,
  dispatchPostParcelPriceConfiguration,
}) => {
  const onSubmit = (formValues) => {
    const newFormValues = {
      ...formValues,
      cargoCorporateCreditId: formValues.cargoCorporateCredit
        ? formValues.cargoCorporateCredit.value
        : null,
    };
    dispatchPostParcelPriceConfiguration(newFormValues);
  };

  const content = <ParcelPriceConfigurationForm onSubmit={onSubmit} />;

  return (
    <Content
      title="Nueva Configuración de Tarifario"
      content={content}
      breadcrumbs={breadcrumbs}
    />
  );
};

NewParcelPriceConfiguration.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostParcelPriceConfiguration: PropTypes.func.isRequired,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Configuración de Tarifas',
      href: PARCEL_PRICE_CONFIGURATION_PATH,
    },
    {
      text: 'Nueva Configuración de Tarifario',
      href: '',
    },
  ],
});

const mapDispatchToProps = {
  dispatchPostParcelPriceConfiguration: postParcelPriceConfiguration,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewParcelPriceConfiguration);
