import React, { useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import Content from '../../../layout/Content';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import {
  clearDeliveryPrice,
  getDeliveryPrice,
} from '../../../../actions/cargo/DeliveryPrice';
import { DELIVERY_PRICE_PATH } from '../../../../config/paths';
import Loader from '../../../common/Loader';

import NoDataResource from '../../../common/resource/NoDataResource';
import DeliveryPriceBasicInformationResource from './DeliveryPriceBasicInformationResource';

const DeliveryPrice = ({
  breadcrumbs,
  loading,
  match: {
    params: { id: deliveryPriceId },
  },
  dispatchGetDeliveryPrice,
  dispatchClearDeliveryPrice,
  deliveryPrice,
}) => {
  useLayoutEffect(() => {
    dispatchGetDeliveryPrice({ deliveryPriceId });
    return () => dispatchClearDeliveryPrice();
  }, []);

  let content;
  let toolbar;

  if (loading) content = <Loader />;
  else if (deliveryPrice.isEmpty())
    content = <NoDataResource returnPage={DELIVERY_PRICE_PATH} />;
  else {
    const deliveryPriceJson = deliveryPrice.toJS();
    content = <DeliveryPriceBasicInformationResource {...deliveryPriceJson} />;
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      toolbar={toolbar}
      title="Tarifario de Reparto"
      content={content}
    />
  );
};

DeliveryPrice.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  dispatchGetDeliveryPrice: PropTypes.func.isRequired,
  dispatchClearDeliveryPrice: PropTypes.func.isRequired,
  deliveryPrice: PropTypes.instanceOf(Immutable.Map).isRequired,
};

DeliveryPrice.defaultProps = {
  loading: false,
};

const mapDispatchToProps = {
  dispatchClearDeliveryPrice: clearDeliveryPrice,
  dispatchGetDeliveryPrice: getDeliveryPrice,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Tarifarios de Reparto',
      href: DELIVERY_PRICE_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  deliveryPrice: CargoUnit.DeliveryPrice.getIn(['current', 'content']),
  loading: !CargoUnit.DeliveryPrice.getIn(['current', 'activity']).isEmpty(),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryPrice);
