import Immutable from 'immutable';
import { COLLECTION_SHAPE } from '../../../config/shapes';
import {
  CLEAR_CARGO_ITEMS_TO_DELIVER,
  CLEAR_DELIVERY_BUSINESS,
  CLEAR_DELIVERY_BUSINESSES,
  FLAG_DELIVERY_BUSINESS_ACTIVITY,
  FLAG_GETTING_CARGO_ITEMS_TO_DELIVER,
  FLAG_GETTING_DELIVERY_BUSINESSES,
  GET_CARGO_ITEMS_TO_DELIVER,
  GET_DELIVERY_BUSINESS,
  GET_DELIVERY_BUSINESSES,
} from '../../../actions/types';

const INITIAL_STATE = Immutable.Map({
  all: Immutable.Map({
    content: Immutable.Map(COLLECTION_SHAPE),
    loading: false,
  }),
  current: Immutable.Map({
    content: Immutable.Map(),
    activity: Immutable.List(),
  }),
  items: Immutable.Map({
    content: Immutable.List(),
    loading: false,
  }),
});

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case FLAG_GETTING_DELIVERY_BUSINESSES:
      return state.setIn(['all', 'loading'], payload);
    case GET_DELIVERY_BUSINESSES:
      return state.setIn(['all', 'content'], Immutable.Map(payload));
    case CLEAR_DELIVERY_BUSINESSES:
      return state.setIn(
        ['all', 'content'],
        INITIAL_STATE.getIn(['all', 'content']),
      );
    case FLAG_DELIVERY_BUSINESS_ACTIVITY:
      if (payload) {
        return state.setIn(
          ['current', 'activity'],
          state.getIn(['current', 'activity']).push(payload),
        );
      }

      return state.setIn(
        ['current', 'activity'],
        state.getIn(['current', 'activity']).pop(),
      );
    case GET_DELIVERY_BUSINESS:
      return state.setIn(['current', 'content'], Immutable.Map(payload));
    case CLEAR_DELIVERY_BUSINESS:
      return state.setIn(['current'], INITIAL_STATE.getIn(['current']));
    case FLAG_GETTING_CARGO_ITEMS_TO_DELIVER:
      return state.setIn(['items', 'loading'], payload);
    case GET_CARGO_ITEMS_TO_DELIVER:
      return state.setIn(['items', 'content'], Immutable.List(payload));
    case CLEAR_CARGO_ITEMS_TO_DELIVER:
      return state.setIn(
        ['items', 'content'],
        INITIAL_STATE.getIn(['items', 'content']),
      );
    default:
      return state;
  }
};
