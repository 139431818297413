import React from 'react';
import { Form } from 'reactstrap';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import DynamicForm from '../../../common/forms/DynamicForm';
import FormFooter from '../../../common/forms/FormFooter';
import { isRequired } from '../../../../utils/validators';
import TextInput from '../../../common/forms/input/TextInput';
import CustomerSelect from '../../../common/forms/select/CustomerSelect';

export const CargoCorporateCreditCustomerForm = ({
  handleSubmit,
  cityList,
}) => {
  const handleAddCustomer = (fields) => {
    fields.push({
      cityList: cityList.map(({ name, id }) => ({
        city: name,
        sender: false,
        consignee: false,
        id,
      })),
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <DynamicForm
        name="customerList"
        columns={[
          {
            sm: 12,
            name: 'customer',
            label: 'Cliente',
            component: CustomerSelect,
            validate: [isRequired],
          },
          {
            name: 'cityList',
            isFieldArray: true,
            fields: [
              {
                name: 'city',
                label: 'Ciudad',
                component: TextInput,
                disabled: true,
                validate: [isRequired],
              },
              {
                lg: 1,
                md: 2,
                sm: 12,
                label: 'Remitente',
                name: 'sender',
                component: 'input',
                type: 'checkbox',
                otherProps: { className: 'mx-4' },
              },
              {
                lg: 1,
                md: 2,
                sm: 12,
                label: 'Consignado',
                name: 'consignee',
                component: 'input',
                type: 'checkbox',
                otherProps: { className: 'mx-4' },
              },
            ],
          },
        ]}
        onClickAdd={handleAddCustomer}
        showSubRemoveButton={false}
        showSubAddButton={false}
      />
      <FormFooter />
    </Form>
  );
};

CargoCorporateCreditCustomerForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  cityList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

const form = reduxForm({
  form: 'cargoCorporateCreditCustomerForm',
})(CargoCorporateCreditCustomerForm);

export default form;
