import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, FormGroup, Label } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { isRequired } from '../../../../utils/validators';
import FormItem from '../../../common/forms/FormItem';
import Loader from '../../../common/Loader';
import FormFooter from '../../../common/forms/FormFooter';
import BusinessInputGroup from '../../../common/forms/BusinessInputGroup';
import AgencySelect from '../../../common/forms/select/AgencySelect';
import UserSelect from '../../../common/forms/select/UserSelect';

export const DeliveryBusinessForm = ({
  initialValues,
  handleSubmit,
  loading,
}) => {
  const [editingMode, setEditingMode] = useState(false);

  useLayoutEffect(() => {
    if (initialValues) {
      setEditingMode(true);
    }
  }, []);

  if (loading) return <Loader />;

  return (
    <Form onSubmit={handleSubmit}>
      <BusinessInputGroup
        label="Empresa"
        labelRequired
        name="business"
        form="DeliveryBusinessForm"
        validate={[isRequired]}
        disabled={editingMode}
        showDetails
      />
      <FormGroup row>
        <FormItem label="Agencias" required>
          <Field
            name="agencies"
            component={AgencySelect}
            placeholder="Agencias"
            validate={[isRequired]}
            isMulti
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Usuarios" required>
          <Field
            name="users"
            component={UserSelect}
            placeholder="Usuarios"
            validate={[isRequired]}
            isMulti
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Estado">
          <Label>
            <Field name="active" component="input" type="checkbox" /> Activo
          </Label>
        </FormItem>
      </FormGroup>
      <FormFooter />
    </Form>
  );
};

DeliveryBusinessForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  initialValues: PropTypes.instanceOf(Object),
};

DeliveryBusinessForm.defaultProps = {
  initialValues: null,
};

const mapStateToProps = ({ CargoUnit }) => ({
  loading: !CargoUnit.DeliveryBusiness.getIn(['current', 'activity']).isEmpty(),
});

const formComponent = reduxForm({
  form: 'DeliveryBusinessForm',
})(DeliveryBusinessForm);

export default connect(mapStateToProps)(formComponent);
