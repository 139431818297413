import React, { useEffect, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { push } from 'react-router-redux';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { breadcrumbsPropTypes } from '../../common/resource/proptypes/CommonPropTypes';
import { clearTickets, getTickets } from '../../../actions';
import { MY_RESERVATIONS_UNIT_PATH } from '../../../config/paths';
import { MY_RESERVATIONS_COLUMNS } from '../../../config/columns';
import ModuleList from '../../layout/ModuleList';
import Loader from '../../common/Loader';
import { DEFAULT_PAGE_SIZE } from '../../../config/constants';

export const MyReservationsHome = ({
  breadcrumbs,
  tickets,
  loading,
  dispatchGetTickets,
  dispatchClearTickets,
  dispatchPush,
  user,
  isForMine,
}) => {
  const [query, setQuery] = useState(null);
  const [buttonContent, setButtonContent] = useState(
    'Mostrar todas las reservas',
  );
  const [date, setDate] = useState(null);
  const data = tickets.get('content') || [];
  const pages = tickets.get('totalPages') || null;
  const defaultPageSize = tickets.get('size') || DEFAULT_PAGE_SIZE;

  useLayoutEffect(() => {
    if (isForMine) {
      const currentDate = new Date();

      const formattedDate = new Intl.DateTimeFormat('es-PE', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      }).format(currentDate);

      setDate(formattedDate);

      setQuery(
        `departureDate>${formattedDate},ticketStatus:ACTIVE,customerId:${user.customer.id}`,
      );
    }

    return () => {
      dispatchClearTickets();
    };
  }, []);

  useEffect(() => {
    if (query !== null) {
      if (query !== `customerId:${user.customer.id}`) {
        setButtonContent('Mostrar todas las reservas');
      } else {
        setButtonContent('Mostrar las reservas activas');
      }
    }
  }, [query]);

  const onClickShow = () => {
    let newQuery;
    if (query !== `customerId:${user.customer.id}`) {
      newQuery = `customerId:${user.customer.id}`;
      setQuery(newQuery);
    } else {
      newQuery = `departureDate>${date},ticketStatus:ACTIVE,customerId:${user.customer.id}`;
      setQuery(newQuery);
    }
    dispatchGetTickets({
      query: newQuery,
      size: DEFAULT_PAGE_SIZE,
      sort: 'id,desc',
    });
  };

  const onClickTo = (path) => {
    dispatchPush(path);
  };

  const showButton = (
    <div>
      <Button color="primary" onClick={onClickShow}>
        {buttonContent}
      </Button>{' '}
      <Button
        color="success"
        onClick={() => onClickTo('my-reservations/process?mobilizing=true')}
      >
        Reservar movilización
      </Button>{' '}
      <Button
        color="success"
        onClick={() => onClickTo('my-reservations/process?mobilizing=false')}
      >
        Reservar desmovilización
      </Button>
    </div>
  );

  if (query === null && isForMine) {
    return <Loader />;
  }

  return (
    <>
      <ModuleList
        extraButtons={showButton}
        title="Reservas"
        description={
          <h4 id="text">
            Bienvenido, <strong>{user.customer.fullName}</strong>
          </h4>
        }
        breadcrumbs={breadcrumbs}
        tableStructure={{
          columns: MY_RESERVATIONS_COLUMNS,
          data,
          pages,
          defaultPageSize,
          filterable: true,
          fetchData: dispatchGetTickets,
          modelPath: MY_RESERVATIONS_UNIT_PATH,
          loading,
          navigateToModelOnRowClick: true,
          queryProps: query,
        }}
      />
    </>
  );
};

const mapStateToProps = ({
  MyReservationsUnit,
  ReservationUnit,
  authentication,
}) => ({
  breadcrumbs: [
    ...MyReservationsUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Reservaciones',
      href: MY_RESERVATIONS_UNIT_PATH,
    },
  ],
  tickets: ReservationUnit.Ticket.getIn(['all', 'content']),
  loading: ReservationUnit.Ticket.getIn(['all', 'loading']),
  isForMine: !!authentication.get('user').salesSessionUserId,
  user: authentication.get('user'),
});

const mapDispatchToProps = {
  dispatchGetTickets: getTickets,
  dispatchClearTickets: clearTickets,
  dispatchPush: push,
};

MyReservationsHome.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  tickets: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetTickets: PropTypes.func.isRequired,
  dispatchClearTickets: PropTypes.func.isRequired,
  dispatchPush: PropTypes.func.isRequired,
  isForMine: PropTypes.bool,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    username: PropTypes.string.isRequired,
    customer: PropTypes.shape({
      id: PropTypes.number.isRequired,
      fullName: PropTypes.string.isRequired,
    }),
  }),
};

MyReservationsHome.defaultProps = {
  tickets: null,
  loading: false,
  isForMine: false,
  user: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyReservationsHome);
