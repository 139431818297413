import React from 'react';
import PropTypes from 'prop-types';
import Modal from './Modal';
import { MODAL_ACTION_CONFIRM_MESSAGE } from '../../../config/messages';

const ConfirmationModal = ({
  show,
  title,
  body,
  onClickConfirm,
  onClickCancel,
  size,
  confirmButtonColor,
  confirmButtonText,
  cancelButtonColor,
  cancelButtonText,
  onClickClose,
}) => (
  <Modal
    show={show}
    title={title}
    body={body}
    confirmButtonText={confirmButtonText}
    confirmButtonColor={confirmButtonColor}
    cancelButtonText={cancelButtonText}
    cancelButtonColor={cancelButtonColor}
    onClickConfirm={onClickConfirm}
    onClickCancel={onClickCancel}
    size={size}
    onClickClose={onClickCancel || onClickClose}
  />
);

ConfirmationModal.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onClickConfirm: PropTypes.func,
  onClickCancel: PropTypes.func,
  onClickClose: PropTypes.func,
  size: PropTypes.string,
  confirmButtonColor: PropTypes.string,
  confirmButtonText: PropTypes.string,
  cancelButtonColor: PropTypes.string,
  cancelButtonText: PropTypes.string,
};

ConfirmationModal.defaultProps = {
  show: false,
  title: 'Confirmación',
  body: MODAL_ACTION_CONFIRM_MESSAGE,
  onClickConfirm: null,
  onClickCancel: null,
  onClickClose: null,
  size: 'md',
  confirmButtonColor: 'secondary',
  confirmButtonText: 'Continuar',
  cancelButtonColor: 'danger',
  cancelButtonText: 'Cancelar',
};

export default ConfirmationModal;
