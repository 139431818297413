import React from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from '../../../common/modal/Modal';
import DeliveryCarrierRemissionGuide from './DeliveryCarrierRemissionGuideModal';
import { openModalDeliveryBillProcess } from '../../../../actions';

const DeliveryCarrierRemissionGuideButton = ({
  remissionGuideList,
  deliveryId,
  isModalOpen,
  dispatchOpenModal,
}) => {
  const onShowModal = () => {
    dispatchOpenModal(true);
  };

  const onCancel = () => {
    dispatchOpenModal(false);
  };

  return (
    <>
      <Button color="info" onClick={onShowModal}>
        <i className="fa fa-file-pdf-o" /> Guías de Remisión
      </Button>
      <Modal
        show={isModalOpen}
        title="Guías de Remisión del Reparto"
        body={
          <DeliveryCarrierRemissionGuide
            remissionGuideList={remissionGuideList}
            deliveryId={deliveryId}
          />
        }
        size="xl"
        onClickClose={onCancel}
        onClickCancel={onCancel}
      />
    </>
  );
};

DeliveryCarrierRemissionGuideButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  remissionGuideList: PropTypes.arrayOf(PropTypes.object).isRequired,
  deliveryId: PropTypes.number.isRequired,
  isModalOpen: PropTypes.bool,
  dispatchOpenModal: PropTypes.func.isRequired,
};

DeliveryCarrierRemissionGuideButton.defaultProps = {
  isModalOpen: false,
};

const mapStateToProps = ({ CargoUnit }) => ({
  loading: !CargoUnit.Delivery.getIn(['current', 'activity']).isEmpty(),
  isModalOpen: CargoUnit.Delivery.get('modal'),
});

const mapDispatchToProps = {
  dispatchOpenModal: openModalDeliveryBillProcess,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeliveryCarrierRemissionGuideButton);
