import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import EditButton from '../../../common/button/EditButton';
import { DELIVERY_LOCATION_DISTRICT_PATH } from '../../../../config/paths';
import DeliveryLocationDistrictDeleteButton from './DeliveryLocationDistrictDeleteButton';

const DeliveryLocationDistrictToolbar = ({ locationId }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <EditButton
        path={`${DELIVERY_LOCATION_DISTRICT_PATH}/${locationId}/edit`}
      />{' '}
      <DeliveryLocationDistrictDeleteButton locationId={locationId} />
    </div>
  </ButtonToolbar>
);

DeliveryLocationDistrictToolbar.propTypes = {
  locationId: PropTypes.number.isRequired,
};

export default DeliveryLocationDistrictToolbar;
