import { string, number, bool, arrayOf, shape } from 'prop-types';

const shelfInformationResourceProptypes = {
  id: number.isRequired,
  name: string.isRequired,
  active: bool.isRequired,
  width: number.isRequired,
  height: number.isRequired,
  shelfSpaceList: arrayOf(
    shape({
      spaceName: string.isRequired,
      rowPosition: number.isRequired,
      columnPosition: number.isRequired,
      disabled: bool.isRequired,
    }),
  ).isRequired,
};

export default shelfInformationResourceProptypes;
