import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { Container, Form, FormGroup, Label } from 'reactstrap';
import { reduxForm, Field } from 'redux-form';
import { isRequired } from '../../../../utils/validators';
import CustomerInputGroup from '../../../common/forms/CustomerInputGroup';
import FormItem from '../../../common/forms/FormItem';
import {
  clearParcel,
  getActiveSalesSession,
  getParcel,
  getUserAgenciesV2,
} from '../../../../actions';
import { optionsPropTypes } from '../../../common/forms/select/SelectPropTypes';
import Select from '../../../common/forms/select/Select';
import CargoPickupResults from './CargoPickupResults';
import { generateParcelsToPickupColumns } from '../../../../config/columns';
import {
  clearAllDetailsFromParcelsToPickup,
  clearParcelsToPickup,
  getParcelsToPickup,
  putParcelsToPickup,
} from '../../../../actions/cargo/CargoPickup';
import Modal from '../../../common/modal/Modal';
import CargoPickupModel from './CargoPickupModal';
import Alert from '../../../common/informative/Alert';
import Loader from '../../../common/Loader';
import { CARGO_PICKUP_PATH } from '../../../../config/paths';
import printVoucherCargo from '../../../../utils/printers/CargoVoucher';
import FormFooter from '../../../common/forms/FormFooter';
import { PAYMENT_METHOD_PAGO_EN_DESTINO } from '../../../../config/constants';
import BusinessSelect from '../../../common/forms/select/BusinessSelect';
import CustomerSelect from '../../../common/forms/select/CustomerSelect';

export const CargoPickupForm = ({
  handleSubmit,
  loadingAgencies,
  agencyOptions,
  user,
  loadingActiveSalesSession,
  activeSalesSession,
  parcel,
  dispatchGetActiveSalesSession,
  dispatchGetUserAgenciesV2,
  dispatchGetParcelsToPickup,
  dispatchClearParcelsToPickup,
  dispatchClearAllDetailsFromParcelsToPickup,
  dispatchPutParcelsToPickup,
  dispatchGetParcel,
  dispatchClearParcel,
}) => {
  useEffect(() => {
    dispatchGetActiveSalesSession({ source: CARGO_PICKUP_PATH });
    if (user) {
      dispatchGetUserAgenciesV2({ userId: user.id });
    }
  }, []);

  const [showDetailFields, setShowDetailFields] = useState(false);
  const [consigneeCustomerId, setConsigneeCustomerId] = useState(0);
  const [destinationLocationId, setDestinationLocationId] = useState(0);
  const [selectedParcel, setSelectedParcel] = useState(null);
  const [modalBody, setModalBody] = useState(null);

  useEffect(() => {
    if (
      !parcel.isEmpty() &&
      selectedParcel.transactionCode === PAYMENT_METHOD_PAGO_EN_DESTINO
    ) {
      printVoucherCargo(parcel.toJSON());
      dispatchClearParcel();
    }
    setSelectedParcel(null);
  }, [parcel]);

  const onCancel = () => {
    setSelectedParcel(null);
    setModalBody(null);
  };

  const onModalSubmit = async ({ secretCode, paymentMethod, voucherCode }) => {
    const isParcelDelivered = await dispatchPutParcelsToPickup({
      parcelId: selectedParcel.id,
      ...selectedParcel,
      secretCode,
      paymentMethod,
      voucherCode,
    });
    if (isParcelDelivered) {
      dispatchGetParcel({ parcelId: selectedParcel.id });
      setModalBody(null);
    }
  };

  const buildModalBody = () => {
    const newModalBody = (
      <CargoPickupModel
        parcel={selectedParcel}
        onCancel={onCancel}
        handleProcess={onModalSubmit}
      />
    );
    setModalBody(newModalBody);
  };

  useEffect(() => {
    if (selectedParcel !== null) {
      buildModalBody();
    }
  }, [selectedParcel]);

  const onHandleSubmit = ({
    consigneeCustomer,
    destinationLocation,
    senderCustomer,
    customer,
    business,
  }) => {
    setConsigneeCustomerId(consigneeCustomer.id);
    setDestinationLocationId(destinationLocation.value);

    if (!showDetailFields) {
      dispatchGetParcelsToPickup({
        consigneeCustomerId: consigneeCustomer.id,
        destinationLocationId: destinationLocation.value,
      });
      return;
    }

    dispatchGetParcelsToPickup({
      consigneeCustomerId: consigneeCustomer.id,
      destinationLocationId: destinationLocation.value,
      ...(senderCustomer && { senderCustomerId: senderCustomer.id }),
      ...(customer && { customerId: customer.id }),
      ...(business && { businessId: business.value }),
    });
  };

  const onChangeSelections = () => {
    dispatchClearAllDetailsFromParcelsToPickup();
    dispatchClearParcelsToPickup();
  };

  const onClickPickupParcel = (parcelToPickup) => {
    if (parcelToPickup.internal)
      dispatchPutParcelsToPickup({
        parcelId: parcelToPickup.id,
        ...parcelToPickup,
      });
    else {
      setSelectedParcel(parcelToPickup);
    }
  };

  if (loadingActiveSalesSession) {
    return <Loader />;
  }

  if (activeSalesSession.isEmpty()) {
    return (
      <Container>
        <Alert message="No hay ninguna sesión de ventas activa." />
      </Container>
    );
  }

  const renderDetailFields = () =>
    !showDetailFields ? null : (
      <>
        <FormGroup row>
          <FormItem label="Remitente">
            <Field name="senderCustomer" component={CustomerSelect} />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Cliente">
            <Field name="customer" component={CustomerSelect} />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Empresa">
            <Field name="business" component={BusinessSelect} />
          </FormItem>
        </FormGroup>
      </>
    );

  return (
    <>
      <Form onSubmit={handleSubmit(onHandleSubmit)}>
        <CustomerInputGroup
          label="Consignado"
          labelRequired
          name="consigneeCustomer"
          form="CargoPickupForm"
          validate={[isRequired]}
          onChange={onChangeSelections}
          showDetails
        />
        <FormGroup row>
          <FormItem>
            <Label>
              <Field
                name="delivery"
                id="delivery"
                component="input"
                type="checkbox"
                checked={showDetailFields}
                onChange={(e) => setShowDetailFields(e.target.checked)}
              />{' '}
              Ampliar búsqueda
            </Label>
          </FormItem>
        </FormGroup>
        {renderDetailFields()}
        <FormGroup row>
          <FormItem label="Destino" required>
            <Field
              name="destinationLocation"
              component={Select}
              onChange={onChangeSelections}
              isLoading={loadingAgencies}
              options={agencyOptions}
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormFooter saveButtonText="Buscar" saveButtonIcon="fa fa-search" />
      </Form>
      <h4>Resultado de cargas</h4>
      <CargoPickupResults
        columns={generateParcelsToPickupColumns(onClickPickupParcel)}
        searchFormValues={{ consigneeCustomerId, destinationLocationId }}
      />
      <Modal
        show={!!selectedParcel}
        title="Completar entrega"
        body={modalBody}
        size="lg"
      />
    </>
  );
};

CargoPickupForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loadingAgencies: PropTypes.bool,
  agencyOptions: optionsPropTypes,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    customer: PropTypes.shape({
      fullName: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  loadingActiveSalesSession: PropTypes.bool.isRequired,
  activeSalesSession: PropTypes.instanceOf(Immutable.Map).isRequired,
  parcel: PropTypes.instanceOf(Immutable.Map).isRequired,
  dispatchGetActiveSalesSession: PropTypes.func.isRequired,
  dispatchGetUserAgenciesV2: PropTypes.func.isRequired,
  dispatchGetParcelsToPickup: PropTypes.func.isRequired,
  dispatchClearParcelsToPickup: PropTypes.func.isRequired,
  dispatchClearAllDetailsFromParcelsToPickup: PropTypes.func.isRequired,
  dispatchPutParcelsToPickup: PropTypes.func.isRequired,
  dispatchGetParcel: PropTypes.func.isRequired,
  dispatchClearParcel: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  HumanResourcesUnit,
  authentication,
  SalesUnit,
  CargoUnit,
}) => ({
  loadingAgencies: HumanResourcesUnit.Agency.getIn(['all', 'loading']),
  agencyOptions: HumanResourcesUnit.Agency.getIn([
    'all',
    'content',
    'content',
  ]).map((agency) => ({
    value: agency.locationId,
    label: agency.name,
  })),
  user: authentication.get('user') || undefined,
  loadingActiveSalesSession: SalesUnit.SalesSession.getIn([
    'active',
    'loading',
  ]),
  activeSalesSession: SalesUnit.SalesSession.getIn(['active', 'content']),
  parcel: CargoUnit.Cargo.getIn(['current', 'content']),
});

CargoPickupForm.defaultProps = {
  loadingAgencies: false,
  agencyOptions: [],
};

const mapDispatchToProps = {
  dispatchGetUserAgenciesV2: getUserAgenciesV2,
  dispatchGetParcelsToPickup: getParcelsToPickup,
  dispatchClearParcelsToPickup: clearParcelsToPickup,
  dispatchClearAllDetailsFromParcelsToPickup:
    clearAllDetailsFromParcelsToPickup,
  dispatchPutParcelsToPickup: putParcelsToPickup,
  dispatchGetActiveSalesSession: getActiveSalesSession,
  dispatchGetParcel: getParcel,
  dispatchClearParcel: clearParcel,
};

const formComponent = reduxForm({
  form: 'CargoPickupForm',
})(CargoPickupForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
