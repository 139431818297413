import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import { DELIVERY_BUSINESS_PATH } from '../../../../config/paths';
import {
  clearDeliveryBusiness,
  getDeliveryBusiness,
} from '../../../../actions';
import DeliveryBusinessBasicInformationResource from './resource/DeliveryBusinessBasicInformationResource';
import DeliveryBusinessToolbar from './DeliveryBusinessToolbar';

export const DeliveryBusiness = ({
  breadcrumbs,
  dispatchGetDeliveryBusiness,
  dispatchClearDeliveryBusiness,
  match: {
    params: { id: deliveryBusinessId },
  },
  loading,
  deliveryBusiness,
}) => {
  useLayoutEffect(() => {
    dispatchGetDeliveryBusiness({ deliveryBusinessId });

    return () => dispatchClearDeliveryBusiness();
  }, []);

  let content;
  let toolbar;

  if (loading) content = <Loader />;
  else if (deliveryBusiness.isEmpty())
    content = <NoDataResource returnPage={DELIVERY_BUSINESS_PATH} />;
  else {
    const deliveryBusinessJson = deliveryBusiness.toJS();

    content = (
      <DeliveryBusinessBasicInformationResource {...deliveryBusinessJson} />
    );

    toolbar = (
      <DeliveryBusinessToolbar deliveryBusinessId={deliveryBusinessJson.id} />
    );
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      toolbar={toolbar}
      title="Empresa de Delivery"
      content={content}
    />
  );
};

DeliveryBusiness.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchClearDeliveryBusiness: PropTypes.func.isRequired,
  dispatchGetDeliveryBusiness: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  deliveryBusiness: PropTypes.instanceOf(Immutable.Map).isRequired,
};

DeliveryBusiness.defaultProps = {
  loading: false,
};

const mapDispatchToProps = {
  dispatchClearDeliveryBusiness: clearDeliveryBusiness,
  dispatchGetDeliveryBusiness: getDeliveryBusiness,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Empresas de Reparto',
      href: DELIVERY_BUSINESS_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  deliveryBusiness: CargoUnit.DeliveryBusiness.getIn(['current', 'content']),
  loading: !CargoUnit.DeliveryBusiness.getIn(['current', 'activity']).isEmpty(),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryBusiness);
