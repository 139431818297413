import React from 'react';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import Checkbox from '../../../../common/forms/Checkbox';
import shelfInformationResourceProptypes from './ShelfBasicInformationResourceProptypes';
import { tzNormalizeDate } from '../../../../../utils/date';
import { DATE_TIME_FORMAT } from '../../../../../config/locale';
import './ShelfGrid.css';

const renderShelfGrid = (shelfSpaceList, width, height) => {
  if (!shelfSpaceList || shelfSpaceList.length === 0)
    return <p>No hay espacios definidos.</p>;

  return (
    <div className="shelf-grid-container">
      <div
        className="shelf-grid"
        style={{
          gridTemplateColumns: `repeat(${width}, minmax(50px, 1fr))`,
          gridTemplateRows: `repeat(${height}, minmax(50px, 1fr))`,
        }}
      >
        {shelfSpaceList.map((space) =>
          space.disabled ? (
            <div
              key={`${space.rowPosition}-${space.columnPosition}`}
              className="shelf-space-hidden"
            />
          ) : (
            <div
              key={`${space.rowPosition}-${space.columnPosition}`}
              className="shelf-space"
            >
              {space.spaceName}
            </div>
          ),
        )}
      </div>
    </div>
  );
};

const ShelfBasicInformationResource = ({
  name,
  location,
  active,
  shelfSpaceList,
  width,
  height,
  createDate,
}) => (
  <>
    <ResourceProperty label="Nombre:">{name || '-'}</ResourceProperty>
    <ResourceProperty label="Ubicación:">
      {location.name || '-'}
    </ResourceProperty>
    <ResourceProperty label="Filas:">{height || '-'}</ResourceProperty>
    <ResourceProperty label="Columnas:">{width || '-'}</ResourceProperty>
    <ResourceProperty label="Fecha de Creación:">
      {tzNormalizeDate({
        date: createDate,
        format: DATE_TIME_FORMAT,
      })}
    </ResourceProperty>

    <div>
      <Checkbox checked={active} /> Activo
    </div>

    <h3 style={{ textAlign: 'center', marginTop: '10px', marginBottom: '5px' }}>
      Distribución del Anaquel
    </h3>

    {renderShelfGrid(shelfSpaceList, width, height)}
  </>
);

ShelfBasicInformationResource.propTypes = shelfInformationResourceProptypes;

export default ShelfBasicInformationResource;
