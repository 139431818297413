export const FLAG_GETTING_DELIVERY_PRICE_CONFIGURATIONS =
  'FLAG_GETTING_DELIVERY_PRICE_CONFIGURATIONS';
export const GET_DELIVERY_PRICE_CONFIGURATIONS =
  'GET_DELIVERY_PRICE_CONFIGURATIONS';
export const CLEAR_DELIVERY_PRICE_CONFIGURATIONS =
  'CLEAR_DELIVERY_PRICE_CONFIGURATIONS';
export const FLAG_DELIVERY_PRICE_CONFIGURATION_ACTIVITY =
  'FLAG_DELIVERY_PRICE_CONFIGURATION_ACTIVITY';
export const GET_DELIVERY_PRICE_CONFIGURATION =
  'GET_DELIVERY_PRICE_CONFIGURATION';
export const CLEAR_DELIVERY_PRICE_CONFIGURATION =
  'CLEAR_DELIVERY_PRICE_CONFIGURATION';
export const FLAG_GETTING_DELIVERY_LAST_CONFIGURATION =
  'FLAG_GETTING_DELIVERY_LAST_CONFIGURATION';
export const GET_DELIVERY_LAST_CONFIGURATION =
  'GET_DELIVERY_LAST_CONFIGURATION';
export const CLEAR_DELIVERY_LAST_CONFIGURATION =
  'CLEAR_DELIVERY_LAST_CONFIGURATION';
