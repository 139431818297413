import { arrayOf, bool, number, shape, string } from 'prop-types';

const deliveryBusinessBasicInformationPropTypes = {
  id: number.isRequired,
  business: shape({
    id: number.isRequired,
    name: string.isRequired,
    businessTaxId: string.isRequired,
  }).isRequired,
  agencyList: arrayOf(
    shape({
      agencyId: number.isRequired,
      agency: shape({
        id: number.isRequired,
        name: string.isRequired,
        locationId: number.isRequired,
      }),
    }),
  ),
  userList: arrayOf(
    shape({
      userId: number.isRequired,
      user: shape({
        id: number.isRequired,
        username: string.isRequired,
        customer: shape({
          id: number.isRequired,
          fullName: string.isRequired,
          idDocumentNumber: string.isRequired,
          idCountryOfOrigin: string.isRequired,
          identificationType: shape({
            name: string.isRequired,
          }),
        }),
      }),
    }),
  ),
  active: bool.isRequired,
};

export default deliveryBusinessBasicInformationPropTypes;
