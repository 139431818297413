import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { Alert, ListGroup, Button } from 'reactstrap';
import {
  clearItinerariesForBooking,
  clearSeatsForBooking,
  postSendTicketByEmailSMSToCustomer,
} from '../../../../actions/index';
import { printPrintedReservations } from '../../../../utils/printers/ReservationVoucher';
import PrintedTicket from '../../../modules/booking/confirmation/PrintedTicket';

export const MyReservationsPrintedTickets = ({
  printedTickets,
  dispatchClearItinerariesForBooking,
  dispatchClearSeatsForBooking,
  disptachPostSendTicketByEmailSMSToCustomer,
  dispatchPush,
}) => {
  const [printed, setPrinted] = useState(false);

  const handleRestartBookingProcess = () => {
    dispatchClearItinerariesForBooking();
    dispatchClearSeatsForBooking();
    dispatchPush('/my-reservations');
  };

  const handlePrintTickets = () => {
    const printedTicketsObject = printedTickets.toJS();
    console.log(printedTicketsObject);

    printPrintedReservations(printedTicketsObject);
    printedTicketsObject.map((printedTicket) =>
      disptachPostSendTicketByEmailSMSToCustomer({
        email: printedTicket.passenger.email,
        ticketId: printedTicket.ticketId,
      }),
    );
    setPrinted(true);
  };

  const printedTicketsObject = printedTickets.toJS();
  const index = 0;
  let printedTicketComponents;
  const hasTickets = printedTicketsObject.length;
  if (hasTickets) {
    printedTicketComponents = printedTicketsObject.map((printedTicket) => (
      <PrintedTicket
        key={printedTicket.passenger.idDocumentNumber}
        printedTicket={printedTicket}
        index={index}
      />
    ));
  } else {
    printedTicketComponents = (
      <Alert color="danger">
        No hay boletos electrónicos listos para imprimir.
      </Alert>
    );
  }
  const showNewSearchButton = !hasTickets || (hasTickets && printed);
  return (
    <>
      <ListGroup className="mb-3">{printedTicketComponents}</ListGroup>
      <div>
        <Button
          color="primary"
          className="float-left"
          disabled={!showNewSearchButton}
          onClick={handleRestartBookingProcess}
          size="lg"
        >
          <i className="fa fa-arrow-circle-left" /> Regresar a Reservas
        </Button>
      </div>
      <div>
        <Button
          color="primary"
          className="float-right"
          disabled={!hasTickets}
          onClick={handlePrintTickets}
          size="lg"
        >
          <i className="fa fa-print" /> Imprimir
        </Button>
      </div>
    </>
  );
};

MyReservationsPrintedTickets.propTypes = {
  printedTickets: PropTypes.instanceOf(Immutable.Set).isRequired,
  dispatchClearItinerariesForBooking: PropTypes.func.isRequired,
  dispatchClearSeatsForBooking: PropTypes.func.isRequired,
  disptachPostSendTicketByEmailSMSToCustomer: PropTypes.func.isRequired,
  dispatchPush: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchClearItinerariesForBooking: clearItinerariesForBooking,
  dispatchClearSeatsForBooking: clearSeatsForBooking,
  disptachPostSendTicketByEmailSMSToCustomer:
    postSendTicketByEmailSMSToCustomer,
  dispatchPush: push,
};

export default connect(null, mapDispatchToProps)(MyReservationsPrintedTickets);
