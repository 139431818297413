import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import EditButton from '../../../common/button/EditButton';
import { DELIVERY_BUSINESS_PATH } from '../../../../config/paths';

const DeliveryBusinessToolbar = ({ deliveryBusinessId }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <EditButton
        path={`${DELIVERY_BUSINESS_PATH}/${deliveryBusinessId}/edit`}
      />{' '}
    </div>
  </ButtonToolbar>
);

DeliveryBusinessToolbar.propTypes = {
  deliveryBusinessId: PropTypes.number.isRequired,
};

export default DeliveryBusinessToolbar;
