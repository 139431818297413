import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, FormGroup } from 'reactstrap';
import { connect } from 'react-redux';
import { change, Field, reduxForm } from 'redux-form';
import {
  DATE_TIME_FORMAT,
  INT_DATE_FORMAT,
  INT_DATE_TIME_FORMAT,
  TIME_FORMAT,
} from '../../../../config/locale';
import { isRequired } from '../../../../utils/validators';
import { timeFormat, tzNormalizeDate } from '../../../../utils/date';
import Loader from '../../../common/Loader';
import FormItem from '../../../common/forms/FormItem';
import TimeInput from '../../../common/forms/input/TimeInput';
import TimetableTemplateEditable from '../itinerary/TimetableTemplateEditable';
import FormFooter from '../../../common/forms/FormFooter';
import DatePicker from '../../../common/forms/input/DatePicker';

export const EditProcessTimetableForm = ({
  onSubmit,
  handleSubmit,
  loading,
  itineraryScheduleList,
  startDate: startDateProp,
  dispatchChange,
}) => {
  const [routeSegmentListState, setRouteSegmentListState] = useState([]);
  const [layoverListState, setLayoverListState] = useState([]);
  const [durationAdjustmentListState, setDurationAdjustmentListState] =
    useState([]);
  const [departureTimeState, setDepartureTimeState] = useState([]);
  const [startDateState, setStartDateState] = useState();

  if (loading) return <Loader />;

  const onHandleSubmit = (formValues) => {
    const newFormValues = { ...formValues };

    newFormValues.itineraryScheduleSegmentList = routeSegmentListState;

    const departureTimes = Object.keys(newFormValues).filter((key) =>
      key.startsWith('departureTime'),
    );

    const newItineraryScheduleList = departureTimes.map((key, index) => ({
      departureTime: tzNormalizeDate({
        date: newFormValues[key],
      }),
      circuitId: itineraryScheduleList[index].circuitId,
      id: itineraryScheduleList[index].id,
      itinerarySchedulePricingProfiles:
        itineraryScheduleList[index].itinerarySchedulePricingProfiles,
      itineraryScheduleSegmentList:
        newFormValues.itineraryScheduleSegmentList[index],
      companyId: itineraryScheduleList[index].companyId,
    }));

    onSubmit(newItineraryScheduleList);
  };

  const generateItineraryScheduleValues = ({
    departureTime,
    itineraryScheduleSegmentListProcess,
    itineraryCode,
    startDate,
    endDate,
  }) => {
    const itineraryScheduleValues = {
      itineraryCode,
    };

    itineraryScheduleValues.departureTime = tzNormalizeDate({
      date: departureTime,
    });

    itineraryScheduleValues.startDate = tzNormalizeDate({
      date: startDate,
    });

    itineraryScheduleValues.endDate = tzNormalizeDate({ date: endDate });

    itineraryScheduleValues.routeSegmentList =
      itineraryScheduleSegmentListProcess;

    const durationAdjustmentList = [];

    const layoverList = [];

    itineraryScheduleSegmentListProcess.forEach(
      (
        { segmentId, segmentAdjustment, duration, startOffset },
        index,
        array,
      ) => {
        durationAdjustmentList[segmentId] = segmentAdjustment;

        if (array[index + 1]) {
          const { startOffset: nextStartOffset } = array[index + 1];

          const currentLayover =
            nextStartOffset - segmentAdjustment - duration - startOffset;

          layoverList[segmentId] = currentLayover;
        }
      },
    );

    itineraryScheduleValues.durationAdjustmentList = durationAdjustmentList;

    itineraryScheduleValues.layoverList = layoverList;

    return itineraryScheduleValues;
  };

  const generateTimetableData = (
    routeSegmentList,
    departureTime,
    startDate,
    durationAdjustmentList,
    layoverList,
    index,
  ) => {
    const timetableData = [];

    if (!routeSegmentList) {
      routeSegmentListState[index] = timetableData;
      setRouteSegmentListState(routeSegmentListState);
      return;
    }

    const formattedDepartureTime = tzNormalizeDate({
      date: departureTime,
      format: TIME_FORMAT,
    });
    const formattedStartDate = tzNormalizeDate({
      date: startDate,
      format: INT_DATE_FORMAT,
    });

    let lastArrivalTimeWithoutFormat = null;
    let lastDurationAdjustment = 0;
    let lastLayover = 0;

    routeSegmentList.forEach(
      (
        { segmentId, sourceLocationName, destinationLocationName, duration },
        routeIndex,
        array,
      ) => {
        const segmentAdjustment = durationAdjustmentList[segmentId]
          ? Number(durationAdjustmentList[segmentId])
          : 0;
        const layover = layoverList[segmentId]
          ? Number(layoverList[segmentId])
          : 0;

        const timetable = {
          segmentId,
          sourceLocationName,
          destinationLocationName,
          duration: timeFormat(duration),
          segmentAdjustment,
          layover,
          startOffset: '-',
          departureTime: '-',
          arrivalTime: '-',
        };

        if (startDate && departureTime) {
          if (routeIndex === 0) {
            timetable.startOffset = 0;
            timetable.departureTimeWithoutFormat = `${formattedStartDate} ${formattedDepartureTime}`;
          } else {
            const { duration: lastDuration } = array[routeIndex - 1];
            const { startOffset: lastStartOffset } =
              timetableData[routeIndex - 1];
            timetable.startOffset =
              lastStartOffset +
              lastDuration +
              lastDurationAdjustment +
              lastLayover;
            timetable.departureTimeWithoutFormat = lastArrivalTimeWithoutFormat;
          }
          timetable.departureTime = tzNormalizeDate({
            date: timetable.departureTimeWithoutFormat,
          });

          timetable.departureTimeFormatted = tzNormalizeDate({
            date: timetable.departureTimeWithoutFormat,

            format: DATE_TIME_FORMAT,
          });

          timetable.arrivalTimeWithoutFormat = tzNormalizeDate({
            date: timetable.departureTimeWithoutFormat,
            addTime: {
              amount: duration + segmentAdjustment + layover,
              unit: 'minutes',
            },
            format: INT_DATE_TIME_FORMAT,
          });

          timetable.arrivalTime = tzNormalizeDate({
            date: timetable.arrivalTimeWithoutFormat,
          });

          timetable.arrivalTimeFormatted = tzNormalizeDate({
            date: timetable.arrivalTimeWithoutFormat,
            format: DATE_TIME_FORMAT,
          });

          lastArrivalTimeWithoutFormat = timetable.arrivalTimeWithoutFormat;
          lastDurationAdjustment = segmentAdjustment;
          lastLayover = layover;
        }

        timetableData.push(timetable);
      },
    );

    routeSegmentListState[index] = timetableData;
    setRouteSegmentListState(routeSegmentListState);
  };

  useEffect(() => {
    if (itineraryScheduleList) {
      dispatchChange('EditProcessTimetableForm', `startDate`, startDateProp);
      itineraryScheduleList.map((itinerarySchedule, index) => {
        const currentItinerarySchedule =
          generateItineraryScheduleValues(itinerarySchedule);
        generateTimetableData(
          currentItinerarySchedule.routeSegmentList,
          currentItinerarySchedule.departureTime,
          startDateProp,
          currentItinerarySchedule.durationAdjustmentList,
          currentItinerarySchedule.layoverList,
          index,
        );
        departureTimeState[index] = currentItinerarySchedule.departureTime;
        durationAdjustmentListState[index] =
          currentItinerarySchedule.durationAdjustmentList;
        layoverListState[index] = currentItinerarySchedule.layoverList;

        dispatchChange(
          'EditProcessTimetableForm',
          `departureTime${index}`,
          departureTimeState[index],
        );
        setStartDateState(startDateProp);
        setDepartureTimeState(departureTimeState);
        setDurationAdjustmentListState(durationAdjustmentListState);
        setLayoverListState(layoverListState);
        return currentItinerarySchedule;
      });
    }
  }, []);

  const onValueChangeDurationAdjustment = ({ id, value, index }) => {
    const newDurationAdjustmentList = [...durationAdjustmentListState];
    newDurationAdjustmentList[index][id] = value;

    setDurationAdjustmentListState(newDurationAdjustmentList);

    generateTimetableData(
      itineraryScheduleList[index].itineraryScheduleSegmentListProcess,
      departureTimeState[index],
      itineraryScheduleList[index].startDate,
      durationAdjustmentListState[index],
      layoverListState[index],
      index,
    );
  };

  const onValueChangeLayover = ({ id, value, index }) => {
    const newLayoverList = [...layoverListState];
    newLayoverList[index][id] = value;

    setLayoverListState(newLayoverList);

    generateTimetableData(
      itineraryScheduleList[index].itineraryScheduleSegmentListProcess,
      departureTimeState[index],
      itineraryScheduleList[index].startDate,
      durationAdjustmentListState[index],
      layoverListState[index],
      index,
    );
  };

  const onChangeDepartureTime = (departureTime, index) => {
    dispatchChange(
      'EditProcessTimetableForm',
      `departureTime${index}`,
      departureTime,
    );
    departureTimeState[index] = departureTime;

    setDepartureTimeState(departureTimeState);

    generateTimetableData(
      itineraryScheduleList[index].itineraryScheduleSegmentListProcess,
      departureTimeState[index],
      startDateState,
      durationAdjustmentListState[index],
      layoverListState[index],
      index,
    );
  };

  const adjustTime = (date, time) => {
    const departureTimeDate = new Date(time);
    const hours = departureTimeDate.getHours();
    const minutes = departureTimeDate.getMinutes();
    const seconds = departureTimeDate.getSeconds();

    const newDepartureTime = new Date(date);
    newDepartureTime.setHours(hours);
    newDepartureTime.setMinutes(minutes);
    newDepartureTime.setSeconds(seconds);
    return newDepartureTime;
  };

  const onChangeStartDate = (startDate) => {
    dispatchChange('EditProcessTimetableForm', `startDate`, startDate);
    setStartDateState(startDate);

    itineraryScheduleList.forEach((_, index) => {
      departureTimeState[index] = adjustTime(
        startDate,
        departureTimeState[index],
      );

      dispatchChange(
        'EditProcessTimetableForm',
        `departureTime${index}`,
        departureTimeState[index],
      );

      setDepartureTimeState(departureTimeState);

      generateTimetableData(
        itineraryScheduleList[index].itineraryScheduleSegmentListProcess,
        departureTimeState[index],
        startDate,
        durationAdjustmentListState[index],
        layoverListState[index],
        index,
      );
    });
  };

  return (
    <Form onSubmit={handleSubmit(onHandleSubmit)}>
      <FormGroup row>
        <FormItem label="Fecha de Salida" required>
          <Field
            name="startDate"
            component={DatePicker}
            input={{
              onChange: (startDate) => onChangeStartDate(startDate),
              value: startDateState,
            }}
            validate={[isRequired]}
            min={tzNormalizeDate()}
          />
        </FormItem>
      </FormGroup>
      {itineraryScheduleList.map((itinerarySchedule, index) => (
        <div key={itinerarySchedule.itineraryCode} className="mt-3">
          <h3>Horario de la ruta {itinerarySchedule.routeName}</h3>
          <FormGroup row>
            <FormItem label="Hora de Salida" required>
              <Field
                name={`departureTime${index}`}
                component={TimeInput}
                input={{
                  onChange: (departureTime) =>
                    onChangeDepartureTime(departureTime, index),
                  value: departureTimeState[index],
                }}
                validate={[isRequired]}
              />
            </FormItem>
          </FormGroup>
          <TimetableTemplateEditable
            routeSegmentList={routeSegmentListState[index]}
            onValueChangeLayover={(args) =>
              onValueChangeLayover({ ...args, index })
            }
            onValueChangeDurationAdjustment={(args) =>
              onValueChangeDurationAdjustment({ ...args, index })
            }
            setInitialValues={
              !!itinerarySchedule.itineraryScheduleSegmentListProcess
            }
          />
        </div>
      ))}
      <FormFooter />
    </Form>
  );
};

EditProcessTimetableForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  itineraryScheduleList: PropTypes.instanceOf(Array).isRequired,
  startDate: PropTypes.number.isRequired,
  dispatchChange: PropTypes.func.isRequired,
};

const mapStateToProps = ({ ItineraryUnit }) => ({
  loading: !ItineraryUnit.Process.getIn(['current', 'activity']).isEmpty(),
});

const mapDispatchToProps = {
  dispatchChange: change,
};

const formComponent = reduxForm({
  form: 'EditProcessTimetableForm',
})(EditProcessTimetableForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
