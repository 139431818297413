import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import { Container } from 'reactstrap';
import { Route, Switch } from 'react-router-dom';
import {
  clearChildCities,
  clearDestinationParentCities,
  clearOriginParentCities,
  getActiveSalesSession,
} from '../../../../actions';
import Loader from '../../../common/Loader';
import Alert from '../../../common/informative/Alert';
import {
  MY_RESERVATIONS_CONFIRMATION_STEP_PATH,
  MY_RESERVATIONS_PASSENGERS_STEP_PATH,
  MY_RESERVATIONS_SEARCH_STEP_PATH,
} from '../../../../config/paths';
import BookingNavbar from '../../../modules/booking/BookingNavbar';
import Passengers from '../../../modules/booking/passengers/Passengers';
import MyReservationsRoundTrip from './MyReservationsRoundTrip';
import MyReservationsConfirmation from './MyReservationsConfirmation';

const MyReservationsBooking = ({
  currentStep,
  dispatchGetActiveSalesSession,
  gettingActiveSalesSession,
  activeSalesSession,
  dispatchClearOriginParentCities,
  dispatchClearDestinationParentCities,
  dispatchClearChildCities,
  user,
}) => {
  useLayoutEffect(() => {
    if (user) dispatchGetActiveSalesSession({ source: '/booking/search' });
    return () => {
      dispatchClearOriginParentCities();
      dispatchClearDestinationParentCities();
      dispatchClearChildCities();
    };
  }, []);

  if (gettingActiveSalesSession) return <Loader />;

  if (user) {
    if (!user.salesSessionUserId && activeSalesSession.isEmpty()) {
      return (
        <Container>
          <Alert message="No hay ninguna sesión de ventas activa." />
        </Container>
      );
    }
  }

  return (
    <div>
      <Container fluid>
        <h1>Nueva Reserva</h1>
        <BookingNavbar currentStep={currentStep} />
        <Switch>
          <Route
            exact
            path={MY_RESERVATIONS_SEARCH_STEP_PATH}
            component={MyReservationsRoundTrip}
          />
          <Route
            path={MY_RESERVATIONS_PASSENGERS_STEP_PATH}
            component={Passengers}
          />
          <Route
            path={MY_RESERVATIONS_CONFIRMATION_STEP_PATH}
            component={MyReservationsConfirmation}
          />
        </Switch>
      </Container>
    </div>
  );
};

MyReservationsBooking.propTypes = {
  currentStep: PropTypes.number.isRequired,
  dispatchGetActiveSalesSession: PropTypes.func.isRequired,
  dispatchClearOriginParentCities: PropTypes.func.isRequired,
  dispatchClearDestinationParentCities: PropTypes.func.isRequired,
  dispatchClearChildCities: PropTypes.func.isRequired,
  gettingActiveSalesSession: PropTypes.bool.isRequired,
  activeSalesSession: PropTypes.instanceOf(Immutable.Map).isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    securityProfileSet: PropTypes.arrayOf(
      PropTypes.shape({
        viewAuthorizationList: PropTypes.arrayOf(
          PropTypes.shape({
            view: PropTypes.shape({
              route: PropTypes.string,
            }),
          }),
        ).isRequired,
      }),
    ).isRequired,
    salesSessionUserId: PropTypes.number,
  }).isRequired,
};

const mapStateToProps = ({ BookingUnit, SalesUnit, authentication }) => ({
  currentStep: BookingUnit.Booking.get('currentStep'),
  gettingActiveSalesSession: SalesUnit.SalesSession.getIn([
    'active',
    'loading',
  ]),
  activeSalesSession: SalesUnit.SalesSession.getIn(['active', 'content']),
  user: authentication.get('user'),
});

const mapDispatchToProps = {
  dispatchGetActiveSalesSession: getActiveSalesSession,
  dispatchClearOriginParentCities: clearOriginParentCities,
  dispatchClearDestinationParentCities: clearDestinationParentCities,
  dispatchClearChildCities: clearChildCities,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MyReservationsBooking);
