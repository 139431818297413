import React, { useLayoutEffect } from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { Form } from 'reactstrap';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import {
  clearCargoCorporateInvoices,
  getCargoCorporateInvoices,
} from '../../../../actions/cargo/CargoCorporateCredit';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import SelectionableTable from '../../../common/forms/table/SelectionableTable';
import { CARGO_CORPORATE_CREDIT_UNIFY_INVOICES_COLUMNS } from '../../../../config/columns';
import FormFooter from '../../../common/forms/FormFooter';
import Alert from '../../../common/informative/Alert';
import {
  CARGO_CORPORATE_CREDIT_INVOICES_INFO_MESSAGE,
  CARGO_CORPORATE_CREDIT_RECEIPT_INFO_MESSAGE,
} from '../../../../config/messages';

const CargoCorporateCreditUnifyInvoicesForm = ({
  handleSubmit,
  cargoCorporateCreditId,
  loadingInvoices,
  cargoCorporateInvoices,
  dispatchGetCargoCorporateInvoices,
  dispatchClearCargoCorporateInvoices,
  companyId,
}) => {
  useLayoutEffect(() => {
    dispatchGetCargoCorporateInvoices({ cargoCorporateCreditId });

    return () => {
      dispatchClearCargoCorporateInvoices();
    };
  }, []);

  const message = companyId
    ? CARGO_CORPORATE_CREDIT_INVOICES_INFO_MESSAGE
    : CARGO_CORPORATE_CREDIT_RECEIPT_INFO_MESSAGE;

  return cargoCorporateInvoices.isEmpty() ? (
    <Alert message={message} type="info" />
  ) : (
    <Form onSubmit={handleSubmit}>
      <h5>
        {companyId
          ? 'Seleccione las facturas que desea unificar'
          : 'Seleccione las boletas que desea unificar'}
      </h5>

      <SelectionableTable
        name="parcelIds"
        columns={CARGO_CORPORATE_CREDIT_UNIFY_INVOICES_COLUMNS}
        data={cargoCorporateInvoices.toJS()}
        defaultPageSize={DEFAULT_PAGE_SIZE}
        returnOnlySelectedItems
        keyField="id"
        loading={loadingInvoices}
        form="CargoCorporateCreditUnifyInvoicesForm"
      />
      <FormFooter />
    </Form>
  );
};

CargoCorporateCreditUnifyInvoicesForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  cargoCorporateCreditId: PropTypes.number.isRequired,
  loadingInvoices: PropTypes.bool,
  cargoCorporateInvoices: PropTypes.instanceOf(Immutable.Set),
  dispatchGetCargoCorporateInvoices: PropTypes.func.isRequired,
  dispatchClearCargoCorporateInvoices: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
};

CargoCorporateCreditUnifyInvoicesForm.defaultProps = {
  cargoCorporateInvoices: Immutable.Set(),
  loadingInvoices: false,
};

const mapStateToProps = ({ CargoUnit }) => ({
  cargoCorporateInvoices: CargoUnit.CargoCorporateCredit.getIn([
    'current',
    'invoices',
  ]),
  loadingInvoices: CargoUnit.CargoCorporateCredit.getIn([
    'current',
    'loadingInvoices',
  ]),
});

const mapDispatchToProps = {
  dispatchGetCargoCorporateInvoices: getCargoCorporateInvoices,
  dispatchClearCargoCorporateInvoices: clearCargoCorporateInvoices,
};

const formComponent = reduxForm({
  form: 'CargoCorporateCreditUnifyInvoicesForm',
})(CargoCorporateCreditUnifyInvoicesForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
