import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Form, reduxForm } from 'redux-form';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import withEndpointAuthorization from '../../../authorization/withEndPointAuthorization';
import { generateDeliveryItemsConfirmReturnEndpoint } from '../../../../../config/endpoints';
import { POST } from '../../../../../config/permissions';
import ConfirmationModal from '../../../../common/modal/ConfirmationModal';
import { patchConfirmReturnItems } from '../../../../../actions';
import SelectionableTable from '../../../../common/forms/table/SelectionableTable';
import { DELIVERY_RETURN_CARGO_ITEMS_COLUMNS } from '../../../../../config/columns';
import { DEFAULT_PAGE_SIZE } from '../../../../../config/constants';
import FormFooter from '../../../../common/forms/FormFooter';

const DeliveryItemsConfirmReturnButton = ({
  items,
  deliveryId,
  handleSubmit,
  dispatchPatchConfirmReturnItems,
}) => {
  const [showModal, setShowModal] = useState(false);

  const onSubmit = (formValues) => {
    const newFormValues = formValues.returnItems.map((item) => ({
      ...item,
      cargoItemId: item.id,
    }));

    dispatchPatchConfirmReturnItems(deliveryId, newFormValues);
  };

  const body = (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <SelectionableTable
          name="returnItems"
          manual={false}
          columns={DELIVERY_RETURN_CARGO_ITEMS_COLUMNS}
          data={items}
          defaultPageSize={DEFAULT_PAGE_SIZE}
          keyField="cargoItemId"
          form="DeliveryItemsConfirmReturnForm"
        />
        <FormFooter saveButtonText="Confirmar" />
      </Form>
    </>
  );

  const openModal = () => setShowModal(true);

  const closeModal = () => setShowModal(false);

  return (
    <>
      <ConfirmationModal
        show={showModal}
        body={body}
        onClickClose={closeModal}
      />
      <Button color="info" onClick={openModal}>
        <i className="fa fa-reply" /> Confirmar retorno
      </Button>
    </>
  );
};

DeliveryItemsConfirmReturnButton.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  deliveryId: PropTypes.number.isRequired,
  items: PropTypes.instanceOf(Array).isRequired,
  dispatchPatchConfirmReturnItems: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchPatchConfirmReturnItems: patchConfirmReturnItems,
};

const formComponent = reduxForm({
  form: 'DeliveryItemsConfirmReturnForm',
})(DeliveryItemsConfirmReturnButton);

const connectedComponent = connect(null, mapDispatchToProps)(formComponent);

export default withEndpointAuthorization({
  url: generateDeliveryItemsConfirmReturnEndpoint(),
  mapUrlParamsToProps: { deliveryId: 'deliveryId' },
  permissionType: POST,
})(connectedComponent);
