import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import EditButton from '../../../common/button/EditButton';
import { DELIVERY_PRICE_CONFIGURATION_PATH } from '../../../../config/paths';
import DeliveryPriceConfigurationDeleteButton from './DeliveryPriceConfigurationDeleteButton';

const DeliveryPriceConfigurationToolbar = ({
  deliveryPriceConfigurationId,
}) => (
  <ButtonToolbar className="pull-right">
    <div>
      <EditButton
        path={`${DELIVERY_PRICE_CONFIGURATION_PATH}/${deliveryPriceConfigurationId}/edit`}
      />{' '}
      <DeliveryPriceConfigurationDeleteButton
        deliveryPriceConfigurationId={deliveryPriceConfigurationId}
      />
    </div>
  </ButtonToolbar>
);

DeliveryPriceConfigurationToolbar.propTypes = {
  deliveryPriceConfigurationId: PropTypes.number.isRequired,
};

export default DeliveryPriceConfigurationToolbar;
