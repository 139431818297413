import { toastr } from 'react-redux-toastr';
import QueryString from 'query-string';
import { push } from 'react-router-redux';
import {
  FLAG_DELIVERY_PRICE_ACTIVITY,
  GET_DELIVERY_PRICES,
  CLEAR_DELIVERY_PRICES,
  GET_DELIVERY_PRICE,
  CLEAR_DELIVERY_PRICE,
  FLAG_GETTING_DELIVERY_PRICES,
} from '../types/cargo/DeliveryPrice';
import { isErrorResponse } from '../../utils/error-handlers';
import { DELIVERY_PRICE_ENDPOINT } from '../../config/endpoints';
import { BASIC_POST_CONFIG, DEFAULT_GET_CONFIG } from '../../config/rest';
import { DELIVERY_PRICE_PATH } from '../../config/paths';
import { DELIVERY_TEMPLATE_FILENAME } from '../../config/constants';

const flagGettingDeliveryPrices = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_DELIVERY_PRICES,
    payload: flag,
  });

const getDeliveryPrices =
  async (tableFilters = null) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingDeliveryPrices(true));
      const query = tableFilters;
      const url = `${DELIVERY_PRICE_ENDPOINT}?${QueryString.stringify(query)}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      await isErrorResponse(response);
      const deliveryPrices = await response.json();
      dispatch({ type: GET_DELIVERY_PRICES, payload: deliveryPrices });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagGettingDeliveryPrices(false));
    }
  };

const clearDeliveryPrices = () => (dispatch) =>
  dispatch({
    type: CLEAR_DELIVERY_PRICES,
  });

const flagDeliveryPriceActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_DELIVERY_PRICE_ACTIVITY,
    payload: flag,
  });

const getDeliveryPrice =
  async ({ deliveryPriceId }) =>
  async (dispatch) => {
    try {
      dispatch(flagDeliveryPriceActivity(true));
      const url = `${DELIVERY_PRICE_ENDPOINT}/${deliveryPriceId}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      await isErrorResponse(response);
      const deliveryPrice = await response.json();
      dispatch({ type: GET_DELIVERY_PRICE, payload: deliveryPrice });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagDeliveryPriceActivity(false));
    }
  };

const clearDeliveryPrice = () => (dispatch) =>
  dispatch({
    type: CLEAR_DELIVERY_PRICE,
  });

const postDeliveryPrice =
  async ({ description, file }) =>
  async (dispatch) => {
    try {
      dispatch(flagDeliveryPriceActivity(true));
      const url = `${DELIVERY_PRICE_ENDPOINT}/import-data`;
      const formData = new FormData();
      formData.append('file', file);
      formData.append('description', description || '');
      const response = await fetch(url, {
        ...BASIC_POST_CONFIG,
        body: formData,
      });
      await isErrorResponse(response);
      const deliveryPrice = await response.json();
      dispatch(push(`${DELIVERY_PRICE_PATH}/${deliveryPrice.id}`));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagDeliveryPriceActivity(false));
    }
  };

const getExcelTemplate = async () => async (dispatch) => {
  try {
    dispatch(flagDeliveryPriceActivity(true));
    const url = `${DELIVERY_PRICE_ENDPOINT}/template`;
    const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
    await isErrorResponse(response);
    const blob = await response.blob();
    const downloadUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = DELIVERY_TEMPLATE_FILENAME;
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    toastr.error('Error', error.message);
  } finally {
    dispatch(flagDeliveryPriceActivity(false));
  }
};

export {
  flagGettingDeliveryPrices,
  getDeliveryPrices,
  clearDeliveryPrices,
  getDeliveryPrice,
  clearDeliveryPrice,
  postDeliveryPrice,
  getExcelTemplate,
};
