import autoTable from 'jspdf-autotable';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '../../config/locale';
import { tzNormalizeDate } from '../date';
import { numberFormatter } from '../number';
import {
  addQrCode,
  addText,
  createPdfDocument,
  paragraphSize,
  setFont,
  y,
} from '../pdf';
import { padStart } from '../string';
import {
  PARCEL_PAYMENT_STATUS,
  PAYMENT_METHOD_PAGO_EN_DESTINO,
  TRANSACTION_TYPE_BUSINESS_CREDIT,
} from '../../config/constants';

const myX = 3;
let myY = y;
const margin = 3;
let verticalOffset = 0;
let isFirstPrint = true;
const preFooter =
  'Autorizado mediante Resolución de Intendencia: Número 0180050002242/SUNAT Para mayor información sobre todos los términos y condiciones puede ingresar a nuestra página web: http://www.civa.com.pe';

const footer =
  'Al recibir el presente DOCUMENTO, acepto todos los Terminos y Condiciones del Contrato del servicio de Transporte detallado en el letrero, banner y/o Panel a la vista ubicados en el counter de ventas al momento de la compra, los cuales también se encuentran publicados en la página WEB www.civa.com.pe';

const postFooter =
  'En calidad de remitente, declaro que el contenido de la encomienda no es ilegal, ni es para comercio. En caso contrario me someto a las acciones legales que realice la empresa y/o tercero afectado.';
const qrWidth = 35;
const qrHeight = 35;

const printLine = (doc, factor = 1) => {
  addText(
    doc,
    '----------------------------------------------------------------------------',
    40,
    (myY += margin * factor),
    80,
  );
};

const addSpace = (doc, text, maxWidht = 55) => {
  const destinyDirectionText = doc.splitTextToSize(`: ${text}`, maxWidht);
  if (destinyDirectionText.length >= 2) {
    myY += margin * (destinyDirectionText.length - 1);
  }
};

const printCompanyFields = (doc) => {
  setFont(doc, 'bold');
  doc.setFontSize(paragraphSize);
  addText(doc, 'TURISMO CIVA S.A.C.', 40, myY, 80);
  setFont(doc);
  addText(doc, 'Si va de viaje, ¡viaje en Civa!', 40, (myY += margin), 80);
  addText(doc, 'R.U.C 20102427891', 40, (myY += margin), 80);
  addText(doc, 'JR SANCHO DE RIVERA 1184', 40, (myY += margin), 80);
  addText(doc, 'URB. MONSERRATE LIMA', 40, (myY += margin), 80);
  addText(doc, 'Central Telefónica: (511) 418-1111', 40, (myY += margin), 80);
  printLine(doc);
};

const printTicketFields = (
  doc,
  { issuingAgency, createDate, userName, voucherType, ticketCode },
) => {
  const agencyName = doc.splitTextToSize(
    `Agencia de Emisión: ${issuingAgency}`,
    75,
  );
  addText(doc, agencyName, myX, (myY += margin), 75, 'left');
  addText(
    doc,
    `Fecha/Hora Emisión: ${createDate}`,
    myX,
    (myY += margin * 2),
    75,
    'left',
  );
  addText(doc, 'Usuario:', myX, (myY += margin), 75, 'left');
  addText(doc, ':', myX + 34, myY);
  const newUserName = doc.splitTextToSize(userName, 75);
  addText(doc, newUserName, myX + 11, myY, 80, 'left');
  addSpace(doc, userName, 80);
  setFont(doc, 'bold');
  doc.setFontSize(14);
  myY += 2;

  const voucherText = doc.splitTextToSize(voucherType.toUpperCase(), 75);
  addText(doc, voucherText, 40, (myY += margin), 75);

  addText(doc, ticketCode, 40, (myY += 5.5 * voucherText.length), 75);
  doc.setFontSize(paragraphSize);
  setFont(doc);
  printLine(doc);
};

const printShipmentField = (
  doc,
  {
    origin,
    sourceAgencyCode,
    destiny,
    destinationAgencyCode,
    destinyDirection,
    deliveryAddress,
    deliveryDistrict,
  },
) => {
  setFont(doc, 'bold');
  addText(doc, 'DATOS DEL ENVIO', myX, (myY += margin * 2), 75, 'left');

  addText(doc, 'Origen', myX, (myY += margin), 30, 'left');
  addText(doc, `: ${origin}`, 23, myY, 55, 'left');

  addText(doc, 'Cod. de Establecimiento', myX, (myY += margin), 35, 'left');
  setFont(doc, 'bold');
  addText(doc, `: ${sourceAgencyCode || ''}`, 37, myY, 42, 'left');

  setFont(doc, 'bold');
  addText(doc, 'Destino', myX, (myY += margin), 30, 'left');
  addText(doc, `: ${destiny}`, 23, myY, 55, 'left');

  addText(doc, 'Cod. de Establecimiento', myX, (myY += margin), 35, 'left');
  setFont(doc, 'bold');
  addText(doc, `: ${destinationAgencyCode || ''}`, 37, myY, 42, 'left');

  addText(doc, 'Dirección', myX, (myY += margin), 30, 'left');
  setFont(doc, 'bold');
  addText(doc, `: ${destinyDirection}`, 23, myY, 55, 'left');

  let deliveryAddressText = [];

  if (deliveryAddress) {
    deliveryAddressText = doc.splitTextToSize(
      `Dirección de Reparto: ${deliveryAddress}`,
      75,
    );
    addText(doc, deliveryAddressText, myX, (myY += margin), 80, 'left');
  }

  let deliveryDistrictText = [];

  if (deliveryDistrict) {
    deliveryDistrictText = doc.splitTextToSize(
      `Distrito de Reparto: ${deliveryDistrict}`,
      75,
    );
    addText(
      doc,
      deliveryDistrictText,
      myX,
      (myY += margin * deliveryAddressText.length),
      80,
      'left',
    );
    myY += margin * (deliveryDistrictText.length - 1);
  }

  addSpace(doc, destinyDirection);

  doc.setFontSize(paragraphSize);
  setFont(doc);
  printLine(doc);
};

const printSenderField = (
  doc,
  { senderName, senderDocumentType, senderDocumentNumber },
) => {
  setFont(doc, 'bold');
  addText(doc, 'DATOS DEL REMITENTE', myX, (myY += margin * 2), 75, 'left');

  const senderNameText = doc.splitTextToSize(`Nombre: ${senderName}`, 75);

  addText(doc, senderNameText, myX, (myY += margin), 80, 'left');

  addText(
    doc,
    'Tipo de Documento',
    myX,
    (myY += margin * senderNameText.length),
    30,
    'left',
  );
  addText(doc, `: ${senderDocumentType}`, 31, myY, 55, 'left');

  addText(doc, 'Número de Documento', myX, (myY += margin), 35, 'left');
  addText(doc, `: ${senderDocumentNumber}`, 36, myY, 55, 'left');

  doc.setFontSize(paragraphSize);
  setFont(doc);
  printLine(doc);
};

const printConsignedField = (
  doc,
  { consignedName, consignedDocumentType, consignedDocumentNumber },
) => {
  setFont(doc, 'bold');
  addText(doc, 'DATOS DEL CONSIGNADO', myX, (myY += margin * 2), 75, 'left');

  const consignedNameText = doc.splitTextToSize(`Nombre: ${consignedName}`, 75);

  addText(doc, consignedNameText, myX, (myY += margin), 80, 'left');

  addText(
    doc,
    'Tipo de Documento',
    myX,
    (myY += margin * consignedNameText.length),
    30,
    'left',
  );
  addText(doc, `: ${consignedDocumentType}`, 31, myY, 55, 'left');

  addText(doc, 'Número de Documento', myX, (myY += margin), 35, 'left');
  addText(doc, `: ${consignedDocumentNumber}`, 36, myY, 55, 'left');
  doc.setFontSize(paragraphSize);
  setFont(doc);
  printLine(doc);
};

const printSecondaryConsignedField = (
  doc,
  {
    secondaryConsignedName,
    secondaryConsignedDocumentType,
    secondaryConsignedDocumentNumber,
  },
) => {
  setFont(doc, 'bold');
  addText(
    doc,
    'DATOS DEL CONSIGNADO SECUNDARIO',
    myX,
    (myY += margin * 2),
    75,
    'left',
  );

  const consignedNameText = doc.splitTextToSize(
    `Nombre: ${secondaryConsignedName}`,
    75,
  );

  addText(doc, consignedNameText, myX, (myY += margin), 80, 'left');

  addText(
    doc,
    'Tipo de Documento',
    myX,
    (myY += margin * consignedNameText.length),
    30,
    'left',
  );
  addText(doc, `: ${secondaryConsignedDocumentType}`, 31, myY, 55, 'left');

  addText(doc, 'Número de Documento', myX, (myY += margin), 35, 'left');
  addText(doc, `: ${secondaryConsignedDocumentNumber}`, 36, myY, 55, 'left');
  doc.setFontSize(paragraphSize);
  setFont(doc);
  printLine(doc);
};
const printCustomerField = (
  doc,
  { customerName, customerDocumentType, customerDocumentNumber },
) => {
  setFont(doc, 'bold');
  addText(doc, 'DATOS DEL CLIENTE', myX, (myY += margin * 2), 75, 'left');

  const customerNameText = doc.splitTextToSize(`Nombre: ${customerName}`, 75);

  addText(doc, customerNameText, myX, (myY += margin), 80, 'left');

  addText(
    doc,
    'Tipo de Documento',
    myX,
    (myY += margin * customerNameText.length),
    30,
    'left',
  );

  addText(doc, `: ${customerDocumentType}`, 31, myY, 55, 'left');
  addText(doc, 'Número de Documento', myX, (myY += margin), 35, 'left');
  addText(doc, `: ${customerDocumentNumber}`, 36, myY, 55, 'left');
  doc.setFontSize(paragraphSize);
  setFont(doc);
  printLine(doc);
};
const printBusinessFields = (doc, { businessName, businessTaxId }) => {
  setFont(doc, 'bold');
  addText(doc, 'DATOS DE LA EMPRESA', myX, (myY += margin * 2), 75, 'left');
  addText(doc, 'Nombre', myX, (myY += margin), 30, 'left');
  addText(doc, `: ${businessName}`, 15, myY, 55, 'left');
  addSpace(doc, businessName);
  addText(doc, 'Tipo de Documento', myX, (myY += margin), 30, 'left');
  addText(doc, ': RUC', 31, myY, 55, 'left');
  addText(doc, 'Número de Documento', myX, (myY += margin), 35, 'left');
  addText(doc, `: ${businessTaxId}`, 36, myY, 55, 'left');
  doc.setFontSize(paragraphSize);
  setFont(doc);
  printLine(doc);
};

const groupItemsByTicketIdentification = (cargoItemList) =>
  Object.values(
    cargoItemList.reduce((accumulator, currentItem) => {
      const ticketId = currentItem.ticketIdentificationId;
      const existingItem = accumulator[ticketId] || {
        ...currentItem,
        quantity: 0,
        totalPrice: 0,
      };

      return {
        ...accumulator,
        [ticketId]: {
          ...existingItem,
          quantity: existingItem.quantity + currentItem.quantity,
          totalPrice: existingItem.totalPrice + currentItem.salePrice,
        },
      };
    }, {}),
  );
const printList = (doc, cargoItemList, deliveryPrice) => {
  const MAX_LENGTH = 20;
  const columns = [
    { header: 'Cant.', dataKey: 'quantity' },
    { header: 'Descripción', dataKey: 'description' },
    { header: 'Peso', dataKey: 'weight' },
    { header: 'P. Unitario', dataKey: 'salePrice' },
    { header: 'P. Total', dataKey: 'totalPrice' },
  ];

  const body = cargoItemList.map((item) => [
    item.quantity.toString(),
    item.description.length > MAX_LENGTH
      ? item.description.substring(0, MAX_LENGTH)
      : item.description,
    item.weight.toFixed(2),
    item.salePrice.toFixed(2),
    item.totalPrice.toFixed(2),
  ]);

  if (deliveryPrice) {
    body.push([
      '-',
      'Reparto',
      '-',
      deliveryPrice.toFixed(2),
      deliveryPrice.toFixed(2),
    ]);
  }

  autoTable(doc, {
    columns,
    body,
    headStyles: {
      fillColor: '#fff',
      lineColor: '#000',
      lineWidth: { top: 0.1, bottom: 0.1 },
      halign: 'center',
    },
    startY: myY + margin * 2,
    margin: 4,
    theme: 'plain',
    styles: {
      overflow: 'linebreak',
      cellPadding: 0,
      textColor: 0,
      fontSize: 9,
      halign: 'center',
    },
  });

  // Update myY after printing table
  myY = doc.autoTable.previous.finalY;
};

const sumTotalWeight = (cargoItemList) =>
  cargoItemList.reduce((total, item) => total + item.weight, 0);

const printPaymentFields = (
  doc,
  {
    ticketSalePrice,
    ticketSalePriceString,
    ticketListPrice,
    detractionAmount,
    salePriceWithDetraction,
    discountAmount,
    salePriceWithDiscount,
    salePriceWithDiscountString,
    detractionCode,
    bankAccountNumber,
    detractionRate,
    paymentMethod,
    paymentStatus,
    paymentDueDate,
    comments,
    transactionCode,
  },
) => {
  const isCredit =
    transactionCode === PAYMENT_METHOD_PAGO_EN_DESTINO ||
    transactionCode === TRANSACTION_TYPE_BUSINESS_CREDIT;
  setFont(doc, 'bold');
  addText(doc, 'DATOS DE PAGO:', myX, (myY += margin * 2), 75, 'left');
  setFont(doc);
  addText(doc, 'IGV', myX, (myY += margin), 75, 'left');
  addText(
    doc,
    `: ${numberFormatter({ value: ticketSalePrice - ticketListPrice })}`,
    myX + 34,
    myY,
  );
  addText(doc, 'OP. GRATUITO', myX, (myY += margin), 75, 'left');
  addText(doc, `: ${numberFormatter({ value: 0 })}`, myX + 34, myY);
  addText(doc, 'OP. GRAVADA', myX, (myY += margin), 75, 'left');
  addText(
    doc,
    `: ${numberFormatter({ value: ticketListPrice })}`,
    myX + 34,
    myY,
  );
  if (discountAmount !== null) {
    addText(doc, 'IMPORTE TOTAL', myX, (myY += margin), 75, 'left');
    addText(
      doc,
      `: ${numberFormatter({
        value: ticketSalePrice,
      })}`,
      myX + 34,
      myY,
    );
  }
  addText(doc, 'DESCUENTO', myX, (myY += margin), 75, 'left');
  addText(
    doc,
    `: ${numberFormatter({ value: discountAmount || 0 })}`,
    myX + 34,
    myY,
  );

  setFont(doc, 'bold');

  addText(doc, 'IMPORTE TOTAL A PAGAR', myX, (myY += margin), 75, 'left');
  addText(
    doc,
    `: ${numberFormatter({
      value: salePriceWithDiscount || ticketSalePrice,
    })}`,
    myX + 37,
    myY,
  );
  setFont(doc);
  addText(doc, 'Son:', myX, (myY += margin), 10, 'left');
  const priceString =
    salePriceWithDiscountString != null
      ? salePriceWithDiscountString
      : ticketSalePriceString;
  const newticketSalePriceString = doc.splitTextToSize(priceString, 75);
  addText(doc, newticketSalePriceString, myX + 6, myY, 75, 'left');
  addSpace(doc, newticketSalePriceString, 75);

  const paymentType = isCredit
    ? doc.splitTextToSize('Crédito', 40)
    : doc.splitTextToSize('Contado', 40);

  addText(
    doc,
    'Tipo de Pago',
    myX,
    (myY += margin * paymentType.length),
    75,
    'left',
  );
  addText(doc, ':', myX + 34, myY);
  paymentType.forEach((line, index) => {
    addText(doc, line, myX + 36, myY + index * 10, 40, 'left');
  });

  addText(
    doc,
    'Método de Pago',
    myX,
    (myY += margin * paymentType.length),
    75,
    'left',
  );
  addText(doc, ':', myX + 34, myY);
  const newPaymentMethod = doc.splitTextToSize(paymentMethod, 40);
  newPaymentMethod.forEach((line, index) => {
    addText(doc, line, myX + 36, myY + index * 10, 40, 'left');
  });

  if (isCredit && transactionCode === PAYMENT_METHOD_PAGO_EN_DESTINO) {
    addText(
      doc,
      'Estado de Pago',
      myX,
      (myY += margin * paymentType.length),
      75,
      'left',
    );
    addText(doc, ':', myX + 34, myY);
    const newPaymentStatus = doc.splitTextToSize(
      PARCEL_PAYMENT_STATUS[paymentStatus].label,
      40,
    );
    newPaymentStatus.forEach((line, index) => {
      addText(doc, line, myX + 36, myY + index * 10, 40, 'left');
    });
  }

  addText(
    doc,
    'Número Tarjeta',
    myX,
    (myY += margin * newPaymentMethod.length),
    75,
    'left',
  );
  addText(doc, `: -`, myX + 34, myY);
  addText(doc, 'Número Voucher', myX, (myY += margin), 75, 'left');
  addText(doc, `: -`, myX + 34, myY);
  if (detractionAmount !== null) {
    doc.setFontSize(paragraphSize);
    setFont(doc);
    printLine(doc);
    setFont(doc, 'bold');
    addText(doc, 'DETRACCION:', myX, (myY += margin * 2), 80, 'left');
    setFont(doc);
    addText(doc, 'Código de detracción', myX, (myY += margin), 75, 'left');
    addText(
      doc,
      `: ${numberFormatter({ value: detractionCode, digits: 3 })}`,
      myX + 34,
      myY,
    );
    addText(doc, 'Número de cuenta', myX, (myY += margin), 75, 'left');
    addText(doc, `: ${bankAccountNumber}`, myX + 34, myY);
    addText(doc, 'Porcentaje de detracción', myX, (myY += margin), 75, 'left');
    addText(
      doc,
      `: ${numberFormatter({ value: detractionRate, style: 'percent' })}`,
      myX + 34,
      myY,
    );
    addText(doc, 'Monto de detracción', myX, (myY += margin), 75, 'left');
    addText(
      doc,
      `: ${numberFormatter({ value: detractionAmount })}`,
      myX + 34,
      myY,
    );
    addText(doc, 'IMPORTE NETO A PAGAR', myX, (myY += margin), 75, 'left');
    addText(
      doc,
      `: ${numberFormatter({ value: salePriceWithDetraction })}`,
      myX + 36,
      myY,
    );
    setFont(doc);
  }

  if (paymentDueDate !== null) {
    doc.setFontSize(paragraphSize);
    setFont(doc);
    printLine(doc);
    setFont(doc, 'bold');
    addText(
      doc,
      'FECHA DE VENCIMIENTO DE PAGO:',
      myX,
      (myY += margin * 2),
      80,
      'left',
    );
    setFont(doc);
    addText(doc, 'Importe Neto a Pagar', myX, (myY += margin), 75, 'left');
    addText(
      doc,
      `: ${numberFormatter({
        value: salePriceWithDiscount || ticketSalePrice,
      })}`,
      myX + 34,
      myY,
    );
    addText(doc, 'Fecha de vencimiento', myX, (myY += margin), 75, 'left');
    addText(doc, `: ${paymentDueDate}`, myX + 34, myY);
    addText(doc, 'N° de cuotas', myX, (myY += margin), 75, 'left');
    addText(doc, ': 1', myX + 34, myY);
  }

  if (comments) {
    printLine(doc);
    setFont(doc, 'bold');
    addText(doc, 'Comentarios', myX, (myY += margin), 75, 'left');
    setFont(doc);

    const commentsText = doc.splitTextToSize(comments, 75);
    addText(doc, commentsText, myX, (myY += margin), 75, 'left');

    if (commentsText.length > 2) {
      myY += commentsText.length + margin;
    }
  }

  setFont(doc, 'bold');
  doc.setFontSize(14);
  // TODO Commented line, can be used in the future
  // myY += newUserName.length * margin * 2 + (8 - newUserName.length * margin);
  doc.setFontSize(paragraphSize);
  addText(doc, preFooter, myX, (myY += margin * 2), 74, 'justify');
  myY += 15;
  setFont(doc);
  addText(doc, footer, myX, (myY += margin * 1), 74, 'justify');
  setFont(doc, 'bold');
  addText(doc, postFooter, myX, (myY += margin * 7), 74, 'justify');
};

const addTicket = async (
  doc,
  {
    ticketFields: {
      issuingAgency,
      createDate,
      userName,
      voucherType,
      ticketCode,
    },
    shipmentFields: {
      origin,
      sourceAgencyCode,
      destiny,
      destinationAgencyCode,
      destinyDirection,
      deliveryAddress,
      deliveryDistrict,
    },
    senderFields: { senderName, senderDocumentType, senderDocumentNumber },
    consignedFields: {
      consignedName,
      consignedDocumentType,
      consignedDocumentNumber,
    },
    secondaryConsignedFields: {
      secondaryConsignedName,
      secondaryConsignedDocumentType,
      secondaryConsignedDocumentNumber,
    },
    customerFields: {
      customerName,
      customerDocumentType,
      customerDocumentNumber,
    },
    businessFields: { businessName, businessTaxId },
    paymentFields: {
      ticketSalePrice,
      ticketSalePriceString,
      ticketListPrice,
      detractionAmount,
      salePriceWithDetraction,
      discountAmount,
      salePriceWithDiscount,
      salePriceWithDiscountString,
      detractionCode,
      bankAccountNumber,
      detractionRate,
      paymentMethod,
      paymentStatus,
      paymentDueDate,
      transactionCode,
    },
    cargoItemList,
    deliveryPrice,
    comments,
  },
) => {
  await addQrCode(doc, ticketCode, 22.5, 0, qrWidth, qrHeight);
  myY += 32;
  printCompanyFields(doc);
  printTicketFields(doc, {
    issuingAgency,
    createDate,
    userName,
    voucherType,
    ticketCode,
  });
  printShipmentField(doc, {
    origin,
    sourceAgencyCode,
    destiny,
    destinationAgencyCode,
    destinyDirection,
    deliveryAddress,
    deliveryDistrict,
  });

  printSenderField(doc, {
    senderName,
    senderDocumentType,
    senderDocumentNumber,
  });
  printConsignedField(doc, {
    consignedName,
    consignedDocumentType,
    consignedDocumentNumber,
  });
  if (secondaryConsignedName) {
    printSecondaryConsignedField(doc, {
      secondaryConsignedName,
      secondaryConsignedDocumentType,
      secondaryConsignedDocumentNumber,
    });
  }

  if (customerName) {
    printCustomerField(doc, {
      customerName,
      customerDocumentType,
      customerDocumentNumber,
    });
  } else {
    printBusinessFields(doc, {
      businessName,
      businessTaxId,
    });
  }
  setFont(doc, 'bold');
  addText(doc, 'DATOS DE LA CARGA', myX, (myY += margin * 2), 75, 'left');
  printList(
    doc,
    groupItemsByTicketIdentification(cargoItemList),
    deliveryPrice,
  );

  setFont(doc, 'bold');
  addText(
    doc,
    `CANT .TOTAL: ${cargoItemList.length}`,
    myX,
    (myY += margin * 2),
    75,
    'left',
  );
  addText(
    doc,
    `PESO TOTAL: ${sumTotalWeight(cargoItemList).toFixed(2)}`,
    myX,
    (myY += margin),
    75,
    'left',
  );

  doc.setFontSize(paragraphSize);
  setFont(doc);
  printLine(doc);

  printPaymentFields(doc, {
    ticketSalePrice,
    ticketSalePriceString,
    ticketListPrice,
    detractionAmount,
    salePriceWithDetraction,
    discountAmount,
    salePriceWithDiscount,
    salePriceWithDiscountString,
    detractionCode,
    bankAccountNumber,
    detractionRate,
    paymentMethod,
    paymentStatus,
    paymentDueDate,
    comments,
    transactionCode,
    // TODO Commented line, can be used in the future
    // userName,
  });

  if (isFirstPrint) {
    verticalOffset = myY;
  }

  doc.addPage('p', 'mm', [80, verticalOffset + 100]);

  myY = 5;

  await addQrCode(doc, ticketCode, 22.5, 0, qrWidth, qrHeight);
  myY += 32;
  printCompanyFields(doc);
  printTicketFields(doc, {
    issuingAgency,
    createDate,
    userName,
    voucherType,
    ticketCode,
  });
  printShipmentField(doc, {
    origin,
    destiny,
    destinyDirection,
    deliveryAddress,
    deliveryDistrict,
  });

  printSenderField(doc, {
    senderName,
    senderDocumentType,
    senderDocumentNumber,
  });
  printConsignedField(doc, {
    consignedName,
    consignedDocumentType,
    consignedDocumentNumber,
  });
  if (secondaryConsignedName) {
    printSecondaryConsignedField(doc, {
      secondaryConsignedName,
      secondaryConsignedDocumentType,
      secondaryConsignedDocumentNumber,
    });
  }
  if (customerName) {
    printCustomerField(doc, {
      customerName,
      customerDocumentType,
      customerDocumentNumber,
    });
  } else {
    printBusinessFields(doc, {
      businessName,
      businessTaxId,
    });
  }
  setFont(doc, 'bold');
  addText(doc, 'DATOS DE LA CARGA', myX, (myY += margin * 2), 75, 'left');
  printList(
    doc,
    groupItemsByTicketIdentification(cargoItemList),
    deliveryPrice,
  );

  setFont(doc, 'bold');
  addText(
    doc,
    `CANT .TOTAL: ${cargoItemList.length}`,
    myX,
    (myY += margin * 2),
    75,
    'left',
  );
  addText(
    doc,
    `PESO TOTAL: ${sumTotalWeight(cargoItemList).toFixed(2)}`,
    myX,
    (myY += margin),
    75,
    'left',
  );

  doc.setFontSize(paragraphSize);
  setFont(doc);
  printLine(doc);

  printPaymentFields(doc, {
    ticketSalePrice,
    ticketSalePriceString,
    ticketListPrice,
    detractionAmount,
    salePriceWithDetraction,
    discountAmount,
    salePriceWithDiscount,
    salePriceWithDiscountString,
    detractionCode,
    bankAccountNumber,
    detractionRate,
    paymentMethod,
    paymentStatus,
    paymentDueDate,
    comments,
    transactionCode,
  });

  myY = 5;
};

const formatTicket = (ticket) => {
  const formatedTicket = {
    ticketFields: {
      issuingAgency: `${ticket.agency.name}\n${ticket.agency.address}`,
      createDate: tzNormalizeDate({
        date: ticket.createDate,
        format: DATE_TIME_FORMAT,
      }),
      userName: ticket.user.customer.fullName,
      voucherType: ticket.voucherTypeName,
      ticketCode: `${ticket.documentSeries}-${padStart(
        ticket.documentCode,
        7,
      )}`,
    },
    shipmentFields: {
      origin: ticket.sourceLocationName,
      sourceAgencyCode: ticket.sourceAgencyCode,
      destiny: ticket.destinationLocationName,
      destinationAgencyCode: ticket.destinationAgencyCode,
      destinyDirection: ticket.destinationAddress,
      deliveryAddress: ticket.deliveryAddress,
      deliveryDistrict: ticket.deliveryDistrict,
    },
    senderFields: {
      senderName: ticket.senderCustomer.fullName,
      senderDocumentType: ticket.senderCustomer.identificationType.name,
      senderDocumentNumber: ticket.senderCustomer.idDocumentNumber,
    },
    consignedFields: {
      consignedName: ticket.consigneeCustomer.fullName,
      consignedDocumentType: ticket.consigneeCustomer.identificationType.name,
      consignedDocumentNumber: ticket.consigneeCustomer.idDocumentNumber,
    },
    secondaryConsignedFields: {
      secondaryConsignedName: ticket.secondaryConsigneeCustomer
        ? ticket.secondaryConsigneeCustomer.fullName
        : undefined,
      secondaryConsignedDocumentType: ticket.secondaryConsigneeCustomer
        ? ticket.secondaryConsigneeCustomer.identificationType.name
        : undefined,
      secondaryConsignedDocumentNumber: ticket.secondaryConsigneeCustomer
        ? ticket.secondaryConsigneeCustomer.idDocumentNumber
        : undefined,
    },
    customerFields: {
      customerName: ticket.customer ? ticket.customer.fullName : undefined,
      customerDocumentType: ticket.customer
        ? ticket.customer.identificationType.name
        : undefined,
      customerDocumentNumber: ticket.customer
        ? ticket.customer.idDocumentNumber
        : undefined,
    },
    businessFields: {
      businessName: ticket.business ? ticket.business.name : undefined,
      businessTaxId: ticket.business
        ? ticket.business.businessTaxId
        : undefined,
    },
    paymentFields: {
      ticketSalePrice: ticket.salePrice,
      ticketSalePriceString: ticket.salePriceString,
      ticketListPrice: ticket.listPrice,
      detractionAmount: ticket.detractionAmount,
      salePriceWithDetraction: ticket.salePriceWithDetraction,
      detractionCode: ticket.detractionCode,
      discountAmount: ticket.discountAmount,
      salePriceWithDiscountString: ticket.salePriceWithDiscountString,
      salePriceWithDiscount: ticket.salePriceWithDiscount,
      bankAccountNumber: ticket.bankAccountNumber,
      detractionRate: ticket.detractionRate,
      paymentMethod: ticket.paymentMethodName,
      paymentStatus: ticket.paymentStatus,
      paymentDueDate:
        ticket.paymentDueDate != null
          ? tzNormalizeDate({
              date: ticket.paymentDueDate,
              format: DATE_FORMAT,
            })
          : null,
      transactionCode: ticket.transactionCode,
    },
    cargoItemList: ticket.cargoItemList,
    deliveryPrice: ticket.deliveryPrice,
    comments: ticket.comments,
  };

  return formatedTicket;
};

export default async function printVoucherCargo(ticket) {
  const doc = createPdfDocument('p', 'mm', [80, 1000]); // Create the document with an initial size
  // Call addTicket to update verticalOffset
  const formatedTicket = formatTicket(ticket);
  await addTicket(doc, formatedTicket);

  // Create the resized PDF document
  const adjustedDoc = createPdfDocument('p', 'mm', [80, verticalOffset + 40]);

  // Call addTicket again with the updated document
  await addTicket(adjustedDoc, formatedTicket);

  isFirstPrint = false;

  adjustedDoc.autoPrint();
  window.open(adjustedDoc.output('bloburl'), '_blank');
}
