import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  clearBookingState,
  selfSearchForItineraries,
} from '../../../../actions/booking';
import { CIVA_BOOKING_SEARCH_STEP_INDEX } from '../../../../config/constants';
import MyReservationsItinerarySearchForm from './MyReservationsItinerarySearchForm';

const MyReservationsItinerarySearch = ({
  mobilizing,
  dispatchSelfSearchForItineraries,
  dispatchClearBookingState,
}) => {
  useLayoutEffect(() => {
    dispatchClearBookingState(CIVA_BOOKING_SEARCH_STEP_INDEX);
  }, []);

  const onSubmit = (formValues) => {
    if (formValues.embarkation) {
      dispatchSelfSearchForItineraries({
        ...formValues,
        destinationCity: formValues.destination.label,
        childLocationId: formValues.embarkation.value,
        processId: formValues.processId,
      });
    }

    if (formValues.desembarkation) {
      dispatchSelfSearchForItineraries({
        ...formValues,
        sourceCity: formValues.source.label,
        childLocationId: formValues.desembarkation.value,
        processId: formValues.processId,
      });
    }
  };

  return (
    <div>
      <MyReservationsItinerarySearchForm
        onSubmit={onSubmit}
        mobilizing={mobilizing}
      />
    </div>
  );
};

const mapDispatchToProps = {
  dispatchSelfSearchForItineraries: selfSearchForItineraries,
  dispatchClearBookingState: clearBookingState,
};

MyReservationsItinerarySearch.propTypes = {
  mobilizing: PropTypes.bool.isRequired,
  dispatchSelfSearchForItineraries: PropTypes.func.isRequired,
  dispatchClearBookingState: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(MyReservationsItinerarySearch);
