import React from 'react';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import cabinCrewBasicInformationPropTypes from '../proptypes/CabinCrewPropTypes';
import LinkToModuleResource from '../../../../common/resource/LinkToModuleResource';
import { CUSTOMER_PATH, ITINERARY_PATH } from '../../../../../config/paths';
import Table from '../../../../common/Table';
import { DEFAULT_PAGE_SIZE } from '../../../../../config/constants';
import { filterMethodCaseInsensitive } from '../../../../../utils/filter-methods';
import {
  CABIN_CREW_CONTRACT_COLUMNS,
  CABIN_CREW_DOCUMENT_COLUMNS,
  CABIN_CREW_ITINERARY_COLUMNS,
} from '../../../../../config/columns';
import Alert from '../../../../common/informative/Alert';

const CabinCrewBasicInformationResource = ({
  customer: { id: customerId, firstName, lastName, idDocumentNumber },
  cabinCrewType: { name: cabinCrewTypeName },
  location: { name: locationName },
  crewStatus: { name: crewStatusName },
  cabinCrewDocumentList,
  cabinCrewContractList,
  loadingTrips,
  cabinCrewTrips,
  dispatchGetCabinCrewTripsTable,
}) => (
  <>
    <h3>Información Básica</h3>
    <ResourceProperty label="ID:">
      <LinkToModuleResource
        text={customerId}
        href={`${CUSTOMER_PATH}/${customerId}`}
        isExternalPath
      />
    </ResourceProperty>
    <ResourceProperty label="Nombres:">{firstName || '-'}</ResourceProperty>
    <ResourceProperty label="Apellidos:">{lastName || '-'}</ResourceProperty>
    <ResourceProperty label="Pasaporte/DNI:">
      {idDocumentNumber || '-'}
    </ResourceProperty>
    <h3>Documentos</h3>
    {cabinCrewDocumentList.length > 0 ? (
      <Table
        columns={CABIN_CREW_DOCUMENT_COLUMNS}
        data={cabinCrewDocumentList}
        defaultPageSize={
          cabinCrewDocumentList.length <= 10
            ? cabinCrewDocumentList.length
            : DEFAULT_PAGE_SIZE
        }
        showPagination={cabinCrewDocumentList.length > 10}
        manual={false}
        filterable
        defaultFilterMethod={filterMethodCaseInsensitive}
      />
    ) : (
      <Alert type="warning" message="No hay documentos para mostrar" />
    )}
    <h3>Estado</h3>
    <ResourceProperty label="Tipo:">
      {cabinCrewTypeName || '-'}
    </ResourceProperty>
    <ResourceProperty label="Base:">{locationName || '-'}</ResourceProperty>
    <ResourceProperty label="Estado:">{crewStatusName || '-'}</ResourceProperty>
    <h3>Contratos</h3>
    {cabinCrewContractList.length > 0 ? (
      <Table
        columns={CABIN_CREW_CONTRACT_COLUMNS}
        data={cabinCrewContractList}
        defaultPageSize={
          cabinCrewContractList.length <= 10
            ? cabinCrewContractList.length
            : DEFAULT_PAGE_SIZE
        }
        showPagination={cabinCrewContractList.length > 10}
        manual={false}
        filterable
        defaultFilterMethod={filterMethodCaseInsensitive}
      />
    ) : (
      <Alert type="warning" message="No hay contratos para mostrar" />
    )}
    <h3>Viajes Recientes</h3>
    <Table
      columns={CABIN_CREW_ITINERARY_COLUMNS}
      data={cabinCrewTrips.get('content') || []}
      pages={cabinCrewTrips.get('totalPages') || null}
      defaultPageSize={cabinCrewTrips.get('size' || DEFAULT_PAGE_SIZE)}
      fetchData={dispatchGetCabinCrewTripsTable}
      modelPath={ITINERARY_PATH}
      loading={loadingTrips}
      navigateToModelOnRowClick
    />
  </>
);

CabinCrewBasicInformationResource.propTypes =
  cabinCrewBasicInformationPropTypes;

export default CabinCrewBasicInformationResource;
