import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { Button } from 'reactstrap';
import printShelfSpace from '../../../../utils/printers/ShelfSpace';

const PrintShelfSpaceButton = ({ shelf }) => {
  const printTicket = () => {
    printShelfSpace(shelf.toJS());
  };

  return (
    <Button color="light" className="border" onClick={printTicket}>
      <i className="fa fa-print" /> Imprimir QR
    </Button>
  );
};

PrintShelfSpaceButton.propTypes = {
  shelf: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.object])
    .isRequired,
};

export default PrintShelfSpaceButton;
