import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { Container } from 'reactstrap';
import { connect } from 'react-redux';
import { getPrintedTickets, clearPrintedTickets } from '../../../../actions';
import Loader from '../../../common/Loader';
import MyReservationsPrintedTickets from './MyReservationsPrintedTickets';

const MyReservationsConfirmation = ({
  loading,
  dispatchClearPrintedTickets,
  dispatchGetPrintedTickets,
  printedTickets,
  reservationId,
}) => {
  useLayoutEffect(() => {
    if (reservationId) {
      dispatchGetPrintedTickets(reservationId);
    }

    return () => {
      dispatchClearPrintedTickets();
    };
  }, []);

  if (loading) return <Loader />;

  return (
    <Container className="Confirmation">
      <h1>Confirmación</h1>
      <MyReservationsPrintedTickets printedTickets={printedTickets} />
    </Container>
  );
};

const mapStateToProps = ({ BookingUnit }) => ({
  printedTickets: BookingUnit.Booking.getIn(['confirmation', 'printedTickets']),
  reservationId: BookingUnit.Booking.getIn(['confirmation', 'reservationId']),
  loading: BookingUnit.Booking.getIn(['confirmation', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetPrintedTickets: getPrintedTickets,
  dispatchClearPrintedTickets: clearPrintedTickets,
};

MyReservationsConfirmation.propTypes = {
  loading: PropTypes.bool,
  dispatchClearPrintedTickets: PropTypes.func.isRequired,
  dispatchGetPrintedTickets: PropTypes.func.isRequired,
  printedTickets: PropTypes.instanceOf(Immutable.Set).isRequired,
  reservationId: PropTypes.number.isRequired,
};

MyReservationsConfirmation.defaultProps = {
  loading: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MyReservationsConfirmation);
