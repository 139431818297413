import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import { DELIVERY_BUSINESS_PATH } from '../../../../config/paths';
import AssignCargoToUserForm from './AssignCargoToUserForm';
import { postDelivery } from '../../../../actions';

const AssignCargoToUser = ({ breadcrumbs, dispatchPostDelivery }) => {
  const onSubmit = (formValues) => {
    if (!formValues.items || formValues.items.length === 0) {
      toastr.error('Error', 'Debe seleccionar al menos 1 encomienda');
      return;
    }
    const payload = {
      businessId: formValues.business.value,
      agencyId: formValues.agency.value,
      userId: formValues.user.value,
      cost: formValues.cost,
      cargoItemIds: formValues.items,
      externalLicensePlate: formValues.externalLicensePlate,
      externalDriverList: formValues.externalDriverList
        ? formValues.externalDriverList.map((driver) => ({
            ...driver,
            documentType: driver.documentType.value,
          }))
        : null,
      frequentVehicle: formValues.frequentVehicle
        ? formValues.frequentVehicle
        : false,
    };

    dispatchPostDelivery(payload);
  };

  const content = <AssignCargoToUserForm onSubmit={onSubmit} />;

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Asignación de Encomiendas"
      subtitle="Asignar encomiendas a usuario"
      content={content}
    />
  );
};

AssignCargoToUser.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostDelivery: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchPostDelivery: postDelivery,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Empresas de Reparto',
      href: DELIVERY_BUSINESS_PATH,
    },
    {
      text: 'Asignación de Encomiendas',
      href: '',
    },
  ],
});

export default connect(mapStateToProps, mapDispatchToProps)(AssignCargoToUser);
