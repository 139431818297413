import React, { useEffect, useLayoutEffect, useState } from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, FormGroup, Label } from 'reactstrap';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import FormItem from '../../../common/forms/FormItem';
import { optionsPropTypes } from '../../../common/forms/select/SelectPropTypes';
import Select from '../../../common/forms/select/Select';
import { isRequired, validateNumber } from '../../../../utils/validators';
import {
  clearAgencies,
  clearCargoItemsToDeliver,
  clearDeliveryBusinesses,
  getCargoItemsToDeliver,
  getDeliveryBusinesses,
  getUserAgencies,
} from '../../../../actions';
import { DEFAULT_QUERY_GET_ALL } from '../../../../config/queries';
import SelectionableTable from '../../../common/forms/table/SelectionableTable';
import { CARGO_ITEMS_TO_DELIVER_COLUMNS } from '../../../../config/columns';
import {
  BILLING_DOCUMENT_TYPE,
  DEFAULT_PAGE_SIZE,
} from '../../../../config/constants';
import { filterMethodCaseInsensitive } from '../../../../utils/filter-methods';
import FormFooter from '../../../common/forms/FormFooter';
import TextInput from '../../../common/forms/input/TextInput';
import DynamicForm from '../../../common/forms/DynamicForm';
import { MANUAL_GRR_DRIVER_COLUMNS } from '../../../../config/dynamicFormFields';
import CargoDeliveryFrequentVehicleSelect from '../../../common/forms/select/CargoDeliveryFrequentVehicleSelect';

const selector = formValueSelector('AssignCargoToUserForm');

export const AssignCargoToUserForm = ({
  change,
  handleSubmit,
  user,
  items,
  agencies,
  loadingItems,
  selectedItems,
  loadingAgencies,
  deliveryBusinesses,
  loadingDeliveryBusiness,
  dispatchGetUserAgencies,
  dispatchGetDeliveryBusinesses,
  dispatchGetCargoItemsToDeliver,
  dispatchClearCargoItemsToDeliver,
  dispatchClearDeliveryBusinesses,
  dispatchClearAgencies,
  selectCargoDeliveryVehicle,
}) => {
  const [subContractedCompany, setSubContractedCompany] = useState(false);

  useLayoutEffect(
    () => () => {
      dispatchClearCargoItemsToDeliver();
      dispatchClearDeliveryBusinesses();
      dispatchClearAgencies();
    },
    [],
  );
  const [userListOptions, setUserListOptions] = useState([]);

  useEffect(() => {
    dispatchGetUserAgencies({ userId: user.id });
  }, [user]);

  const agencyOptions = agencies.map((agency) => ({
    value: agency.id,
    label: agency.name,
    locationId: agency.locationId,
  }));

  const deliveryBusinessOptions = deliveryBusinesses.map(({ business }) => ({
    value: business.id,
    label: `${business.businessTaxId} - ${business.name}`,
  }));

  useEffect(() => {
    if (agencies.length === 0) return;
    change('agency', agencyOptions[0]);
    dispatchGetDeliveryBusinesses({
      ...DEFAULT_QUERY_GET_ALL,
      query: `agencyList.agencyId:${agencyOptions[0].value},active:true`,
    });
    dispatchGetCargoItemsToDeliver(agencyOptions[0].locationId);
  }, [agencies]);

  useEffect(() => {
    if (selectCargoDeliveryVehicle) {
      const updatedDriverList =
        selectCargoDeliveryVehicle.cargoDeliveryVehicleDriverList.map(
          (driver) => ({
            ...driver,
            documentType: BILLING_DOCUMENT_TYPE[driver.documentType], // Transformación del tipo de documento
          }),
        );

      change('externalDriverList', updatedDriverList);
    }
  }, [selectCargoDeliveryVehicle]);

  useEffect(() => {
    if (deliveryBusinesses.length === 0) return;
    change('business', deliveryBusinessOptions[0]);
    const newUserListOptions = deliveryBusinesses[0].userList.map(
      ({ user: deliveryBusinessUser }) => {
        const identificationTypeText = deliveryBusinessUser.customer
          .identificationType
          ? ` | ${deliveryBusinessUser.customer.identificationType.name}: `
          : '';
        const idDocumentNumberText =
          deliveryBusinessUser.customer.idDocumentNumber || '';
        const idCountryOfOriginText = deliveryBusinessUser.customer
          .idCountryOfOrigin
          ? ` (${deliveryBusinessUser.customer.idCountryOfOrigin}) `
          : '';

        return {
          value: deliveryBusinessUser.id,
          label: `${deliveryBusinessUser.customer.fullName}${identificationTypeText}${idDocumentNumberText}${idCountryOfOriginText}`,
        };
      },
    );
    change('user', newUserListOptions[0]);
    setUserListOptions(newUserListOptions);
  }, [deliveryBusinesses]);

  useEffect(() => {
    const itemsToSelect = items
      .toJS()
      .filter((item) => selectedItems.includes(item.id))
      .map((item) => item.id);

    change('items', itemsToSelect);
  }, [items]);

  const onChangeSourceAgency = ({ value, locationId }) => {
    dispatchGetDeliveryBusinesses({
      ...DEFAULT_QUERY_GET_ALL,
      query: `agencyList.agencyId:${value},active:true`,
    });
    dispatchGetCargoItemsToDeliver(locationId);
  };

  const handleSubContractedCompany = (event) => {
    setSubContractedCompany(event.target.checked);
    change('cargoDeliveryFrequentVehicleId', null);
    change('externalDriverList', []);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup row>
        <FormItem label="Agencia Actual" required>
          <Field
            name="agency"
            component={Select}
            isLoading={loadingAgencies}
            options={agencyOptions}
            isDisabled={agencyOptions.length <= 1}
            onChange={onChangeSourceAgency}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Empresa" required>
          <Field
            name="business"
            component={Select}
            isLoading={loadingDeliveryBusiness}
            options={deliveryBusinessOptions}
            isDisabled={deliveryBusinessOptions.length <= 1}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Usuario" required>
          <Field
            name="user"
            component={Select}
            isLoading={false}
            options={userListOptions}
            isDisabled={userListOptions.length <= 1}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Costo">
          <div className="flex">
            <h4 className="mr-2 mb-0 align-self-center">S/.</h4>
            <Field
              name="cost"
              component={TextInput}
              type="text"
              validate={[validateNumber]}
            />
          </div>
        </FormItem>
      </FormGroup>
      <SelectionableTable
        manual={false}
        columns={CARGO_ITEMS_TO_DELIVER_COLUMNS}
        data={items.toJS()}
        loading={loadingItems}
        defaultPageSize={DEFAULT_PAGE_SIZE}
        filterable
        defaultFilterMethod={filterMethodCaseInsensitive}
        returnOnlySelectedItems
        keyField="id"
        form="AssignCargoToUserForm"
        shouldResetSelection
      />
      <h3 className="mt-3">Datos para Guía de Remisión</h3>

      {!subContractedCompany && (
        <FormGroup row>
          <FormItem label="Vehículo Frecuente" required>
            <Field
              name="cargoDeliveryFrequentVehicleId"
              component={CargoDeliveryFrequentVehicleSelect}
              validate={[isRequired]}
              onlyActive
            />
          </FormItem>
        </FormGroup>
      )}

      {subContractedCompany && (
        <>
          <FormGroup row>
            <FormItem label="Placa del Vehículo" required>
              <Field
                name="externalLicensePlate"
                component={TextInput}
                validate={[isRequired]}
              />
            </FormItem>
          </FormGroup>

          <FormGroup row>
            <FormItem label="Acción">
              <Label>
                <Field
                  name="frequentVehicle"
                  component="input"
                  type="checkbox"
                />
                Guardar como Frecuente
              </Label>
            </FormItem>
          </FormGroup>
        </>
      )}

      <h3>Datos de Conductores</h3>
      <Field name="driversError" component={TextInput} type="hidden" />
      <DynamicForm
        title="Conductor"
        name="externalDriverList"
        columns={MANUAL_GRR_DRIVER_COLUMNS}
      />

      <FormGroup row>
        <FormItem label="Acción">
          <Label>
            <Field
              component="input"
              type="checkbox"
              onChange={handleSubContractedCompany}
            />
            Llenar Datos Manualmente
          </Label>
        </FormItem>
      </FormGroup>

      <FormFooter />
    </Form>
  );
};

AssignCargoToUserForm.propTypes = {
  change: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    customer: PropTypes.shape({
      fullName: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  items: PropTypes.instanceOf(Immutable.List).isRequired,
  selectedItems: PropTypes.instanceOf(Array),
  agencies: optionsPropTypes.isRequired,
  deliveryBusinesses: optionsPropTypes.isRequired,
  loadingItems: PropTypes.bool,
  loadingAgencies: PropTypes.bool,
  loadingDeliveryBusiness: PropTypes.bool,
  dispatchGetUserAgencies: PropTypes.func.isRequired,
  dispatchGetDeliveryBusinesses: PropTypes.func.isRequired,
  dispatchGetCargoItemsToDeliver: PropTypes.func.isRequired,
  dispatchClearCargoItemsToDeliver: PropTypes.func.isRequired,
  dispatchClearDeliveryBusinesses: PropTypes.func.isRequired,
  dispatchClearAgencies: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  selectCargoDeliveryVehicle: PropTypes.instanceOf(Object),
};

AssignCargoToUserForm.defaultProps = {
  loadingItems: false,
  selectedItems: [],
  loadingAgencies: false,
  loadingDeliveryBusiness: false,
  selectCargoDeliveryVehicle: null,
};

const mapStateToProps = (state) => ({
  user: state.authentication.get('user'),
  loadingAgencies: state.HumanResourcesUnit.Agency.getIn(['all', 'loading']),
  agencies: state.HumanResourcesUnit.Agency.getIn([
    'all',
    'content',
    'content',
  ]),
  deliveryBusinesses: state.CargoUnit.DeliveryBusiness.getIn([
    'all',
    'content',
    'content',
  ]),
  loadingDeliveryBusiness: state.CargoUnit.DeliveryBusiness.getIn([
    'all',
    'loading',
  ]),
  items: state.CargoUnit.DeliveryBusiness.getIn(['items', 'content']),
  loadingItems: state.CargoUnit.DeliveryBusiness.getIn(['items', 'loading']),
  selectedItems: selector(state, 'items'),
  selectCargoDeliveryVehicle: selector(state, 'cargoDeliveryFrequentVehicleId'),
});

const mapDispatchToProps = {
  dispatchGetCargoItemsToDeliver: getCargoItemsToDeliver,
  dispatchGetDeliveryBusinesses: getDeliveryBusinesses,
  dispatchGetUserAgencies: getUserAgencies,
  dispatchClearCargoItemsToDeliver: clearCargoItemsToDeliver,
  dispatchClearDeliveryBusinesses: clearDeliveryBusinesses,
  dispatchClearAgencies: clearAgencies,
};

const formComponent = reduxForm({
  form: 'AssignCargoToUserForm',
})(AssignCargoToUserForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
