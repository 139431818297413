import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert, Button } from 'reactstrap';
import { generatePrintBillingColumnsDelivery } from '../../../../config/columns';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import Table from '../../../common/Table';
import { getPdfManualSenderRemissionGuide } from '../../../../actions/cargo/ManualSenderRemissionGuide';
import {
  getDelivery,
  obtainBillingDeliveryContingecyGuide,
} from '../../../../actions';
import Loader from '../../../common/Loader';
import printContingencyDeliveryCarrierRemissionGuide from '../../../../utils/printers/ContingencyDeliveryCarrierRemissionGuide';

const DeliveryCarrierRemissionGuide = ({
  dispatchGetDelivery,
  remissionGuideList,
  dispatchGetPdfManualSenderRemissionGuide,
  dispatchObtainBillingDeliveryContingecyGuide,
  deliveryId,
  loading,
}) => {
  const printRemissionGuide = (ticket) => {
    dispatchGetPdfManualSenderRemissionGuide(ticket);
  };

  const printRemissionGuideContingency = async (billingId) => {
    const response = await dispatchObtainBillingDeliveryContingecyGuide({
      billingId,
    });

    if (response) {
      printContingencyDeliveryCarrierRemissionGuide(response);
    }
  };

  const refreshDataDelivery = () => {
    dispatchGetDelivery({ deliveryId });
  };

  if (loading) return <Loader />;

  return (
    <div>
      {remissionGuideList.length !== 0 ? (
        <Table
          columns={generatePrintBillingColumnsDelivery(
            printRemissionGuide,
            printRemissionGuideContingency,
          )}
          data={remissionGuideList}
          showPagination={remissionGuideList.length > 10}
          defaultPageSize={
            remissionGuideList.length > 10
              ? DEFAULT_PAGE_SIZE
              : remissionGuideList.length
          }
        />
      ) : (
        <Alert color="warning">No hay guías de remisión disponibles.</Alert>
      )}
      <Button
        color="primary"
        onClick={() => refreshDataDelivery()}
        className="mt-3"
      >
        Actualizar Estado
      </Button>
    </div>
  );
};

DeliveryCarrierRemissionGuide.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  remissionGuideList: PropTypes.arrayOf(PropTypes.object).isRequired,
  dispatchGetDelivery: PropTypes.func.isRequired,
  dispatchGetPdfManualSenderRemissionGuide: PropTypes.func.isRequired,
  dispatchObtainBillingDeliveryContingecyGuide: PropTypes.func.isRequired,
  deliveryId: PropTypes.number.isRequired,
  loading: PropTypes.bool,
};

DeliveryCarrierRemissionGuide.defaultProps = {
  loading: false,
};

const mapDispatchToProps = {
  dispatchGetPdfManualSenderRemissionGuide: getPdfManualSenderRemissionGuide,
  dispatchGetDelivery: getDelivery,
  dispatchObtainBillingDeliveryContingecyGuide:
    obtainBillingDeliveryContingecyGuide,
};
const mapStateToProps = (state) => ({
  loading: !state.AccountingUnit.Billing.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeliveryCarrierRemissionGuide);
