import React, { Component } from 'react';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import {
  ITINERARY_PRINT_BUTTON,
  ITINERARY_PRINT_DROPDOWN_OPTIONS,
  ITINERARY_PRINT_DROPDOWN_OPTIONS_COMPANY,
} from '../../../../config/constants';
import DropdownButton from '../../../common/button/DropdownButton';
import { printSeatingChart } from '../../../../utils/printers/SeatingChart';
import { printManifest } from '../../../../utils/printers/Manifest';
import {
  getSeatingChart,
  getManifest,
  getBaggageManifest,
} from '../../../../actions';
import { printBaggageManifest } from '../../../../utils/printers/BaggageManifest';
import withEndpointAuthorization from '../../authorization/withEndPointAuthorization';
import { generateGetManifestEndpoint } from '../../../../config/endpoints';
import { GET } from '../../../../config/permissions';
import { MANIFEST_ITINERARY_PATH } from '../../../../config/paths';

class PrintDropdownButton extends Component {
  handleClick = async (value) => {
    const {
      itinerary,
      dispatchGetSeatingChart,
      dispatchGetManifest,
      dispatchGetBaggageManifest,
      user: {
        customer: { fullName },
      },
    } = this.props;

    switch (value) {
      case ITINERARY_PRINT_BUTTON.MANIFEST.value: {
        const manifest = await dispatchGetManifest({
          itineraryId: itinerary.get('id'),
        });
        if (manifest) {
          printManifest(manifest);
        }
        break;
      }
      case ITINERARY_PRINT_BUTTON.SEATING_CHART.value: {
        const seatingChart = await dispatchGetSeatingChart({
          itineraryId: itinerary.get('id'),
        });
        if (seatingChart) {
          printSeatingChart(seatingChart);
        }
        break;
      }
      case ITINERARY_PRINT_BUTTON.BAGGAGE_MANIFEST.value: {
        const manifest = await dispatchGetBaggageManifest({
          itineraryId: itinerary.get('id'),
        });

        if (manifest) {
          manifest.itineraryId = itinerary.get('id');
          manifest.serviceTypeName =
            itinerary.get('serviceTypeName') ||
            itinerary.get('serviceType').name;
          manifest.user = fullName;
          printBaggageManifest(manifest);
        }

        break;
      }
      case ITINERARY_PRINT_BUTTON.CARGO_MANIFEST.value: {
        window.open(
          `${MANIFEST_ITINERARY_PATH}/${itinerary.get('id')}`,
          '_blank',
        );

        break;
      }
      default:
        break;
    }
  };

  render() {
    return (
      <DropdownButton
        title="Imprimir"
        color="secondary"
        options={
          !this.props.isForMine
            ? ITINERARY_PRINT_DROPDOWN_OPTIONS
            : ITINERARY_PRINT_DROPDOWN_OPTIONS_COMPANY
        }
        handleClick={this.handleClick}
      />
    );
  }
}

PrintDropdownButton.propTypes = {
  itinerary: PropTypes.instanceOf(Immutable.Map).isRequired,
  dispatchGetManifest: PropTypes.func.isRequired,
  dispatchGetSeatingChart: PropTypes.func.isRequired,
  dispatchGetBaggageManifest: PropTypes.func.isRequired,
  user: PropTypes.shape({
    customer: PropTypes.shape({
      fullName: PropTypes.string,
    }),
  }).isRequired,
  isForMine: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ ItineraryUnit, authentication }) => ({
  itinerary: ItineraryUnit.Itinerary.getIn(['current', 'content']),
  user: authentication.getIn(['user']),
});

const mapDispatchToProps = {
  dispatchGetSeatingChart: getSeatingChart,
  dispatchGetManifest: getManifest,
  dispatchGetBaggageManifest: getBaggageManifest,
};

const connectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PrintDropdownButton);

export default withEndpointAuthorization({
  url: generateGetManifestEndpoint(0),
  permissionType: GET,
})(connectedComponent);
