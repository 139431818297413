import React from 'react';
import { Route, Switch } from 'react-router-dom';
import TrafficHome from './TrafficHome';
import {
  TRAFFIC_UNIT_PATH,
  EXPECTED_FUEL_CONSUMPTION_PATH,
  BUS_FUEL_GROUP_PATH,
  NEW_BUS_FUEL_GROUP_PATH,
  NEW_EXPECTED_FUEL_CONSUMPTION_PATH,
  FUEL_PROFILE_PATH,
  NEW_FUEL_PROFILE_PATH,
  PURCHASE_ORDER_PATH,
  EXTRAORDINARY_MOVEMENT_CONTROL_PATH,
  NEW_PURCHASE_ORDER_PATH,
  generateExtraordinaryMovementControlEditPath,
  generateEditPurchaseOrderPath,
  FUEL_VOUCHER_PATH,
  NEW_FUEL_VOUCHER_PATH,
  BASE_FUEL_REFILL_PATH,
  DRIVER_BUS_ASSIGNMENT_PATH,
  NEW_DRIVER_BUS_ASSIGNMENT_PATH,
  FUEL_CONSUMPTION_REPORT_PER_BUS_PATH,
  FUEL_CONSUMPTION_REPORT_PER_DRIVER_PATH,
  FUEL_REPORT_PREVIEW_PATH,
  DRIVER_PATH,
  NEW_DRIVER_PATH,
  NEW_BASE_FUEL_REFILL_PATH,
  DRIVER_MOVEMENT_HISTORY_PATH,
  NEW_DRIVER_MOVEMENT_HISTORY_PATH,
  DRIVER_TRIP_HISTORY_PATH,
  CABIN_CREW_PATH,
  NEW_CABIN_CREW_PATH,
} from '../../../config/paths';
import BusFuelGroups from './bus-fuel-group/BusFuelGroups';
import NewBusFuelGroup from './bus-fuel-group/NewBusFuelGroup';
import BusFuelGroup from './bus-fuel-group/BusFuelGroup';
import EditBusFuelGroup from './bus-fuel-group/EditBusFuelGroup';
import ExpectedFuelConsumptions from './expected-fuel-consumption/ExpectedFuelConsumptions';
import ExpectedFuelConsumption from './expected-fuel-consumption/ExpectedFuelConsumption';
import NewExpectedFuelConsumption from './expected-fuel-consumption/NewExpectedFuelConsumption';
import EditExpectedFuelConsumption from './expected-fuel-consumption/EditExpectedFuelConsumption';
import FuelProfiles from './fuel-profile/FuelProfiles';
import NewFuelProfile from './fuel-profile/NewFuelProfile';
import FuelProfile from './fuel-profile/FuelProfile';
import EditFuelProfile from './fuel-profile/EditFuelProfile';
import PurchaseOrders from './purchase-order/PurchaseOrders';
import ExtraordinaryMovementControls from './extraordinary-movement-control/ExtraordinaryMovementControls';
import ExtraordinaryMovementControl from './extraordinary-movement-control/ExtraordinaryMovementControl';
import PurchaseOrder from './purchase-order/PurchaseOrder';
import NewPurchaseOrder from './purchase-order/NewPurchaseOrder';
import EditExtraordinaryMovementControl from './extraordinary-movement-control/EditExtraordinaryMovementControl';
import EditPurchaseOrder from './purchase-order/EditPurchaseOrder';
import FuelVouchers from './fuel-voucher/FuelVouchers';
import FuelVoucher from './fuel-voucher/FuelVoucher';
import NewFuelVoucher from './fuel-voucher/NewFuelVoucher';
import EditFuelVoucher from './fuel-voucher/EditFuelVoucher';
import BaseFuelRefills from './base-fuel-refill/BaseFuelRefills';
import DriverBusAssignments from './driver-bus-assignment/DriverBusAssignments';
import NewDriverBusAssignment from './driver-bus-assignment/NewDriverBusAssignment';
import DriverBusAssignment from './driver-bus-assignment/DriverBusAssignment';
import EditDriverBusAssignment from './driver-bus-assignment/EditDriverBusAssignment';
import FuelConsumptionReportPerBus from './fuel-consumption-report-per-bus/FuelConsumptionReportPerBus';
import FuelConsumptionReportPerDriver from './fuel-consumption-report-per-driver/FuelConsumptionReportPerDriver';
import FuelReportPreview from './fuel-report-preview/FuelReportPreview';
import Drivers from './driver/Drivers';
import NewDriver from './driver/NewDriver';
import Driver from './driver/Driver';
import EditDriver from './driver/EditDriver';
import DriverTrips from './driver/DriverTrips';
import NewBaseFuelRefill from './base-fuel-refill/NewBaseFuelRefill';
import BaseFuelRefill from './base-fuel-refill/BaseFuelRefill';
import EditBaseFuelRefill from './base-fuel-refill/EditBaseFuelRefill';
import DriversMovementHistory from './driver/DriversMovementHistory';
import DriverMovementHistory from './driver/DriverMovementHistory';
import NewDriverMovementHistory from './driver/NewDriverMovementHistory';
import DriversTripHistory from './driver/DriversTripHistory';
import EditDriverMovementHistory from './driver/EditDriverMovementHistory';
import CabinCrews from './cabin-crew/CabinCrews';
import CabinCrew from './cabin-crew/CabinCrew';
import NewCabinCrew from './cabin-crew/NewCabinCrew';
import EditCabinCrew from './cabin-crew/EditCabinCrew';

const TrafficUnit = () => (
  <Switch>
    <Route exact path={TRAFFIC_UNIT_PATH} component={TrafficHome} />
    <Route exact path={BUS_FUEL_GROUP_PATH} component={BusFuelGroups} />
    <Route
      exact
      path={DRIVER_BUS_ASSIGNMENT_PATH}
      component={DriverBusAssignments}
    />
    <Route
      exact
      path={NEW_DRIVER_BUS_ASSIGNMENT_PATH}
      component={NewDriverBusAssignment}
    />
    <Route
      exact
      path={`${DRIVER_BUS_ASSIGNMENT_PATH}/:id`}
      component={DriverBusAssignment}
    />
    <Route
      exact
      path={`${DRIVER_BUS_ASSIGNMENT_PATH}/:id/:edit`}
      component={EditDriverBusAssignment}
    />
    <Route exact path={NEW_BUS_FUEL_GROUP_PATH} component={NewBusFuelGroup} />
    <Route exact path={`${BUS_FUEL_GROUP_PATH}/:id`} component={BusFuelGroup} />
    <Route
      exact
      path={`${BUS_FUEL_GROUP_PATH}/:id/edit`}
      component={EditBusFuelGroup}
    />
    <Route
      exact
      path={EXPECTED_FUEL_CONSUMPTION_PATH}
      component={ExpectedFuelConsumptions}
    />
    <Route
      exact
      path={NEW_EXPECTED_FUEL_CONSUMPTION_PATH}
      component={NewExpectedFuelConsumption}
    />
    <Route
      exact
      path={`${EXPECTED_FUEL_CONSUMPTION_PATH}/:id`}
      component={ExpectedFuelConsumption}
    />
    <Route
      exact
      path={`${EXPECTED_FUEL_CONSUMPTION_PATH}/:id/edit`}
      component={EditExpectedFuelConsumption}
    />
    <Route exact path={FUEL_PROFILE_PATH} component={FuelProfiles} />
    <Route exact path={NEW_FUEL_PROFILE_PATH} component={NewFuelProfile} />
    <Route exact path={`${FUEL_PROFILE_PATH}/:id`} component={FuelProfile} />
    <Route
      exact
      path={`${FUEL_PROFILE_PATH}/:id/edit`}
      component={EditFuelProfile}
    />
    <Route
      exact
      path={EXTRAORDINARY_MOVEMENT_CONTROL_PATH}
      component={ExtraordinaryMovementControls}
    />
    <Route
      exact
      path={`${EXTRAORDINARY_MOVEMENT_CONTROL_PATH}/:id`}
      component={ExtraordinaryMovementControl}
    />
    <Route
      exact
      path={generateExtraordinaryMovementControlEditPath({})}
      component={EditExtraordinaryMovementControl}
    />
    <Route exact path={PURCHASE_ORDER_PATH} component={PurchaseOrders} />
    <Route exact path={NEW_PURCHASE_ORDER_PATH} component={NewPurchaseOrder} />
    <Route
      exact
      path={`${PURCHASE_ORDER_PATH}/:id`}
      component={PurchaseOrder}
    />
    <Route
      exact
      path={generateEditPurchaseOrderPath()}
      component={EditPurchaseOrder}
    />
    <Route exact path={FUEL_VOUCHER_PATH} component={FuelVouchers} />
    <Route exact path={NEW_FUEL_VOUCHER_PATH} component={NewFuelVoucher} />
    <Route exact path={`${FUEL_VOUCHER_PATH}/:id`} component={FuelVoucher} />
    <Route
      exact
      path={`${FUEL_VOUCHER_PATH}/:id/edit`}
      component={EditFuelVoucher}
    />
    <Route exact path={BASE_FUEL_REFILL_PATH} component={BaseFuelRefills} />
    <Route
      exact
      path={NEW_BASE_FUEL_REFILL_PATH}
      component={NewBaseFuelRefill}
    />
    <Route
      exact
      path={`${BASE_FUEL_REFILL_PATH}/:id`}
      component={BaseFuelRefill}
    />
    <Route
      exact
      path={`${BASE_FUEL_REFILL_PATH}/:id/edit`}
      component={EditBaseFuelRefill}
    />
    <Route
      exact
      path={FUEL_REPORT_PREVIEW_PATH}
      component={FuelReportPreview}
    />
    <Route
      exact
      path={FUEL_CONSUMPTION_REPORT_PER_BUS_PATH}
      component={FuelConsumptionReportPerBus}
    />
    <Route
      exact
      path={FUEL_CONSUMPTION_REPORT_PER_DRIVER_PATH}
      component={FuelConsumptionReportPerDriver}
    />
    <Route exact path={DRIVER_PATH} component={Drivers} />
    <Route exact path={NEW_DRIVER_PATH} component={NewDriver} />
    <Route exact path={`${DRIVER_PATH}/:id`} component={Driver} />
    <Route exact path={`${DRIVER_PATH}/:id/edit`} component={EditDriver} />
    <Route exact path={`${DRIVER_PATH}/:id/trip`} component={DriverTrips} />
    <Route
      exact
      path={DRIVER_MOVEMENT_HISTORY_PATH}
      component={DriversMovementHistory}
    />
    <Route
      exact
      path={NEW_DRIVER_MOVEMENT_HISTORY_PATH}
      component={NewDriverMovementHistory}
    />
    <Route
      exact
      path={`${DRIVER_MOVEMENT_HISTORY_PATH}/:id/edit`}
      component={EditDriverMovementHistory}
    />
    <Route
      exact
      path={`${DRIVER_MOVEMENT_HISTORY_PATH}/:id`}
      component={DriverMovementHistory}
    />
    <Route
      exact
      path={DRIVER_TRIP_HISTORY_PATH}
      component={DriversTripHistory}
    />
    <Route exact path={CABIN_CREW_PATH} component={CabinCrews} />
    <Route exact path={NEW_CABIN_CREW_PATH} component={NewCabinCrew} />
    <Route exact path={`${CABIN_CREW_PATH}/:id`} component={CabinCrew} />
    <Route
      exact
      path={`${CABIN_CREW_PATH}/:id/edit`}
      component={EditCabinCrew}
    />
  </Switch>
);

export default TrafficUnit;
