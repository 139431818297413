import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from './Select';
import {
  inputPropTypes,
  metaPropTypes,
  inputDefaultProps,
  metaDefaultProps,
} from '../../forms/input/InputPropTypes';
import { DEFAULT_QUERY_GET_ALL } from '../../../../config/queries';
import { optionsPropTypes } from './SelectPropTypes';
import { clearCabinCrewTypes, getCabinCrewTypes } from '../../../../actions';

const CabinCrewTypeSelect = ({
  dispatchGetCabinCrewTypes,
  dispatchClearCabinCrewTypes,
  isMulti,
  input,
  options,
  meta,
  isLoading,
  cleanWhenUnmount,
}) => {
  useLayoutEffect(() => {
    dispatchGetCabinCrewTypes(DEFAULT_QUERY_GET_ALL);

    return () => {
      if (cleanWhenUnmount) {
        dispatchClearCabinCrewTypes();
      }
    };
  }, []);

  return (
    <Select
      options={options}
      isLoading={isLoading}
      isMulti={isMulti}
      input={input}
      meta={meta}
    />
  );
};

CabinCrewTypeSelect.propTypes = {
  dispatchGetCabinCrewTypes: PropTypes.func.isRequired,
  dispatchClearCabinCrewTypes: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  input: inputPropTypes,
  options: optionsPropTypes,
  meta: metaPropTypes,
  isLoading: PropTypes.bool,
  cleanWhenUnmount: PropTypes.bool,
};

CabinCrewTypeSelect.defaultProps = {
  isLoading: false,
  input: inputDefaultProps,
  meta: metaDefaultProps,
  isMulti: false,
  options: [],
  cleanWhenUnmount: true,
};

const mapStateToProps = ({ TrafficUnit }) => ({
  options: TrafficUnit.CabinCrewType.getIn(['all', 'content', 'content']).map(
    (status) => ({
      value: status.id,
      label: status.name,
    }),
  ),
  loading: TrafficUnit.CabinCrewType.getIn(['all', 'loading']),
});

const mapDispatchToprops = {
  dispatchGetCabinCrewTypes: getCabinCrewTypes,
  dispatchClearCabinCrewTypes: clearCabinCrewTypes,
};

export default connect(
  mapStateToProps,
  mapDispatchToprops,
)(CabinCrewTypeSelect);
