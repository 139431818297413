import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_DELETE_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import {
  CLEAR_CARGO_ITEMS_TO_DELIVER,
  CLEAR_DELIVERY_BUSINESS,
  CLEAR_DELIVERY_BUSINESSES,
  FLAG_DELIVERY_BUSINESS_ACTIVITY,
  FLAG_GETTING_CARGO_ITEMS_TO_DELIVER,
  FLAG_GETTING_DELIVERY_BUSINESSES,
  GET_CARGO_ITEMS_TO_DELIVER,
  GET_DELIVERY_BUSINESS,
  GET_DELIVERY_BUSINESSES,
} from '../types';
import { DELIVERY_BUSINESS_PATH } from '../../config/paths';
import {
  DELIVERY_BUSINESS_ENDPOINT,
  GET_CARGO_ITEM_TO_DELIVER_ENDPOINT,
} from '../../config/endpoints';

const flagGettingDeliveryBusinesses = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_DELIVERY_BUSINESSES,
    payload: flag,
  });

const getDeliveryBusinesses = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingDeliveryBusinesses(true));
    const query = tableFilters;
    const url = `${DELIVERY_BUSINESS_ENDPOINT}?${QueryString.stringify(query)}`;
    const promise = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(promise, null, dispatch);
    const response = await promise.json();
    dispatch({
      type: GET_DELIVERY_BUSINESSES,
      payload: response,
    });
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagGettingDeliveryBusinesses(false));
  }
};

const clearDeliveryBusinesses = () => (dispatch) =>
  dispatch({
    type: CLEAR_DELIVERY_BUSINESSES,
  });

const flagDeliveryBusinessActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_DELIVERY_BUSINESS_ACTIVITY,
    payload: flag,
  });

const getDeliveryBusiness =
  async ({ deliveryBusinessId }) =>
  async (dispatch) => {
    try {
      dispatch(flagDeliveryBusinessActivity(true));
      const url = `${DELIVERY_BUSINESS_ENDPOINT}/${deliveryBusinessId}`;
      const promise = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(promise, null, dispatch);
      const response = await promise.json();
      dispatch({
        type: GET_DELIVERY_BUSINESS,
        payload: response,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagDeliveryBusinessActivity(false));
    }
  };

const clearDeliveryBusiness = () => (dispatch) =>
  dispatch({
    type: CLEAR_DELIVERY_BUSINESS,
  });

const postDeliveryBusiness =
  async ({ businessId, agencyList, userList }) =>
  async (dispatch) => {
    try {
      dispatch(flagDeliveryBusinessActivity(true));
      const payload = {
        businessId,
        agencyList,
        userList,
      };
      const url = DELIVERY_BUSINESS_ENDPOINT;
      const promise = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise, null, dispatch);
      await promise.json();
      dispatch(push(DELIVERY_BUSINESS_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagDeliveryBusinessActivity(false));
    }
  };

const deleteDeliveryBusiness =
  async ({ deliveryBusinessId }) =>
  async (dispatch) => {
    try {
      dispatch(flagDeliveryBusinessActivity(true));
      const url = `${DELIVERY_BUSINESS_ENDPOINT}/${deliveryBusinessId}`;
      const promise = await fetch(url, {
        ...DEFAULT_DELETE_CONFIG,
      });
      await isErrorResponse(promise, null, dispatch);
      await promise.json();
      dispatch(push(DELIVERY_BUSINESS_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagDeliveryBusinessActivity(false));
    }
  };

const putDeliveryBusiness =
  async (deliveryBusinessId, { businessId, agencyList, userList, active }) =>
  async (dispatch) => {
    try {
      dispatch(flagDeliveryBusinessActivity(true));
      const payload = {
        id: deliveryBusinessId,
        businessId,
        agencyList,
        userList,
        active,
      };
      const url = `${DELIVERY_BUSINESS_ENDPOINT}/${deliveryBusinessId}`;
      const promise = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise, null, dispatch);
      await promise.json();
      dispatch(push(DELIVERY_BUSINESS_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagDeliveryBusinessActivity(false));
    }
  };

const flagGettingCargoItemsToDeliver = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_CARGO_ITEMS_TO_DELIVER,
    payload: flag,
  });

const getCargoItemsToDeliver = async (locationId) => async (dispatch) => {
  try {
    dispatch(flagGettingCargoItemsToDeliver(true));
    const query = { locationId };
    const url = `${GET_CARGO_ITEM_TO_DELIVER_ENDPOINT}?${QueryString.stringify(
      query,
    )}`;
    const promise = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(promise, null, dispatch);
    const response = await promise.json();
    dispatch({
      type: GET_CARGO_ITEMS_TO_DELIVER,
      payload: response,
    });
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagGettingCargoItemsToDeliver(false));
  }
};

const clearCargoItemsToDeliver = () => (dispatch) =>
  dispatch({
    type: CLEAR_CARGO_ITEMS_TO_DELIVER,
  });

export {
  flagGettingDeliveryBusinesses,
  getDeliveryBusinesses,
  clearDeliveryBusinesses,
  flagDeliveryBusinessActivity,
  getDeliveryBusiness,
  postDeliveryBusiness,
  clearDeliveryBusiness,
  deleteDeliveryBusiness,
  putDeliveryBusiness,
  getCargoItemsToDeliver,
  clearCargoItemsToDeliver,
};
