import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Form, FormGroup, Label, Button, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { toastr } from 'react-redux-toastr';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import {
  postPayment,
  getPaymentMethodsPerSecurityProfile,
  getCreditNotes,
  clearCreditNotes,
} from '../../../../actions';
import { isRequired } from '../../../../utils/validators';
import TextInput from '../../../common/forms/input/TextInput';
import {
  TRANSACTION_TYPE_CREDIT_CARD,
  CREDIT_NOTE,
  CONTRACT_TYPE,
  TRANSACTION_TYPE_BUSINESS_CREDIT,
} from '../../../../config/constants';
import {
  DEFAULT_CORPORATE_VOUCHER_TYPE_OPTION,
  DEFAULT_PAYMENT_METHOD_OPTION,
  DEFAULT_SELF_SERVICE_VOUCHER_TYPE_OPTION,
  DEFAULT_VOUCHER_TYPE_OPTION,
  DEV_SELF_SERVICE_PAYMENT_METHOD_ID,
} from '../../../../config/defaults';
import VoucherTypeInputGroup from '../../../common/forms/VoucherTypeInputGroup';
import Select from '../../../common/forms/select/Select';
import { optionPropTypes } from '../../../common/forms/select/SelectPropTypes';
import FormItem from '../../../common/forms/FormItem';
import CreditNoteSelect from '../../../common/forms/select/CreditNoteSelect';
import PaymentMethodSelect from '../../../common/forms/select/PaymentMethodSelect';
import { numberFormatter } from '../../../../utils/number';
import Badge from '../../../common/Badge';

const selector = formValueSelector('PaymentForm');

export const PaymentForm = ({
  change,
  clients,
  submitting,
  totalPrice,
  seatsNumber,
  creditNotes,
  handleSubmit,
  paymentMethods,
  contingencyData,
  activeSaleSession,
  paymentMethodValue,
  dispatchPostPayment,
  selectedCreditNotes,
  dispatchGetCreditNotes,
  dispatchClearCreditNotes,
  dispatchGetPaymentMethodsPerSecurityProfile,
}) => {
  const [showVoucherCode, setShowVoucherCode] = useState(false);
  const [showCreditNoteInput, setShowCreditNoteInput] = useState(false);
  const [remainingPrice, setRemainingPrice] = useState(totalPrice);
  const [hasCreditNoteCode, setHasCreditNoteCode] = useState(false);

  useLayoutEffect(() => {
    dispatchGetPaymentMethodsPerSecurityProfile();
    if (clients.length === 1) {
      const query = [
        `customerId:${clients[0].value}`,
        'isValid:true',
        'previousTicketId:!Ø',
      ];
      dispatchGetCreditNotes({ query });
    }
    return () => {
      dispatchClearCreditNotes();
    };
  }, []);

  useEffect(() => {
    setHasCreditNoteCode(creditNotes.length > 0);
  }, [creditNotes]);

  useEffect(() => {
    if (selectedCreditNotes.length > 0) {
      setRemainingPrice(
        totalPrice -
          selectedCreditNotes.reduce(
            (accumulated, creditNote) => accumulated + creditNote.amount,
            0,
          ),
      );
    }
  }, [selectedCreditNotes]);

  const handlePaymentMethodChange = (paymentMethod) => {
    setShowVoucherCode(
      paymentMethod.transactionCode &&
        paymentMethod.transactionCode === TRANSACTION_TYPE_CREDIT_CARD,
    );

    const showCreditNote =
      paymentMethod.value && paymentMethod.transactionCode === CREDIT_NOTE;

    if (seatsNumber === 1) {
      setShowCreditNoteInput(showCreditNote);
    }

    if (!showCreditNote) {
      change('creditNotes', []);
    }

    if (showVoucherCode) {
      change('voucherCode', '');
    }
  };

  const handleNextPaymentMethodChange = (paymentMethod) => {
    setShowVoucherCode(
      paymentMethod.value &&
        paymentMethod.transactionCode === TRANSACTION_TYPE_CREDIT_CARD,
    );
    if (showVoucherCode) change('voucherCode', '');
  };

  const contingencyDocumentList = (contingencyDataValue) =>
    Object.keys(contingencyDataValue).map((ticketId) => {
      const contingencyDetails = contingencyDataValue[ticketId];
      return {
        documentSerie: contingencyDetails.contingencySeries,
        documentCode: parseInt(contingencyDetails.contingencyCode, 10),
        ticketId: parseInt(contingencyDetails.ticketId, 10),
        contingencyMotive: contingencyDetails.contingencyMotive,
      };
    });

  const validateContingencyData = (contingencyDataValue, contingencyFlag) => {
    if (!contingencyFlag) {
      return true;
    }

    if (!contingencyData || contingencyData.length === 0) {
      return false;
    }

    return contingencyData.every((data) => {
      if (
        !data.contingencySeries ||
        !data.contingencyCode ||
        !data.contingencyMotive
      ) {
        return false;
      }

      const isValidSeries = /^[0-9]{4}$/.test(data.contingencySeries);

      const isValidCode = /^[0-9]+$/.test(data.contingencyCode);

      return isValidSeries && isValidCode;
    });
  };

  const handlePaymentSubmit = (formValues) => {
    const transactions = [];

    const currentContingencyDocumentList =
      contingencyDocumentList(contingencyData);

    const isContingencySalesSession =
      activeSaleSession.get('workstation').contingency;

    if (
      !validateContingencyData(
        currentContingencyDocumentList,
        isContingencySalesSession,
      )
    ) {
      toastr.error(
        'Error',
        'Por favor, complete todos los campos de contingencia. Asegúrese de que la serie tenga 4 dígitos y el código solo contenga números.',
      );
      return;
    }

    if (
      paymentMethodValue.transactionCode === CREDIT_NOTE &&
      selectedCreditNotes.length === 0
    ) {
      toastr.error('Error', 'No se ha aplicado ninguna nota de crédito');
      return;
    }
    let currentRemainingPrice = totalPrice;
    selectedCreditNotes
      .sort((a, b) => a.amount - b.amount)
      .forEach((creditNote) => {
        let creditNoteAmount = 0;
        if (currentRemainingPrice === 0) return;
        if (currentRemainingPrice > creditNote.amount) {
          currentRemainingPrice -= creditNote.amount;
          creditNoteAmount = creditNote.amount;
        } else {
          creditNoteAmount = currentRemainingPrice;
          currentRemainingPrice = 0;
        }
        const creditNoteTransaction = {
          customerId: formValues.customer.value,
          paymentMethodId: formValues.paymentMethod.value,
          creditNoteId: creditNote.value,
          voucherTypeId: formValues.voucherType.value,
          voucherCode: formValues.voucherCode || null,
          businessId: formValues.business ? formValues.business.value : null,
          amount: creditNoteAmount,
        };
        transactions.push(creditNoteTransaction);
      });
    if (
      currentRemainingPrice !== 0 ||
      paymentMethodValue.transactionCode !== CREDIT_NOTE
    ) {
      const transaction = {
        customerId: formValues.customer.value,
        paymentMethodId: formValues.nextPaymentMethod
          ? formValues.nextPaymentMethod.value
          : formValues.paymentMethod.value,
        voucherTypeId: formValues.voucherType.value,
        voucherCode: formValues.voucherCode || null,
        businessId: formValues.business ? formValues.business.value : null,
        amount: currentRemainingPrice,
      };
      transactions.push(transaction);
    }
    const payReservationRequest = {
      transactionList: transactions,
      contingencyDocumentCorrelationList: currentContingencyDocumentList,
    };

    dispatchPostPayment({
      payReservationRequest,
      customerOrderId: formValues.customerOrderId,
      businessTaxId: formValues.businessTaxId,
      businessName: formValues.businessName,
      addressSummary: formValues.addressSummary,
    });
  };

  let paymentMethodComponent = null;
  let nextPaymentMethodComponent = null;
  let creditNoteWarning = null;
  if (
    seatsNumber > 1 &&
    paymentMethodValue &&
    paymentMethodValue.transactionCode &&
    paymentMethodValue.transactionCode === CREDIT_NOTE
  ) {
    creditNoteWarning = (
      <p className="text-danger">
        Este método de pago sólo aplica para venta un solo pasaje
      </p>
    );
  }
  paymentMethodComponent = (
    <FormGroup row>
      <FormItem label="Método de Pago" required>
        <Field
          name="paymentMethod"
          component={PaymentMethodSelect}
          onChange={handlePaymentMethodChange}
          validate={[isRequired]}
          givenOptions={paymentMethods}
          transactionCodesNotIncluded={[]}
        />
        {creditNoteWarning}
      </FormItem>
    </FormGroup>
  );
  nextPaymentMethodComponent = selectedCreditNotes.length > 0 &&
    remainingPrice > 0 && (
      <>
        <h3>
          <b>{`Restante: ${numberFormatter({
            style: 'currency',
            value: remainingPrice,
          })}`}</b>
        </h3>
        <FormGroup row>
          <FormItem label="Siguiente método de Pago">
            <Field
              name="nextPaymentMethod"
              component={PaymentMethodSelect}
              onChange={handleNextPaymentMethodChange}
              validate={[isRequired]}
              transactionCodesNotIncluded={[CREDIT_NOTE]}
              notClearOnUnmount
            />
          </FormItem>
        </FormGroup>
      </>
    );

  const voucherCode = showVoucherCode ? (
    <FormGroup row>
      <Col sm={3} xs={12}>
        <Label>
          Nro Referencia (POS) <span className="text-danger">*</span>
        </Label>
      </Col>
      <Col sm={8} xs={12}>
        <Field
          name="voucherCode"
          component={TextInput}
          type="text"
          placeholder="Código de Voucher"
          validate={[isRequired]}
        />
      </Col>
    </FormGroup>
  ) : null;

  let creditNoteComponent = null;
  if (showCreditNoteInput) {
    creditNoteComponent = (
      <FormGroup row>
        <FormItem label="Nota de crédito" required>
          <Field
            name="creditNotes"
            component={CreditNoteSelect}
            isMulti
            validate={[isRequired]}
            isCustomerIdRequired
            optionsRegistered
          />
        </FormItem>
      </FormGroup>
    );
  }

  const creditNoteResource = hasCreditNoteCode && (
    <Badge color="success" text="Este cliente tiene una nota de crédito" />
  );

  return (
    <div>
      <br />
      <h2>Pago</h2>
      <Form onSubmit={handleSubmit(handlePaymentSubmit)}>
        <FormGroup row>
          <FormItem label="Cliente" required>
            <Field
              name="customer"
              component={Select}
              options={clients}
              validate={[isRequired]}
            />
            {creditNoteResource}
          </FormItem>
        </FormGroup>
        <VoucherTypeInputGroup
          voucherTypeFieldName="voucherType"
          businessFieldName="business"
          businessTaxIdFieldName="businessTaxId"
          businessNameFieldName="businessName"
          addressSummaryFieldName="addressSummary"
          change={change}
        />
        {paymentMethodComponent}
        {creditNoteComponent}
        {nextPaymentMethodComponent}
        {voucherCode}
        <div className="FormButtonGroup">
          <Button type="submit" disabled={submitting} color="primary" size="lg">
            Pagar <i className="fa fa-arrow-circle-right" />
          </Button>
        </div>
      </Form>
    </div>
  );
};

PaymentForm.propTypes = {
  change: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  paymentMethods: Select.propTypes.options.isRequired,
  clients: Select.propTypes.options.isRequired,
  dispatchGetPaymentMethodsPerSecurityProfile: PropTypes.func.isRequired,
  dispatchGetCreditNotes: PropTypes.func.isRequired,
  dispatchClearCreditNotes: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  totalPrice: PropTypes.number.isRequired,
  dispatchPostPayment: PropTypes.func.isRequired,
  seatsNumber: PropTypes.number.isRequired,
  paymentMethodValue: PropTypes.shape({
    value: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    transactionCode: PropTypes.string,
  }),
  initialValues: PropTypes.shape({
    customer: optionPropTypes,
  }),
  activeSaleSession: PropTypes.instanceOf(Immutable.Map).isRequired,
  contingencyData: PropTypes.arrayOf(
    PropTypes.shape({
      documentSerie: PropTypes.string,
      documentCode: PropTypes.number,
      ticketId: PropTypes.number,
      contingencyMotive: PropTypes.string,
    }),
  ),
  selectedCreditNotes: PropTypes.instanceOf(Array),
  creditNotes: PropTypes.instanceOf(Array),
};

PaymentForm.defaultProps = {
  selectedCreditNotes: [],
  creditNotes: [],
  paymentMethodValue: null,
  initialValues: null,
  contingencyData: [],
};

const mapStateToProps = (state) => {
  const clients = state.BookingUnit.Booking.getIn([
    'payment',
    'customerOrder',
    'passengers',
  ]).map((passenger) => ({
    value: passenger.id,
    label: passenger.fullName,
  }));

  const paymentMethods = state.AccountingUnit.PaymentMethod.getIn([
    'all',
    'content',
    'content',
  ]).map((paymentMethod) => ({
    value: paymentMethod.id,
    label: paymentMethod.name,
    transactionCode: paymentMethod.transactionCode,
  }));

  let newPaymentMethods = [];

  let defaultPaymentMethod = DEFAULT_PAYMENT_METHOD_OPTION;

  let defaultVoucherType = DEFAULT_VOUCHER_TYPE_OPTION;

  let defaultBusiness = null;

  // Checking self service sale
  const { salesSessionUserId } = state.authentication.get('user');

  const activeSaleSession = state.SalesUnit.SalesSession.getIn([
    'active',
    'content',
  ]);

  if (!salesSessionUserId) {
    // Checking corporate sale

    const { contractType, company } = activeSaleSession.get('agency');

    if (contractType === CONTRACT_TYPE.CORPORATE.value) {
      defaultPaymentMethod = null;

      defaultVoucherType = DEFAULT_CORPORATE_VOUCHER_TYPE_OPTION;

      paymentMethods.forEach((method) => {
        if (
          method.transactionCode === CREDIT_NOTE ||
          method.transactionCode === TRANSACTION_TYPE_BUSINESS_CREDIT
        ) {
          newPaymentMethods.push(method);
        }
      });

      if (company) {
        defaultBusiness = {
          value: company.business.businessTaxId,
          label: company.business.name,
        };
      }
    } else {
      newPaymentMethods = paymentMethods;
    }
  } else {
    defaultPaymentMethod = DEV_SELF_SERVICE_PAYMENT_METHOD_ID;

    newPaymentMethods.push(defaultPaymentMethod);

    defaultVoucherType = DEFAULT_SELF_SERVICE_VOUCHER_TYPE_OPTION;
  }

  const businesses = state.UserUnit.Business.getIn([
    'all',
    'content',
    'content',
  ]).map(({ id, businessTaxId, name }) => ({
    value: id,
    label: `${businessTaxId} - ${name}`,
  }));

  const customerOrderId = state.BookingUnit.Booking.getIn([
    'payment',
    'customerOrder',
    'id',
  ]);

  const initialValues = {
    customerOrderId,
    // by default select the first client as the one who pays
    customer: clients[0],
    voucherType: defaultVoucherType,
    paymentMethod: null,
    business: defaultBusiness,
  };

  const selectedCreditNotes = selector(state, 'creditNotes');
  const paymentMethodValue = selector(state, 'paymentMethod');
  const creditNotes = state.SalesUnit.CreditNote.getIn([
    'all',
    'content',
    'content',
  ]);

  return {
    paymentMethods: newPaymentMethods,
    clients,
    businesses,
    initialValues,
    creditNotes,
    selectedCreditNotes,
    paymentMethodValue,
    activeSaleSession,
  };
};

const mapDispatchToProps = {
  dispatchGetPaymentMethodsPerSecurityProfile:
    getPaymentMethodsPerSecurityProfile,
  dispatchPostPayment: postPayment,
  dispatchGetCreditNotes: getCreditNotes,
  dispatchClearCreditNotes: clearCreditNotes,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'PaymentForm',
  })(PaymentForm),
);
