import { combineReducers } from 'redux';
import UnitHome from './UnitHome';
import BasePricePerKilometer from './BasePricePerKilometer';
import GeographicZonesCombination from './GeographicZonesCombination';
import DepreciationRule from './DepreciationRule';
import DeliveryGroup from './DeliveryGroup';
import CommonProduct from './CommonProduct';
import CargoDistance from './CargoDistance';
import Cargo from './Cargo';
import CargoCategory from './CargoCategory';
import CargoMinimalPrice from './CargoMinimalPrice';
import ItemToItinerary from './ItemToItinerary';
import CargoItem from './CargoItem';
import InternalTransfer from './InternalTransfer';
import ItineraryManifest from './ItineraryManifest';
import LegalAbandonment from './LegalAbandonment';
import CargoPickup from './CargoPickup';
import ManualSenderRemissionGuide from './ManualSenderRemissionGuide';
import CargoCorporateCredit from './CargoCorporateCredit';
import ManifestCargo from './ManifestCargo';
import ParcelPrice from './ParcelPrice';
import ParcelServiceType from './ParcelServiceType';
import ParcelPriceConfiguration from './ParcelPriceConfiguration';
import DeliveryBusiness from './DeliveryBusiness';
import Delivery from './Delivery';
import Shelf from './Shelf';
import DeliveryLocationDistrict from './DeliveryLocationDistrict';
import DeliveryPriceConfiguration from './DeliveryPriceConfiguration';
import DeliveryPrice from './DeliveryPrice';
import CargoCorporateCreditCity from './CargoCorporateCreditCity';
import CargoDeliveryFrequentVehicle from './CargoDeliveryFrequentVehicle';

export default combineReducers({
  UnitHome,
  BasePricePerKilometer,
  GeographicZonesCombination,
  DepreciationRule,
  DeliveryGroup,
  CommonProduct,
  CargoDistance,
  Cargo,
  CargoCategory,
  CargoMinimalPrice,
  ItineraryManifest,
  ItemToItinerary,
  CargoItem,
  InternalTransfer,
  LegalAbandonment,
  CargoPickup,
  ManualSenderRemissionGuide,
  CargoCorporateCredit,
  ManifestCargo,
  ParcelPrice,
  ParcelServiceType,
  ParcelPriceConfiguration,
  DeliveryBusiness,
  Delivery,
  CargoCorporateCreditCity,
  Shelf,
  DeliveryLocationDistrict,
  DeliveryPriceConfiguration,
  DeliveryPrice,
  CargoDeliveryFrequentVehicle,
});
