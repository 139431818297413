import React, { useEffect } from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import {
  clearDeliveryLastConfiguration,
  getDeliveryLastConfiguration,
  postDeliveryPriceConfiguration,
} from '../../../../actions';
import { DELIVERY_PRICE_CONFIGURATION_PATH } from '../../../../config/paths';
import DeliveryPriceConfigurationForm from './DeliveryPriceConfigurationForm';
import Loader from '../../../common/Loader';

const NewDeliveryPriceConfiguration = ({
  breadcrumbs,
  dispatchPostDeliveryPriceConfiguration,
  dispatchGetDeliveryLastConfiguration,
  dispatchClearDeliveryLastConfiguration,
  lastConfiguration,
  loadingLastConfiguration,
}) => {
  useEffect(() => {
    dispatchGetDeliveryLastConfiguration();

    return () => {
      dispatchClearDeliveryLastConfiguration();
    };
  }, []);

  const onSubmit = (formValues) =>
    dispatchPostDeliveryPriceConfiguration({ ...formValues });

  const content = loadingLastConfiguration ? (
    <Loader />
  ) : (
    <DeliveryPriceConfigurationForm
      onSubmit={onSubmit}
      initialValues={{
        minKilograms: lastConfiguration.isEmpty()
          ? 0
          : lastConfiguration.get('maxKilograms'),
      }}
    />
  );

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Nuevo Configuración de Precio de Reparto"
      subtitle="Crear nueva configuración de precio de reparto"
      content={content}
    />
  );
};

NewDeliveryPriceConfiguration.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetDeliveryLastConfiguration: PropTypes.func.isRequired,
  dispatchPostDeliveryPriceConfiguration: PropTypes.func.isRequired,
  dispatchClearDeliveryLastConfiguration: PropTypes.func.isRequired,
  lastConfiguration: PropTypes.instanceOf(Immutable.Map).isRequired,
  loadingLastConfiguration: PropTypes.bool.isRequired,
};

const mapDispatchToProps = {
  dispatchPostDeliveryPriceConfiguration: postDeliveryPriceConfiguration,
  dispatchClearDeliveryLastConfiguration: clearDeliveryLastConfiguration,
  dispatchGetDeliveryLastConfiguration: getDeliveryLastConfiguration,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Configuraciones de Precio de Reparto',
      href: DELIVERY_PRICE_CONFIGURATION_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
  lastConfiguration: CargoUnit.DeliveryPriceConfiguration.getIn([
    'last',
    'content',
  ]),
  loadingLastConfiguration: CargoUnit.DeliveryPriceConfiguration.getIn([
    'last',
    'loading',
  ]),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewDeliveryPriceConfiguration);
