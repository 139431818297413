import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import DeliveryDeleteButton from './DeliveryDeleteButton';
import DeliveryStartButton from './DeliveryStartButton';
import DeliveryItemsConfirmReturnButton from './resource/DeliveryItemsConfirmReturnButton';
import {
  CARGO_ITEM_STATUS,
  DELIVERY_STATUS,
} from '../../../../config/constants';
import DeliveryCarrierRemissionGuideButton from './DeliveryCarrierRemissionGuideButton';

const DeliveryToolbar = ({
  deliveryId,
  deliveryStatus,
  items,
  remissionGuideList,
}) => {
  const backToAgencyItems = items.filter(
    (item) => item.cargoItemStatus === CARGO_ITEM_STATUS.BACK_TO_AGENCY.value,
  );
  return (
    <ButtonToolbar className="pull-right">
      {deliveryStatus === DELIVERY_STATUS.REGISTERED.value && (
        <div>
          <DeliveryStartButton deliveryId={deliveryId} />{' '}
          <DeliveryDeleteButton deliveryId={deliveryId} />{' '}
          <DeliveryCarrierRemissionGuideButton
            remissionGuideList={remissionGuideList}
            deliveryId={deliveryId}
          />
        </div>
      )}
      {backToAgencyItems.length > 0 && (
        <DeliveryItemsConfirmReturnButton
          items={backToAgencyItems}
          deliveryId={deliveryId}
        />
      )}
    </ButtonToolbar>
  );
};

DeliveryToolbar.propTypes = {
  items: PropTypes.instanceOf(Array).isRequired,
  deliveryId: PropTypes.number.isRequired,
  deliveryStatus: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  remissionGuideList: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default DeliveryToolbar;
