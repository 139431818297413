export const GET_CARGO_CORPORATE_CREDIT_CITY =
  'GET_CARGO_CORPORATE_CREDIT_CITY';
export const GET_CARGO_CORPORATE_CREDIT_CITIES =
  'GET_CARGO_CORPORATE_CREDIT_CITIES';
export const FLAG_GETTING_CARGO_CORPORATE_CREDIT_CITIES =
  'FLAG_GETTING_CARGO_CORPORATE_CREDIT_CITIES';
export const CLEAR_CARGO_CORPORATE_CREDIT_CITY =
  'CLEAR_CARGO_CORPORATE_CREDIT_CITY';
export const FLAG_CARGO_CORPORATE_CREDIT_CITY_ACTIVITY =
  'FLAG_CARGO_CORPORATE_CREDIT_CITY_ACTIVITY';
export const CLEAR_CARGO_CORPORATE_CREDIT_CITIES =
  'CLEAR_CARGO_CORPORATE_CREDIT_CITIES';

export const POST_CARGO_CORPORATE_CREDIT_CITY =
  'POST_CARGO_CORPORATE_CREDIT_CITY';
export const PUT_CARGO_CORPORATE_CREDIT_CITY =
  'PUT_CARGO_CORPORATE_CREDIT_CITY';
