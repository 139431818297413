import Immutable from 'immutable';

import { COLLECTION_SHAPE } from '../../../config/shapes';
import {
  CLEAR_DELIVERY_LOCATION_DISTRICT,
  CLEAR_DELIVERY_LOCATION_DISTRICTS,
  FLAG_DELIVERY_LOCATION_DISTRICT_ACTIVITY,
  FLAG_GETTING_DELIVERY_LOCATION_DISTRICTS,
  GET_DELIVERY_LOCATION_DISTRICT,
  GET_DELIVERY_LOCATION_DISTRICTS,
} from '../../../actions/types';

const INITIAL_STATE = Immutable.Map({
  all: Immutable.Map({
    content: Immutable.Map(COLLECTION_SHAPE),
    loading: false,
  }),
  current: Immutable.Map({
    content: Immutable.Map(),
    activity: Immutable.List(),
  }),
});

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case FLAG_GETTING_DELIVERY_LOCATION_DISTRICTS:
      return state.setIn(['all', 'loading'], payload);
    case GET_DELIVERY_LOCATION_DISTRICTS:
      return state.setIn(['all', 'content'], Immutable.Map(payload));
    case CLEAR_DELIVERY_LOCATION_DISTRICTS:
      return state.setIn(
        ['all', 'content'],
        INITIAL_STATE.getIn(['all', 'content']),
      );
    case FLAG_DELIVERY_LOCATION_DISTRICT_ACTIVITY:
      if (payload) {
        return state.setIn(
          ['current', 'activity'],
          state.getIn(['current', 'activity']).push(payload),
        );
      }

      return state.setIn(
        ['current', 'activity'],
        state.getIn(['current', 'activity']).pop(),
      );
    case GET_DELIVERY_LOCATION_DISTRICT:
      return state.setIn(['current', 'content'], Immutable.Map(payload));
    case CLEAR_DELIVERY_LOCATION_DISTRICT:
      return state.setIn(['current'], INITIAL_STATE.getIn(['current']));
    default:
      return state;
  }
};
