import React, { useLayoutEffect, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field, change } from 'redux-form';
import { Button, Col, Form, FormGroup } from 'reactstrap';
import {
  clearItemToItinerary,
  getManifestCargoItemsToItinerary,
  getUserAgencies,
} from '../../../../actions';
import { isRequired } from '../../../../utils/validators';
import { optionsPropTypes } from '../../../common/forms/select/SelectPropTypes';
import Select from '../../../common/forms/select/Select';
import FormItem from '../../../common/forms/FormItem';
import RelocationCargoItemResult from './RelocationCargoItemResult';
import { NO_SHELF_CARGO_ITEM_SELECTED } from '../../../../config/messages';
import Alert from '../../../common/informative/Alert';

export const RelocationCargoItemForm = ({
  user,
  handleSubmit,
  agencyOptions,
  loadingAgencies,
  dispatchGetUserAgencies,
  dispatchClearItemToItinerary,
  dispatchChange,
  dispatchGetManifestCargoItemsToItinerary,
}) => {
  const didDispatchAgency = useRef(false);

  const [locationIdSelected, setLocationIdSelected] = useState(null);

  useLayoutEffect(
    () => () => {
      dispatchClearItemToItinerary();
    },
    [],
  );

  useEffect(() => {
    dispatchGetUserAgencies({ userId: user.id });
  }, [user]);

  useEffect(() => {
    if (
      agencyOptions &&
      agencyOptions.length > 0 &&
      !didDispatchAgency.current
    ) {
      dispatchChange(
        'RelocationCargoItemForm',
        'sourceAgency',
        agencyOptions[0],
      );

      didDispatchAgency.current = true;
    }
  }, [agencyOptions]);

  const onHandleSubmit = ({ sourceAgency }) => {
    const sourceLocationId = sourceAgency.value;

    dispatchGetManifestCargoItemsToItinerary({
      sourceLocationId,
      listLegalAbandonment: true,
      relocationItem: true,
    });

    setLocationIdSelected(sourceLocationId);
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onHandleSubmit)}>
        <FormGroup row>
          <FormItem label="Agencia Actual" required>
            <Field
              name="sourceAgency"
              component={Select}
              isLoading={loadingAgencies}
              options={agencyOptions}
              isDisabled={agencyOptions.length <= 1}
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <Col className="flex row-reverse">
            <Button type="submit" color="primary">
              Buscar
            </Button>
          </Col>
        </FormGroup>
      </Form>

      {locationIdSelected == null && (
        <Alert type="info" message={NO_SHELF_CARGO_ITEM_SELECTED} />
      )}
      {locationIdSelected && (
        <RelocationCargoItemResult locationId={locationIdSelected} />
      )}
    </>
  );
};

RelocationCargoItemForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    customer: PropTypes.shape({
      fullName: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  loadingAgencies: PropTypes.bool,
  agencyOptions: optionsPropTypes,
  dispatchChange: PropTypes.func.isRequired,
  dispatchGetUserAgencies: PropTypes.func.isRequired,
  dispatchClearItemToItinerary: PropTypes.func.isRequired,
  dispatchGetManifestCargoItemsToItinerary: PropTypes.func.isRequired,
};

RelocationCargoItemForm.defaultProps = {
  loadingAgencies: false,
  agencyOptions: [],
};

const mapStateToProps = (state) => ({
  user: state.authentication.get('user') || undefined,
  loadingAgencies: state.HumanResourcesUnit.Agency.getIn(['all', 'loading']),
  agencyOptions: state.HumanResourcesUnit.Agency.getIn([
    'all',
    'content',
    'content',
  ]).map((agency) => ({
    value: agency.locationId,
    label: agency.name,
  })),
});

const mapDispatchToProps = {
  dispatchChange: change,
  dispatchGetUserAgencies: getUserAgencies,
  dispatchClearItemToItinerary: clearItemToItinerary,
  dispatchGetManifestCargoItemsToItinerary: getManifestCargoItemsToItinerary,
};

const formComponent = reduxForm({
  form: 'RelocationCargoItemForm',
})(RelocationCargoItemForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
