import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import {
  DELIVERY_LOCATION_DISTRICT_PATH,
  NEW_DELIVERY_LOCATION_DISTRICT_PATH,
} from '../../../../config/paths';
import {
  clearDeliveryLocationDistricts,
  getDeliveryLocationDistricts,
} from '../../../../actions';
import { DELIVERY_LOCATION_DISTRICT_COLUMNS } from '../../../../config/columns';

export const DeliveryLocationDistricts = ({
  breadcrumbs,
  deliveryLocationDistricts,
  loading,
  dispatchGetDeliveryLocationDistricts,
  dispatchClearDeliveryLocationDistricts,
}) => {
  useLayoutEffect(() => () => dispatchClearDeliveryLocationDistricts(), []);

  const data = deliveryLocationDistricts.get('content') || [];
  const pages = deliveryLocationDistricts.get('totalPages') || null;
  const defaultPageSize =
    deliveryLocationDistricts.get('size') || DEFAULT_PAGE_SIZE;

  return (
    <ModuleList
      title="Lista de Ubicaciones con Distritos Autorizados para Reparto"
      breadcrumbs={breadcrumbs}
      buttonPath={NEW_DELIVERY_LOCATION_DISTRICT_PATH}
      tableStructure={{
        columns: DELIVERY_LOCATION_DISTRICT_COLUMNS,
        data,
        pages,
        defaultPageSize,
        filterable: true,
        fetchData: dispatchGetDeliveryLocationDistricts,
        modelPath: DELIVERY_LOCATION_DISTRICT_PATH,
        loading,
        modelId: 'locationId',
        navigateToModelOnRowClick: true,
      }}
    />
  );
};

const mapDispatchToProps = {
  dispatchGetDeliveryLocationDistricts: getDeliveryLocationDistricts,
  dispatchClearDeliveryLocationDistricts: clearDeliveryLocationDistricts,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Ubicaciones con Distritos Autorizados para Reparto',
      href: DELIVERY_LOCATION_DISTRICT_PATH,
    },
  ],
  deliveryLocationDistricts: CargoUnit.DeliveryLocationDistrict.getIn([
    'all',
    'content',
  ]),
  loading: CargoUnit.DeliveryLocationDistrict.getIn(['all', 'loading']),
});

DeliveryLocationDistricts.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  deliveryLocationDistricts: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetDeliveryLocationDistricts: PropTypes.func.isRequired,
  dispatchClearDeliveryLocationDistricts: PropTypes.func.isRequired,
};

DeliveryLocationDistricts.defaultProps = {
  deliveryLocationDistricts: null,
  loading: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeliveryLocationDistricts);
