import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import { DELIVERY_PATH } from '../../../../config/paths';
import { clearDelivery, getDelivery } from '../../../../actions';
import DeliveryBasicInformationResource from './resource/DeliveryBasicInformationResource';
import DeliveryToolbar from './DeliveryToolbar';

export const Delivery = ({
  breadcrumbs,
  dispatchGetDelivery,
  dispatchClearDelivery,
  match: {
    params: { id: deliveryId },
  },
  loading,
  delivery,
}) => {
  useLayoutEffect(() => {
    dispatchGetDelivery({ deliveryId });

    return () => dispatchClearDelivery();
  }, []);

  let content;
  let toolbar;

  if (loading) content = <Loader />;
  else if (delivery.isEmpty())
    content = <NoDataResource returnPage={DELIVERY_PATH} />;
  else {
    const deliveryJson = delivery.toJS();

    content = <DeliveryBasicInformationResource {...deliveryJson.delivery} />;

    toolbar = (
      <DeliveryToolbar
        items={deliveryJson.delivery.items}
        deliveryId={deliveryJson.delivery.id}
        deliveryStatus={deliveryJson.delivery.deliveryStatus}
        remissionGuideList={deliveryJson.remissionGuideList}
      />
    );
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      toolbar={toolbar}
      title="Reparto"
      content={content}
    />
  );
};

Delivery.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchClearDelivery: PropTypes.func.isRequired,
  dispatchGetDelivery: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  delivery: PropTypes.instanceOf(Immutable.Map).isRequired,
};

Delivery.defaultProps = {
  loading: false,
};

const mapDispatchToProps = {
  dispatchClearDelivery: clearDelivery,
  dispatchGetDelivery: getDelivery,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Repartos',
      href: DELIVERY_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  delivery: CargoUnit.Delivery.getIn(['current', 'content']),
  loading: !CargoUnit.Delivery.getIn(['current', 'activity']).isEmpty(),
});

export default connect(mapStateToProps, mapDispatchToProps)(Delivery);
