import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import { CARGO_CORPORATE_CREDIT_CITY_ENDPOINT } from '../../config/endpoints';
import { DEFAULT_GET_CONFIG, DEFAULT_POST_CONFIG } from '../../config/rest';
import { CARGO_CORPORATE_CREDIT_PATH } from '../../config/paths';
import { isErrorResponse } from '../../utils/error-handlers';
import {
  CLEAR_CARGO_CORPORATE_CREDIT_CITIES,
  CLEAR_CARGO_CORPORATE_CREDIT_CITY,
  FLAG_CARGO_CORPORATE_CREDIT_CITY_ACTIVITY,
  FLAG_GETTING_CARGO_CORPORATE_CREDIT_CITIES,
  GET_CARGO_CORPORATE_CREDIT_CITIES,
  GET_CARGO_CORPORATE_CREDIT_CITY,
  GET_COMMON_PRODUCT_PRICE,
} from '../types';

const flagGettingCargoCorporateCreditCities = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_CARGO_CORPORATE_CREDIT_CITIES,
    payload: flag,
  });

const getCargoCorporateCreditCitiesByLocationAndCustomer =
  async ({ sourceLocationId, destinationLocationId, customerId, businessId }) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingCargoCorporateCreditCities(true));
      const query = {
        sourceLocationId,
        destinationLocationId,
      };
      if (customerId) query.customerId = customerId;
      if (businessId) query.businessId = businessId;
      const url = `${CARGO_CORPORATE_CREDIT_CITY_ENDPOINT}?${new URLSearchParams(
        query,
      )}`;
      const response = await fetch(url, DEFAULT_GET_CONFIG);
      const payload = await response.json();
      dispatch({
        type: GET_CARGO_CORPORATE_CREDIT_CITIES,
        payload,
      });
      if (
        payload.cargoCorporateCredit &&
        payload.cargoCorporateCredit.commonProductList &&
        payload.cargoCorporateCredit.commonProductList.length > 0
      ) {
        dispatch({
          type: GET_COMMON_PRODUCT_PRICE,
          payload: payload.cargoCorporateCredit.commonProductList,
        });
      }

      return payload;
    } catch (error) {
      return null;
    } finally {
      dispatch(flagGettingCargoCorporateCreditCities(false));
    }
  };

const getCargoCorporateCreditCities =
  async ({ cargoCorporateCreditId }) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingCargoCorporateCreditCities(true));
      const url = `${CARGO_CORPORATE_CREDIT_CITY_ENDPOINT}/cargo-corporate-credit/${cargoCorporateCreditId}`;
      const response = await fetch(url, DEFAULT_GET_CONFIG);
      const data = await response.json();
      dispatch({
        type: GET_CARGO_CORPORATE_CREDIT_CITIES,
        payload: data,
      });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagGettingCargoCorporateCreditCities(false));
    }
  };

const clearCargoCorporateCreditCities = () => (dispatch) =>
  dispatch({
    type: CLEAR_CARGO_CORPORATE_CREDIT_CITIES,
  });

const flagCargoCorporateCreditCityActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_CARGO_CORPORATE_CREDIT_CITY_ACTIVITY,
    payload: flag,
  });

const getCargoCorporateCreditCity =
  async ({ cargoCorporateCreditCityId }) =>
  async (dispatch) => {
    try {
      dispatch(flagCargoCorporateCreditCityActivity(true));
      const url = `${CARGO_CORPORATE_CREDIT_CITY_ENDPOINT}/${cargoCorporateCreditCityId}`;
      const response = await fetch(url, DEFAULT_GET_CONFIG);
      const data = await response.json();
      dispatch({
        type: GET_CARGO_CORPORATE_CREDIT_CITY,
        payload: data,
      });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagCargoCorporateCreditCityActivity(false));
    }
  };

const clearCargoCorporateCreditCity = () => (dispatch) =>
  dispatch({
    type: CLEAR_CARGO_CORPORATE_CREDIT_CITY,
  });

const postCargoCorporateCreditCity =
  async (cargoCorporateCreditId, { cityId, customerList }) =>
  async (dispatch) => {
    try {
      dispatch(flagCargoCorporateCreditCityActivity(true));
      const url = `${CARGO_CORPORATE_CREDIT_CITY_ENDPOINT}/cargo-corporate-credit/${cargoCorporateCreditId}`;
      const payload = { cityId, customerList };
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      const data = await response.json();
      dispatch(
        push(`${CARGO_CORPORATE_CREDIT_PATH}/${data.cargoCorporateCreditId}`),
      );
      toastr.success('Ubicación agregada');
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagCargoCorporateCreditCityActivity(false));
    }
  };

export {
  getCargoCorporateCreditCities,
  clearCargoCorporateCreditCities,
  getCargoCorporateCreditCity,
  postCargoCorporateCreditCity,
  clearCargoCorporateCreditCity,
  getCargoCorporateCreditCitiesByLocationAndCustomer,
};
