import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import Modal from '../../../common/modal/Modal';
import RelocationCargoItemWizard from './relocation-cargo-item-qr/RelocationCargoItemWizard';

const RelocationCargoItemByQrButton = ({ disabled, locationId }) => {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => setShowModal(true);

  const body = (
    <RelocationCargoItemWizard
      setShowModal={setShowModal}
      locationId={locationId}
    />
  );

  const onCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <Modal
        show={showModal}
        title="Reubicar Encomiendas por QR"
        size="lg"
        onClickClose={() => onCloseModal()}
        onClickCancel={() => onCloseModal()}
        body={body}
      />
      <Button color="success" onClick={openModal} disabled={disabled}>
        <i className="fa fa-qrcode" /> Reubicar por QR
      </Button>
    </>
  );
};

RelocationCargoItemByQrButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  locationId: PropTypes.number,
};

RelocationCargoItemByQrButton.defaultProps = {
  locationId: 0,
};

export default RelocationCargoItemByQrButton;
