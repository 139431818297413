import React, { useLayoutEffect, useState, useEffect } from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import Loader from '../../../common/Loader';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import {
  clearActiveProcess,
  clearActiveProcesses,
  clearItinerariesForBooking,
  getActiveProcess,
  getProcessByUser,
} from '../../../../actions';
import { filterMethodCaseInsensitive } from '../../../../utils/filter-methods';
import Content from '../../../layout/Content';
import Table from '../../../common/Table';
import { MY_RESERVATIONS_UNIT_PATH } from '../../../../config/paths';
import { genereateMyReservationsActiveProcessColumns } from '../../../../config/columns';

export const MyReservationsActiveProcess = ({
  breadcrumbs,
  activeProcesses,
  loading,
  dispatchGetProcessByUser,
  dispatchClearActiveProcesses,
  location,
  dispatchPush,
  dispatchGetActiveProcess,
  dispatchClearActiveProcess,
  dispatchClearItinerariesForBooking,
}) => {
  useLayoutEffect(() => {
    dispatchClearActiveProcess();
    dispatchClearItinerariesForBooking();
    return () => {
      dispatchClearActiveProcesses();
    };
  }, []);

  const [mobilizing, setMobilizing] = useState('pending');
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    try {
      setMobilizing(JSON.parse(searchParams.get('mobilizing')));
    } catch (error) {
      setMobilizing(null);
    }
  }, [location.search]);

  useEffect(() => {
    if (mobilizing === null) {
      dispatchPush('/my-reservations');
    }

    if (mobilizing !== 'pending') {
      dispatchGetProcessByUser(mobilizing);
    }
  }, [mobilizing]);

  if (mobilizing === 'pending') return <Loader />;

  const data = activeProcesses.toJS() || [];

  const content = (
    <Table
      manual={false}
      columns={genereateMyReservationsActiveProcessColumns(
        dispatchGetActiveProcess,
        dispatchPush,
        mobilizing,
      )}
      data={data}
      filterable
      loading={loading}
      defaultFilterMethod={filterMethodCaseInsensitive}
    />
  );

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title={`Procesos activos para ${
        mobilizing ? 'movilización' : 'desmovilización'
      }`}
      content={content}
    />
  );
};

MyReservationsActiveProcess.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  activeProcesses: PropTypes.instanceOf(Immutable.List),
  loading: PropTypes.bool,
  dispatchGetProcessByUser: PropTypes.func.isRequired,
  dispatchClearActiveProcesses: PropTypes.func.isRequired,
  dispatchClearActiveProcess: PropTypes.func.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  dispatchPush: PropTypes.func.isRequired,
  dispatchGetActiveProcess: PropTypes.func.isRequired,
  dispatchClearItinerariesForBooking: PropTypes.func.isRequired,
};

MyReservationsActiveProcess.defaultProps = {
  activeProcesses: null,
  loading: false,
};

const mapStateToProps = ({ MyReservationsUnit, ItineraryUnit }) => ({
  breadcrumbs: [
    ...MyReservationsUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Reservaciones',
      href: MY_RESERVATIONS_UNIT_PATH,
    },
    {
      text: 'Procesos activos',
      href: '',
    },
  ],
  activeProcesses: ItineraryUnit.Process.getIn(['active', 'content']),
  loading: ItineraryUnit.Process.getIn(['active', 'loading']),
});

const mapDispatchToProps = {
  dispatchPush: push,
  dispatchGetProcessByUser: getProcessByUser,
  dispatchClearActiveProcesses: clearActiveProcesses,
  dispatchGetActiveProcess: getActiveProcess,
  dispatchClearActiveProcess: clearActiveProcess,
  dispatchClearItinerariesForBooking: clearItinerariesForBooking,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MyReservationsActiveProcess);
