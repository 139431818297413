import React, { useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import Content from '../../../layout/Content';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import { CARGO_CORPORATE_CREDIT_PATH } from '../../../../config/paths';
import CargoCorporateCreditCommonProductForm from './CargoCorporateCreditCommonProductForm';
import {
  clearCargoCorporateCredit,
  getCargoCorporateCredit,
  postCargoCorporateCreditCommonProduct,
} from '../../../../actions';

const NewCargoCorporateCreditCommonProduct = ({
  breadcrumbs,
  dispatchGetCargoCorporateCredit,
  dispatchClearCargoCorporateCredit,
  dispatchPostCargoCorporateCreditCommonProduct,
  match: {
    params: { id: cargoCorporateCreditId },
  },
  cargoCorporateCredit,
  loading,
}) => {
  useLayoutEffect(() => {
    dispatchGetCargoCorporateCredit({ cargoCorporateCreditId });
    return () => dispatchClearCargoCorporateCredit();
  }, []);

  let content;

  const handleSubmit = (formValues) => {
    const newFormvalues = {
      height: formValues.height,
      length: formValues.length,
      width: formValues.width,
      weight: formValues.weight,
      productName: formValues.productName,
      productDescription: formValues.productDescription,
      cargoCategoryId: formValues.cargoCategory.value,
      commonProductTemplateId: formValues.commonProductTemplate
        ? formValues.commonProductTemplate.value
        : null,
      cargoCorporateCreditId,
    };
    dispatchPostCargoCorporateCreditCommonProduct(
      cargoCorporateCreditId,
      newFormvalues,
    );
  };

  if (loading) content = <Loader />;
  else if (cargoCorporateCredit.isEmpty())
    content = (
      <NoDataResource
        returnPage={`${CARGO_CORPORATE_CREDIT_PATH}/${cargoCorporateCreditId}`}
      />
    );
  else {
    const cargoCorporateCrediJson = cargoCorporateCredit.toJS();
    content = (
      <CargoCorporateCreditCommonProductForm
        onSubmit={handleSubmit}
        initialValues={{
          cargoCorporateCredit: cargoCorporateCrediJson.name,
        }}
      />
    );
  }

  return (
    <Content
      title="Agregar Producto Frecuente"
      subtitle="Cargo Corporativo"
      content={content}
      breadcrumbs={breadcrumbs}
    />
  );
};

NewCargoCorporateCreditCommonProduct.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetCargoCorporateCredit: PropTypes.func.isRequired,
  dispatchClearCargoCorporateCredit: PropTypes.func.isRequired,
  dispatchPostCargoCorporateCreditCommonProduct: PropTypes.func.isRequired,
  match: matchPropTypes.isRequired,
  loading: PropTypes.bool,
  cargoCorporateCredit: PropTypes.instanceOf(Immutable.Map).isRequired,
};

NewCargoCorporateCreditCommonProduct.defaultProps = {
  loading: false,
};

const mapStateToProps = (
  { CargoUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Créditos Corporativos',
      href: CARGO_CORPORATE_CREDIT_PATH,
    },
    {
      text: 'Ver Crédito Corporativo',
      href: `${CARGO_CORPORATE_CREDIT_PATH}/${id}`,
    },
    {
      text: 'Agregar Producto Frecuente',
      href: '',
    },
  ],
  cargoCorporateCredit: CargoUnit.CargoCorporateCredit.getIn([
    'current',
    'content',
  ]),
  loading: !CargoUnit.CargoCorporateCredit.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetCargoCorporateCredit: getCargoCorporateCredit,
  dispatchClearCargoCorporateCredit: clearCargoCorporateCredit,
  dispatchPostCargoCorporateCreditCommonProduct:
    postCargoCorporateCreditCommonProduct,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewCargoCorporateCreditCommonProduct);
