import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  FLAG_GETTING_COMMON_PRODUCTS,
  GET_COMMON_PRODUCTS,
  CLEAR_COMMON_PRODUCTS,
  GET_COMMON_PRODUCT,
  CLEAR_COMMON_PRODUCT,
  FLAG_COMMON_PRODUCT_ACTIVITY,
  GET_COMMON_PRODUCT_PRICE,
} from '../types/cargo';
import {
  COMMON_PRODUCT_ENDPOINT,
  COMMON_PRODUCT_PRICE_SCHEDULE_ENDPOINT,
  COMMON_PRODUCT_PRICE_SCHEDULE_ITEM_ENDPOINT,
} from '../../config/endpoints';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
  DEFAULT_DELETE_CONFIG,
  BASIC_POST_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import { COMMON_PRODUCT_PATH } from '../../config/paths';

const flagGettingCommonProducts = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_COMMON_PRODUCTS,
    payload: flag,
  });

const getCommonProducts = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingCommonProducts(true));
    const query = tableFilters;
    if (tableFilters && tableFilters.query) {
      tableFilters.query.push('cargoCorporateCreditId:Ø');
    }
    const url = `${COMMON_PRODUCT_ENDPOINT}?${QueryString.stringify(query)}`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(response, null, dispatch);
    const commonProducts = await response.json();
    dispatch({
      type: GET_COMMON_PRODUCTS,
      payload: commonProducts,
    });
  } catch (error) {
    toastr.error('Error', error.message);
  } finally {
    dispatch(flagGettingCommonProducts(false));
  }
};

const clearCommonProducts = () => (dispatch) =>
  dispatch({
    type: CLEAR_COMMON_PRODUCTS,
  });

const flagCommonProductActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_COMMON_PRODUCT_ACTIVITY,
    payload: flag,
  });

const getCommonProduct =
  async ({ id }) =>
  async (dispatch) => {
    try {
      dispatch(flagCommonProductActivity(true));
      const url = `${COMMON_PRODUCT_ENDPOINT}/${id}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      await isErrorResponse(response, null, dispatch);
      const commonProduct = await response.json();
      dispatch({
        type: GET_COMMON_PRODUCT,
        payload: commonProduct,
      });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagCommonProductActivity(false));
    }
  };

const clearCommonProduct = () => (dispatch) =>
  dispatch({
    type: CLEAR_COMMON_PRODUCT,
  });

const postCommonProduct =
  async ({
    productName,
    productDescription,
    internalProduct,
    active,
    weight,
    length,
    width,
    height,
    cargoCategory,
  }) =>
  async (dispatch) => {
    try {
      dispatch(flagCommonProductActivity(true));
      const payload = {
        productName,
        productDescription,
        internalProduct,
        active,
        weight,
        length,
        width,
        height,
        cargoCategoryId: cargoCategory.value,
      };
      const url = COMMON_PRODUCT_ENDPOINT;
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      const commonProduct = await response.json();
      dispatch(push(`${COMMON_PRODUCT_PATH}/${commonProduct.id}`));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagCommonProductActivity(false));
    }
  };

const putCommonProduct =
  async (
    commonProductId,
    {
      productName,
      productDescription,
      internalProduct,
      active,
      weight,
      length,
      width,
      height,
      cargoCategory,
    },
  ) =>
  async (dispatch) => {
    try {
      dispatch(flagCommonProductActivity(true));
      const payload = {
        id: commonProductId,
        productName,
        productDescription,
        internalProduct,
        active,
        weight,
        length,
        width,
        height,
        cargoCategoryId: cargoCategory.value,
      };
      const url = `${COMMON_PRODUCT_ENDPOINT}/${commonProductId}`;
      const response = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      const commonProduct = await response.json();
      dispatch(push(`${COMMON_PRODUCT_PATH}/${commonProduct.id}`));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagCommonProductActivity(false));
    }
  };

const deleteCommonProduct =
  async ({ commonProductId }) =>
  async (dispatch) => {
    try {
      dispatch(flagCommonProductActivity(true));
      const url = `${COMMON_PRODUCT_ENDPOINT}/${commonProductId}`;
      const response = await fetch(url, {
        ...DEFAULT_DELETE_CONFIG,
      });
      await isErrorResponse(response, null, dispatch);
      await response;
      dispatch(push(COMMON_PRODUCT_PATH));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagCommonProductActivity(false));
    }
  };

const getCommonProductPrice =
  async ({ sourceLocationId, destinationLocationId, customerId, businessId }) =>
  async (dispatch) => {
    try {
      dispatch(flagCommonProductActivity(true));
      const url = COMMON_PRODUCT_PRICE_SCHEDULE_ITEM_ENDPOINT;
      const query = {
        sourceLocationId,
        destinationLocationId,
      };
      if (customerId) query.customerId = customerId;
      if (businessId) query.businessId = businessId;
      const response = await fetch(
        `${url}?${QueryString.stringify(query)}`,
        DEFAULT_GET_CONFIG,
      );
      await isErrorResponse(response, null, dispatch);
      const commonProducts = await response.json();
      dispatch({
        type: GET_COMMON_PRODUCT_PRICE,
        payload: commonProducts,
      });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagCommonProductActivity(false));
    }
  };

const getCommonProductExcelTemplate =
  async ({ commonProductId }) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingCommonProducts(true));
      const url = `${COMMON_PRODUCT_PRICE_SCHEDULE_ENDPOINT}/common-product/${commonProductId}/template`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      await isErrorResponse(response, null, dispatch);
      const blob = await response.blob();
      const urlBlob = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = urlBlob;
      link.download = `Plantilla de Tarifario ${commonProductId}.xlsx`;
      link.click();
      link.remove();
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagGettingCommonProducts(false));
    }
  };

const postCommonProductPrice =
  async ({ commonProductId, file }) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingCommonProducts(true));
      const url = `${COMMON_PRODUCT_PRICE_SCHEDULE_ENDPOINT}/common-product/import-data`;
      const formData = new FormData();
      formData.append('file', file);
      formData.append('id', commonProductId);
      const response = await fetch(url, {
        ...BASIC_POST_CONFIG,
        body: formData,
      });
      await isErrorResponse(response, null, dispatch);
      toastr.success('Success', 'Tarifario importado correctamente');
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagGettingCommonProducts(false));
      dispatch(getCommonProduct({ id: commonProductId }));
    }
  };

export {
  flagGettingCommonProducts,
  getCommonProducts,
  clearCommonProducts,
  getCommonProduct,
  clearCommonProduct,
  postCommonProduct,
  putCommonProduct,
  deleteCommonProduct,
  getCommonProductPrice,
  getCommonProductExcelTemplate,
  postCommonProductPrice,
};
