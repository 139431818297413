export const GET_ORIGIN_PARENT_CITIES = 'GET_ORIGIN_PARENT_CITIES';
export const FLAG_GETTING_ORIGIN_PARENT_CITIES =
  'FLAG_GETTING_ORIGIN_PARENT_CITIES';
export const CLEAR_ORIGIN_PARENT_CITIES = 'CLEAR_ORIGIN_PARENT_CITIES';
export const GET_DESTINATION_PARENT_CITIES = 'GET_DESTINATION_PARENT_CITIES';
export const FLAG_GETTING_DESTINATION_PARENT_CITIES =
  'FLAG_GETTING_DESTINATION_PARENT_CITIES';
export const CLEAR_DESTINATION_PARENT_CITIES =
  'CLEAR_DESTINATION_PARENT_CITIES';
export const GET_CHILD_CITIES = 'GET_CHILD_CITIES';
export const FLAG_GETTING_CHILD_CITIES = 'FLAG_GETTING_CHILD_CITIES';
export const CLEAR_CHILD_CITIES = 'CLEAR_CHILD_CITIES';
