import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from './Home';
import BookingUnit from '../modules/booking/BookingModule';
import PostpaidBookingUnit from '../units/postpaidBooking/PostpaidBookingUnit';
import ReportUnit from '../modules/report/ReportModule';
import BusUnit from '../units/bus/BusUnit';
import UserUnit from '../units/user/UserUnit';
import LocationUnit from '../units/location/LocationUnit';
import RouteUnit from '../units/route/RouteUnit';
import ItineraryUnit from '../units/itinerary/ItineraryUnit';
import ReservationUnit from '../units/reservation/ReservationUnit';
import TrafficUnit from '../units/traffic/TrafficUnit';
import SalesUnit from '../units/sales/SalesUnit';
import MacivaUnit from '../modules/maciva-management/MacivaManagementModule';
import HumanResourcesUnit from '../units/human-resources/HumanResourcesUnit';
import SecurityUnit from '../units/security/SecurityUnit';
import AccountingUnit from '../units/accounting/AccountingUnit';
import SystemUnit from '../units/system/SystemUnit';
import SignIn from '../modules/authentication/SignIn';
import SignOut from '../modules/authentication/SignOut';
import Authorization from '../units/authorization/Authorization';
import BaggageUnit from '../units/baggage/BaggageUnit';
import ContractUnit from '../units/contract/ContractUnit';
import Callback from '../modules/authentication/Callback';
import Register from '../modules/authentication/Register';
import MechanicalMaintenanceUnit from '../units/mechanical-maintenance/MechanicalMaintenanceUnit';
import './Main.css';
import {
  ACCOUNTING_UNIT_PATH,
  USER_UNIT_PATH,
  BAGGAGE_UNIT_PATH,
  CARGO_UNIT_PATH,
  BUS_UNIT_PATH,
  LOCATION_UNIT_PATH,
  RESERVATION_UNIT_PATH,
  ITINERARY_UNIT_PATH,
  ROUTE_UNIT_PATH,
  SALES_UNIT_PATH,
  TRAFFIC_UNIT_PATH,
  BOOKING_UNIT_PATH,
  POSTPAID_BOOKING_UNIT_PATH,
  CONTRACT_UNIT_PATH,
  SYSTEM_UNIT_PATH,
  MECHANICAL_MAINTENANCE_UNIT_PATH,
  MY_RESERVATIONS_UNIT_PATH,
} from '../../config/paths';
import CargoUnit from '../units/cargo/CargoUnit';
import MyReservationsUnit from '../units/my-reservations/MyReservationsUnit';

const home = Authorization()(Home);
const bookingUnit = Authorization()(BookingUnit);
const postpaidBookingUnit = Authorization()(PostpaidBookingUnit);
const reportUnit = Authorization()(ReportUnit);
const busUnit = Authorization()(BusUnit);
const userUnit = Authorization()(UserUnit);
const locationUnit = Authorization()(LocationUnit);
const routeUnit = Authorization()(RouteUnit);
const itineraryUnit = Authorization()(ItineraryUnit);
const reservationUnit = Authorization()(ReservationUnit);
const salesUnit = Authorization()(SalesUnit);
const trafficUnit = Authorization()(TrafficUnit);
const macivaUnit = Authorization()(MacivaUnit);
const humanResourcesUnit = Authorization()(HumanResourcesUnit);
const securityUnit = Authorization()(SecurityUnit);
const accountingUnit = Authorization()(AccountingUnit);
const systemUnit = Authorization()(SystemUnit);
const baggageUnit = Authorization()(BaggageUnit);
const cargoUnit = Authorization()(CargoUnit);
const contractUnit = Authorization()(ContractUnit);
const mechanicalMaintenanceUnit = Authorization()(MechanicalMaintenanceUnit);
const myReservationsUnit = Authorization()(MyReservationsUnit);

const Main = () => (
  <main className="Main">
    <Switch>
      <Route exact path="/" component={home} />
      <Route exact path="/sign-in" component={SignIn} />
      <Route exact path="/sign-out" component={SignOut} />
      <Route exact path="/callback" component={Callback} />
      <Route exact path="/register" component={Register} />
      <Route path={BOOKING_UNIT_PATH} component={bookingUnit} />
      <Route
        path={POSTPAID_BOOKING_UNIT_PATH}
        component={postpaidBookingUnit}
      />
      <Route path="/reports" component={reportUnit} />
      <Route path={BUS_UNIT_PATH} component={busUnit} />
      <Route path={USER_UNIT_PATH} component={userUnit} />
      <Route path={LOCATION_UNIT_PATH} component={locationUnit} />
      <Route path={ROUTE_UNIT_PATH} component={routeUnit} />
      <Route path={ITINERARY_UNIT_PATH} component={itineraryUnit} />
      <Route path={RESERVATION_UNIT_PATH} component={reservationUnit} />
      <Route path={SALES_UNIT_PATH} component={salesUnit} />
      <Route path={TRAFFIC_UNIT_PATH} component={trafficUnit} />
      <Route path="/maciva-management" component={macivaUnit} />
      <Route path="/human-resources" component={humanResourcesUnit} />
      <Route path="/security" component={securityUnit} />
      <Route path={ACCOUNTING_UNIT_PATH} component={accountingUnit} />
      <Route path={SYSTEM_UNIT_PATH} component={systemUnit} />
      <Route path={BAGGAGE_UNIT_PATH} component={baggageUnit} />
      <Route path={CARGO_UNIT_PATH} component={cargoUnit} />
      <Route path={CONTRACT_UNIT_PATH} component={contractUnit} />
      <Route
        path={MECHANICAL_MAINTENANCE_UNIT_PATH}
        component={mechanicalMaintenanceUnit}
      />
      <Route path={MY_RESERVATIONS_UNIT_PATH} component={myReservationsUnit} />
    </Switch>
  </main>
);

export default Main;
