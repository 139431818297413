import React from 'react';
import { Col, Row } from 'reactstrap';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import CargoCorporateCreditInformationPropTypes from '../proptypes/CargoCorporateCreditInformationPropTypes';
import IsActive from '../../../../common/cells/IsActive';
import Table from '../../../../common/Table';
import {
  CONTRACT_TYPE,
  DEFAULT_PAGE_SIZE,
} from '../../../../../config/constants';
import { filterMethodCaseInsensitive } from '../../../../../utils/filter-methods';
import {
  CARGO_CORPORATE_CREDIT_CITY_COMMON_PRODUCT_COLUMNS,
  CARGO_CORPORATE_CREDIT_CITY_CUSTOMER_COLUMNS,
  generateCargoCorporateCreditCityCustomerColumns,
} from '../../../../../config/columns';
import Alert from '../../../../common/informative/Alert';
import {
  CARGO_CORPORATE_CREDIT_COMMON_PRODUCT_INFO_MESSAGE,
  CARGO_CORPORATE_CREDIT_CUSTOMER_INFO_MESSAGE,
} from '../../../../../config/messages';
import InternalResource from '../../../../common/resource/InternalResource';
import { numberFormatter } from '../../../../../utils/number';
import { COMMON_PRODUCT_PATH } from '../../../../../config/paths';

const CargoCorporateCreditInformationResource = ({
  id,
  name,
  description,
  active,
  company,
  contractType,
  initialBalance,
  actualBalance,
  creditDays,
  createDate,
  lastUpdate,
  discountPercentage,
  cargoCorporateCreditCityList,
  customer,
  commonProductList,
  parcelPriceConfiguration,
  isDiscountAllowed,
}) => {
  const renderCargoCorporateCreditCityCustomerComponent = ({
    original: { customerList },
  }) =>
    customerList.length && (
      <Table
        manual={false}
        columns={CARGO_CORPORATE_CREDIT_CITY_CUSTOMER_COLUMNS}
        data={customerList}
        defaultPageSize={customerList.length}
        defaultFilterMethod={filterMethodCaseInsensitive}
        showPagination={false}
      />
    );

  const customerTable =
    cargoCorporateCreditCityList.length > 0 ? (
      <Table
        columns={generateCargoCorporateCreditCityCustomerColumns()}
        data={cargoCorporateCreditCityList}
        defaultPageSize={DEFAULT_PAGE_SIZE}
        manual={false}
        rowComponent={renderCargoCorporateCreditCityCustomerComponent}
      />
    ) : (
      <Alert
        message={CARGO_CORPORATE_CREDIT_CUSTOMER_INFO_MESSAGE}
        type="warning"
      />
    );

  const customerResource = (
    <div className="mb-3">
      <h3>Personal Corporativo</h3>
      {customerTable}
    </div>
  );

  const commonProductTable =
    commonProductList.length > 0 ? (
      <Table
        columns={CARGO_CORPORATE_CREDIT_CITY_COMMON_PRODUCT_COLUMNS}
        data={commonProductList}
        defaultPageSize={DEFAULT_PAGE_SIZE}
        manual={false}
        modelPath={COMMON_PRODUCT_PATH}
        navigateToModelOnRowClick
        openPathInNewTab
      />
    ) : (
      <Alert
        message={CARGO_CORPORATE_CREDIT_COMMON_PRODUCT_INFO_MESSAGE}
        type="warning"
      />
    );

  const commonProductResource = (
    <div className="mb-3">
      <h3>Productos Frecuentes</h3>
      {commonProductTable}
    </div>
  );

  const contractInformationResource = (
    <>
      <h3>Información de Contrato</h3>
      {company && (
        <ResourceProperty label="Empresa:">{company.name}</ResourceProperty>
      )}
      {customer && (
        <ResourceProperty label="Persona Natural:">
          {customer.fullName}
        </ResourceProperty>
      )}
      <ResourceProperty label="Tipo de Contrato:">
        {contractType ? CONTRACT_TYPE[contractType].label : '-'}
      </ResourceProperty>
      <ResourceProperty label="Saldo Inicial:">
        {numberFormatter({ value: initialBalance })}
      </ResourceProperty>
      <ResourceProperty label="Saldo Actual:">
        {numberFormatter({ value: actualBalance })}
      </ResourceProperty>
      <ResourceProperty label="Crédito a (días):">
        {creditDays || '-'}
      </ResourceProperty>
      <ResourceProperty label="Porcentaje de Descuento:">
        {numberFormatter({ value: discountPercentage, style: 'percent' })}
      </ResourceProperty>
      <ResourceProperty label="Código de Descuento:">
        <IsActive value={isDiscountAllowed} />
      </ResourceProperty>
    </>
  );

  const internalResource = (
    <InternalResource id={id} createDate={createDate} lastUpdate={lastUpdate} />
  );

  const parcelPriceConfigurationResource = parcelPriceConfiguration && (
    <>
      <h3>Configuración de Tarifa</h3>
      <ResourceProperty label="Precio Base:">
        {parcelPriceConfiguration.basePrice}
      </ResourceProperty>
      <ResourceProperty label="Peso Máximo de Sobre:">
        {parcelPriceConfiguration.maxEnvelopeWeight}
      </ResourceProperty>
      <ResourceProperty label="Divisor Volumétrico:">
        {parcelPriceConfiguration.volumetricDivisor}
      </ResourceProperty>
    </>
  );

  return (
    <>
      <h3>Información Básica</h3>
      <ResourceProperty label="Nombre:">{name}</ResourceProperty>
      <ResourceProperty label="Descripción:">{description}</ResourceProperty>
      <ResourceProperty label="Estado:">
        <IsActive value={active} />
      </ResourceProperty>
      <Row>
        <Col md={6}>{contractInformationResource}</Col>
        <Col md={6}>{internalResource}</Col>
      </Row>
      {parcelPriceConfigurationResource}
      <Row>
        <Col md={6}>{customerResource}</Col>
        <Col md={6}>{commonProductResource}</Col>
      </Row>
    </>
  );
};

CargoCorporateCreditInformationResource.propTypes =
  CargoCorporateCreditInformationPropTypes;

export default CargoCorporateCreditInformationResource;
