import React, { useEffect, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Form, FormGroup, Button, Label, ButtonToolbar, Col } from 'reactstrap';
import { Field, reduxForm, change, formValueSelector } from 'redux-form';
import {
  postCargoPrice,
  getActiveSalesSession,
  getDetractionAmountBySalePrice,
  clearCargoPrices,
  getCommonProductPrice,
  getParcelPriceConfigurations,
  getCreditNotes,
  clearCreditNotes,
  getDeliveryLocationDistrict,
  clearDeliveryLocationDistrict,
  getCargoCorporateCreditCitiesByLocationAndCustomer,
  getCargoCorporateCreditSearch,
  clearCargoCorporateCredit,
} from '../../../../actions';
import {
  isRequired,
  validateNumber,
  validateMaxLength,
  isValidNumber,
  validateLength,
} from '../../../../utils/validators';
import { CARGO_PATH } from '../../../../config/paths';
import {
  ATTACHED_DOCUMENT_PARCEL_COLUMNS,
  generateCargoItemColumns,
} from '../../../../config/dynamicFormFields';
import {
  CONTINGENCY_MOTIVE_OPTION,
  CREDIT_NOTE,
  PARCEL_CATEGORY_ENVELOPE,
  VOUCHER_TYPE_BUSINESS_INVOICE_ID,
  TRANSACTION_TYPE_CREDIT_CARD,
} from '../../../../config/constants';
import Alert from '../../../common/informative/Alert';
import Loader from '../../../common/Loader';
import Select from '../../../common/forms/select/Select';
import FormItem from '../../../common/forms/FormItem';
import TextInput from '../../../common/forms/input/TextInput';
import FormFooter from '../../../common/forms/FormFooter';
import DynamicForm from '../../../common/forms/DynamicForm';
import VoucherTypeSelect from '../../../common/forms/select/VoucherTypeSelect';
import BusinessInputGroup from '../../../common/forms/BusinessInputGroup';
import CustomerInputGroup from '../../../common/forms/CustomerInputGroup';
import CargoLocationSelect from '../../../common/forms/select/CargoLocationSelect';
import PaymentMethodInputGroup from '../../../common/forms/PaymentMethodInputGroup';
import './ParcelForm.css';
import {
  DEFAULT_PARCEL_SERVICE_TYPE_OPTION,
  DEFAULT_VOUCHER_TYPE_OPTION,
} from '../../../../config/defaults';
import ParcelServiceTypeSelect from '../../../common/forms/select/ParcelServiceTypeSelect';
import { isNumber, numberFormatter } from '../../../../utils/number';
import Badge from '../../../common/Badge';
import CreditNoteSelect from '../../../common/forms/select/CreditNoteSelect';
import PaymentMethodSelect from '../../../common/forms/select/PaymentMethodSelect';

const selector = formValueSelector('ParcelForm');
const validataMaxLength10 = validateMaxLength(10);
const validateLength4 = validateLength(4);

const validateSecretCode = (confirmSecretCode, { secretCode }) => {
  if (!confirmSecretCode) return undefined;

  return confirmSecretCode !== secretCode
    ? 'Las claves secretas no coinciden'
    : undefined;
};

const isInvalidNumberValue = (item, value) =>
  !item[value] || !isNumber(item[value], 10) || item[value] <= 0;

export const ParcelForm = ({
  prices,
  cargaItemsValues,
  selectedVoucherType,
  senderCustomerValue,
  onSubmit,
  handleSubmit,
  dispatchChange,
  loading,
  activeSalesSession,
  gettingActiveSalesSession,
  dispatchPostCargoPrice,
  dispatchClearCargoPrices,
  dispatchGetActiveSalesSession,
  dispatchGetDetractionAmountBySalePrice,
  discountCode,
  dispatchPush,
  cargoCategories,
  parcelServiceTypes,
  customerValue,
  businessValue,
  dispatchGetCommonProductPrice,
  dispatchGetParcelPriceConfigurations,
  dispatchGetDeliveryLocationDistrict,
  parcelPriceConfigurations,
  destinationLocationValue,
  dispatchGetCargoCorporateCreditCitiesByLocationAndCustomer,
  dispatchGetCreditNotes,
  dispatchClearCreditNotes,
  creditNotes,
  selectedCreditNotes,
  salePriceValue,
  salePriceWithDetractionValue,
  salePriceWithDiscountValue,
  paymentMethodValue,
  deliveryLocationDistrict,
  loadingDeliveryLocationDistrict,
  dispatchClearDeliveryLocationDistrict,
  district,
  dispatchGetCargoCorporateCreditSearch,
  cargoCorporateCredit,
  dispatchClearCargoCorporateCredit,
}) => {
  const [showDeliveryFields, setShowDeliveryFields] = useState(false);
  const [sourceLocationId, setSourceLocationId] = useState(0);
  const [destinationLocationId, setDestinationLocationId] = useState(0);
  const [parcelServiceTypeId, setParcelServiceTypeId] = useState(0);
  const [showDetraction, setShowDetraction] = useState(false);
  const [detraction, setDetraction] = useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const [isDiscountCodeChecked, setIsDiscountCodeChecked] = useState(false);
  const [showSecondaryConsigneeField, SetShowSecondaryConsigneeField] =
    useState(false);
  const [businessId, setBusinessId] = useState(0);
  const [totaListPrice, setTotaListPrice] = useState(0);
  const [isDocumentListChecked, setIsDocumentListChecked] = useState(false);

  const [cargoCorporateCreditCity, setCargoCorporateCreditCity] =
    useState(null);

  const [showTotalPriceWithDiscount, setShowTotalPriceWithDiscount] =
    useState(false);
  const [showCreditNoteInput, setShowCreditNoteInput] = useState(false);
  const [hasCreditNoteCode, setHasCreditNoteCode] = useState(false);
  const [remainingPrice, setRemainingPrice] = useState(0);
  const [showVoucherCode, setShowVoucherCode] = useState(false);
  const [showDiscountCheckbox, setShowDiscountCheckbox] = useState(false);

  useLayoutEffect(() => {
    dispatchGetActiveSalesSession({ source: CARGO_PATH });
    return () => {
      dispatchClearCargoPrices();
      dispatchClearCreditNotes();
      dispatchClearDeliveryLocationDistrict();
      dispatchClearCargoCorporateCredit();
    };
  }, []);

  const clearSalePrice = async () => {
    dispatchChange('ParcelForm', 'listPrice', '');
    dispatchChange('ParcelForm', 'salePrice', '');
    dispatchChange('ParcelForm', 'discountPrice', '');
    dispatchChange('ParcelForm', 'servicePrice', '');
    dispatchChange('ParcelForm', 'deliveryPrice', '');
  };

  const clearItemMeasures = (index) => {
    dispatchChange('ParcelForm', `cargaItems[${index}].width`, '');
    dispatchChange('ParcelForm', `cargaItems[${index}].height`, '');
    dispatchChange('ParcelForm', `cargaItems[${index}].length`, '');
  };

  useEffect(() => {
    if (
      selectedVoucherType &&
      selectedVoucherType.value !== VOUCHER_TYPE_BUSINESS_INVOICE_ID
    ) {
      setBusinessId(0);
      setDetraction(0);
      setShowDetraction(false);
    }
    clearSalePrice();
    dispatchClearCreditNotes();
    dispatchChange('ParcelForm', 'creditNotes', null);
  }, [selectedVoucherType]);

  useEffect(() => {
    setShowDeliveryFields(false);
    dispatchChange('ParcelForm', 'delivery', false);
    dispatchChange('ParcelForm', 'district', null);
    dispatchChange('ParcelForm', 'deliveryAddress', null);
  }, [loadingDeliveryLocationDistrict]);

  useEffect(() => {
    clearSalePrice();
    if (customerValue !== null) {
      const query = [
        `customerId:${customerValue.value}`,
        'isValid:true',
        'previousParcelId:!Ø',
      ];
      dispatchGetCreditNotes({ query });
    }
    if (businessValue !== null) {
      const query = [
        `businessId:${businessValue.value}`,
        'isValid:true',
        'previousParcelId:!Ø',
      ];
      dispatchGetCreditNotes({ query });
    }
  }, [customerValue, businessValue, discountCode]);

  useEffect(() => {
    setShowLoader(gettingActiveSalesSession || activeSalesSession.isEmpty());
    if (!activeSalesSession.isEmpty()) {
      const {
        locationId,
        location: {
          name,
          address: { city },
          receiveCargoPoint,
        },
      } = activeSalesSession.get('agency');
      if (!receiveCargoPoint) {
        toastr.error('Error', 'La agencia no puede recibir carga.');
        dispatchPush(CARGO_PATH);
        return;
      }
      const sourceLocation = { value: locationId, label: name, city };
      setSourceLocationId(locationId);
      dispatchChange('ParcelForm', 'sourceLocation', sourceLocation);
    }
  }, [gettingActiveSalesSession, activeSalesSession]);

  useEffect(() => {
    if (!prices.isEmpty()) {
      const salePriceWithDetraction = prices.get('finalPrice') - detraction;

      dispatchChange('ParcelForm', 'detraction', detraction.toFixed(2));
      dispatchChange(
        'ParcelForm',
        'salePriceWithDetraction',
        salePriceWithDetraction.toFixed(2),
      );
      dispatchChange('ParcelForm', 'servicePrice', prices.get('servicePrice'));
      dispatchChange(
        'ParcelForm',
        'deliveryPrice',
        prices.get('deliveryPrice'),
      );
    }
  }, [detraction, prices]);

  useEffect(() => {
    if (parcelServiceTypes) {
      dispatchChange(
        'ParcelForm',
        'parcelServiceType',
        DEFAULT_PARCEL_SERVICE_TYPE_OPTION,
      );
      setParcelServiceTypeId(DEFAULT_PARCEL_SERVICE_TYPE_OPTION.value);
    }
  }, [parcelServiceTypes]);

  const changeCommonProductList = async () => {
    const customer = customerValue;
    const business = businessValue;
    const sourceLocation = sourceLocationId;
    const destinationLocation = destinationLocationValue;
    dispatchGetCommonProductPrice({
      sourceLocationId: sourceLocation,
      destinationLocationId: destinationLocation.value,
      customerId: customer ? customer.value : null,
      businessId: business ? business.value : null,
    });
  };

  const changeCustomersByCargoCorporateCreditCity = async () => {
    const customer = customerValue;
    const business = businessValue;

    const response =
      await dispatchGetCargoCorporateCreditCitiesByLocationAndCustomer({
        sourceLocationId,
        destinationLocationId,
        customerId: customer ? customer.value : null,
        businessId: business ? business.value : null,
      });

    setCargoCorporateCreditCity(response);
  };

  useEffect(() => {
    if (destinationLocationId) {
      dispatchGetDeliveryLocationDistrict({
        locationId: destinationLocationId,
      });
    }

    if (
      sourceLocationId &&
      destinationLocationId &&
      (customerValue || businessValue)
    ) {
      changeCustomersByCargoCorporateCreditCity();
      dispatchGetParcelPriceConfigurations();
      dispatchChange('ParcelForm', 'cargaItems', []);
      clearSalePrice();
      changeCommonProductList();
    }
  }, [customerValue, businessValue, sourceLocationId, destinationLocationId]);

  useEffect(() => {
    if (customerValue || businessValue) {
      dispatchClearCargoCorporateCredit();
      dispatchGetCargoCorporateCreditSearch({
        customerId: customerValue ? customerValue.value : null,
        businessId: businessValue ? businessValue.value : null,
      });
    }
  }, [customerValue, businessValue]);

  useEffect(() => {
    if (cargoCorporateCredit.isEmpty()) {
      setShowDiscountCheckbox(true);
      return;
    }

    if (
      !cargoCorporateCredit.isEmpty() &&
      cargoCorporateCredit.toJS().isDiscountAllowed
    ) {
      setShowDiscountCheckbox(true);
      return;
    }

    setShowDiscountCheckbox(false);
  }, [cargoCorporateCredit]);

  useEffect(() => {
    setHasCreditNoteCode(creditNotes.length > 0);
  }, [creditNotes]);

  const calculateTotalListPrice = () => {
    let total = 0;

    prices.get('pricesList').forEach(({ price: { listPrice } }) => {
      total += listPrice;
    });

    dispatchChange('ParcelForm', 'listPrice', total.toFixed(2));
  };

  const calculateTotalSalePrice = async () => {
    const total = prices.get('totalPrice');
    const finalPrice = prices.get('finalPrice');
    const corporateCreditDiscountAmount = prices.get('corporateCreditAmount');
    const listPrice = prices.get('listPrice');

    if (
      selectedVoucherType &&
      selectedVoucherType.value === VOUCHER_TYPE_BUSINESS_INVOICE_ID
    ) {
      const updatedDetraction = await dispatchGetDetractionAmountBySalePrice({
        salePrice: total,
      });
      setDetraction(updatedDetraction);
      setShowDetraction(updatedDetraction !== 0);
    }

    dispatchChange(
      'ParcelForm',
      'corporateCreditAmount',
      corporateCreditDiscountAmount,
    );

    dispatchChange('ParcelForm', 'salePrice', finalPrice.toFixed(2));
    setTotaListPrice(listPrice);

    if (isDiscountCodeChecked) {
      dispatchChange('ParcelForm', 'salePrice', total.toFixed(2));
      dispatchChange('ParcelForm', 'discountPrice', finalPrice.toFixed(2));
    }

    if (corporateCreditDiscountAmount) {
      setShowTotalPriceWithDiscount(true);
      dispatchChange('ParcelForm', 'salePrice', total.toFixed(2));
      dispatchChange('ParcelForm', 'discountPrice', finalPrice.toFixed(2));
    } else {
      setShowTotalPriceWithDiscount(false);
    }
  };

  const showItemListPrice = () => {
    prices
      .get('pricesList')
      .forEach(({ index, price: { listPrice, salePrice } }) => {
        dispatchChange(
          'ParcelForm',
          `cargaItems[${index}].listPrice`,
          listPrice.toFixed(2),
        );
        dispatchChange(
          'ParcelForm',
          `cargaItems[${index}].salePrice`,
          salePrice,
        );
      });

    calculateTotalListPrice();
    calculateTotalSalePrice();
  };

  useEffect(() => {
    if (!prices.isEmpty()) {
      showItemListPrice();
    }
  }, [prices]);

  const determinateFinalSalePrice = () => {
    if (salePriceWithDiscountValue !== null) {
      return salePriceWithDiscountValue;
    }
    if (
      salePriceWithDetractionValue !== null &&
      salePriceWithDetractionValue !== salePriceValue
    ) {
      return salePriceWithDetractionValue;
    }
    return salePriceValue;
  };

  useEffect(() => {
    const totalPrice = parseFloat(determinateFinalSalePrice());

    if (selectedCreditNotes && selectedCreditNotes.length > 0) {
      setRemainingPrice(
        totalPrice -
          selectedCreditNotes.reduce(
            (accumulated, creditNote) => accumulated + creditNote.amount,
            0,
          ),
      );
    }
  }, [selectedCreditNotes]);

  useEffect(() => {
    if (
      paymentMethodValue !== null &&
      paymentMethodValue.value &&
      paymentMethodValue.transactionCode === CREDIT_NOTE
    ) {
      setShowCreditNoteInput(true);
    } else {
      dispatchChange('ParcelForm', 'creditNotes', null);
      setShowCreditNoteInput(false);
    }
  }, [paymentMethodValue]);

  const reinicializeItemPrices = (index) => {
    dispatchChange('ParcelForm', `cargaItems[${index}].listPrice`, '');
    dispatchChange('ParcelForm', `cargaItems[${index}].salePrice`, '');
    dispatchClearCargoPrices();
  };

  const onClickRemove = (index, fields) => {
    fields.remove(index);
    clearSalePrice();
  };

  const onChangeCategory = (index, payload) => {
    const actualCategory =
      typeof payload === 'object'
        ? payload
        : cargaItemsValues[index].cargoCategoryId;

    if (!actualCategory || !actualCategory.value) return;

    dispatchChange(
      'ParcelForm',
      `cargaItems[${index}].cargoCategoryId`,
      actualCategory,
    );

    const actualCommonProduct = cargaItemsValues[index].commonProductId;

    if (!actualCommonProduct) {
      clearItemMeasures(index);
    }

    const categorySelected = cargoCategories.find(
      (category) => category.id === actualCategory.value,
    );

    if (
      categorySelected.name.toLocaleLowerCase() ===
      PARCEL_CATEGORY_ENVELOPE.toLocaleLowerCase()
    ) {
      dispatchChange('ParcelForm', `cargaItems[${index}].isEnvelope`, true);

      if (parcelPriceConfigurations) {
        const parcelPriceConfiguration = parcelPriceConfigurations.find(
          (config) => !config.cargoCorporateCreditId,
        );

        if (parcelPriceConfiguration) {
          dispatchChange(
            'ParcelForm',
            `cargaItems[${index}].weight`,
            parcelPriceConfiguration.maxEnvelopeWeight,
          );
        }
      }
    } else {
      dispatchChange('ParcelForm', `cargaItems[${index}].isEnvelope`, false);
    }
    reinicializeItemPrices(index);
  };

  const onQuantityInputChange = (index) => {
    reinicializeItemPrices(index);
  };

  const clearCommonProduct = (index) => {
    dispatchChange('ParcelForm', `cargaItems[${index}].commonProductId`, null);
    dispatchChange(
      'ParcelForm',
      `cargaItems[${index}].isCommonProductSelected`,
      false,
    );
    clearItemMeasures(index);
    dispatchChange('ParcelForm', `cargaItems[${index}].weight`, '');
    dispatchChange('ParcelForm', `cargaItems[${index}].description`, '');
  };

  const onChangeCommonProduct = (index, payload) => {
    const currentCommonProduct =
      typeof payload === 'object'
        ? payload
        : cargaItemsValues[index].commonProductId;

    if (!currentCommonProduct || !currentCommonProduct.value) return;

    dispatchChange(
      'ParcelForm',
      `cargaItems[${index}].commonProductId`,
      currentCommonProduct,
    );

    dispatchChange('ParcelForm', `cargaItems[${index}].isEnvelope`, false);

    const {
      weight,
      width,
      height,
      length,
      cargoCategoryId,
      productDescription,
    } = currentCommonProduct;

    dispatchChange('ParcelForm', `cargaItems[${index}].weight`, weight);
    dispatchChange('ParcelForm', `cargaItems[${index}].width`, width);
    dispatchChange('ParcelForm', `cargaItems[${index}].height`, height);
    dispatchChange('ParcelForm', `cargaItems[${index}].length`, length);
    dispatchChange(
      'ParcelForm',
      `cargaItems[${index}].description`,
      productDescription,
    );

    const categoryCommonProduct = cargoCategories.find(
      (category) => category.id === cargoCategoryId,
    );

    dispatchChange('ParcelForm', `cargaItems[${index}].cargoCategoryId`, {
      value: categoryCommonProduct.id,
      label: categoryCommonProduct.name,
    });

    if (
      categoryCommonProduct.name.toLocaleLowerCase() ===
      PARCEL_CATEGORY_ENVELOPE.toLocaleLowerCase()
    ) {
      dispatchChange('ParcelForm', `cargaItems[${index}].isEnvelope`, true);
    }

    dispatchChange(
      'ParcelForm',
      `cargaItems[${index}].isCommonProductSelected`,
      true,
    );

    if (currentCommonProduct && currentCommonProduct.price) {
      dispatchChange(
        'ParcelForm',
        `cargaItems[${index}].salePrice`,
        currentCommonProduct.price,
      );
      if (
        cargaItemsValues[index].quantity &&
        isValidNumber(cargaItemsValues[index].quantity) &&
        cargaItemsValues[index].quantity > 0
      ) {
        const total =
          currentCommonProduct.price * cargaItemsValues[index].quantity;
        dispatchChange('ParcelForm', `cargaItems[${index}].salePrice`, total);
      }
    }
  };

  const onCommonProductChange = (index, commonProduct) => {
    clearSalePrice();
    reinicializeItemPrices(index);

    if (!commonProduct.value) {
      clearCommonProduct(index);
      return;
    }

    onChangeCommonProduct(index, commonProduct);
  };

  const onWeightChange = (index) => {
    if (cargaItemsValues[index].commonProductId) return;
    reinicializeItemPrices(index);
  };

  const validateItemValues = (itemsValues) => {
    let isValid = true;
    if (itemsValues.length) {
      const isInvalidData = itemsValues.some((itemValue) => {
        const isEnvelop = !!itemValue.isEnvelope;

        if (isInvalidNumberValue(itemValue, 'quantity')) {
          toastr.error('Error', 'Ingrese una cantidad válida.');
          return true;
        }

        if (!itemValue.cargoCategoryId) {
          toastr.error('Error', 'Seleccione una categoría válida');
          return true;
        }

        if (!itemValue.description) {
          toastr.error('Error', 'Ingrese una descripción válida');
          return true;
        }

        if (isInvalidNumberValue(itemValue, 'weight')) {
          toastr.error('Error', 'Ingrese un peso válido.');
          return true;
        }

        if (isEnvelop) {
          return false;
        }

        if (isInvalidNumberValue(itemValue, 'length')) {
          toastr.error('Error', 'Ingrese un largo válido.');
          return true;
        }

        if (isInvalidNumberValue(itemValue, 'width')) {
          toastr.error('Error', 'Ingrese un ancho válido.');
          return true;
        }

        if (isInvalidNumberValue(itemValue, 'height')) {
          toastr.error('Error', 'Ingrese un alto válido.');
          return true;
        }

        return false;
      });
      isValid = !isInvalidData;
    } else {
      toastr.error('Error', 'Ingrese al menos un item al detalle de carga.');
      isValid = false;
    }
    return isValid;
  };

  const calculateItemListPrice = async () => {
    if (validateItemValues(cargaItemsValues)) {
      dispatchClearCargoPrices();
      const indexes = [];
      const cargoItemList = [];
      cargaItemsValues.forEach((item, index) => {
        const itemToList = {
          quantity: item.quantity,
          weight: item.weight,
          height: item.height,
          width: item.width,
          length: item.length,
          price: item.salePrice,
        };

        if (item.commonProductId) {
          itemToList.commonProductId = item.commonProductId.value;
        }

        if (item.cargoCategoryId) {
          itemToList.categoryId = item.cargoCategoryId.value;
        }

        indexes.push(index);
        cargoItemList.push(itemToList);
      });

      if (showDeliveryFields && !(district && district.value)) {
        toastr.error('Error', 'Seleccione un distrito válido');
        return;
      }

      const request = {
        sourceLocationId,
        destinationLocationId,
        cargoItemList,
        parcelServiceTypeId,
        hasDelivery: showDeliveryFields,
        districtId: (district && district.value) || null,
        businessId: businessId > 0 ? businessId : null,
        discountCodeString: isDiscountCodeChecked ? discountCode : null,
        customerId: customerValue ? customerValue.value : null,
      };

      const response = await dispatchPostCargoPrice(indexes, request);

      if (response === null) {
        clearSalePrice();
      }
    }
  };

  const copySenderToConsignee = () => {
    dispatchChange('ParcelForm', 'consigneeCustomer', senderCustomerValue);
  };
  const copySenderToClient = () => {
    dispatchChange('ParcelForm', 'customer', senderCustomerValue);
  };

  const handleLocationChange = (location) => {
    setDestinationLocationId(location.value || 0);
    clearSalePrice();
  };

  const clearSenderAndConsignee = () => {
    dispatchChange('ParcelForm', 'senderCustomer', '');
    dispatchChange('ParcelForm', 'consigneeCustomer', '');
  };

  const handleBusinessChange = (business) => {
    setBusinessId(business.value || 0);
    clearSenderAndConsignee();
  };

  const handleParcelServiceTypeChange = (parcelServiceType) => {
    setParcelServiceTypeId(parcelServiceType.value || 0);
    clearSalePrice();
  };

  const handleChangeDelivery = ({ target: { checked } }) => {
    setShowDeliveryFields(checked);
    dispatchChange('ParcelForm', 'district', null);
    dispatchChange('ParcelForm', 'deliveryAddress', null);
    clearSalePrice();
  };

  const renderDeliveryFields = () =>
    !showDeliveryFields ? null : (
      <>
        <FormGroup row>
          <FormItem label="Distrito" required>
            <Field
              name="district"
              component={Select}
              options={deliveryLocationDistrict
                .get('districtList')
                .map((currentDistrict) => ({
                  value: currentDistrict.id,
                  label: currentDistrict.name,
                }))}
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Dirección de Entrega" required>
            <Field
              name="deliveryAddress"
              component={TextInput}
              type="text"
              placeholder="Dirección"
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
      </>
    );

  const renderDynamicForm = () => {
    if (sourceLocationId === 0 || destinationLocationId === 0) {
      return <Alert message="Seleccione Origen y Destino" />;
    }
    if (loading) {
      return <Loader />;
    }
    return (
      <DynamicForm
        name="cargaItems"
        columns={generateCargoItemColumns(
          onQuantityInputChange,
          onCommonProductChange,
          onWeightChange,
          onChangeCategory,
          onChangeCommonProduct,
        )}
        onClickRemove={onClickRemove}
      />
    );
  };

  const renderCalculatePriceButton = () => {
    if (
      sourceLocationId === 0 ||
      destinationLocationId === 0 ||
      cargaItemsValues.length === 0
    ) {
      return null;
    }
    return (
      <div className="mt-2 mb-3">
        <ButtonToolbar className="flex row-reverse">
          <div>
            <Button
              type="button"
              outline
              color="primary"
              onClick={() => calculateItemListPrice()}
            >
              Calcular precio
            </Button>
          </div>
        </ButtonToolbar>
      </div>
    );
  };

  const renderDetraction = () => {
    const detractionField = showDetraction ? (
      <>
        <FormGroup row>
          <FormItem label="Detracción">
            <Field
              name="detraction"
              component={TextInput}
              type="text"
              placeholder="Detracción"
              disabled
              validate={[validateNumber]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Precio Total de Venta con Detracción">
            <Field
              name="salePriceWithDetraction"
              component={TextInput}
              type="text"
              placeholder="Precio Total de Venta con Detracción"
              disabled
              validate={[validateNumber]}
            />
          </FormItem>
        </FormGroup>
      </>
    ) : null;
    return detractionField;
  };

  const renderClient = () => {
    if (
      selectedVoucherType &&
      selectedVoucherType.value === VOUCHER_TYPE_BUSINESS_INVOICE_ID
    ) {
      dispatchChange('ParcelForm', 'customer', '');
      return (
        <BusinessInputGroup
          label="Empresa"
          labelRequired
          name="business"
          form="ParcelForm"
          validate={[isRequired]}
          showDetails
          onChange={(option) => handleBusinessChange(option)}
        />
      );
    }
    dispatchChange('ParcelForm', 'business', '');
    return (
      <CustomerInputGroup
        label="Cliente"
        labelRequired
        name="customer"
        form="ParcelForm"
        validate={[isRequired]}
        showDetails
        onChange={clearSenderAndConsignee}
      />
    );
  };

  const handleDiscountCodeChange = (isChecked) => {
    clearSalePrice();
    if (isChecked) {
      setIsDiscountCodeChecked(true);
      return;
    }
    setIsDiscountCodeChecked(false);
    dispatchChange('ParcelForm', 'discountCode', '');
  };

  const renderCopySenderToButton = () => {
    if (!senderCustomerValue) {
      return null;
    }
    const copyButton = (action, className) =>
      action === copySenderToConsignee && (
        <Button
          type="button"
          className={className}
          outline
          color="primary"
          onClick={action}
        >
          Copiar Consignado
        </Button>
      );
    return selectedVoucherType &&
      selectedVoucherType.value === VOUCHER_TYPE_BUSINESS_INVOICE_ID ? (
      <FormGroup row>
        <FormItem>{copyButton(copySenderToConsignee)}</FormItem>
      </FormGroup>
    ) : (
      <>
        <FormGroup row>
          <FormItem>{copyButton(copySenderToConsignee)}</FormItem>
        </FormGroup>
        <FormGroup row>{copyButton(copySenderToClient)}</FormGroup>
      </>
    );
  };

  const onHandleSubmit = (formValues) => {
    if (validateItemValues(cargaItemsValues)) {
      onSubmit(
        formValues,
        showDeliveryFields,
        isDiscountCodeChecked,
        totaListPrice,
        determinateFinalSalePrice(),
      );
    }
  };

  const onShowVoucherCode = () => {
    dispatchChange('CargoPickupModelForm', 'voucherCode', '');
  };

  const voucherCode = showVoucherCode ? (
    <FormGroup row>
      <Col sm={3} xs={12}>
        <Label>
          Nro Referencia (POS) <span className="text-danger">*</span>
        </Label>
      </Col>
      <Col sm={8} xs={12}>
        <Field
          name="voucherCode"
          component={TextInput}
          type="text"
          placeholder="Código de Voucher"
          validate={[isRequired]}
        />
      </Col>
    </FormGroup>
  ) : null;

  const renderSecondaryConsigneeFields = () =>
    showSecondaryConsigneeField &&
    (cargoCorporateCreditCity ? (
      <FormGroup row>
        <FormItem label="Consignado Secundario" required>
          <Field
            name="secondaryConsigneeCustomer"
            component={Select}
            options={
              cargoCorporateCreditCity.consigneeCargoCorporateCreditCity
                ? cargoCorporateCreditCity.consigneeCargoCorporateCreditCity.customerList
                    .filter(
                      (customer) =>
                        customer.customerType === 'SENDER_CONSIGNEE' ||
                        customer.customerType === 'CONSIGNEE',
                    )
                    .map((corporate) => ({
                      value: corporate.customer.id,
                      label: corporate.customer.fullName,
                    }))
                : []
            }
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
    ) : (
      <CustomerInputGroup
        label="Consignado Secundario"
        labelRequired
        name="secondaryConsigneeCustomer"
        form="ParcelForm"
        validate={[isRequired]}
        showDetails
      />
    ));

  const renderContingencyFields = () => {
    let isContingencySalesSession = false;

    if (!activeSalesSession.isEmpty()) {
      isContingencySalesSession =
        activeSalesSession.get('workstation').contingency;
    }
    const contingencyField = isContingencySalesSession ? (
      <>
        <h5>Datos de Contingencia</h5>
        <FormGroup row>
          <FormItem label="Serie de Contingencia" required>
            <Field
              name="contingencyDocumentSeries"
              component={TextInput}
              type="text"
              placeholder="Serie de Contingencia"
              validate={
                isContingencySalesSession
                  ? [isRequired, validateNumber, validateLength4]
                  : []
              }
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Código de Contingencia" required>
            <Field
              name="contingencyDocumentCode"
              component={TextInput}
              type="text"
              placeholder="Código de Contingencia"
              validate={
                isContingencySalesSession ? [isRequired, validateNumber] : []
              }
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Motivo de Contingencia" required>
            <Field
              name="contingencyMotive"
              component={Select}
              placeholder="Motivo de Contingencia"
              options={CONTINGENCY_MOTIVE_OPTION}
              validate={isContingencySalesSession ? [isRequired] : []}
            />
          </FormItem>
        </FormGroup>
      </>
    ) : null;
    return contingencyField;
  };

  if (showLoader) {
    return <Loader />;
  }
  const renderRelationDocumentFields = () => {
    const bussinessVoucher =
      selectedVoucherType &&
      selectedVoucherType.value === VOUCHER_TYPE_BUSINESS_INVOICE_ID;

    return (
      <>
        {bussinessVoucher && (
          <FormGroup row>
            <FormItem>
              <Label>
                <Field
                  name="isRelationDocumentList"
                  id="isRelationDocumentList"
                  component="input"
                  type="checkbox"
                  onChange={(e) => setIsDocumentListChecked(e.target.checked)}
                />{' '}
                Agregar Listado de documentos Relacionados
              </Label>
            </FormItem>
          </FormGroup>
        )}

        {!isDocumentListChecked && (
          <FormGroup row>
            <FormItem label="Documento Relacionado">
              <Field
                name="attachedDocument"
                component={TextInput}
                type="text"
                placeholder="Guia Remisión y/o Factura"
              />
            </FormItem>
          </FormGroup>
        )}

        {isDocumentListChecked && bussinessVoucher && (
          <DynamicForm
            name="attachedDocuments"
            columns={ATTACHED_DOCUMENT_PARCEL_COLUMNS}
          />
        )}
      </>
    );
  };

  let creditNoteResource;

  if (hasCreditNoteCode && customerValue !== null) {
    creditNoteResource = (
      <div className="text-center">
        <Badge color="success" text="Este cliente tiene una nota de crédito" />
      </div>
    );
  } else if (hasCreditNoteCode && businessValue !== null) {
    creditNoteResource = (
      <div className="text-center">
        <Badge color="success" text="Esta empresa tiene una nota de crédito" />
      </div>
    );
  }

  let creditNoteComponent = null;
  if (showCreditNoteInput) {
    creditNoteComponent = (
      <FormGroup row>
        <FormItem label="Nota de crédito" required>
          <Field
            name="creditNotes"
            component={CreditNoteSelect}
            isMulti
            validate={[isRequired]}
            isCustomerIdRequired
            optionsRegistered
          />
        </FormItem>
      </FormGroup>
    );
  }

  const handleNextPaymentMethodChange = (paymentMethod) => {
    setShowVoucherCode(
      paymentMethod.value &&
        paymentMethod.transactionCode === TRANSACTION_TYPE_CREDIT_CARD,
    );
    if (showVoucherCode) change('voucherCode', '');
  };

  let deliveryComponent = null;

  if (
    !deliveryLocationDistrict.isEmpty() &&
    deliveryLocationDistrict.get('districtList').length > 0
  ) {
    deliveryComponent = (
      <FormGroup row>
        <FormItem>
          <Label>
            <Field
              name="delivery"
              id="delivery"
              component="input"
              type="checkbox"
              onChange={handleChangeDelivery}
            />{' '}
            Entrega a Domicilio
          </Label>
        </FormItem>
      </FormGroup>
    );
  }

  if (loadingDeliveryLocationDistrict) {
    deliveryComponent = <Loader />;
  }

  let nextPaymentMethodComponent = null;
  nextPaymentMethodComponent = selectedCreditNotes &&
    selectedCreditNotes.length > 0 &&
    remainingPrice > 0 && (
      <>
        <h3>
          <b>{`Restante: ${numberFormatter({
            style: 'currency',
            value: remainingPrice,
          })}`}</b>
        </h3>
        <FormGroup row>
          <FormItem label="Siguiente método de Pago">
            <Field
              name="nextPaymentMethod"
              component={PaymentMethodSelect}
              onChange={handleNextPaymentMethodChange}
              validate={[isRequired]}
              transactionCodesNotIncluded={[CREDIT_NOTE]}
            />
          </FormItem>
        </FormGroup>
      </>
    );

  return (
    <>
      <Form onSubmit={handleSubmit(onHandleSubmit)}>
        <FormGroup row>
          <FormItem label="Tipo de Comprobante" required>
            <Field
              name="voucherType"
              component={VoucherTypeSelect}
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Tipo de Servicio" required>
            <Field
              name="parcelServiceType"
              component={ParcelServiceTypeSelect}
              validate={[isRequired]}
              onChange={(option) => handleParcelServiceTypeChange(option)}
            />
          </FormItem>
        </FormGroup>
        {renderClient()}
        {creditNoteResource}
        <FormGroup row>
          <FormItem label="Origen" required>
            <Field
              name="sourceLocation"
              component={CargoLocationSelect}
              isDisabled
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Destino" required>
            <Field
              name="destinationLocation"
              component={CargoLocationSelect}
              onChange={handleLocationChange}
              validate={[isRequired]}
              forPickupCargo
            />
          </FormItem>
        </FormGroup>
        {deliveryComponent}
        {renderDeliveryFields()}
        <h5>Detalle de Carga</h5>
        {renderDynamicForm()}
        {renderCalculatePriceButton()}
        <h5>Datos de Pago</h5>
        {cargaItemsValues.length > 0 && (
          <>
            {showDiscountCheckbox && (
              <FormGroup row>
                <FormItem>
                  <Label>
                    <Field
                      name="discount"
                      id="discount"
                      component="input"
                      type="checkbox"
                      onChange={(e) =>
                        handleDiscountCodeChange(e.target.checked)
                      }
                    />{' '}
                    Aplicar Descuento
                  </Label>
                </FormItem>
              </FormGroup>
            )}
            <FormGroup row>
              <FormItem label="Código de Descuento">
                <Field
                  name="discountCode"
                  component={TextInput}
                  type="text"
                  placeholder="Código de Descuento"
                  disabled={!isDiscountCodeChecked}
                  validate={isDiscountCodeChecked ? [isRequired] : []}
                />
              </FormItem>
            </FormGroup>
            <FormGroup row>
              <FormItem label="Servicio de Carga">
                <Field
                  name="servicePrice"
                  component={TextInput}
                  type="text"
                  placeholder="Precio por Servicio de Carga"
                  disabled
                  validate={[validateNumber]}
                />
              </FormItem>
            </FormGroup>
            {showDeliveryFields && (
              <FormGroup row>
                <FormItem label="Servicio de Reparto">
                  <Field
                    name="deliveryPrice"
                    component={TextInput}
                    type="text"
                    placeholder="Precio por Reparto"
                    disabled
                    validate={[validateNumber]}
                  />
                </FormItem>
              </FormGroup>
            )}
          </>
        )}

        <FormGroup row>
          <FormItem label="Precio Total de Venta">
            <Field
              name="salePrice"
              component={TextInput}
              type="text"
              placeholder="Precio Total de Venta"
              disabled
              validate={[isRequired, validateNumber]}
            />
          </FormItem>
        </FormGroup>
        {(isDiscountCodeChecked || showTotalPriceWithDiscount) && (
          <>
            <FormGroup row>
              <FormItem label="Precio Total con Descuento">
                <Field
                  name="discountPrice"
                  component={TextInput}
                  type="text"
                  placeholder="Precio Total con Descuento"
                  disabled
                  validate={[isRequired, validateNumber]}
                />
              </FormItem>
            </FormGroup>
          </>
        )}

        {renderDetraction()}
        <PaymentMethodInputGroup onShowVoucherCode={onShowVoucherCode} />
        {creditNoteComponent}
        {nextPaymentMethodComponent}
        {voucherCode}
        <h5>Datos de Envío</h5>
        {cargoCorporateCreditCity ? (
          <>
            <FormGroup row>
              <FormItem label="Remitente" required>
                <Field
                  name="senderCustomer"
                  options={
                    cargoCorporateCreditCity.senderCargoCorporateCreditCity
                      ? cargoCorporateCreditCity.senderCargoCorporateCreditCity.customerList
                          .filter(
                            (customer) =>
                              customer.customerType === 'SENDER_CONSIGNEE' ||
                              customer.customerType === 'SENDER',
                          )
                          .map((corporate) => ({
                            value: corporate.customer.id,
                            label: corporate.customer.fullName,
                          }))
                      : []
                  }
                  component={Select}
                  validate={[isRequired]}
                  noOptionsMessage={() =>
                    'No hay remitentes disponibles para esta Ciudad del Corporativo'
                  }
                />
              </FormItem>
            </FormGroup>
            <FormGroup row>
              <FormItem label="Consignado" required>
                <Field
                  name="consigneeCustomer"
                  options={
                    cargoCorporateCreditCity.consigneeCargoCorporateCreditCity
                      ? cargoCorporateCreditCity.consigneeCargoCorporateCreditCity.customerList
                          .filter(
                            (customer) =>
                              customer.customerType === 'SENDER_CONSIGNEE' ||
                              customer.customerType === 'CONSIGNEE',
                          )
                          .map((corporate) => ({
                            value: corporate.customer.id,
                            label: corporate.customer.fullName,
                          }))
                      : []
                  }
                  component={Select}
                  validate={[isRequired]}
                  noOptionsMessage={() =>
                    'No hay consignados disponibles para esta Ciudad del Corporativo'
                  }
                />
              </FormItem>
            </FormGroup>
          </>
        ) : (
          <>
            <CustomerInputGroup
              label="Remitente"
              labelRequired
              name="senderCustomer"
              form="ParcelForm"
              validate={[isRequired]}
              showDetails
            />
            {renderCopySenderToButton()}
            <CustomerInputGroup
              label="Consignado"
              labelRequired
              name="consigneeCustomer"
              form="ParcelForm"
              validate={[isRequired]}
              showDetails
            />
          </>
        )}
        <FormGroup row>
          <FormItem>
            <Label>
              <Field
                name="secondaryConsignee"
                id="secondaryConsignee"
                component="input"
                type="checkbox"
                onChange={(e) =>
                  SetShowSecondaryConsigneeField(e.target.checked)
                }
              />{' '}
              Agregar Consignado Secundario
            </Label>
          </FormItem>
        </FormGroup>
        {renderSecondaryConsigneeFields()}
        <FormGroup row>
          <FormItem label="Clave Secreta" required>
            <Field
              className="password"
              name="secretCode"
              component={TextInput}
              type="text"
              placeholder="Clave Secreta"
              validate={[isRequired, validateNumber, validataMaxLength10]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Confirmar Clave Secreta" required>
            <Field
              className="password"
              name="confirmSecretCode"
              component={TextInput}
              type="text"
              placeholder="Confirmar Clave Secreta"
              validate={[
                isRequired,
                validateNumber,
                validataMaxLength10,
                validateSecretCode,
              ]}
            />
          </FormItem>
        </FormGroup>
        {renderRelationDocumentFields()}
        <FormGroup row>
          <FormItem label="Nota">
            <Field
              name="note"
              component={TextInput}
              type="textarea"
              placeholder="Nota"
            />
          </FormItem>
        </FormGroup>
        {renderContingencyFields()}
        <FormFooter
          saveButtonDisabled={loading}
          saveButtonIcon={loading ? 'fa fa-spinner fa-spin' : 'fa fa-save'}
        />
      </Form>
    </>
  );
};

ParcelForm.propTypes = {
  prices: PropTypes.instanceOf(Immutable.Map).isRequired,
  cargaItemsValues: PropTypes.instanceOf(Array),
  selectedVoucherType: PropTypes.instanceOf(Object),
  senderCustomerValue: PropTypes.instanceOf(Object),
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatchChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  activeSalesSession: PropTypes.instanceOf(Immutable.Map).isRequired,
  gettingActiveSalesSession: PropTypes.bool.isRequired,
  dispatchPostCargoPrice: PropTypes.func.isRequired,
  dispatchClearCargoPrices: PropTypes.func.isRequired,
  dispatchGetActiveSalesSession: PropTypes.func.isRequired,
  dispatchGetDetractionAmountBySalePrice: PropTypes.func.isRequired,
  discountCode: PropTypes.string,
  dispatchPush: PropTypes.func.isRequired,
  cargoCategories: PropTypes.instanceOf(Array).isRequired,
  parcelServiceTypes: PropTypes.instanceOf(Array).isRequired,
  customerValue: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  }),
  businessValue: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  }),
  dispatchGetCommonProductPrice: PropTypes.func.isRequired,
  dispatchGetParcelPriceConfigurations: PropTypes.func.isRequired,
  dispatchGetDeliveryLocationDistrict: PropTypes.func.isRequired,
  dispatchClearDeliveryLocationDistrict: PropTypes.func.isRequired,
  parcelPriceConfigurations: PropTypes.instanceOf(Array).isRequired,
  destinationLocationValue: PropTypes.instanceOf(Object),
  dispatchGetCargoCorporateCreditCitiesByLocationAndCustomer:
    PropTypes.func.isRequired,
  dispatchGetCreditNotes: PropTypes.func.isRequired,
  dispatchClearCreditNotes: PropTypes.func.isRequired,
  creditNotes: PropTypes.instanceOf(Array),
  selectedCreditNotes: PropTypes.instanceOf(Array),
  salePriceValue: PropTypes.string,
  salePriceWithDetractionValue: PropTypes.string,
  salePriceWithDiscountValue: PropTypes.string,
  paymentMethodValue: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
    transactionCode: PropTypes.string,
  }),
  district: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  }),
  deliveryLocationDistrict: PropTypes.instanceOf(Immutable.Map).isRequired,
  loadingDeliveryLocationDistrict: PropTypes.bool,
  dispatchGetCargoCorporateCreditSearch: PropTypes.func.isRequired,
  cargoCorporateCredit: PropTypes.instanceOf(Immutable.Map).isRequired,
  dispatchClearCargoCorporateCredit: PropTypes.func.isRequired,
};

ParcelForm.defaultProps = {
  cargaItemsValues: [],
  selectedVoucherType: null,
  senderCustomerValue: null,
  loading: false,
  discountCode: null,
  customerValue: null,
  businessValue: null,
  creditNotes: [],
  selectedCreditNotes: [],
  salePriceValue: null,
  salePriceWithDetractionValue: null,
  salePriceWithDiscountValue: null,
  destinationLocationValue: null,
  paymentMethodValue: null,
  district: null,
  loadingDeliveryLocationDistrict: false,
};

const mapStateToProps = (state) => ({
  loading: !state.CargoUnit.Cargo.getIn(['current', 'activity']).isEmpty(),
  loadingCustomer: !state.UserUnit.Customer.getIn([
    'current',
    'activity',
  ]).isEmpty(),
  cargaItemsValues: selector(state, 'cargaItems'),
  salePriceValue: selector(state, 'salePrice'),
  selectedVoucherType: selector(state, 'voucherType'),
  senderCustomerValue: selector(state, 'senderCustomer'),
  consigneeCustomerValue: selector(state, 'consigneeCustomer'),
  customerValue: selector(state, 'customer'),
  businessValue: selector(state, 'business'),
  destinationLocationValue: selector(state, 'destinationLocation'),
  prices: state.CargoUnit.Cargo.getIn(['current', 'price']),
  gettingActiveSalesSession: state.SalesUnit.SalesSession.getIn([
    'active',
    'loading',
  ]),
  activeSalesSession: state.SalesUnit.SalesSession.getIn(['active', 'content']),
  commonProducts: state.CargoUnit.CommonProduct.getIn([
    'current',
    'price',
  ]).toJS(),
  discountCode: selector(state, 'discountCode'),
  cargoCategories: state.CargoUnit.CargoCategory.getIn([
    'all',
    'content',
    'content',
  ]),
  parcelServiceTypes: state.CargoUnit.ParcelServiceType.getIn([
    'all',
    'content',
    'content',
  ]),
  commonProductPrices: state.CargoUnit.CommonProduct.getIn([
    'current',
    'price',
  ]).toJS(),
  parcelPriceConfigurations: state.CargoUnit.ParcelPriceConfiguration.getIn([
    'all',
    'content',
    'content',
  ]),
  creditNotes: state.SalesUnit.CreditNote.getIn(['all', 'content', 'content']),
  selectedCreditNotes: selector(state, 'creditNotes'),
  salePriceWithDiscountValue: selector(state, 'discountPrice'),
  salePriceWithDetractionValue: selector(state, 'salePriceWithDetraction'),
  paymentMethodValue: selector(state, 'paymentMethod'),
  district: selector(state, 'district'),
  deliveryLocationDistrict: state.CargoUnit.DeliveryLocationDistrict.getIn([
    'current',
    'content',
  ]),
  loadingDeliveryLocationDistrict:
    !state.CargoUnit.DeliveryLocationDistrict.getIn([
      'current',
      'activity',
    ]).isEmpty(),
  cargoCorporateCredit: state.CargoUnit.CargoCorporateCredit.getIn([
    'current',
    'content',
  ]),
});

const mapDispatchToProps = {
  dispatchChange: change,
  dispatchPostCargoPrice: postCargoPrice,
  dispatchClearCargoPrices: clearCargoPrices,
  dispatchGetActiveSalesSession: getActiveSalesSession,
  dispatchGetDetractionAmountBySalePrice: getDetractionAmountBySalePrice,
  dispatchPush: push,
  dispatchGetCommonProductPrice: getCommonProductPrice,
  dispatchGetParcelPriceConfigurations: getParcelPriceConfigurations,
  dispatchGetCargoCorporateCreditCitiesByLocationAndCustomer:
    getCargoCorporateCreditCitiesByLocationAndCustomer,
  dispatchGetCreditNotes: getCreditNotes,
  dispatchClearCreditNotes: clearCreditNotes,
  dispatchGetDeliveryLocationDistrict: getDeliveryLocationDistrict,
  dispatchClearDeliveryLocationDistrict: clearDeliveryLocationDistrict,
  dispatchGetCargoCorporateCreditSearch: getCargoCorporateCreditSearch,
  dispatchClearCargoCorporateCredit: clearCargoCorporateCredit,
};

const formComponent = reduxForm({
  form: 'ParcelForm',
  initialValues: {
    voucherType: DEFAULT_VOUCHER_TYPE_OPTION,
  },
})(ParcelForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
