import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { change, Field, reduxForm } from 'redux-form';
import { PropTypes } from 'prop-types';
import { Button, FormGroup, Form } from 'reactstrap';
import FormFooter from '../../../../common/forms/FormFooter';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import FormItem from '../../../../common/forms/FormItem';
import { isRequired } from '../../../../../utils/validators';
import Select from '../../../../common/forms/select/Select';
import { optionsPropTypes } from '../../../../common/forms/select/SelectPropTypes';
import { getUserAgenciesV2 } from '../../../../../actions';

const ContingencySenderRemisionGuideForm = ({
  handleSubmit,
  itineraryId,
  loadingAgencies,
  agencyOptions,
  handleProcess,
  onCancel,
  user,
  dispatchGetUserAgenciesV2,
  dispatchChange,
}) => {
  useEffect(() => {
    dispatchGetUserAgenciesV2({ userId: user.id });
  }, [user]);

  useEffect(() => {
    if (agencyOptions.length !== 0) {
      dispatchChange(
        'ContingencySenderRemisionGuideForm',
        'agency',
        agencyOptions[0],
      );
    }
  }, [agencyOptions]);

  return (
    <Form onSubmit={handleSubmit(handleProcess)}>
      <p>Se imprimirá la guía de remisión de contingencia para:</p>
      <ResourceProperty label="Itinerario:">{itineraryId}</ResourceProperty>
      <hr />

      <FormGroup row className="mt-4">
        <FormItem label="Agencia Actual" required>
          <Field
            name="agency"
            component={Select}
            isLoading={loadingAgencies}
            options={agencyOptions}
            isDisabled={agencyOptions.length <= 1}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormFooter
        saveButtonColor="secondary"
        saveButtonIcon="fa fa-print"
        saveButtonText="Imprimir Contingencia"
      >
        <Button color="primary" onClick={onCancel}>
          Cancelar
        </Button>
      </FormFooter>
    </Form>
  );
};

ContingencySenderRemisionGuideForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loadingAgencies: PropTypes.bool,
  agencyOptions: optionsPropTypes,
  itineraryId: PropTypes.number,
  handleProcess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    customer: PropTypes.shape({
      fullName: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  dispatchGetUserAgenciesV2: PropTypes.func.isRequired,
  dispatchChange: PropTypes.func.isRequired,
};

ContingencySenderRemisionGuideForm.defaultProps = {
  itineraryId: 0,
  agencyOptions: [],
  loadingAgencies: false,
};

const mapStateToProps = (state) => ({
  user: state.authentication.get('user') || undefined,
  loadingAgencies: state.HumanResourcesUnit.Agency.getIn(['all', 'loading']),
  agencyOptions: state.HumanResourcesUnit.Agency.getIn([
    'all',
    'content',
    'content',
  ]).map((agency) => ({
    value: agency.locationId,
    label: agency.name,
  })),
});
const mapDispatchToProps = {
  dispatchGetUserAgenciesV2: getUserAgenciesV2,
  dispatchChange: change,
};

const formComponent = reduxForm({
  form: 'ContingencySenderRemisionGuideForm',
})(ContingencySenderRemisionGuideForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
