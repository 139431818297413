import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SHELF_PATH } from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import { postShelf } from '../../../../actions';
import ShelfForm from './ShelfForm';

const NewShelf = ({ breadcrumbs, dispatchPostShelf }) => {
  const onSubmit = (formValues, shelfData) => {
    const newFormValues = {
      name: formValues.name,
      locationId: formValues.locationId ? formValues.locationId.value : null,
      width: formValues.width,
      height: formValues.height,
      shelfSpaceList: shelfData,
    };
    dispatchPostShelf(newFormValues);
  };

  const content = <ShelfForm onSubmit={onSubmit} />;

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Nuevo Anaquel"
      subtitle="Crear anaquel"
      content={content}
    />
  );
};

NewShelf.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostShelf: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchPostShelf: postShelf,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Anaqueles',
      href: SHELF_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
});

export default connect(mapStateToProps, mapDispatchToProps)(NewShelf);
