import React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  MY_RESERVATIONS_BOOKING_PATH,
  MY_RESERVATIONS_UNIT_PATH,
} from '../../../config/paths';
import MyReservationsHome from './MyReservationsHome';
import MyReservationsBooking from './booking/MyReservationsBooking';
import MyReservation from './MyReservation';
import MyReservationsActiveProcess from './process/MyReservationsActiveProcess';

const MyReservationsUnit = () => (
  <Switch>
    <Route
      exact
      path={MY_RESERVATIONS_UNIT_PATH}
      component={MyReservationsHome}
    />
    <Route
      path={MY_RESERVATIONS_BOOKING_PATH}
      component={MyReservationsBooking}
    />
    <Route
      path={`${MY_RESERVATIONS_UNIT_PATH}/process`}
      component={MyReservationsActiveProcess}
    />
    <Route
      exact
      path={`${MY_RESERVATIONS_UNIT_PATH}/:id`}
      component={MyReservation}
    />
  </Switch>
);

export default MyReservationsUnit;
