import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from './Select';

const CommonProductSelectCargoForm = ({ commonProducts, loading, ...rest }) => (
  <Select isLoading={loading} options={commonProducts} isClearable {...rest} />
);

CommonProductSelectCargoForm.propTypes = {
  commonProducts: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
    }),
  ).isRequired,
  loading: PropTypes.bool,
};

CommonProductSelectCargoForm.defaultProps = {
  loading: false,
};

const mapStateToProps = ({ CargoUnit }) => ({
  commonProducts: CargoUnit.CommonProduct.getIn(['current', 'price']).map(
    (commonProductPrice) => ({
      value: commonProductPrice.id,
      label: commonProductPrice.productName,
      ...commonProductPrice,
    }),
  ),
  loading: !CargoUnit.CommonProduct.getIn(['current', 'activity']).isEmpty(),
});

export default connect(mapStateToProps, null)(CommonProductSelectCargoForm);
