export const FLAG_GETTING_PROCESSES = 'FLAG_GETTING_PROCESSES';
export const GET_PROCESSES = 'GET_PROCESSES';
export const CLEAR_PROCESSES = 'CLEAR_PROCESSES';
export const FLAG_PROCESS_ACTIVITY = 'FLAG_PROCESS_ACTIVITY';
export const GET_PROCESS = 'GET_PROCESS';
export const CLEAR_PROCESS = 'CLEAR_PROCESS';
export const FLAG_GETTING_ACTIVE_PROCESSES = 'FLAG_GETTING_ACTIVE_PROCESSES';
export const GET_ACTIVE_PROCESSES = 'GET_ACTIVE_PROCESSES';
export const CLEAR_ACTIVE_PROCESSES = 'CLEAR_ACTIVE_PROCESSES';
export const FLAG_ACTIVE_PROCESS_ACTIVITY = 'FLAG_ACTIVE_PROCESS_ACTIVITY';
export const GET_ACTIVE_PROCESS = 'GET_ACTIVE_PROCESS';
export const CLEAR_ACTIVE_PROCESS = 'CLEAR_ACTIVE_PROCESS';
