import React, { useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getUserAgenciesV2, clearAgencies } from '../../../../actions/index';
import { optionsPropTypes } from './SelectPropTypes';
import Select from './Select';
import { USER } from '../../../../config/shapes';

export const UserAgenciesSelect = ({
  loading,
  options,
  isGridVariant,
  isMulti,
  isClearable,
  onValueChange,
  dispatchClearAgencies,
  dispatchGetUserAgenciesV2,
  user,
  ...rest
}) => {
  useEffect(() => {
    if (user) {
      dispatchGetUserAgenciesV2({ userId: user.id });
    }
  }, [user]);

  useLayoutEffect(
    () => () => {
      dispatchClearAgencies();
    },
    [],
  );

  return (
    <Select
      isLoading={loading}
      options={options}
      isMulti={isMulti}
      onValueChange={onValueChange}
      isClearable={isClearable}
      isGridVariant={isGridVariant}
      {...rest}
    />
  );
};

UserAgenciesSelect.propTypes = {
  isGridVariant: PropTypes.bool,
  dispatchGetUserAgenciesV2: PropTypes.func.isRequired,
  dispatchClearAgencies: PropTypes.func.isRequired,
  onValueChange: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  isMulti: PropTypes.bool,
  options: optionsPropTypes,
  isClearable: PropTypes.bool,
  placeholder: PropTypes.string,
  noOptionsMessage: PropTypes.func,
  user: PropTypes.shape(USER),
};

UserAgenciesSelect.defaultProps = {
  isGridVariant: false,
  isMulti: false,
  options: [],
  isClearable: false,
  onValueChange: undefined,
  placeholder: 'Selecciona una Agencia',
  noOptionsMessage: undefined,
  user: null,
};

const mapStateToProps = ({ HumanResourcesUnit, authentication }) => ({
  user: authentication.get('user') || undefined,
  options: HumanResourcesUnit.Agency.getIn(['all', 'content', 'content']).map(
    (agency) => ({
      value: agency.id,
      label: agency.name,
    }),
  ),
  loading: HumanResourcesUnit.Agency.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetUserAgenciesV2: getUserAgenciesV2,
  dispatchClearAgencies: clearAgencies,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserAgenciesSelect);
