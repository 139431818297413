import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { MAINTENANCE_TASK_PATH, SHELF_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import { clearShelf, getShelf } from '../../../../actions';
import ShelfBasicInformationResource from './resource/ShelfBasicInformationResource';
import ShelfToolbar from './ShelfToolbar';

export const Shelf = ({
  breadcrumbs,
  dispatchClearShelf,
  dispatchGetShelf,
  match: {
    params: { id: shelfId },
  },
  loading,
  shelf,
}) => {
  useLayoutEffect(() => {
    dispatchGetShelf({ shelfId });

    return () => dispatchClearShelf();
  }, []);

  let content;
  let toolbar;

  if (loading) content = <Loader />;
  else if (shelf.isEmpty())
    content = <NoDataResource returnPage={MAINTENANCE_TASK_PATH} />;
  else {
    const shelfJson = shelf.toJS();

    content = (
      <>
        <h3>Información Básica</h3>
        <ShelfBasicInformationResource {...shelfJson} />
      </>
    );

    toolbar = <ShelfToolbar shelfId={shelfJson.id} shelf={shelf} />;
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      toolbar={toolbar}
      title="Anaquel"
      content={content}
    />
  );
};

Shelf.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchClearShelf: PropTypes.func.isRequired,
  dispatchGetShelf: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  shelf: PropTypes.instanceOf(Immutable.Map).isRequired,
};

Shelf.defaultProps = {
  loading: false,
};

const mapDispatchToProps = {
  dispatchClearShelf: clearShelf,
  dispatchGetShelf: getShelf,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Anaqueles',
      href: SHELF_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  shelf: CargoUnit.Shelf.getIn(['current', 'content']),
  loading: !CargoUnit.Shelf.getIn(['current', 'activity']).isEmpty(),
});

export default connect(mapStateToProps, mapDispatchToProps)(Shelf);
