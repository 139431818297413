import React, { useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import {
  clearCargoCorporateCredit,
  getCargoCorporateCredit,
} from '../../../../actions/cargo/CargoCorporateCredit';
import { CARGO_CORPORATE_CREDIT_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import ParcelPriceForm from '../parcel-price/ParcelPriceForm';
import {
  getExcelTemplate,
  postParcelPrice,
} from '../../../../actions/cargo/ParcelPrice';

const NewCorporateParcelPriceSchedule = ({
  breadcrumbs,
  loading,
  cargoCorporateCredit,
  dispatchGetCargoCorporateCredit,
  dispatchClearCargoCorporateCredit,
  match: {
    params: { id: cargoCorporateCreditId },
  },
  dispatchPostParcelPrice,
  dispatchGetTemplate,
}) => {
  useLayoutEffect(() => {
    dispatchGetCargoCorporateCredit({ cargoCorporateCreditId });
    return () => dispatchClearCargoCorporateCredit();
  }, []);

  let content;

  const onSubmit = (formValues) => {
    const newFormValues = {
      ...formValues,
      cargoCorporateCreditId,
    };
    dispatchPostParcelPrice(newFormValues);
  };

  const onGetTemplate = () => {
    dispatchGetTemplate({ cargoCorporateCreditId });
  };

  if (loading) content = <Loader />;
  else if (cargoCorporateCredit.isEmpty()) {
    content = (
      <NoDataResource
        returnPage={`${CARGO_CORPORATE_CREDIT_PATH}/${cargoCorporateCreditId}`}
      />
    );
  } else {
    content = (
      <ParcelPriceForm
        onSubmit={onSubmit}
        dispatchGetTemplate={onGetTemplate}
      />
    );
  }

  return (
    <Content title="Nueva Tarifa" breadcrumbs={breadcrumbs} content={content} />
  );
};

NewCorporateParcelPriceSchedule.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  match: matchPropTypes.isRequired,
  loading: PropTypes.bool,
  cargoCorporateCredit: PropTypes.instanceOf(Immutable.Map),
  dispatchGetCargoCorporateCredit: PropTypes.func.isRequired,
  dispatchClearCargoCorporateCredit: PropTypes.func.isRequired,
  dispatchPostParcelPrice: PropTypes.func.isRequired,
  dispatchGetTemplate: PropTypes.func.isRequired,
};

NewCorporateParcelPriceSchedule.defaultProps = {
  loading: false,
  cargoCorporateCredit: Immutable.Map(),
};

const mapStateToProps = (
  { CargoUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Créditos Corporativos',
      href: CARGO_CORPORATE_CREDIT_PATH,
    },
    {
      text: 'Ver Crédito Corporativo',
      href: `${CARGO_CORPORATE_CREDIT_PATH}/${id}`,
    },
    {
      text: 'Nueva Tarifa',
      href: '',
    },
  ],
  cargoCorporateCredit: CargoUnit.CargoCorporateCredit.getIn([
    'current',
    'content',
  ]),
  loading: !CargoUnit.CargoCorporateCredit.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetCargoCorporateCredit: getCargoCorporateCredit,
  dispatchClearCargoCorporateCredit: clearCargoCorporateCredit,
  dispatchPostParcelPrice: postParcelPrice,
  dispatchGetTemplate: getExcelTemplate,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewCorporateParcelPriceSchedule);
