import React from 'react';
import { Form, FormGroup } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { change, Field, reduxForm } from 'redux-form';
import FormItem from '../../../common/forms/FormItem';
import TextInput from '../../../common/forms/input/TextInput';
import CommonProductSelect from '../../../common/forms/select/CommonProductSelect';
import { isRequired } from '../../../../utils/validators';
import CargoCategorySelect from '../../../common/forms/select/CargoCategorySelect';
import FormFooter from '../../../common/forms/FormFooter';

const CargoCorporateCreditCommonProductForm = ({
  dispatchChange,
  handleSubmit,
}) => {
  const handleCommonProductTemplateChange = (commonProduct) => {
    const currentCommonProduct =
      commonProduct && commonProduct.value ? commonProduct : null;

    if (!currentCommonProduct) {
      dispatchChange(
        'cargoCorporateCreditCommonProductForm',
        'productName',
        '',
      );
      dispatchChange(
        'cargoCorporateCreditCommonProductForm',
        'productDescription',
        '',
      );
      dispatchChange(
        'cargoCorporateCreditCommonProductForm',
        'cargoCategory',
        '',
      );
      dispatchChange('cargoCorporateCreditCommonProductForm', 'weight', '');
      dispatchChange('cargoCorporateCreditCommonProductForm', 'length', '');
      dispatchChange('cargoCorporateCreditCommonProductForm', 'width', '');
      dispatchChange('cargoCorporateCreditCommonProductForm', 'height', '');

      return;
    }

    dispatchChange(
      'cargoCorporateCreditCommonProductForm',
      'productName',
      currentCommonProduct.label,
    );
    dispatchChange(
      'cargoCorporateCreditCommonProductForm',
      'productDescription',
      currentCommonProduct.productDescription,
    );
    dispatchChange('cargoCorporateCreditCommonProductForm', 'cargoCategory', {
      value: currentCommonProduct.cargoCategoryId,
      label: currentCommonProduct.cargoCategory.name,
    });
    dispatchChange(
      'cargoCorporateCreditCommonProductForm',
      'weight',
      currentCommonProduct.weight,
    );
    dispatchChange(
      'cargoCorporateCreditCommonProductForm',
      'length',
      currentCommonProduct.length,
    );
    dispatchChange(
      'cargoCorporateCreditCommonProductForm',
      'width',
      currentCommonProduct.width,
    );
    dispatchChange(
      'cargoCorporateCreditCommonProductForm',
      'height',
      currentCommonProduct.height,
    );
  };

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup row>
        <FormItem label="Corporativo" required>
          <Field
            name="cargoCorporateCredit"
            component={TextInput}
            placeholder="Ubicación"
            disabled
          />
        </FormItem>
      </FormGroup>

      <FormGroup row>
        <FormItem label="Plantilla de Producto Frecuente">
          <Field
            name="commonProductTemplate"
            component={CommonProductSelect}
            placeholder="Producto Frecuente"
            isClearable
            onChange={handleCommonProductTemplateChange}
            isGlobalCommonProduct
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Nombre Producto" required>
          <Field
            name="productName"
            component={TextInput}
            type="text"
            placeholder="Nombre Producto"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Descripción Producto">
          <Field
            name="productDescription"
            component={TextInput}
            type="text"
            placeholder="Descripción Producto"
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Categoria" required>
          <Field
            name="cargoCategory"
            component={CargoCategorySelect}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Peso (kg)" required>
          <Field
            name="weight"
            component={TextInput}
            type="text"
            placeholder="Peso (kg)"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Largo (cm)">
          <Field
            name="length"
            component={TextInput}
            type="text"
            placeholder="Largo (cm)"
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Ancho (cm)">
          <Field
            name="width"
            component={TextInput}
            type="text"
            placeholder="Ancho (cm)"
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Alto (cm)">
          <Field
            name="height"
            component={TextInput}
            type="text"
            placeholder="Alto (cm)"
          />
        </FormItem>
      </FormGroup>
      <FormFooter />
    </Form>
  );
};

CargoCorporateCreditCommonProductForm.propTypes = {
  dispatchChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchChange: change,
};

const form = reduxForm({
  form: 'cargoCorporateCreditCommonProductForm',
})(CargoCorporateCreditCommonProductForm);

export default connect(null, mapDispatchToProps)(form);
