import {
  addImage,
  addQrCode,
  addText,
  createPdfDocument,
  setFont,
} from '../pdf';
import LogoCiva from '../../resources/images/logo-civa.png';

const margin = 5;
const qrSize = 50;
const imageWidth = 25;
const imageHeight = 12;
const ticketWidth = 112;
const ticketHeight = 65;

export default async function printShelfSpace(shelf) {
  if (!shelf || !Array.isArray(shelf.shelfSpaceList)) {
    return;
  }

  const doc = createPdfDocument('l', 'mm', [ticketWidth, ticketHeight]);

  const activeSpaces = shelf.shelfSpaceList.filter((space) => !space.disabled);

  if (activeSpaces.length === 0) {
    return;
  }

  const printTasks = activeSpaces.map((space, index) => async () => {
    if (index > 0) {
      doc.addPage('l', 'mm');
    }

    const myY = margin;

    addImage(doc, LogoCiva, margin, myY, imageWidth, imageHeight);

    setFont(doc, 'bold');
    doc.setFontSize(14);
    addText(
      doc,
      shelf.name.toUpperCase(),
      margin,
      myY + imageHeight + 5,
      80,
      'left',
    );

    doc.setFontSize(35);
    addText(
      doc,
      space.spaceName,
      margin + 20,
      myY + imageHeight + 25,
      80,
      'left',
    );

    const qrX = ticketWidth - qrSize - margin;
    const qrY = (ticketHeight - qrSize) / 2;
    const qrContent = `A-${String(space.id)}-${String(space.spaceName)}`.trim();
    await addQrCode(doc, qrContent, qrX, qrY, qrSize, qrSize);
  });

  await printTasks.reduce(
    (promiseChain, currentTask) => promiseChain.then(() => currentTask()),
    Promise.resolve(),
  );

  doc.autoPrint();
  window.open(doc.output('bloburl'), '_blank');
}
