import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import { DELIVERY_LOCATION_DISTRICT_PATH } from '../../../../config/paths';
import DeliveryLocationDistrictForm from './DeliveryLocationDistrictForm';
import {
  clearDeliveryLocationDistrict,
  getDeliveryLocationDistrict,
  putDeliveryLocationDistrict,
} from '../../../../actions';

const EditDeliveryLocationDistrict = ({
  breadcrumbs,
  dispatchGetDeliveryLocationDistrict,
  dispatchClearDeliveryLocationDistrict,
  dispatchPutDeliveryLocationDistrict,
  match: {
    params: { id: locationId },
  },
  loading,
  deliveryLocationDistrict,
}) => {
  useLayoutEffect(() => {
    dispatchGetDeliveryLocationDistrict({ locationId });

    return () => dispatchClearDeliveryLocationDistrict();
  }, []);

  const onSubmit = (formValues) =>
    dispatchPutDeliveryLocationDistrict(locationId, {
      locationId: formValues.location.value,
      districtIds: formValues.districts.map((district) => district.value),
    });

  const generateInitialValues = ({
    locationId: currentLocationId,
    locationName,
    districtList,
  }) => ({
    location: {
      value: currentLocationId,
      label: locationName,
    },
    districts: districtList.map((district) => ({
      value: district.id,
      label: district.name,
    })),
  });

  let content;

  if (loading) content = <Loader />;
  else if (deliveryLocationDistrict.isEmpty())
    content = <NoDataResource returnPage={DELIVERY_LOCATION_DISTRICT_PATH} />;
  else
    content = (
      <DeliveryLocationDistrictForm
        onSubmit={onSubmit}
        initialValues={generateInitialValues(deliveryLocationDistrict.toJS())}
      />
    );

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Editar Ubicación con Distritos Autorizados para Reparto"
      subtitle="Editar ubicación con distritos autorizados para reparto"
      content={content}
    />
  );
};

EditDeliveryLocationDistrict.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchClearDeliveryLocationDistrict: PropTypes.func.isRequired,
  dispatchGetDeliveryLocationDistrict: PropTypes.func.isRequired,
  dispatchPutDeliveryLocationDistrict: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  deliveryLocationDistrict: PropTypes.instanceOf(Immutable.Map).isRequired,
};

EditDeliveryLocationDistrict.defaultProps = {
  loading: false,
};

const mapDispatchToProps = {
  dispatchClearDeliveryLocationDistrict: clearDeliveryLocationDistrict,
  dispatchGetDeliveryLocationDistrict: getDeliveryLocationDistrict,
  dispatchPutDeliveryLocationDistrict: putDeliveryLocationDistrict,
};

const mapStateToProps = (
  { CargoUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Ubicaciones con Distritos Autorizados para Reparto',
      href: DELIVERY_LOCATION_DISTRICT_PATH,
    },
    {
      text: 'Ver',
      href: `${DELIVERY_LOCATION_DISTRICT_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  deliveryLocationDistrict: CargoUnit.DeliveryLocationDistrict.getIn([
    'current',
    'content',
  ]),
  loading: !CargoUnit.DeliveryLocationDistrict.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditDeliveryLocationDistrict);
