const SAVE_FUEL_PROFILE_WARNING_MESSAGE =
  'Al guardar, el nuevo Perfil de Combustible quedará ACTIVO en el circuito asignado. Si existe otro Perfil de Combustible asignado al circuito, este quedará inactivo';
const PASSWORD_CHANGE_INFO_MESSAGE =
  'Esta contraseña es la que autoriza el cambio y corresponde al usuario que se encuentra actualmente registrado en el sistema. Si está cambiando su propia contraseña, es su contraseña anterior. Si es administrador y está cambiando la contraseña de otro usuario, es su contraseña actual y no la del otro usuario';
const ITINERARY_CREATED_FROM_API_WARNING_MESSAGE =
  'No se pueden editar los itinerarios creados a través de la API';
const ITINERARY_WITHOUT_DRIVER_INFO_MESSAGE = 'No hay conductores asignados';
const ITINERARY_WITHOUT_CABIN_CREW_INFO_MESSAGE =
  'No hay servicio a bordo asignado';
const ITINERARY_WITHOUT_PASSENGER_FLOW_MESSAGE = 'No hay flujo de pasajeros';
const ITINERARY_SCHEDULE_WITHOUT_SEGMENT_LIST_MESSAGE =
  'No hay lista de segmentos en plantilla';
const ITINERARY_WITHOUT_CARGO_MESSAGE = 'No hay carga asignada';
const PENDING_SCHEDULING_WARNING_MESSAGE = 'Programación pendiente';
const SCHEDULED_ITINERARY_INFO_MESSAGE = 'Este itinerario ya está programado';
const PRICE_BY_DAY_OF_WEEK_ARE_ZERO_WARNING_MESSAGE =
  'Este perfil de precios tiene variaciones de precios por día de la semana asociadas, pero todas son iguales a cero';
const EXPECTED_AMOUNT_TO_DEPOSIT_INFO_MESSAGE =
  'Esta cantidad se basa en las liquidaciones especificadas';
const NOT_FOUND_BASE_FUEL_REFILL_ERROR_MESSAGE =
  'No se ha encontrado relleno final asociado para alguno de estos itinerarios del circuito seleccionado, seleccione uno de los siguientes itinerarios por favor';
const BAGGAGE_NO_CONFIGURED_ALERT_MESSAGE =
  'Precio por kg y Peso máximo libre por boleto no están configurados';
const TICKET_WITH_DIFFERENT_ITINERARIES_ALERT_MESSAGE =
  'Uno de los boletos ingresados no pertenece al mismo itinerario';
const ITINERARIES_WITHOUT_EXPECTED_CONSUMPTION_ERROR_MESSAGE =
  '1. LOS ITINERARIOS CON MARCA ROJA NO TIENEN DOTACIÓN! Registre la dotación faltante para poder generar el reporte';
const EXTRAORDINARY_WITHOUT_REAL_VALUE_ERROR_MESSAGE =
  '2. LOS ITINERARIOS CON MARCA AMARILLA NO TIENEN DATOS REALES EN EL MODULO DE CONTROL! Registre los datos reales faltantes para poder generar el reporte';
const NO_ACTIVE_SALES_SESSION_ERROR_MESSAGE = 'No hay Sesión de Ventas activa';
const TEMPLATE_WITHOUT_ITINERARY_ERROR_MESSAGE =
  'La plantilla no se puede modificar porque el itinerario asociado fue eliminado';
const COORDINATE_BUTTON_NOT_CLICKED_ERROR_MESSAGE =
  'Haz click primero en uno de los botones para seleccionar las esquinas de una zona';
const generateErrorMessageForIncompleteDataInPricingByZone = (zoneNumber = 0) =>
  `Por favor asegurar que todos los datos de la zona ${zoneNumber} hayan sido especificados.`;
const generateErrorMessageForZoneFloorNumberNotSelected = (zoneNumber = 0) =>
  `Por favor seleccione el número de piso de la zona ${zoneNumber}`;
const NO_DATA_ERROR_MESSAGE = 'Hubo un error obteniendo los datos';
const ROUTE_WITHOUT_ITINERARIES =
  'No hay itinerarios para esta ruta en estas fechas';
const BUSES_WITHOUT_TRIP_CIRCUIT_STATUS_ERROR_MESSAGE =
  'Debe seleccionar los Buses con su respectivo Orden Dentro del Circuito';
const BASE_FUEL_REFILL_WITH_DUPLICATED_ITINERARIES_ERROR_MESSAGE =
  'HAY MAS DE UN ABASTECIMIENTO EN BASE PARA UN MISMO ITINERARIO DEL CIRCUITO SELECCIONADO. Para poder generar el reporte sólo debe existe un abastecimiento en base';
const SEGMENT_WITHOUT_TOLL_MESSAGE = 'No hay peajes asignados a este segmento';
const DRIVER_WITHOUT_DOCUMENTS_WARNING_MESSAGE = 'No hay documentos asignados';
const DRIVER_WITHOUT_CONTRACTS_WARNING_MESSAGE = 'No hay contratos asignados';
const MODAL_ACTION_CONFIRM_MESSAGE =
  'Dado a que esta acción no se puede deshacer, debes confirmar que en verdad quieres proceder con esta acción';
const TICKETS_IN_DIFFERENT_GROUPS_ALERT_MESSAGE =
  'Uno de los boletos ingresados no pertenece al grupo';
const SOME_TICKET_IS_NOT_ACTIVE_ALERT_MESSAGE =
  'Uno de los boletos ingresados no está activo';
const EMPLOYEE_WITHOUT_BOSS = 'Este personal no tiene jefe asignado';
const EXECUTION_REGISTER_REQUIRED_FIELDS_ERROR_MESSAGE =
  'Debe ingresar seleccionar Estado y Mecánicos y registrar Km a Agregar, Fecha/Hora Inicio, Fecha/Hora Final y Horas Trabajadas';
const DATA_NOT_REGISTERED_ERROR_MESSAGE =
  'Los datos no pudieron ser procesador, intente nuevamente';
const THERE_ARE_NOT_DATA_TO_SHOW = 'No hay datos para mostrar';
const PERSONAL_PASSWORD_FOR_DISPATCH_INFO_MESSAGE =
  'Esta contraseña es la que usará al momento del despacho de materiales en Logística';
const AGENCY_WITHOUT_USERS_INFO_MESSAGE = 'No hay usuarios asignados';
const AGENCY_WITHOUT_WORKSTATIONS_INFO_MESSAGE = 'No hay cajas asignados';
const REPORT_REGENERATE_INFO_MESSAGE =
  'Recuerda que está opción consume recursos del servidor. Si descargas el reporte con la opción activada puede que otros procesos se vuelvan lentos';
const LOADING_REPORT_INFO_MESSAGE = 'El reporte se está generando';
const ENTER_DATA_TO_SHOW_DATA =
  'Ingrese los criterios de búsqueda para mostrar datos';
const generateDifferentBusCapacityWarningMessage = ({
  newCompanyBusId = 0,
  currentCompanyBusId = 0,
  newTotalPassengerSeats = 0,
  currentTotalPassengerSeats = 0,
}) => {
  const initialMessage =
    currentCompanyBusId > 0
      ? `bus ${currentCompanyBusId}`
      : 'itinerario seleccionado';
  const secondMessage =
    currentCompanyBusId > 0
      ? `bus ${currentCompanyBusId}=${currentTotalPassengerSeats} asientos)`
      : `itinerario=${currentTotalPassengerSeats} asientos)`;
  return `El bus ${newCompanyBusId} tiene menos capacidad de asientos de pasajeros que el ${initialMessage}. (Bus ${newCompanyBusId}=${newTotalPassengerSeats} asientos, ${secondMessage}`;
};
const SERVICE_ORDER_WITHOUT_ACTIVITY_DANGER_MESSAGE =
  'No hay actividades asignadas a esta Orden de Servicio';

const CARGO_CORPORATE_CREDIT_CUSTOMER_INFO_MESSAGE =
  'No hay clientes asignados';

const CARGO_CORPORATE_CREDIT_COMMON_PRODUCT_INFO_MESSAGE =
  'No hay productos comunes asignados';

const CARGO_ITEM_WITHOUT_MANIFEST = 'Esta encomienda no tiene manifiestos';

const CARGO_CORPORATE_CREDIT_INVOICES_INFO_MESSAGE =
  'No hay facturas para unificar';

const CARGO_CORPORATE_CREDIT_RECEIPT_INFO_MESSAGE =
  'No hay boletos para unificar';

const CARGO_ITEM_WITHOUT_GUIDES = 'Esta encomienda no tiene guías de remisión';

const generateErrorMessageForIncompleteDataInPriceBySegmentZone = (
  zoneNumber,
) =>
  `Falta el valor de ''Cantidad de Asientos por Promoción' en la zona ${zoneNumber}.`;

const NO_MANIFEST_CARGO_ITEM_TO_ITINERARY = 'No hay encomiendas para subir';

const ITINERARY_WITHOUT_MANIFEST =
  'No hay manifiestos asociados a este itinerario';

const NO_MANIFEST_CARGO_ITEM_IN_ITINERARY =
  'No hay encomiendas que bajar en este itinerario para la agencia seleccionada';

const PAYMENT_FORM_NO_COMPANY_WARNING = 'Debe seleccionar una companía';

const TRIP_REQUEST_WITHOUT_CUSTOMERS =
  'No hay personal para esta solicitud de viaje';

const NO_DOCUMENTS_IN_REGISTERED_BUS = 'El bus no tiene documentos asociados';

const MAINTENANCE_TASK_WITHOUT_MATERIAL =
  'Esta Tarea no tiene materiales asignados';

const MAINTENANCE_PLANNING_WITHOUT_ACTIVITY =
  'Este Plan no tiene actividades asignadas';

const MAINTENANCE_PLANNING_WITHOUT_RESOURCE =
  'Este Plan no tiene recursos asignados';

const NO_SHELF_CARGO_ITEM_SELECTED =
  'Haga click en buscar para reubicar encomiendas';

export {
  SAVE_FUEL_PROFILE_WARNING_MESSAGE,
  PASSWORD_CHANGE_INFO_MESSAGE,
  ITINERARY_CREATED_FROM_API_WARNING_MESSAGE,
  ITINERARY_WITHOUT_DRIVER_INFO_MESSAGE,
  ITINERARY_WITHOUT_CABIN_CREW_INFO_MESSAGE,
  PENDING_SCHEDULING_WARNING_MESSAGE,
  SCHEDULED_ITINERARY_INFO_MESSAGE,
  PRICE_BY_DAY_OF_WEEK_ARE_ZERO_WARNING_MESSAGE,
  EXPECTED_AMOUNT_TO_DEPOSIT_INFO_MESSAGE,
  ITINERARY_WITHOUT_PASSENGER_FLOW_MESSAGE,
  ITINERARY_SCHEDULE_WITHOUT_SEGMENT_LIST_MESSAGE,
  ITINERARY_WITHOUT_CARGO_MESSAGE,
  NOT_FOUND_BASE_FUEL_REFILL_ERROR_MESSAGE,
  BAGGAGE_NO_CONFIGURED_ALERT_MESSAGE,
  TICKET_WITH_DIFFERENT_ITINERARIES_ALERT_MESSAGE,
  ITINERARIES_WITHOUT_EXPECTED_CONSUMPTION_ERROR_MESSAGE,
  EXTRAORDINARY_WITHOUT_REAL_VALUE_ERROR_MESSAGE,
  NO_ACTIVE_SALES_SESSION_ERROR_MESSAGE,
  TEMPLATE_WITHOUT_ITINERARY_ERROR_MESSAGE,
  COORDINATE_BUTTON_NOT_CLICKED_ERROR_MESSAGE,
  generateErrorMessageForIncompleteDataInPricingByZone,
  generateErrorMessageForZoneFloorNumberNotSelected,
  NO_DATA_ERROR_MESSAGE,
  ROUTE_WITHOUT_ITINERARIES,
  BUSES_WITHOUT_TRIP_CIRCUIT_STATUS_ERROR_MESSAGE,
  BASE_FUEL_REFILL_WITH_DUPLICATED_ITINERARIES_ERROR_MESSAGE,
  SEGMENT_WITHOUT_TOLL_MESSAGE,
  DRIVER_WITHOUT_DOCUMENTS_WARNING_MESSAGE,
  DRIVER_WITHOUT_CONTRACTS_WARNING_MESSAGE,
  MODAL_ACTION_CONFIRM_MESSAGE,
  TICKETS_IN_DIFFERENT_GROUPS_ALERT_MESSAGE,
  SOME_TICKET_IS_NOT_ACTIVE_ALERT_MESSAGE,
  EMPLOYEE_WITHOUT_BOSS,
  EXECUTION_REGISTER_REQUIRED_FIELDS_ERROR_MESSAGE,
  DATA_NOT_REGISTERED_ERROR_MESSAGE,
  THERE_ARE_NOT_DATA_TO_SHOW,
  PERSONAL_PASSWORD_FOR_DISPATCH_INFO_MESSAGE,
  AGENCY_WITHOUT_USERS_INFO_MESSAGE,
  AGENCY_WITHOUT_WORKSTATIONS_INFO_MESSAGE,
  REPORT_REGENERATE_INFO_MESSAGE,
  LOADING_REPORT_INFO_MESSAGE,
  ENTER_DATA_TO_SHOW_DATA,
  generateDifferentBusCapacityWarningMessage,
  SERVICE_ORDER_WITHOUT_ACTIVITY_DANGER_MESSAGE,
  CARGO_CORPORATE_CREDIT_CUSTOMER_INFO_MESSAGE,
  CARGO_ITEM_WITHOUT_MANIFEST,
  CARGO_CORPORATE_CREDIT_INVOICES_INFO_MESSAGE,
  CARGO_ITEM_WITHOUT_GUIDES,
  generateErrorMessageForIncompleteDataInPriceBySegmentZone,
  NO_MANIFEST_CARGO_ITEM_TO_ITINERARY,
  ITINERARY_WITHOUT_MANIFEST,
  NO_MANIFEST_CARGO_ITEM_IN_ITINERARY,
  PAYMENT_FORM_NO_COMPANY_WARNING,
  TRIP_REQUEST_WITHOUT_CUSTOMERS,
  NO_DOCUMENTS_IN_REGISTERED_BUS,
  MAINTENANCE_TASK_WITHOUT_MATERIAL,
  MAINTENANCE_PLANNING_WITHOUT_ACTIVITY,
  MAINTENANCE_PLANNING_WITHOUT_RESOURCE,
  NO_SHELF_CARGO_ITEM_SELECTED,
  CARGO_CORPORATE_CREDIT_COMMON_PRODUCT_INFO_MESSAGE,
  CARGO_CORPORATE_CREDIT_RECEIPT_INFO_MESSAGE,
};
