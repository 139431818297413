import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_DELETE_CONFIG,
  DEFAULT_PUT_CONFIG,
  DEFAULT_PATCH_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import {
  CLEAR_DELIVERIES,
  CLEAR_DELIVERY,
  FLAG_DELIVERY_ACTIVITY,
  FLAG_GETTING_DELIVERIES,
  GET_DELIVERIES,
  GET_DELIVERY,
  OPEN_MODAL,
} from '../types';
import {
  DELIVERY_ENDPOINT,
  generateDeliveryItemsConfirmReturnEndpoint,
} from '../../config/endpoints';
import { DELIVERY_PATH } from '../../config/paths';

const flagGettingDeliveries = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_DELIVERIES,
    payload: flag,
  });

const getDeliveries = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingDeliveries(true));
    const query = tableFilters;
    const url = `${DELIVERY_ENDPOINT}?${QueryString.stringify(query)}`;
    const promise = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(promise, null, dispatch);
    const response = await promise.json();
    dispatch({
      type: GET_DELIVERIES,
      payload: response,
    });
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagGettingDeliveries(false));
  }
};

const clearDeliveries = () => (dispatch) =>
  dispatch({
    type: CLEAR_DELIVERIES,
  });

const flagDeliveryActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_DELIVERY_ACTIVITY,
    payload: flag,
  });

const getDelivery =
  async ({ deliveryId }) =>
  async (dispatch) => {
    try {
      dispatch(flagDeliveryActivity(true));
      const url = `${DELIVERY_ENDPOINT}/${deliveryId}`;
      const promise = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(promise, null, dispatch);
      const response = await promise.json();
      dispatch({
        type: GET_DELIVERY,
        payload: response,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagDeliveryActivity(false));
    }
  };

const clearDelivery = () => (dispatch) =>
  dispatch({
    type: CLEAR_DELIVERY,
  });

const postDelivery =
  async ({
    businessId,
    userId,
    agencyId,
    cost,
    cargoItemIds,
    externalLicensePlate,
    externalDriverList,
    frequentVehicle,
  }) =>
  async (dispatch) => {
    try {
      dispatch(flagDeliveryActivity(true));
      const payload = {
        businessId,
        userId,
        agencyId,
        cost,
        cargoItemIds,
        externalLicensePlate,
        externalDriverList,
        frequentVehicle,
      };
      const url = DELIVERY_ENDPOINT;
      const promise = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise, null, dispatch);
      const response = await promise.json();
      dispatch(push(`${DELIVERY_PATH}/${response.id}`));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagDeliveryActivity(false));
    }
  };

const deleteDelivery =
  async ({ deliveryId }) =>
  async (dispatch) => {
    try {
      dispatch(flagDeliveryActivity(true));
      const url = `${DELIVERY_ENDPOINT}/${deliveryId}`;
      const promise = await fetch(url, {
        ...DEFAULT_DELETE_CONFIG,
      });
      await isErrorResponse(promise, null, dispatch);
      dispatch(push(DELIVERY_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagDeliveryActivity(false));
    }
  };

const putDelivery =
  async (deliveryId, { businessId, agencyList, userList, active }) =>
  async (dispatch) => {
    try {
      dispatch(flagDeliveryActivity(true));
      const payload = {
        id: deliveryId,
        businessId,
        agencyList,
        userList,
        active,
      };
      const url = `${DELIVERY_ENDPOINT}/${deliveryId}`;
      const promise = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise, null, dispatch);
      await promise.json();
      dispatch(push(DELIVERY_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagDeliveryActivity(false));
    }
  };

const patchStartDelivery =
  async ({ deliveryId }) =>
  async (dispatch) => {
    try {
      dispatch(flagDeliveryActivity(true));
      const url = `${DELIVERY_ENDPOINT}/${deliveryId}/actions/start`;
      const promise = await fetch(url, {
        ...DEFAULT_PATCH_CONFIG,
      });
      await isErrorResponse(promise, null, dispatch);
      dispatch(getDelivery({ deliveryId }));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagDeliveryActivity(false));
    }
  };

const patchDeliverItems =
  async (deliveryId, { cargoItemIds, customer }) =>
  async (dispatch) => {
    try {
      dispatch(flagDeliveryActivity(true));
      const url = `${DELIVERY_ENDPOINT}/${deliveryId}/actions/deliver`;
      const payload = {
        cargoItemIds,
        customer,
      };
      const promise = await fetch(url, {
        ...DEFAULT_PATCH_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise, null, dispatch);
      await dispatch(getDelivery({ deliveryId }));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagDeliveryActivity(false));
    }
  };

const patchReturnItems =
  async (deliveryId, { cargoItemIds, justification }) =>
  async (dispatch) => {
    try {
      dispatch(flagDeliveryActivity(true));
      const url = `${DELIVERY_ENDPOINT}/${deliveryId}/actions/return`;
      const payload = {
        cargoItemIds,
        justification,
      };
      const promise = await fetch(url, {
        ...DEFAULT_PATCH_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise, null, dispatch);
      await dispatch(getDelivery({ deliveryId }));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagDeliveryActivity(false));
    }
  };

const patchConfirmReturnItems =
  async (deliveryId, payload) => async (dispatch) => {
    try {
      dispatch(flagGettingDeliveries(true));
      const url = generateDeliveryItemsConfirmReturnEndpoint(deliveryId);
      const promise = await fetch(url, {
        ...DEFAULT_PATCH_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise, null, dispatch);
      await dispatch(getDelivery({ deliveryId }));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagGettingDeliveries(false));
    }
  };

const openModalDeliveryBillProcess = (isOpen) => ({
  type: OPEN_MODAL,
  payload: isOpen,
});

export {
  flagGettingDeliveries,
  getDeliveries,
  clearDeliveries,
  flagDeliveryActivity,
  getDelivery,
  postDelivery,
  clearDelivery,
  deleteDelivery,
  putDelivery,
  patchStartDelivery,
  patchDeliverItems,
  patchReturnItems,
  patchConfirmReturnItems,
  openModalDeliveryBillProcess,
};
