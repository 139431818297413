import React from 'react';
import PropTypes from 'prop-types';
import { Form, FormGroup } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import FormItem from '../../../common/forms/FormItem';
import TextInput from '../../../common/forms/input/TextInput';
import { isRequired } from '../../../../utils/validators';
import FormFooter from '../../../common/forms/FormFooter';
import CargoCorporateCreditSelect from '../../../common/forms/select/CargoCorporateCreditSelect';

const ParcelPriceConfigurationForm = ({ handleSubmit, isEdit }) => (
  <Form onSubmit={handleSubmit}>
    <FormGroup row>
      <FormItem label="Crédito Corporativo" required>
        <Field
          name="cargoCorporateCredit"
          component={CargoCorporateCreditSelect}
          placeholder="Crédito Corporativo"
          validate={[isRequired]}
          isDisabled={isEdit}
        />
      </FormItem>
    </FormGroup>
    <FormGroup row>
      <FormItem label="Precio base" required>
        <Field
          name="basePrice"
          type="number"
          component={TextInput}
          placeholder="Precio base"
          validate={[isRequired]}
        />
      </FormItem>
    </FormGroup>
    <FormGroup row>
      <FormItem label="Peso Máximo de Sobre" required>
        <Field
          name="maxEnvelopeWeight"
          type="number"
          component={TextInput}
          placeholder="Peso Máximo de Sobre"
          validate={[isRequired]}
        />
      </FormItem>
    </FormGroup>
    <FormGroup row>
      <FormItem label="Divisor Volumétrico" required>
        <Field
          name="volumetricDivisor"
          type="number"
          component={TextInput}
          placeholder="Divisor Volumétrico"
          validate={[isRequired]}
        />
      </FormItem>
    </FormGroup>
    <FormFooter />
  </Form>
);

ParcelPriceConfigurationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
};

ParcelPriceConfigurationForm.defaultProps = {
  isEdit: false,
};

const form = reduxForm({
  form: 'parcelPriceConfigurationForm',
})(ParcelPriceConfigurationForm);

export default form;
