import React, { useEffect, useState } from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import Loader from '../../../common/Loader';
import ItinerarySearchResults from '../../../modules/booking/itineraries/ItinerarySearchResults';
import SeatSelection from '../../../modules/booking/seats/SeatSelection';
import SelectedSeats from '../../../modules/booking/seats/SelectedSeats';
import MyReservationsItinerarySearch from './MyReservationsItinerarySearch';

const MyReservationsRoundTrip = ({
  location,
  postingReservation,
  dispatchPush,
  process,
  loading,
}) => {
  const [mobilizing, setMobilizing] = useState('pending');
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    try {
      setMobilizing(JSON.parse(searchParams.get('mobilizing')));
    } catch (error) {
      setMobilizing(null);
    }
  }, [location.search]);

  useEffect(() => {
    if (mobilizing === null) {
      dispatchPush('/my-reservations');
    }
  }, [mobilizing]);

  if (postingReservation || mobilizing === 'pending' || loading)
    return <Loader />;

  if (process.isEmpty()) {
    dispatchPush('/my-reservations');
  }

  return (
    <div>
      <MyReservationsItinerarySearch
        mobilizing={mobilizing}
        processId={process.get('id')}
      />
      <Row>
        <Col lg={6} md={12}>
          <ItinerarySearchResults />
        </Col>
        <Col lg={4} md={6}>
          <SeatSelection />
        </Col>
        <Col lg={2} md={6}>
          <SelectedSeats />
        </Col>
      </Row>
    </div>
  );
};

MyReservationsRoundTrip.propTypes = {
  postingReservation: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  dispatchPush: PropTypes.func.isRequired,
  process: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool,
};

MyReservationsRoundTrip.defaultProps = {
  loading: false,
};

const mapStateToProps = ({ BookingUnit, ItineraryUnit }) => ({
  postingReservation: BookingUnit.Booking.getIn([
    'passengers',
    'postingReservation',
  ]),
  process: ItineraryUnit.Process.getIn(['selectedActive', 'content']),
  loading: !ItineraryUnit.Process.getIn([
    'selectedActive',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchPush: push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MyReservationsRoundTrip);
