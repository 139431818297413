import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { Form, FormGroup, Label } from 'reactstrap';
import { change, Field, reduxForm } from 'redux-form';
import TextInput from '../../../common/forms/input/TextInput';
import FormItem from '../../../common/forms/FormItem';
import {
  COMPANY_BUS_SEAT_COUNT,
  COMPANY_STAFF_TYPE,
  MIN_COMPANY_BUS_SEAT_COUNT,
} from '../../../../config/constants';
import { enumToSelectOptions } from '../../../../utils/enum';
import { isRequired } from '../../../../utils/validators';
import Select from '../../../common/forms/select/Select';
import ShiftSelect from '../../../common/forms/select/ShiftSelect';
import FormFooter from '../../../common/forms/FormFooter';
import DatePicker from '../../../common/forms/input/DatePicker';
import Loader from '../../../common/Loader';
import DynamicForm from '../../../common/forms/DynamicForm';
import { generateProcessRoutesFormColumns } from '../../../../config/dynamicFormFields';
import { tzNormalizeDate } from '../../../../utils/date';
import { INT_DATE_FORMAT, TIME_FORMAT } from '../../../../config/locale';
import generateTimetableData from '../../../../utils/timetable';

export const ProcessForm = ({
  loading,
  handleSubmit,
  onSubmit,
  dispatchChange,
}) => {
  const [dynamicFormProps, setDynamicFormProps] = useState({
    name: 'routes',
    columns: generateProcessRoutesFormColumns(false),
    labelWithoutElements: 'No hay rutas seleccionadas',
  });

  const onChangeMobilizing = ({ target: { checked } }) => {
    dispatchChange('ProcessForm', 'routes', null);
    setDynamicFormProps({
      ...dynamicFormProps,
      columns: generateProcessRoutesFormColumns(checked),
    });
  };

  const onChangeRadioButton = (event) => {
    const isMobilizing = event.target.value === 'mobilizing';
    onChangeMobilizing({
      target: {
        checked: isMobilizing,
      },
    });
  };

  const validateRoutesValues = (routes) => {
    if (!routes || !routes.length) {
      toastr.error('Error', 'Ingrese al menos una ruta');
      return false;
    }
    return true;
  };

  const onHandleSubmit = (formValues) => {
    const {
      shift,
      startDate,
      routes,
      staffType,
      name,
      registrationDate,
      closedDate,
      mobilizationType,
    } = formValues;
    if (!validateRoutesValues(routes)) return;

    const formattedShift = shift
      .map((currentShift) => currentShift.value)
      .join(',');

    if (formattedShift === '') {
      toastr.error('Error', 'Ingrese al menos un regimen');
      return;
    }

    const registrationDateValue = tzNormalizeDate({ date: registrationDate });
    const closedDateValue = tzNormalizeDate({ date: closedDate });

    const companyItineraryRequestList = [];

    routes.forEach(
      ({ routeId: currentRoute, passengerCount, departureTime }) => {
        const startDateformatted = formValues.startDate
          ? tzNormalizeDate({
              date: formValues.startDate,
              format: INT_DATE_FORMAT,
            })
          : tzNormalizeDate({ format: INT_DATE_FORMAT });

        const formattedDepartureTime = departureTime
          ? tzNormalizeDate({
              date: departureTime,
              format: TIME_FORMAT,
            })
          : tzNormalizeDate({ format: TIME_FORMAT });

        const startDateValue = tzNormalizeDate({
          date: `${startDateformatted} ${formattedDepartureTime}`,
        });

        const endDateformatted = startDate
          ? tzNormalizeDate({ date: startDate, format: INT_DATE_FORMAT })
          : tzNormalizeDate({ format: INT_DATE_FORMAT });

        const endDateValue = tzNormalizeDate({
          date: `${endDateformatted} ${formattedDepartureTime}`,
          addTime: { amount: 30, unit: 'seconds' },
        });

        const itineraryScheduleSegmentList = generateTimetableData(
          currentRoute.routeSegmentList,
          departureTime,
          startDate,
        );
        const quantity = Math.ceil(
          passengerCount /
            (currentRoute.intern
              ? MIN_COMPANY_BUS_SEAT_COUNT
              : COMPANY_BUS_SEAT_COUNT),
        );
        const companyItineraryRequest = {
          itineraryScheduleSegmentList,
          endDate: endDateValue,
          startDate: startDateValue,
          registrationDate: registrationDateValue,
          closedDate: closedDateValue,
          name,
          routeId: currentRoute.value,
          shift: formattedShift,
          staffType: staffType.value,
          dailyRepeat: {},
          quantity,
          repeatEvery: '1',
          mobilizing: mobilizationType === 'mobilizing',
        };
        companyItineraryRequestList.push(companyItineraryRequest);
      },
    );

    onSubmit({ companyItineraryRequestList });
  };

  const routeField = <DynamicForm {...dynamicFormProps} />;
  const itineraryFields = (
    <>
      <FormGroup row>
        <FormItem label="Nombre" required>
          <Field
            name="name"
            component={TextInput}
            type="text"
            placeholder="Nombre"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Régimen" required>
          <Field
            name="shift"
            component={ShiftSelect}
            placeholder="Régimen"
            isMulti
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Tipo" required>
          <Field
            name="staffType"
            value={COMPANY_STAFF_TYPE.HOLDERS.value}
            component={Select}
            options={enumToSelectOptions(COMPANY_STAFF_TYPE)}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="">
          <Label className="mr-3">
            <Field
              name="mobilizationType"
              component="input"
              type="radio"
              value="mobilizing"
              onChange={onChangeRadioButton}
              required
            />{' '}
            Movilización
          </Label>
          <Label>
            <Field
              name="mobilizationType"
              component="input"
              type="radio"
              value="desmobilizing"
              onChange={onChangeRadioButton}
              required
            />{' '}
            Desmovilización
          </Label>
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Fecha de salida" required>
          <Field
            name="startDate"
            component={DatePicker}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Fecha de inscripción" required>
          <Field
            name="registrationDate"
            component={DatePicker}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Fecha de cierre" required>
          <Field
            name="closedDate"
            component={DatePicker}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      {routeField}
    </>
  );

  if (loading) return <Loader />;

  return (
    <Form onSubmit={handleSubmit(onHandleSubmit)}>
      <h3>Itinerario</h3>
      {itineraryFields}
      <FormFooter />
    </Form>
  );
};

ProcessForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  dispatchChange: PropTypes.func.isRequired,
};

const mapStateToProps = ({ ItineraryUnit }) => ({
  loading: !ItineraryUnit.ItinerarySchedule.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchChange: change,
};

const formComponent = reduxForm({
  form: 'ProcessForm',
  initialValues: {
    mobilizationType: 'desmobilizing',
  },
})(ProcessForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
