import React, { useLayoutEffect } from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NO_MANIFEST_CARGO_ITEM_TO_ITINERARY } from '../../../../config/messages';
import Loader from '../../../common/Loader';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import { CARGO_ITEM_RELOCATION_COLUMNS } from '../../../../config/columns';
import Table from '../../../common/Table';
import Alert from '../../../common/informative/Alert';
import RelocationCargoItemToolbar from './RelocationCargoItemToolbar';
import { clearManifestItems } from '../../../../actions';

const RelocationCargoItemResult = ({
  items,
  loading,
  dispatchClearManifestItem,
  locationId,
}) => {
  useLayoutEffect(
    () => () => {
      dispatchClearManifestItem();
    },
    [],
  );

  if (items.size === 0) {
    if (loading) {
      return <Loader />;
    }
    return (
      <Alert type="warning" message={NO_MANIFEST_CARGO_ITEM_TO_ITINERARY} />
    );
  }

  const toolbar = <RelocationCargoItemToolbar locationId={locationId} />;

  const data = items.toJS();
  const defaultPageSize =
    items.size > DEFAULT_PAGE_SIZE ? DEFAULT_PAGE_SIZE : items.size;

  return (
    <>
      <div className="mt-3">
        {toolbar}

        <div className="flex">
          <h4>Encomiendas de la Ubicación </h4>
        </div>
        <Table
          columns={CARGO_ITEM_RELOCATION_COLUMNS}
          data={data}
          defaultPageSize={defaultPageSize}
          returnOnlySelectedItems
          loading={loading}
        />
      </div>
    </>
  );
};

RelocationCargoItemResult.propTypes = {
  items: PropTypes.instanceOf(Immutable.List).isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    customer: PropTypes.shape({
      fullName: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  dispatchClearManifestItem: PropTypes.func.isRequired,
  locationId: PropTypes.number,
};

RelocationCargoItemResult.defaultProps = {
  locationId: 0,
};

const mapStateToProps = ({ CargoUnit, authentication, ItineraryUnit }) => ({
  items: CargoUnit.ManifestCargo.getIn(['all', 'content']),
  loading: CargoUnit.ManifestCargo.getIn(['all', 'loading']),
  user: authentication.get('user') || undefined,
  itineraryCounter:
    ItineraryUnit.Itinerary.getIn(['current', 'content']) || undefined,
  disabledButton: CargoUnit.ManifestCargo.getIn(['disabledActions']),
});

const mapDispatchToProps = {
  dispatchClearManifestItem: clearManifestItems,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RelocationCargoItemResult);
