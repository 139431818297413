import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import DeliveryItemDeliveredModal from './DeliveryItemsDeliveredModal';
import { patchDeliverItems } from '../../../../../actions';
import Modal from '../../../../common/modal/Modal';

const DeliveryItemsDeliveredButton = ({
  deliveryId,
  cargoItemIds,
  dispatchPatchDeliverItems,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [modalBody, setModalBody] = useState(null);

  const onModalSubmit = async ({ idDocumentNumber, name }) => {
    await dispatchPatchDeliverItems(deliveryId, {
      cargoItemIds,
      customer: `${name} - DNI ${idDocumentNumber}`,
    });
    setModalBody(null);
    setShowModal(false);
  };

  const onCancel = () => {
    setShowModal(false);
  };

  const onShowModal = async () => {
    const newModalBody = (
      <DeliveryItemDeliveredModal
        onSubmit={onModalSubmit}
        onCancel={onCancel}
      />
    );
    setModalBody(newModalBody);
    setShowModal(true);
  };

  return (
    <>
      <Button color="success" onClick={onShowModal}>
        <i className="fa fa-plus-circle" /> Entregar
      </Button>
      <Modal
        show={showModal}
        title="Entregar encomienda"
        onClickClose={() => setShowModal(false)}
        body={modalBody}
        size="lg"
      />
    </>
  );
};

DeliveryItemsDeliveredButton.propTypes = {
  cargoItemIds: PropTypes.instanceOf(Array).isRequired,
  deliveryId: PropTypes.number.isRequired,
  dispatchPatchDeliverItems: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchPatchDeliverItems: patchDeliverItems,
};

export default connect(null, mapDispatchToProps)(DeliveryItemsDeliveredButton);
