import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, ButtonToolbar } from 'reactstrap';
import { Link } from 'react-router-dom';

// TODO: add support for multiple buttons in toolbar
const TableToolbar = ({
  buttonPath,
  buttonText,
  disabled,
  isExternalPath,
  buttonClassName,
  extraButtons,
  icon,
}) => {
  const iconElement = icon ? <i className={`${icon}`} /> : null;
  return (
    <ButtonToolbar className="pull-right">
      <ButtonGroup>
        {extraButtons}
        {isExternalPath ? (
          <a disabled={disabled} className="btn btn-primary" href={buttonPath}>
            {iconElement} {buttonText}
          </a>
        ) : (
          buttonPath && (
            <Link
              className={buttonClassName}
              to={buttonPath}
              disabled={disabled}
            >
              {iconElement} {buttonText}
            </Link>
          )
        )}
      </ButtonGroup>
    </ButtonToolbar>
  );
};

TableToolbar.propTypes = {
  buttonPath: PropTypes.string,
  buttonText: PropTypes.string,
  buttonClassName: PropTypes.string,
  disabled: PropTypes.bool,
  isExternalPath: PropTypes.bool,
  icon: PropTypes.string,
  extraButtons: PropTypes.instanceOf(Object),
};

TableToolbar.defaultProps = {
  buttonPath: null,
  buttonText: 'Crear',
  disabled: false,
  isExternalPath: false,
  icon: 'fa fa-plus-circle',
  buttonClassName: 'btn btn-primary',
  extraButtons: null,
};

export default TableToolbar;
