import React, { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, InputGroupText } from 'reactstrap';
import { connect } from 'react-redux';
import { Field, reduxForm, Form, change } from 'redux-form';
import FormItem from '../../../common/forms/FormItem';
import {
  isRequired,
  validateIntegerNumber,
  validateMinNumber,
  validateNumber,
} from '../../../../utils/validators';
import TextInput from '../../../common/forms/input/TextInput';
import CompanySelect from '../../../common/forms/select/CompanySelect';
import { CURRENCY } from '../../../../config/locale';
import FormFooter from '../../../common/forms/FormFooter';
import CustomerSelect from '../../../common/forms/select/CustomerSelect';
import { CORPORATE_CREDIT_OPTIONS } from '../../../../config/constants';
import Select from '../../../common/forms/select/Select';

const hasHigherNumber = (currentValue, allValues) =>
  Number(currentValue) < Number(allValues.actualBalance)
    ? 'No debe ser menor que el saldo actual'
    : undefined;

const validateMin = validateMinNumber(0);

export const CargoCorporateCreditForm = ({
  handleSubmit,
  dispatchChange,
  isEdit,
  initialValues,
}) => {
  const [creditActive, setCreditActive] = useState(true);
  const [corporateCreditType, setCorporateCreditType] = useState({});

  useLayoutEffect(() => {
    dispatchChange('CargoCorporateCreditForm', 'creditActive', true);
    if (!isEdit) {
      setCorporateCreditType(CORPORATE_CREDIT_OPTIONS.COMPANY);
      dispatchChange('CargoCorporateCreditForm', 'type', {
        value: CORPORATE_CREDIT_OPTIONS.COMPANY.value,
        label: CORPORATE_CREDIT_OPTIONS.COMPANY.label,
      });
      return;
    }
    if (initialValues.companyId) {
      setCorporateCreditType(CORPORATE_CREDIT_OPTIONS.COMPANY);
      dispatchChange('CargoCorporateCreditForm', 'type', {
        value: CORPORATE_CREDIT_OPTIONS.COMPANY.value,
        label: CORPORATE_CREDIT_OPTIONS.COMPANY.label,
      });
    } else if (initialValues.customerId) {
      setCorporateCreditType(CORPORATE_CREDIT_OPTIONS.PERSON);
      dispatchChange('CargoCorporateCreditForm', 'type', {
        value: CORPORATE_CREDIT_OPTIONS.PERSON.value,
        label: CORPORATE_CREDIT_OPTIONS.PERSON.label,
      });
    }
  }, []);

  const onChangeInitialBalance = (e, value) => {
    if (!initialValues.initialBalance) {
      dispatchChange('CargoCorporateCreditForm', 'actualBalance', value);
    }
  };

  const handleCreditActiveChange = () => {
    setCreditActive(!creditActive);
    if (!creditActive) {
      dispatchChange('CargoCorporateCreditForm', 'actualBalance', null);
      dispatchChange('CargoCorporateCreditForm', 'initialBalance', null);
    }
  };

  const handleTypeChange = (e, type) => {
    if (isEdit) {
      return;
    }
    setCorporateCreditType(type);
    if (type.value === CORPORATE_CREDIT_OPTIONS.COMPANY.value) {
      dispatchChange('CargoCorporateCreditForm', 'customer', null);
    } else if (type.value === CORPORATE_CREDIT_OPTIONS.PERSON.value) {
      dispatchChange('CargoCorporateCreditForm', 'company', null);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup row>
        <FormItem label="Nombre" required>
          <Field
            name="name"
            component={TextInput}
            placeholder="Nombre"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Descripción">
          <Field
            name="description"
            component={TextInput}
            type="textarea"
            placeholder="Descripción"
          />
        </FormItem>
      </FormGroup>

      <FormGroup row>
        <FormItem label="Tipo" required>
          <Field
            name="type"
            options={Object.values(CORPORATE_CREDIT_OPTIONS)}
            component={Select}
            validate={[isRequired]}
            onChange={handleTypeChange}
            value={corporateCreditType}
            isDisabled={isEdit}
          />
        </FormItem>
      </FormGroup>
      {corporateCreditType.value === CORPORATE_CREDIT_OPTIONS.COMPANY.value ? (
        <FormGroup row>
          <FormItem label="Empresa" required>
            <Field
              name="company"
              component={CompanySelect}
              isClearable
              validate={[isRequired]}
              isDisabled={isEdit}
            />
          </FormItem>
        </FormGroup>
      ) : (
        <FormGroup row>
          <FormItem label="Persona Natural" required>
            <Field
              name="customer"
              component={CustomerSelect}
              disabled
              validate={[isRequired]}
              isDisabled={isEdit}
            />
          </FormItem>
        </FormGroup>
      )}

      <FormGroup row>
        <FormItem label="Activar crédito">
          <Label>
            <Field
              name="creditActive"
              component="input"
              type="checkbox"
              onChange={handleCreditActiveChange}
              checked={creditActive}
            />{' '}
            Activo
          </Label>
        </FormItem>
      </FormGroup>

      {creditActive && (
        <>
          <FormGroup row>
            <FormItem label="Saldo Inicial" required>
              <Field
                name="initialBalance"
                component={TextInput}
                type="text"
                append={<InputGroupText>{CURRENCY}</InputGroupText>}
                placeholder="Saldo Inicial"
                validate={[validateNumber, hasHigherNumber, isRequired]}
                onChange={onChangeInitialBalance}
              />
            </FormItem>
          </FormGroup>
          <FormGroup row>
            <FormItem label="Saldo Actual">
              <Field
                name="actualBalance"
                component={TextInput}
                type="text"
                append={<InputGroupText>{CURRENCY}</InputGroupText>}
                placeholder="Saldo Actual"
                validate={[validateNumber]}
                disabled
              />
            </FormItem>
          </FormGroup>

          <FormGroup row>
            <FormItem label="Días de Crédito" required>
              <Field
                name="creditDays"
                component={TextInput}
                type="text"
                placeholder="Días de Crédito"
                validate={[validateIntegerNumber, isRequired]}
              />
            </FormItem>
          </FormGroup>
        </>
      )}
      <FormGroup row>
        <FormItem label="Habilitar Descuento">
          <Label>
            <Field name="isDiscountAllowed" component="input" type="checkbox" />{' '}
            Activar Descuento
          </Label>
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Porcentaje de Descuento">
          <Field
            name="discountPercentage"
            component={TextInput}
            type="number"
            placeholder="Porcentaje de Descuento"
            validate={[validateNumber, validateMin]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Estado">
          <Label>
            <Field name="active" component="input" type="checkbox" /> Activo
          </Label>
        </FormItem>
      </FormGroup>
      <FormFooter />
    </Form>
  );
};
CargoCorporateCreditForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  dispatchChange: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
  initialValues: PropTypes.shape({
    companyId: PropTypes.number,
    customerId: PropTypes.number,
    initialBalance: PropTypes.number,
  }),
};

CargoCorporateCreditForm.defaultProps = {
  isEdit: false,
  initialValues: {},
};

const mapDispatchToProps = {
  dispatchChange: change,
};

const formComponent = reduxForm({
  form: 'CargoCorporateCreditForm',
  initialValues: {
    type: CORPORATE_CREDIT_OPTIONS.COMPANY,
  },
})(CargoCorporateCreditForm);

export default connect(null, mapDispatchToProps)(formComponent);
