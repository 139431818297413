import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import ItineraryForCounterForm from './ItineraryForCounterForm';
import { ITINERARY_FOR_COUNTER_PATH } from '../../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../../common/resource/proptypes/CommonPropTypes';
import {
  getItineraryForCounter,
  clearItinerary,
  patchItinerary,
} from '../../../../../actions';
import Loader from '../../../../common/Loader';
import { TIMESTAMP_FORMAT } from '../../../../../config/locale';
import withEndpointAuthorization from '../../../authorization/withEndPointAuthorization';
import { generateGetItineraryForCounterEndpoint } from '../../../../../config/endpoints';
import { PATCH } from '../../../../../config/permissions';
import NoDataResource from '../../../../common/resource/NoDataResource';
import Content from '../../../../layout/Content';
import { tzNormalizeDate } from '../../../../../utils/date';

export class EditItineraryForCounter extends Component {
  componentDidMount() {
    const {
      dispatchGetItineraryForCounter,
      match: {
        params: { id },
      },
    } = this.props;
    dispatchGetItineraryForCounter(id);
  }

  componentWillUnmount() {
    const { dispatchClearItinerary } = this.props;
    dispatchClearItinerary();
  }

  onSubmit = (formValues) => {
    const {
      match: {
        params: { id },
      },
      dispatchPatchItinerary,
    } = this.props;
    const patchItineraryFormValues = {
      companyBusId: formValues.companyBusId.label,
      driverIdList: formValues.driverIdList.map((driver) => driver.value),
      actualDepartureTime: formValues.actualDepartureTime
        ? tzNormalizeDate({
            date: formValues.actualDepartureTime,
            format: TIMESTAMP_FORMAT,
          })
        : null,
      actualArrivalTime: formValues.actualArrivalTime
        ? tzNormalizeDate({
            date: formValues.actualArrivalTime,
            format: TIMESTAMP_FORMAT,
          })
        : null,
      comment: formValues.comment,
      migratedCargoDocuments: formValues.migratedCargoDocuments
        ? formValues.migratedCargoDocuments
        : false,
    };
    if (formValues.cabinCrewIdList) {
      patchItineraryFormValues.cabinCrewIdList = formValues.cabinCrewIdList.map(
        (cabinCrew) => cabinCrew.value,
      );
    }

    dispatchPatchItinerary(id, patchItineraryFormValues);
  };

  generateInitialValues = (itinerary) => ({
    companyBusId: {
      value: itinerary.get('companyBusId'),
      label: itinerary.get('companyBusId'),
    },
    driverIdList: itinerary.get('driverList').length
      ? itinerary
          .get('driverList')
          .map((driver) => ({ value: driver.id, label: driver.fullName }))
      : null,
    cabinCrewIdList: itinerary.get('cabinCrewList').length
      ? itinerary.get('cabinCrewList').map((cabinCrew) => ({
          value: cabinCrew.id,
          label: cabinCrew.fullName,
        }))
      : null,
    actualDepartureTime: itinerary.get('actualDepartureTime')
      ? tzNormalizeDate({ date: itinerary.get('actualDepartureTime') })
      : null,
    actualArrivalTime: itinerary.get('actualArrivalTime')
      ? tzNormalizeDate({ date: itinerary.get('actualArrivalTime') })
      : null,
    comment: itinerary.get('comment'),
  });

  render() {
    const { breadcrumbs, itinerary, loading } = this.props;

    let content;

    if (loading) content = <Loader />;
    else if (itinerary.isEmpty())
      content = <NoDataResource returnPage={ITINERARY_FOR_COUNTER_PATH} />;
    else
      content = (
        <ItineraryForCounterForm
          onSubmit={this.onSubmit}
          initialValues={this.generateInitialValues(itinerary)}
          seatMap={itinerary.get('seatMap')}
        />
      );

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Editar Itinerario"
        subtitle="Editar itinerario"
        content={content}
      />
    );
  }
}

EditItineraryForCounter.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetItineraryForCounter: PropTypes.func.isRequired,
  dispatchClearItinerary: PropTypes.func.isRequired,
  dispatchPatchItinerary: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  itinerary: PropTypes.instanceOf(Immutable.Map).isRequired,
};

EditItineraryForCounter.defaultProps = {
  loading: false,
};

const mapStateToProps = (
  { ItineraryUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...ItineraryUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Itinerarios',
      href: ITINERARY_FOR_COUNTER_PATH,
    },
    {
      text: 'Ver',
      href: `${ITINERARY_FOR_COUNTER_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  itinerary: ItineraryUnit.Itinerary.getIn(['current', 'content']),
  loading: !ItineraryUnit.Itinerary.getIn(['current', 'activity']).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetItineraryForCounter: getItineraryForCounter,
  dispatchClearItinerary: clearItinerary,
  dispatchPatchItinerary: patchItinerary,
};

const connectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditItineraryForCounter);

export default withEndpointAuthorization({
  url: generateGetItineraryForCounterEndpoint(0),
  permissionType: PATCH,
})(connectedComponent);
