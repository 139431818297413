export const FLAG_GETTING_DELIVERY_BUSINESSES =
  'FLAG_GETTING_DELIVERY_BUSINESSES';
export const GET_DELIVERY_BUSINESSES = 'GET_DELIVERY_BUSINESSES';
export const CLEAR_DELIVERY_BUSINESSES = 'CLEAR_DELIVERY_BUSINESSES';
export const FLAG_DELIVERY_BUSINESS_ACTIVITY =
  'FLAG_DELIVERY_BUSINESS_ACTIVITY';
export const GET_DELIVERY_BUSINESS = 'GET_DELIVERY_BUSINESS';
export const CLEAR_DELIVERY_BUSINESS = 'CLEAR_DELIVERY_BUSINESS';
export const FLAG_GETTING_CARGO_ITEMS_TO_DELIVER =
  'FLAG_GETTING_CARGO_ITEMS_TO_DELIVER';
export const GET_CARGO_ITEMS_TO_DELIVER = 'GET_CARGO_ITEMS_TO_DELIVER';
export const CLEAR_CARGO_ITEMS_TO_DELIVER = 'CLEAR_CARGO_ITEMS_TO_DELIVER';
