import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import {
  CLEAR_CARGO_DELIVERY_FREQUENT_VEHICLES,
  FLAG_GETTING_CARGO_DELIVERY_FREQUENT_VEHICLES,
  GET_CARGO_DELIVERY_FREQUENT_VEHICLES,
} from '../types';
import { CARGO_DELIVERY_FREQUENT_VEHICLE_ENDPOINT } from '../../config/endpoints';
import { isErrorResponse } from '../../utils/error-handlers';
import { DEFAULT_GET_CONFIG } from '../../config/rest';

const flagGettingCargoDeliveryFequentVehicles = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_CARGO_DELIVERY_FREQUENT_VEHICLES,
    payload: flag,
  });

const getCargoDeliveryFequentVehicles =
  async (tableFilters) => async (dispatch) => {
    try {
      dispatch(flagGettingCargoDeliveryFequentVehicles(true));
      const query = tableFilters;
      const url = `${CARGO_DELIVERY_FREQUENT_VEHICLE_ENDPOINT}?${QueryString.stringify(
        query,
      )}`;
      const promise = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(promise, null, dispatch);
      const response = await promise.json();
      dispatch({
        type: GET_CARGO_DELIVERY_FREQUENT_VEHICLES,
        payload: response,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagGettingCargoDeliveryFequentVehicles(false));
    }
  };

const clearCargoDeliveryFequentVehicles = () => (dispatch) =>
  dispatch({
    type: CLEAR_CARGO_DELIVERY_FREQUENT_VEHICLES,
  });

export { getCargoDeliveryFequentVehicles, clearCargoDeliveryFequentVehicles };
