import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { change, Field, reduxForm } from 'redux-form';
import { Form, FormGroup, Label, Button, Col, Row } from 'reactstrap';
import { getChildCities, getParentCities } from '../../../../actions';
import { isRequired } from '../../../../utils/validators';
import Select from '../../../common/forms/select/Select';

const MyReservationsItinerarySearchForm = ({
  mobilizing,
  childCities,
  originParentCities,
  destinationParentCities,
  loadingChildCities,
  loadingOriginParentCities,
  loadingDestinationParentCities,
  submitting,
  handleSubmit,
  dispatchChange,
  dispatchGetChildCities,
  dispatchGetParentCities,
  process,
  onSubmit,
}) => {
  useLayoutEffect(() => {
    if (!process.isEmpty()) {
      dispatchGetParentCities(mobilizing, process.get('id'));
    }
  }, []);

  const getChildrenCities = ({ locationList }) => {
    dispatchChange('MyReservationsItinerarySearchForm', 'embarkation', null);
    dispatchGetChildCities(locationList);
  };

  const onHandleSubmit = (formValues) => {
    onSubmit({ ...formValues, processId: process.get('id') });
  };

  return (
    <Form onSubmit={handleSubmit(onHandleSubmit)}>
      <Row>
        <Col lg={4} md={3} sm={6}>
          <FormGroup>
            <Label>
              Origen <span className="text-danger">*</span>
            </Label>
            <Field
              name="source"
              component={Select}
              options={originParentCities}
              onChange={(mobilizing && getChildrenCities) || null}
              isLoading={loadingOriginParentCities}
              validate={[isRequired]}
              autoFocus
            />
          </FormGroup>
        </Col>
        {mobilizing && (
          <Col lg={4} md={3} sm={6}>
            <FormGroup>
              <Label>
                Embarque <span className="text-danger">*</span>
              </Label>
              <Field
                name="embarkation"
                component={Select}
                options={childCities}
                isLoading={loadingChildCities}
                validate={[isRequired]}
              />
            </FormGroup>
          </Col>
        )}
        <Col lg={4} md={3} sm={6}>
          <FormGroup>
            <Label>
              Destino <span className="text-danger">*</span>
            </Label>
            <Field
              name="destination"
              component={Select}
              options={destinationParentCities}
              onChange={(!mobilizing && getChildrenCities) || null}
              isLoading={loadingDestinationParentCities}
              validate={[isRequired]}
            />
          </FormGroup>
        </Col>
        {!mobilizing && (
          <Col lg={4} md={3} sm={6}>
            <FormGroup>
              <Label>
                Desembarque <span className="text-danger">*</span>
              </Label>
              <Field
                name="desembarkation"
                component={Select}
                options={childCities}
                isLoading={loadingChildCities}
                validate={[isRequired]}
              />
            </FormGroup>
          </Col>
        )}
      </Row>
      <div className="FormButtonGroup mt-0">
        <Button type="submit" disabled={submitting} color="primary">
          <i className="fa fa-search" /> Buscar
        </Button>
      </div>
    </Form>
  );
};

MyReservationsItinerarySearchForm.propTypes = {
  dispatchGetParentCities: PropTypes.func.isRequired,
  dispatchGetChildCities: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  dispatchChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  originParentCities: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  destinationParentCities: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  loadingOriginParentCities: PropTypes.bool.isRequired,
  loadingDestinationParentCities: PropTypes.bool.isRequired,
  childCities: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  loadingChildCities: PropTypes.bool.isRequired,
  mobilizing: PropTypes.bool.isRequired,
  process: PropTypes.instanceOf(Immutable.Map).isRequired,
};

const mapStateToProps = ({ MyReservationsUnit, ItineraryUnit }) => ({
  originParentCities: MyReservationsUnit.CompanyCities.getIn([
    'originParent',
    'content',
    'content',
  ]).map((city) => ({
    value: city.id,
    label: city.name,
    locationList: city.locationList,
  })),
  loadingOriginParentCities: MyReservationsUnit.CompanyCities.getIn([
    'originParent',
    'loading',
  ]),
  destinationParentCities: MyReservationsUnit.CompanyCities.getIn([
    'destinationParent',
    'content',
    'content',
  ]).map((city) => ({
    value: city.id,
    label: city.name,
    locationList: city.locationList,
  })),
  process: ItineraryUnit.Process.getIn(['selectedActive', 'content']),
  loadingDestinationParentCities: MyReservationsUnit.CompanyCities.getIn([
    'destinationParent',
    'loading',
  ]),
  childCities: MyReservationsUnit.CompanyCities.getIn([
    'child',
    'content',
  ]).isEmpty()
    ? []
    : MyReservationsUnit.CompanyCities.getIn(['child', 'content'])
        .map((city) => ({
          value: city.id,
          label: city.name,
        }))
        .toArray(),
  loadingChildCities: MyReservationsUnit.CompanyCities.getIn([
    'child',
    'loading',
  ]),
});

const mapDispatchToProps = {
  dispatchGetParentCities: getParentCities,
  dispatchGetChildCities: getChildCities,
  dispatchChange: change,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'MyReservationsItinerarySearchForm',
  })(MyReservationsItinerarySearchForm),
);
