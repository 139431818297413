import React from 'react';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import deliveryLocationDistrictBasicInformationPropTypes from '../proptypes/DeliveryLocationDistrictPropTypes';
import Table from '../../../../common/Table';
import { DEFAULT_PAGE_SIZE } from '../../../../../config/constants';
import { filterMethodCaseInsensitive } from '../../../../../utils/filter-methods';
import { DELIVERY_DISTRICT_COLUMNS } from '../../../../../config/columns';

const DeliveryLocationDistrictBasicInformationResource = ({
  locationId,
  locationName,
  districtList,
}) => (
  <>
    <ResourceProperty label="Ubicación:">
      {(locationName && locationId && `${locationId} - ${locationName}`) || '-'}
    </ResourceProperty>
    <h3>Distritos</h3>
    <Table
      columns={DELIVERY_DISTRICT_COLUMNS}
      data={districtList}
      defaultPageSize={
        districtList.length <= 10 ? districtList.length : DEFAULT_PAGE_SIZE
      }
      showPagination={districtList.length > 10}
      manual={false}
      filterable
      defaultFilterMethod={filterMethodCaseInsensitive}
    />
  </>
);

DeliveryLocationDistrictBasicInformationResource.propTypes =
  deliveryLocationDistrictBasicInformationPropTypes;

export default DeliveryLocationDistrictBasicInformationResource;
