import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  CARGO_CORPORATE_CREDIT_BUTTON,
  CARGO_CORPORATE_CREDIT_DROPDOWN_OPTIONS,
} from '../../../../config/constants';
import DropdownButton from '../../../common/button/DropdownButton';
import {
  dispatchPush,
  postCargoCorproateCreditExcel,
} from '../../../../actions';
import { CARGO_CORPORATE_CREDIT_PATH } from '../../../../config/paths';
import Modal from '../../../common/modal/Modal';
import CargoCorporateCreditCustomerModalForm from './CargoCorporateCreditCustomerModalForm';

const CargoCorporateCreditDropdownButton = ({
  push,
  cargoCorporateCreditId,
  dispatchPostCargoCorproateCreditExcel,
}) => {
  const [showModal, setshowModal] = useState(false);

  const handleClick = async (value) => {
    switch (value) {
      case CARGO_CORPORATE_CREDIT_BUTTON.CITY.value: {
        push(
          `${CARGO_CORPORATE_CREDIT_PATH}/${cargoCorporateCreditId}/city/new`,
        );
        break;
      }
      case CARGO_CORPORATE_CREDIT_BUTTON.ADD_CUSTOMER.value: {
        push(
          `${CARGO_CORPORATE_CREDIT_PATH}/${cargoCorporateCreditId}/new/customer`,
        );
        break;
      }
      case CARGO_CORPORATE_CREDIT_BUTTON.IMPORT_CUSTOMER.value: {
        setshowModal(true);
        break;
      }
      case CARGO_CORPORATE_CREDIT_BUTTON.COMMON_PRODUCT.value: {
        push(
          `${CARGO_CORPORATE_CREDIT_PATH}/${cargoCorporateCreditId}/common-product/new`,
        );
        break;
      }
      case CARGO_CORPORATE_CREDIT_BUTTON.PARCEL_PRICE_CONFIGURATION.value: {
        push(
          `${CARGO_CORPORATE_CREDIT_PATH}/${cargoCorporateCreditId}/parcel-price-configuration`,
        );
        break;
      }
      case CARGO_CORPORATE_CREDIT_BUTTON.ADD_PARCEL_PRICE.value: {
        push(
          `${CARGO_CORPORATE_CREDIT_PATH}/${cargoCorporateCreditId}/parcel-price-schedule`,
        );
        break;
      }
      default:
        break;
    }
  };

  const handleSubmit = ({ file }) => {
    dispatchPostCargoCorproateCreditExcel({
      cargoCorporateCreditId,
      file,
    });
    setshowModal(false);
  };

  const closeModal = () => {
    setshowModal(false);
  };

  const body = (
    <CargoCorporateCreditCustomerModalForm
      onSubmit={handleSubmit}
      cargoCorporateCreditId={cargoCorporateCreditId}
      onCancel={closeModal}
    />
  );

  return (
    <>
      <DropdownButton
        title="Configuración de Carga"
        color="secondary"
        options={CARGO_CORPORATE_CREDIT_DROPDOWN_OPTIONS}
        handleClick={handleClick}
      />

      <Modal
        title="Agregar Personal Corporativo"
        show={showModal}
        body={body}
        onClickClose={closeModal}
        size="lg"
      />
    </>
  );
};

CargoCorporateCreditDropdownButton.propTypes = {
  push: PropTypes.func.isRequired,
  cargoCorporateCreditId: PropTypes.number.isRequired,
  dispatchPostCargoCorproateCreditExcel: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  push: dispatchPush,
  dispatchPostCargoCorproateCreditExcel: postCargoCorproateCreditExcel,
};

export default connect(
  null,
  mapDispatchToProps,
)(CargoCorporateCreditDropdownButton);
