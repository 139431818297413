import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';
import { Form, FormGroup, Button } from 'reactstrap';
import { Field, FieldArray, reduxForm } from 'redux-form';
import PassengerForm from './PassengerForm';
import './Passengers.css';
import {
  postReservation,
  clearBookingState,
  postThirdPartyReservationWithOtherCustomer,
} from '../../../../actions';
import validate from './validate';
import FormItem from '../../../common/forms/FormItem';
import TextInput from '../../../common/forms/input/TextInput';
import { DEFAULT_FOOD_OPTION } from '../../../../config/defaults';
import {
  MY_RESERVATIONS_UNIT_PATH,
  SEARCH_STEP_PATH,
} from '../../../../config/paths';
import { CIVA_BOOKING_PASSENGERS_STEP_INDEX } from '../../../../config/constants';
import Loader from '../../../common/Loader';
import { orderBy } from '../../../../utils/array';
import PassengerMineForm from './PassengerMineForm';

class Passengers extends Component {
  static renderPassengers({ fields }, isForMine) {
    return fields.map((passenger, index) =>
      isForMine ? (
        <PassengerMineForm
          passenger={passenger}
          key={passenger}
          index={index}
          fields={fields}
        />
      ) : (
        <PassengerForm
          passenger={passenger}
          key={passenger}
          index={index}
          fields={fields}
        />
      ),
    );
  }

  constructor(props) {
    super(props);
    const {
      initialValues: { passengers },
      dispatchReplace,
      dispatchClearBookingState,
      isForMine,
    } = props;

    if (!passengers || !passengers.length) {
      dispatchReplace(
        !isForMine ? SEARCH_STEP_PATH : MY_RESERVATIONS_UNIT_PATH,
      );
    }

    dispatchClearBookingState(CIVA_BOOKING_PASSENGERS_STEP_INDEX);
  }

  componentDidUpdate() {
    const {
      initialValues: { passengers },
      dispatchReplace,
      isForMine,
    } = this.props;

    if (passengers.length === 0) {
      dispatchReplace(
        !isForMine ? SEARCH_STEP_PATH : MY_RESERVATIONS_UNIT_PATH,
      );
    }
  }

  renderPassengerFields = (props) =>
    Passengers.renderPassengers(props, this.props.isForMine);

  render() {
    const {
      handleSubmit,
      submitting,
      postingReservation,
      loadingKidsOnItinerary,
      dispatchPostReservation,
      dispatchPostThirdPartyReservationWithOtherCustomer,
      isForMine,
    } = this.props;

    if (postingReservation) {
      return <Loader />;
    }

    return (
      <div className="container Passengers">
        <Form
          onSubmit={handleSubmit(
            !isForMine
              ? dispatchPostReservation
              : dispatchPostThirdPartyReservationWithOtherCustomer,
          )}
        >
          <FieldArray
            name="passengers"
            component={this.renderPassengerFields}
          />

          {!isForMine && (
            <>
              <hr />
              <FormGroup row>
                <FormItem label="Código de descuento">
                  <Field
                    name="discountCode"
                    component={TextInput}
                    type="text"
                    placeholder="Código de descuento"
                  />
                </FormItem>
              </FormGroup>
            </>
          )}

          <div className="FormButtonGroup">
            <Button
              type="submit"
              className="clickable"
              disabled={submitting || loadingKidsOnItinerary}
              color="primary"
              size="lg"
            >
              Reservar <i className="fa fa-arrow-circle-right" />
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}

Passengers.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  source: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
    ]).isRequired,
    label: PropTypes.string.isRequired,
  }),
  destination: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
    ]).isRequired,
    label: PropTypes.string.isRequired,
  }),
  initialValues: PropTypes.shape({
    passengers: PropTypes.arrayOf(
      PropTypes.shape({
        seatReservation: PropTypes.shape({
          value: PropTypes.number,
          label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
        idCountryOfOrigin: PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string,
        }),
        identificationTypeId: PropTypes.shape({
          value: PropTypes.number,
          label: PropTypes.string,
        }),
        food: PropTypes.shape({
          value: PropTypes.number,
          label: PropTypes.string,
        }),
      }),
    ),
  }).isRequired,
  dispatchReplace: PropTypes.func.isRequired,
  dispatchPostReservation: PropTypes.func.isRequired,
  dispatchClearBookingState: PropTypes.func.isRequired,
  postingReservation: PropTypes.bool.isRequired,
  dispatchPostThirdPartyReservationWithOtherCustomer: PropTypes.func.isRequired,
  loadingKidsOnItinerary: PropTypes.bool,
  isForMine: PropTypes.bool,
};

Passengers.defaultProps = {
  source: null,
  destination: null,
  loadingKidsOnItinerary: false,
  isForMine: false,
};

const mapStateToProps = ({ BookingUnit, ItineraryUnit, authentication }) => {
  const seatReservations = BookingUnit.Booking.getIn([
    'seats',
    'seatReservations',
  ]).toJS();

  const seatReservationsOrdered = orderBy(
    seatReservations,
    ['itinerary.departureTime', 'itineraryId', 'seat.seatNumber'],
    ['asc', 'asc'],
  );

  const passengers = seatReservationsOrdered.map((seatReservation) => ({
    seatReservation: {
      value: seatReservation.id,
      label: seatReservation.seat.seatNumber,
      itinerary: seatReservation.itinerary || null,
      itineraryId: seatReservation.itineraryId || null,
      seatNumber: seatReservation.seat.seatNumber,
      floorNumber: seatReservation.seat.floorNumber,
    },
    food: DEFAULT_FOOD_OPTION,
  }));

  const loadingKidsOnItinerary = !ItineraryUnit.KidsOnItinerary.getIn([
    'current',
    'activity',
  ]).isEmpty();

  const isForMine = !!authentication.get('user').salesSessionUserId;

  return {
    postingReservation: BookingUnit.Booking.getIn([
      'passengers',
      'postingReservation',
    ]),
    initialValues: {
      passengers,
    },
    loadingKidsOnItinerary,
    isForMine,
  };
};

const mapDispatchToProps = {
  dispatchReplace: replace,
  dispatchPostReservation: postReservation,
  dispatchClearBookingState: clearBookingState,
  dispatchPostThirdPartyReservationWithOtherCustomer:
    postThirdPartyReservationWithOtherCustomer,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'PassengersForm',
    validate,
  })(Passengers),
);
