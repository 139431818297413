import React, { useState } from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from '../../../common/modal/Modal';
import AddCommonProductPriceForm from './AddCommonProductPriceForm';
import { postCommonProductPrice } from '../../../../actions';

const CommonProductPriceButton = ({
  commonProductId,
  dispatchPostCommonProductPrice,
}) => {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => setShowModal(true);

  const closeModal = () => setShowModal(false);

  const handleSubmit = ({ file }) => {
    dispatchPostCommonProductPrice({ commonProductId, file });
    closeModal();
  };

  const body = (
    <AddCommonProductPriceForm
      onSubmit={handleSubmit}
      commonProductId={commonProductId}
      onCancel={closeModal}
    />
  );

  return (
    <>
      <Button color="primary" onClick={openModal}>
        Agregar Tarifario
      </Button>
      <Modal
        title="Agregar Tarifario"
        show={showModal}
        body={body}
        onClickClose={closeModal}
        size="lg"
      />
    </>
  );
};

CommonProductPriceButton.propTypes = {
  commonProductId: PropTypes.string.isRequired,
  dispatchPostCommonProductPrice: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchPostCommonProductPrice: postCommonProductPrice,
};

export default connect(null, mapDispatchToProps)(CommonProductPriceButton);
