import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, FormGroup } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { isRequired } from '../../../../utils/validators';
import FormItem from '../../../common/forms/FormItem';
import Loader from '../../../common/Loader';
import FormFooter from '../../../common/forms/FormFooter';
import LocationSelect from '../../../common/forms/select/LocationSelect';
import DistrictSelect from '../../../common/forms/select/DistrictSelect';

export const DeliveryLocationDistrictForm = ({
  initialValues,
  handleSubmit,
  loading,
}) => {
  if (loading) return <Loader />;

  const [editingMode, setEditingMode] = useState(false);

  useLayoutEffect(() => {
    if (initialValues) {
      setEditingMode(true);
    }
  }, []);

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup row>
        <FormItem label="Ubicación" required>
          <Field
            name="location"
            component={LocationSelect}
            validate={[isRequired]}
            isDisabled={editingMode}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Distritos" required>
          <Field
            name="districts"
            component={DistrictSelect}
            validate={[isRequired]}
            isMulti
          />
        </FormItem>
      </FormGroup>
      <FormFooter />
    </Form>
  );
};

DeliveryLocationDistrictForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  initialValues: PropTypes.instanceOf(Object),
};

DeliveryLocationDistrictForm.defaultProps = {
  initialValues: null,
};

const mapStateToProps = ({ CargoUnit }) => ({
  loading: !CargoUnit.DeliveryLocationDistrict.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const formComponent = reduxForm({
  form: 'DeliveryLocationDistrictForm',
})(DeliveryLocationDistrictForm);

export default connect(mapStateToProps)(formComponent);
