import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import RelocationCargoItemByQrButton from './RelocationCargoItemByQrButton';

const RelocationCargoItemToolbar = ({ disabled, locationId }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <RelocationCargoItemByQrButton
        disabled={disabled}
        locationId={locationId}
      />{' '}
    </div>
  </ButtonToolbar>
);

RelocationCargoItemToolbar.propTypes = {
  disabled: PropTypes.bool,
  locationId: PropTypes.number,
};

RelocationCargoItemToolbar.defaultProps = {
  disabled: false,
  locationId: 0,
};

const mapStateToProps = ({ CargoUnit }) => ({
  loadingItemToItinerary: !CargoUnit.ItemToItinerary.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

export default connect(mapStateToProps, null)(RelocationCargoItemToolbar);
