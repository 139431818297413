import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import {
  FLAG_GETTING_CABIN_CREW_CONTRACT_TYPES,
  GET_CABIN_CREW_CONTRACT_TYPES,
  CLEAR_CABIN_CREW_CONTRACT_TYPES,
} from '../types/traffic';
import { CABIN_CREW_CONTRACT_TYPE_ENDPOINT } from '../../config/endpoints';
import { DEFAULT_GET_CONFIG } from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';

const flagGettingCabinCrewContractTypes = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_CABIN_CREW_CONTRACT_TYPES,
    payload: flag,
  });

const getCabinCrewContractTypes = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingCabinCrewContractTypes(true));
    const query = tableFilters;
    const url = `${CABIN_CREW_CONTRACT_TYPE_ENDPOINT}?${QueryString.stringify(
      query,
    )}`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(response, null, dispatch);
    const statuses = await response.json();
    dispatch({
      type: GET_CABIN_CREW_CONTRACT_TYPES,
      payload: statuses,
    });
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagGettingCabinCrewContractTypes(false));
  }
};

const clearCabinCrewContractTypes = () => (dispatch) =>
  dispatch({
    type: CLEAR_CABIN_CREW_CONTRACT_TYPES,
  });

export { getCabinCrewContractTypes, clearCabinCrewContractTypes };
