import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import { DELIVERY_PRICE_CONFIGURATION_PATH } from '../../../../config/paths';
import {
  clearDeliveryLastConfiguration,
  clearDeliveryPriceConfiguration,
  getDeliveryLastConfiguration,
  getDeliveryPriceConfiguration,
} from '../../../../actions';
import DeliveryPriceConfigurationBasicInformationResource from './resource/DeliveryPriceConfigurationBasicInformationResource';
import DeliveryPriceConfigurationToolbar from './DeliveryPriceConfigurationToolbar';

export const DeliveryPriceConfiguration = ({
  breadcrumbs,
  dispatchGetDeliveryPriceConfiguration,
  dispatchClearDeliveryPriceConfiguration,
  match: {
    params: { id: deliveryPriceConfigurationId },
  },
  loading,
  deliveryPriceConfiguration,
  lastConfiguration,
  loadingLastConfiguration,
  dispatchClearDeliveryLastConfiguration,
  dispatchGetDeliveryLastConfiguration,
}) => {
  useLayoutEffect(() => {
    dispatchGetDeliveryPriceConfiguration({ deliveryPriceConfigurationId });
    dispatchGetDeliveryLastConfiguration();

    return () => {
      dispatchClearDeliveryLastConfiguration();
      dispatchClearDeliveryPriceConfiguration();
    };
  }, []);

  let content;
  let toolbar;

  if (loading || loadingLastConfiguration) content = <Loader />;
  else if (deliveryPriceConfiguration.isEmpty())
    content = <NoDataResource returnPage={DELIVERY_PRICE_CONFIGURATION_PATH} />;
  else {
    const deliveryPriceConfigurationJson = deliveryPriceConfiguration.toJS();

    content = (
      <DeliveryPriceConfigurationBasicInformationResource
        {...deliveryPriceConfigurationJson}
      />
    );

    toolbar =
      lastConfiguration.get('id') === +deliveryPriceConfigurationId ? (
        <DeliveryPriceConfigurationToolbar
          deliveryPriceConfigurationId={deliveryPriceConfigurationJson.id}
        />
      ) : null;
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      toolbar={toolbar}
      title="Configuración de Precio de Reparto"
      content={content}
    />
  );
};

DeliveryPriceConfiguration.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchClearDeliveryPriceConfiguration: PropTypes.func.isRequired,
  dispatchClearDeliveryLastConfiguration: PropTypes.func.isRequired,
  dispatchGetDeliveryPriceConfiguration: PropTypes.func.isRequired,
  dispatchGetDeliveryLastConfiguration: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  loadingLastConfiguration: PropTypes.bool,
  match: matchPropTypes.isRequired,
  deliveryPriceConfiguration: PropTypes.instanceOf(Immutable.Map).isRequired,
  lastConfiguration: PropTypes.instanceOf(Immutable.Map).isRequired,
};

DeliveryPriceConfiguration.defaultProps = {
  loading: false,
  loadingLastConfiguration: false,
};

const mapDispatchToProps = {
  dispatchClearDeliveryPriceConfiguration: clearDeliveryPriceConfiguration,
  dispatchClearDeliveryLastConfiguration: clearDeliveryLastConfiguration,
  dispatchGetDeliveryPriceConfiguration: getDeliveryPriceConfiguration,
  dispatchGetDeliveryLastConfiguration: getDeliveryLastConfiguration,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Configuraciones de Precio de Reparto',
      href: DELIVERY_PRICE_CONFIGURATION_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  deliveryPriceConfiguration: CargoUnit.DeliveryPriceConfiguration.getIn([
    'current',
    'content',
  ]),
  loading: !CargoUnit.DeliveryPriceConfiguration.getIn([
    'current',
    'activity',
  ]).isEmpty(),
  lastConfiguration: CargoUnit.DeliveryPriceConfiguration.getIn([
    'last',
    'content',
  ]),
  loadingLastConfiguration: CargoUnit.DeliveryPriceConfiguration.getIn([
    'last',
    'loading',
  ]),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeliveryPriceConfiguration);
