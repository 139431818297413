import React, { useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import Content from '../../../layout/Content';
import { CARGO_CORPORATE_CREDIT_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import CargoCorporateCreditCustomerForm from './CargoCorporateCreditCustomerForm';
import {
  clearCargoCorporateCredit,
  getCargoCorporateCredit,
  postAssignmentCustomerToCargoCorporateCreditCity,
} from '../../../../actions/cargo/CargoCorporateCredit';
import NoDataResource from '../../../common/resource/NoDataResource';
import Loader from '../../../common/Loader';

const NewCargoCorporateCreditCustomer = ({
  breadcrumbs,
  dispatchGetCargoCorporateCredit,
  dispatchClearCargoCorporateCredit,
  match: {
    params: { id: cargoCorporateCreditId },
  },
  cargoCorporateCredit,
  loading,
  dispatchPostAssignmentCustomerToCargoCorporateCreditCity,
}) => {
  useLayoutEffect(() => {
    dispatchGetCargoCorporateCredit({ cargoCorporateCreditId });
    return () => dispatchClearCargoCorporateCredit();
  }, []);

  const handleSubmit = ({ customerList }) => {
    const newCustomerList = customerList.map((customerEntry) => ({
      ...customerEntry,
      customerId: customerEntry.customer.id,
    }));

    dispatchPostAssignmentCustomerToCargoCorporateCreditCity(
      cargoCorporateCreditId,
      newCustomerList,
    );
  };

  const transformCustomerList = (customerList) => {
    const customerMap = new Map();

    customerList.forEach(({ value, label, customerType, city }) => {
      if (!customerMap.has(value)) {
        customerMap.set(value, {
          customer: { label, value, id: value },
          cityList: [],
        });
      }

      customerMap.get(value).cityList.push({
        id: city.id,
        city: city.name,
        sender:
          customerType === 'SENDER' || customerType === 'SENDER_CONSIGNEE',
        consignee:
          customerType === 'CONSIGNEE' || customerType === 'SENDER_CONSIGNEE',
      });
    });

    return Array.from(customerMap.values());
  };

  const generateInitialValues = (cargoCorporateCreditJson) => {
    const cityList = cargoCorporateCreditJson.cargoCorporateCreditCityList.map(
      ({ city }) => city,
    );

    const customerMap = new Map();

    cargoCorporateCreditJson.cargoCorporateCreditCityList.forEach(
      ({ customerList, city }) => {
        customerList.forEach(({ customer, customerType }) => {
          const key = customer.id;

          if (!customerMap.has(key)) {
            customerMap.set(key, {
              value: customer.id,
              label: customer.fullName,
              cities: new Map(),
            });
          }

          customerMap.get(key).cities.set(city.id, customerType);
        });
      },
    );

    const customerList = Array.from(customerMap.values()).flatMap(
      ({ value, label, cities }) =>
        cityList.map((city) => ({
          value,
          label,
          customerType: cities.get(city.id) || null,
          city,
        })),
    );

    return { customerList: transformCustomerList(customerList) };
  };

  let content;

  if (loading) content = <Loader />;
  else if (cargoCorporateCredit.isEmpty())
    content = <NoDataResource returnPage={CARGO_CORPORATE_CREDIT_PATH} />;
  else {
    const cargoCorporateCreditJson = cargoCorporateCredit.toJS();
    const cityList = cargoCorporateCreditJson.cargoCorporateCreditCityList.map(
      ({ city }) => city,
    );
    content = (
      <CargoCorporateCreditCustomerForm
        onSubmit={handleSubmit}
        initialValues={generateInitialValues(cargoCorporateCreditJson)}
        cityList={cityList}
      />
    );
  }

  return (
    <Content
      title="Asociar Clientes a Ciudades"
      content={content}
      breadcrumbs={breadcrumbs}
    />
  );
};

NewCargoCorporateCreditCustomer.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetCargoCorporateCredit: PropTypes.func.isRequired,
  dispatchClearCargoCorporateCredit: PropTypes.func.isRequired,
  match: matchPropTypes.isRequired,
  cargoCorporateCredit: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool,
  dispatchPostAssignmentCustomerToCargoCorporateCreditCity:
    PropTypes.func.isRequired,
};

NewCargoCorporateCreditCustomer.defaultProps = {
  loading: false,
};

const mapStateToProps = (
  { CargoUnit },
  {
    match: {
      params: { id: cargoCorporateCreditId },
    },
  },
) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Créditos Corporativos',
      href: CARGO_CORPORATE_CREDIT_PATH,
    },
    {
      text: 'Ver',
      href: `${CARGO_CORPORATE_CREDIT_PATH}/${cargoCorporateCreditId}`,
    },
    {
      text: 'Asignar clientes a las ubicaciones de crédito corporativo',
      href: '',
    },
  ],
  cargoCorporateCredit: CargoUnit.CargoCorporateCredit.getIn([
    'current',
    'content',
  ]),
  loading: !CargoUnit.CargoCorporateCredit.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetCargoCorporateCredit: getCargoCorporateCredit,
  dispatchClearCargoCorporateCredit: clearCargoCorporateCredit,
  dispatchPostAssignmentCustomerToCargoCorporateCreditCity:
    postAssignmentCustomerToCargoCorporateCreditCity,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewCargoCorporateCreditCustomer);
