import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import { postDeliveryLocationDistrict } from '../../../../actions';
import { DELIVERY_LOCATION_DISTRICT_PATH } from '../../../../config/paths';
import DeliveryLocationDistrictForm from './DeliveryLocationDistrictForm';

const NewDeliveryLocationDistrict = ({
  breadcrumbs,
  dispatchPostDeliveryLocationDistrict,
}) => {
  const onSubmit = (formValues) =>
    dispatchPostDeliveryLocationDistrict({
      locationId: formValues.location.value,
      districtIds: formValues.districts.map((district) => district.value),
    });

  const content = <DeliveryLocationDistrictForm onSubmit={onSubmit} />;

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Nueva Ubicación con Distritos Autorizados para Reparto"
      subtitle="Crear nuevo material"
      content={content}
    />
  );
};

NewDeliveryLocationDistrict.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostDeliveryLocationDistrict: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchPostDeliveryLocationDistrict: postDeliveryLocationDistrict,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Ubicaciones con Distritos Autorizados para Reparto',
      href: DELIVERY_LOCATION_DISTRICT_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewDeliveryLocationDistrict);
