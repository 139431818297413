import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_DELETE_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import {
  CLEAR_DELIVERY_PRICE_CONFIGURATION,
  CLEAR_DELIVERY_PRICE_CONFIGURATIONS,
  CLEAR_DELIVERY_LAST_CONFIGURATION,
  FLAG_DELIVERY_PRICE_CONFIGURATION_ACTIVITY,
  FLAG_GETTING_DELIVERY_PRICE_CONFIGURATIONS,
  FLAG_GETTING_DELIVERY_LAST_CONFIGURATION,
  GET_DELIVERY_PRICE_CONFIGURATION,
  GET_DELIVERY_PRICE_CONFIGURATIONS,
  GET_DELIVERY_LAST_CONFIGURATION,
} from '../types';
import { DELIVERY_PRICE_CONFIGURATION_PATH } from '../../config/paths';
import { DELIVERY_PRICE_CONFIGURATION_ENDPOINT } from '../../config/endpoints';

const flagGettingDeliveryPriceConfigurations = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_DELIVERY_PRICE_CONFIGURATIONS,
    payload: flag,
  });

const getDeliveryPriceConfigurations =
  async (tableFilters) => async (dispatch) => {
    try {
      dispatch(flagGettingDeliveryPriceConfigurations(true));
      const query = tableFilters;
      const url = `${DELIVERY_PRICE_CONFIGURATION_ENDPOINT}?${QueryString.stringify(
        query,
      )}`;
      const promise = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(promise, null, dispatch);
      const response = await promise.json();
      dispatch({
        type: GET_DELIVERY_PRICE_CONFIGURATIONS,
        payload: response,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagGettingDeliveryPriceConfigurations(false));
    }
  };

const clearDeliveryPriceConfigurations = () => (dispatch) =>
  dispatch({
    type: CLEAR_DELIVERY_PRICE_CONFIGURATIONS,
  });

const flagDeliveryPriceConfigurationActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_DELIVERY_PRICE_CONFIGURATION_ACTIVITY,
    payload: flag,
  });

const getDeliveryPriceConfiguration =
  async ({ deliveryPriceConfigurationId }) =>
  async (dispatch) => {
    try {
      dispatch(flagDeliveryPriceConfigurationActivity(true));
      const url = `${DELIVERY_PRICE_CONFIGURATION_ENDPOINT}/${deliveryPriceConfigurationId}`;
      const promise = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(promise, null, dispatch);
      const response = await promise.json();
      dispatch({
        type: GET_DELIVERY_PRICE_CONFIGURATION,
        payload: response,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagDeliveryPriceConfigurationActivity(false));
    }
  };

const clearDeliveryPriceConfiguration = () => (dispatch) =>
  dispatch({
    type: CLEAR_DELIVERY_PRICE_CONFIGURATION,
  });

const postDeliveryPriceConfiguration =
  async ({ minKilograms, maxKilograms }) =>
  async (dispatch) => {
    try {
      dispatch(flagDeliveryPriceConfigurationActivity(true));
      const payload = {
        minKilograms,
        maxKilograms,
      };
      const url = DELIVERY_PRICE_CONFIGURATION_ENDPOINT;
      const promise = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise, null, dispatch);
      await promise.json();
      dispatch(push(DELIVERY_PRICE_CONFIGURATION_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagDeliveryPriceConfigurationActivity(false));
    }
  };

const deleteDeliveryPriceConfiguration =
  async ({ deliveryPriceConfigurationId }) =>
  async (dispatch) => {
    try {
      dispatch(flagDeliveryPriceConfigurationActivity(true));
      const url = `${DELIVERY_PRICE_CONFIGURATION_ENDPOINT}/${deliveryPriceConfigurationId}`;
      const promise = await fetch(url, {
        ...DEFAULT_DELETE_CONFIG,
      });
      await isErrorResponse(promise, null, dispatch);
      dispatch(push(DELIVERY_PRICE_CONFIGURATION_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagDeliveryPriceConfigurationActivity(false));
    }
  };

const putDeliveryPriceConfiguration =
  async (deliveryPriceConfigurationId, { minKilograms, maxKilograms }) =>
  async (dispatch) => {
    try {
      dispatch(flagDeliveryPriceConfigurationActivity(true));
      const payload = {
        id: deliveryPriceConfigurationId,
        minKilograms,
        maxKilograms,
      };
      const url = `${DELIVERY_PRICE_CONFIGURATION_ENDPOINT}/${deliveryPriceConfigurationId}`;
      const promise = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise, null, dispatch);
      const response = await promise.json();
      dispatch(push(`${DELIVERY_PRICE_CONFIGURATION_PATH}/${response.id}`));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagDeliveryPriceConfigurationActivity(false));
    }
  };

const flagGettingDeliveryLastConfiguration = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_DELIVERY_LAST_CONFIGURATION,
    payload: flag,
  });

const getDeliveryLastConfiguration = async () => async (dispatch) => {
  try {
    dispatch(flagGettingDeliveryLastConfiguration(true));
    const url = `${DELIVERY_PRICE_CONFIGURATION_ENDPOINT}/last-configuration`;
    const promise = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(promise, null, dispatch);
    const contentType = promise.headers.get('Content-Type');
    let response = null;
    if (contentType && contentType.includes('application/json')) {
      try {
        response = await promise.json();
      } catch (error) {
        console.error(error);
      }
    }
    if (response) {
      dispatch({
        type: GET_DELIVERY_LAST_CONFIGURATION,
        payload: response,
      });
    }
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagGettingDeliveryLastConfiguration(false));
  }
};

const clearDeliveryLastConfiguration = () => (dispatch) =>
  dispatch({
    type: CLEAR_DELIVERY_LAST_CONFIGURATION,
  });

export {
  flagGettingDeliveryPriceConfigurations,
  getDeliveryPriceConfigurations,
  clearDeliveryPriceConfigurations,
  flagDeliveryPriceConfigurationActivity,
  getDeliveryPriceConfiguration,
  postDeliveryPriceConfiguration,
  clearDeliveryPriceConfiguration,
  deleteDeliveryPriceConfiguration,
  putDeliveryPriceConfiguration,
  getDeliveryLastConfiguration,
  clearDeliveryLastConfiguration,
};
